import { CHANCELLERIES_ENDPOINT } from 'common/constants'
import { request } from 'common/utils'

import query from '../graphql/getChancellery.graphql'
import { GetChancelleryQuery } from '../interfaces/schemaDefinition'
import { mapChancelleryAPIDataToForm } from '../utils'

export const getChancelleryData = async (id: string): Promise<ReturnType<typeof mapChancelleryAPIDataToForm>> => {
  const { chancellery } = await request<GetChancelleryQuery>(CHANCELLERIES_ENDPOINT, query, { id })
  return mapChancelleryAPIDataToForm(chancellery)
}
