import classnames from 'classnames'
import React, { FC } from 'react'

import './FieldRow.scss'

interface FieldRowProps {
  className?: string
  style?: React.CSSProperties | undefined
}

export const FieldRow: FC<FieldRowProps> = ({ className, style, children }) => (
  <div className={classnames('field-row', className)} style={style}>
    {children}
  </div>
)
