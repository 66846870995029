import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  FileDownload,
  Save,
  Delete,
  Check,
  Edit,
  Close as Exit,
  AttachFile as Attachment,
  ControlPoint as New,
} from '@mui/icons-material'
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import React, { ChangeEvent, FC, useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'

import { API_BASE, FILE_BASE } from 'app/constants'
import { PageLayout } from 'common/components-mui'
import { Select, TextField } from 'common/components-mui/react-hook-form'
import { currencyFormatter, formatDate, Procedure } from 'common/utils'
import { fetchDataWith } from 'common/utils/fetchDataWith'

import { assignTo, saveAdviceRequest, fetchAssignableUsers, createSalesDetailsExport } from '../actions'
import { SalesStatusLabel } from '../components/SalesStatusLabel'
import { StaticField } from '../components/StaticField'
import { AdviceRequest, Revenue, SaveAdviceRequestInput } from '../interfaces/schemaDefinition'
import { PARTNER_LOGOS } from '../utils/getPartnerLogos'

interface SalesDetailViewProps {
  adviceRequest: AdviceRequest
  onAddSalesClick: Procedure
  onEditSalesClick: (sales: Revenue) => void
  onDeleteSalesClick: (sales: Revenue) => void
  onCompleteClick: Procedure
  onAddFilesChange: (event: ChangeEvent<HTMLInputElement>) => void
  onDeleteFileClick: (fileId: string) => void
}

const useAssignableUsers = fetchDataWith(fetchAssignableUsers)

// eslint-disable-next-line complexity
export const SalesDetailView: FC<SalesDetailViewProps> = ({
  adviceRequest,
  onAddSalesClick,
  onEditSalesClick,
  onDeleteSalesClick,
  onCompleteClick,
  onAddFilesChange,
  onDeleteFileClick,
}) => {
  const { data: assignableUsers } = useAssignableUsers()
  const [assignTarget, setAssignTarget] = useState<{ id: string; name: string } | undefined>(
    adviceRequest.assignedTo || undefined
  )
  const [editMode, setEditMode] = useState<boolean>(false)
  const [saving, setSaving] = useState<boolean>(false)
  const [adviceDetail, setAdviceDetail] = useState<SaveAdviceRequestInput>()
  const [link, setLink] = useState<string>('')
  const initialValues = {
    mandateName: adviceRequest.mandateName,
    assignedTo: adviceRequest.assignedTo ? adviceRequest.assignedTo.id : '',
    person: adviceRequest.person,
  }

  const onSubmit: SubmitHandler<typeof initialValues> = async (values): Promise<void> => {
    setSaving(true)
    const newAdviceDetail = {
      ...adviceDetail,
      mandateName: values.mandateName,
      person: {
        ...values.person,
        firstname: values.person.firstname,
        lastname: values.person.lastname,
      },
    }
    if (values.assignedTo) {
      await assignTo(adviceRequest.id, values.assignedTo)
      const assignedTo = assignableUsers.find(user => user.id === values.assignedTo)
      setAssignTarget(assignedTo)
    }
    await saveAdviceRequest(newAdviceDetail as SaveAdviceRequestInput)
    setAdviceDetail(newAdviceDetail as SaveAdviceRequestInput)
    setSaving(false)
    setEditMode(false)
  }

  const { control, handleSubmit, reset } = useForm({
    defaultValues: initialValues,
  })

  useEffect(() => {
    setAdviceDetail({
      id: adviceRequest.id,
      fieldOfLawId: adviceRequest.fieldOfLaw ? adviceRequest.fieldOfLaw.id : '',
      language: 'de',
      description: adviceRequest.description,
      person: {
        foa: adviceRequest.person.foa,
        firstname: adviceRequest.person.firstname,
        lastname: adviceRequest.person.lastname,
        address: {
          street: adviceRequest.person.address.street,
          streetNr: adviceRequest.person.address.streetNr,
          city: adviceRequest.person.address.city,
          zip: adviceRequest.person.address.zip,
        },
        phone: adviceRequest.person.phone,
        email: adviceRequest.person.email || '',
      },
      company: adviceRequest.company,
      billingCompany: adviceRequest.billingCompany,
      externalNotes: adviceRequest.externalNotes,
      internalNotes: adviceRequest.internalNotes,
      resultNotes: adviceRequest.resultNotes,
      lawyerNotes: adviceRequest.lawyerNotes,
      insured: adviceRequest.insured,
      insuranceNumber: adviceRequest.insuranceNumber,
      legalProtectionInsurer: adviceRequest.legalProtectionInsurer,
      caseData: adviceRequest.caseData,
      agbsAccepted: adviceRequest.agbsAccepted,
      dataAccepted: adviceRequest.dataAccepted,
      partnerId: adviceRequest.partner ? adviceRequest.partner.id : '',
      mandateName: adviceRequest.mandateName,
    })
    reset({
      mandateName: adviceRequest.mandateName,
      assignedTo: adviceRequest.assignedTo ? adviceRequest.assignedTo.id : '',
      person: adviceRequest.person,
    })
  }, [adviceRequest, reset])

  const getLogo = (): string | JSX.Element => {
    if (!adviceRequest.partner) {
      return '-'
    }

    const Logo = PARTNER_LOGOS[adviceRequest.partner.id]

    return !!Logo ? <Logo /> : adviceRequest.partner.name
  }

  const handleExportSalesDetails = async (): Promise<void> => {
    const nextLink = await createSalesDetailsExport({ adviceRequestId: adviceRequest.id })
    setLink(nextLink)
  }

  return (
    <PageLayout heading={`Umsatzverwaltung für ${adviceRequest.adviceId}`}>
      <Box sx={{ position: 'relative' }} component="form" onSubmit={handleSubmit(onSubmit)}>
        <Typography sx={{ textAlign: 'center' }} component="h3" variant="h5">
          {editMode ? 'Falldetails bearbeiten' : 'Falldetails'}
        </Typography>
        <Box display="flex" gap={2} sx={{ position: 'absolute', right: 0, top: -2 }}>
          {!editMode ? (
            <Button key="edit" variant="contained" startIcon={<Edit width="16" height="16" />} onClick={() => setEditMode(true)}>
              Bearbeiten
            </Button>
          ) : (
            <>
              <Button
                key="save"
                variant="contained"
                startIcon={saving ? <CircularProgress size={16} color="inherit" /> : <Save height="16" width="16" />}
                type="submit"
              >
                Speichern
              </Button>
              <Button
                variant="outlined"
                startIcon={<Exit height="16" width="16" />}
                onClick={() => {
                  reset()
                  setEditMode(false)
                }}
              >
                Abbrechen
              </Button>
            </>
          )}
        </Box>
        <Box gap={2} display="flex" justifyContent="space-between" alignItems="flex-end" sx={{ minHeight: '4rem' }}>
          {!editMode ? (
            <>
              <StaticField label="Partner" value={getLogo()} />
              <StaticField label="Beratungs-ID" value={adviceRequest.adviceId} />
              <StaticField label="Aktenname" value={adviceDetail?.mandateName || '-'} />
              <StaticField label="Bearbeiter" value={assignTarget?.name || '-'} />
              <StaticField label="Mandantenname" value={`${adviceDetail?.person.firstname} ${adviceDetail?.person.lastname}`} />
              <StaticField label="Rechtsgebiet" value={adviceRequest.fieldOfLaw?.name || '-'} />
              <StaticField
                label="Status"
                value={<SalesStatusLabel status={adviceRequest.status} revenue={adviceRequest.revenue!} />}
              />
            </>
          ) : (
            <>
              <TextField fullWidth size="small" control={control} label="Aktenname" name="mandateName" />
              <Select size="small" control={control} name="assignedTo" label="Bearbeiter">
                {[
                  ...(assignTarget && !assignTarget.id ? [{ value: '', label: '---Bitte wählen---' }] : []),
                  ...assignableUsers.map(user => ({
                    value: user.id,
                    label: user.name,
                  })),
                ].map(option => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Select>
              <TextField fullWidth size="small" control={control} label="Mandantenvorname" name="person.firstname" />
              <TextField fullWidth size="small" control={control} label="Mandantennachname" name="person.lastname" />
            </>
          )}
        </Box>
      </Box>

      <Typography mt={4} sx={{ textAlign: 'center' }} component="h3" variant="h5">
        Umsatzdetails
      </Typography>
      {adviceRequest.sales && adviceRequest.sales.entries.length > 0 ? (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Rechnungsnummer</TableCell>
                <TableCell>Erfassungsdatum</TableCell>
                <TableCell>Betrag (netto)</TableCell>
                <TableCell>Optionen</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {(adviceRequest?.sales?.entries ?? []).map(sales => (
                <TableRow key={sales.id}>
                  <TableCell>{sales.invoiceNumber || '-'}</TableCell>
                  <TableCell>{formatDate(new Date(sales.createdAt))}</TableCell>
                  <TableCell>{currencyFormatter(sales.revenue)}</TableCell>
                  <TableCell>
                    <IconButton
                      size="large"
                      color="primary"
                      onClick={() => {
                        setLink('')
                        onEditSalesClick(sales)
                      }}
                    >
                      <Edit width="18" height="18" />
                    </IconButton>
                    <IconButton
                      size="large"
                      color="primary"
                      onClick={() => {
                        setLink('')
                        onDeleteSalesClick(sales)
                      }}
                    >
                      <Delete width="16" height="16" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell colSpan={2} />
                <TableCell>Gesamtumsatz</TableCell>
                <TableCell>{currencyFormatter(adviceRequest.revenue)}</TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      ) : (
        <Typography sx={{ textAlign: 'center', color: '#9c9c9c' }} component="h3" variant="h6">
          Keine Umsätze vorhanden
        </Typography>
      )}
      <Box mt={2} display="flex" justifyContent="center" alignItems="center" gap={1}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setLink('')
            onAddSalesClick()
          }}
          startIcon={<New height="16" width="16" />}
        >
          Umsatz hinzufügen
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setLink('')
            onCompleteClick()
          }}
          startIcon={<Check height="16" width="16" />}
          disabled={!!(adviceRequest.status === 'complete' && (adviceRequest.revenue === 0 || adviceRequest.revenue))}
        >
          {adviceRequest.status === 'complete' && !adviceRequest.revenue ? 'Kein Mandat' : 'Akte schließen'}
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleExportSalesDetails}
          startIcon={<FileDownload height="18" width="18" />}
          disabled={!adviceRequest.sales}
        >
          Umsätze exportieren
        </Button>
      </Box>
      <Box display="flex" alignItems="center" justifyContent="center">
        {link && (
          <Link sx={{ textAlign: 'center' }} mt={4} href={`${FILE_BASE}/${link}`} download>
            <FontAwesomeIcon icon="file-excel" size="3x" />
            <Typography mt={1} variant="body1">
              CSV-Export herunterladen
            </Typography>
          </Link>
        )}
      </Box>

      <Typography mt={4} sx={{ textAlign: 'center' }} component="h3" variant="h5">
        Anhänge (optional)
      </Typography>
      {adviceRequest.sales && adviceRequest.sales.files.length > 0 ? (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Anhänge</TableCell>
                <TableCell> </TableCell>
                <TableCell>Download</TableCell>
                <TableCell>Löschen</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {adviceRequest.sales.files.map(file => (
                <TableRow key={file.id}>
                  <TableCell colSpan={2}>{file.name}</TableCell>
                  <TableCell>
                    <IconButton size="large" component="a" href={`${API_BASE}/files/${file.id}/${file.name}`}>
                      <FileDownload height="16" width="16" />
                    </IconButton>
                  </TableCell>
                  <TableCell>
                    <IconButton size="large" onClick={() => onDeleteFileClick(file.id)}>
                      <Delete width="16" height="16" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Typography sx={{ textAlign: 'center', color: '#9c9c9c' }} component="h3" variant="h6">
          Keine Anhänge vorhanden
        </Typography>
      )}
      <Box mt={2} display="flex" justifyContent="center">
        <Button component="label" variant="contained" color="primary" startIcon={<Attachment width="16" height="16" />}>
          Anhang hinzufügen
          <input type="file" onChange={onAddFilesChange} style={{ display: 'none' }} multiple name="attachment" id="attachment" />
        </Button>
      </Box>
    </PageLayout>
  )
}
