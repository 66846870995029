import { AdviceRequest } from '../interfaces/AdviceRequest'

export const validateOpenContact =
  (setErrors: (errors: { [prop: string]: string }) => void, adviceRequest: AdviceRequest) => () => {
    const errors: { [prop: string]: string } = {}
    if (!adviceRequest.damageNumber && !adviceRequest.insuranceNumber) {
      errors.insuranceNumber =
        'Es muss eine Versicherungsscheinnummer oder eine Schadensnummer vorhanden sein um eine Rechnung/ Vorschuss zu versenden'
    }

    if (adviceRequest.legalProtectionInsurer !== 'DEVK') {
      errors.legalProtectionInsurer =
        'Es kann nur für einen von der DEVK versicherten Fall eine Rechnung/ Vorschuss verschickt werden'
    }

    if (adviceRequest.insuranceNumber && !/^\d{9}$/.test(adviceRequest.insuranceNumber.replace(/ /g, ''))) {
      errors.insuranceNumber = 'Die Versicherungsscheinnummer muss aus 9 Zahlen bestehen'
    }

    if (Object.keys(errors).length) {
      setErrors(errors)
      return false
    }

    return true
  }

export const validateOpenCoverageRequest =
  (setErrors: (errors: { [prop: string]: string }) => void, adviceRequest: AdviceRequest) => () => {
    const errors: { [prop: string]: string } = {}
    if (!adviceRequest.damageNumber && !adviceRequest.insuranceNumber) {
      errors.insuranceNumber =
        'Es muss eine Versicherungsscheinnummer oder eine Schadensnummer vorhanden sein um eine Deckungsanfrage zu versenden'
    }

    if (adviceRequest.legalProtectionInsurer !== 'DEVK') {
      errors.legalProtectionInsurer =
        'Es kann nur für einen von der DEVK versicherten Fall eine Deckungsanfrage verschickt werden'
    }

    if (Object.keys(errors).length) {
      setErrors(errors)
      return false
    }

    return true
  }
