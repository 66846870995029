import { Spinner } from '@grapes-agency/ui'
import { Box, Button, Divider, Stack, Typography } from '@mui/material'
import React, { FC, useEffect, useState } from 'react'

import { useQuery } from 'common/hooks'
import LogoDEVK from 'common/icons/devk_partner.svg'
import SVGKlugoLogo from 'common/icons/klugo-logo-original.svg'
import { enqueueSnackbar } from 'common/utils'
import { SigninBox } from 'packages-mui/login/components'
import { getAzureAuthUrl } from 'packages-mui/login-azure/actions/getAzureAuthUrl'
import theme from 'theme/theme'

import SVGMicrosoft from '../assets/microsoft.svg'

const AzureLoginButton: FC = () => {
  const [azureUrlServer, setAzureUrlServer] = useState<string | null>(null)
  const { loginError } = useQuery<{ loginError?: string }>()
  useEffect(() => {
    if (loginError) {
      enqueueSnackbar('Loginversuch war leider nicht erfolgreich.', { variant: 'error' })
    }
    // REST-API Endpoint in services used for azure redirect.
    // Path from AZURE_SERVER_REDIRECT_URI env variable
    getAzureAuthUrl({ url: new URL('/api/login-azure', window.location.origin).href }).then(setAzureUrlServer)
  }, [loginError])
  return azureUrlServer ? (
    <a href={azureUrlServer}>
      <Button sx={{ mt: 1 }} variant="loginContained" fullWidth type="button" startIcon={<SVGMicrosoft />}>
        Über Microsoft einloggen
      </Button>
    </a>
  ) : (
    <Button disabled sx={{ mt: 1 }} variant="loginContained" fullWidth type="button" startIcon={<SVGMicrosoft />}>
      <Spinner />
    </Button>
  )
}

export const LoginBoxAzure: FC = () => (
  <SigninBox>
    <Stack alignItems="center">
      <SVGKlugoLogo width="124" height="40" />
      <Typography variant="loginTitle">Antragsverwaltung</Typography>
    </Stack>
    <Box
      display="flex"
      gap={theme.spacing(3)}
      alignItems="center"
      justifyContent="center"
      my={theme.spacing(2.5)}
      mx={theme.spacing(1)}
    >
      <Divider color="GrayText" sx={{ flexGrow: 1, borderBottomWidth: 0.5 }} />
      <Typography variant="subtitle2" color="GrayText" fontWeight={400} letterSpacing="1px">
        <LogoDEVK />
      </Typography>
      <Divider color="GrayText" sx={{ flexGrow: 1, borderBottomWidth: 0.5 }} />
    </Box>
    <AzureLoginButton />
  </SigninBox>
)
