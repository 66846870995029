import SaveIcon from '@mui/icons-material/Save'
import { Button, ButtonProps } from '@mui/material'
import React, { FunctionComponent } from 'react'

import { useFormActionButtonStyles } from '../helpers'

interface SaveButtonProps extends ButtonProps {
  submit?: boolean
}

// eslint-disable-next-line fp/no-rest-parameters
export const SaveButton: FunctionComponent<SaveButtonProps> = ({ submit = true, children, ...rest }) => (
  <Button
    variant="contained"
    color="primary"
    type={submit ? 'submit' : 'button'}
    startIcon={<SaveIcon />}
    sx={useFormActionButtonStyles}
    {...rest}
  >
    {children || 'Speichern'}
  </Button>
)
