import {
  Button as MuiButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Typography,
  Box,
} from '@mui/material'
import React, { FunctionComponent } from 'react'

import { UserContextProps, withUser } from 'common/user-context'

import SVGExit from '../images/Exit.svg'
import SVGUserNegative from '../images/User-Negative.svg'

import SVGLogout from './images/logout.svg'

type ProfileProps = UserContextProps

const Profile: FunctionComponent<ProfileProps> = ({
  user,
  signOut,
  userProfile,
  onConfirmSignOut,
  confirmSignOut,
  onCancelSignOut,
}: UserContextProps) => {
  if (!user) {
    return null
  }

  return (
    <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'flex-end' }}>
      <MuiButton
        onClick={userProfile}
        startIcon={<SVGUserNegative width={16} height={16} />}
        color="primary"
        variant="contained"
        disableElevation
        sx={{
          display: { xs: 'none', md: 'flex' },
          '&:focus': {
            outline: 'none',
          },
        }}
      >
        <Typography variant="button">{user.name}</Typography>
      </MuiButton>
      <IconButton
        onClick={signOut}
        sx={{
          '&:focus': {
            outline: 'none',
          },
          ml: 2,
          bgcolor: 'white',
          '&:hover': {
            bgcolor: 'white',
          },
        }}
        color="inherit"
        size="large"
      >
        <SVGExit width={18} height={18} />
      </IconButton>
      <Dialog open={confirmSignOut}>
        <DialogTitle>Ausloggen</DialogTitle>
        <DialogContent>
          <DialogContentText>Möchten Sie sich wirklich ausloggen?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <MuiButton color="primary" variant="outlined" onClick={onConfirmSignOut} endIcon={<SVGLogout width="22" height="22" />}>
            Ja, ausloggen
          </MuiButton>
          <MuiButton onClick={onCancelSignOut}>Zurück</MuiButton>
        </DialogActions>
      </Dialog>
    </Box>
  )
}

const ProfileWithUser = withUser(Profile)

export { ProfileWithUser as Profile }
