import { zodResolver } from '@hookform/resolvers/zod'
import LockIcon from '@mui/icons-material/Lock'
import PersonIcon from '@mui/icons-material/Person'
import { Box, Divider, InputAdornment, Stack, Typography, Button } from '@mui/material'
import React, { useEffect, useContext, useState, FC } from 'react'
import { FieldError, useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'

import { Roles } from 'common/constants'
import SVGKlugoLogo from 'common/icons/klugo-logo-original.svg'
import { Role } from 'common/interfaces'
import { LoggedInUser, UserContext, UserContextProps } from 'common/user-context'
import { containsSome } from 'common/utils'
import { loginUser } from 'packages-mui/login/actions/loginUser'
import { LoginFormFields } from 'packages-mui/login/interfaces/FormFields'

import { Link, LoginTextField, SigninBox } from '../components'
import { loginSchema } from '../constants'

const isAdminOrEmployee = containsSome([Roles.Administrator, Roles.Employee])
const isChannelOrTRB = containsSome([Roles.Channel, Roles.TRB, Roles.TRBManager])
const isLawyer = containsSome([Roles.Lawyer])
const isCustomer = containsSome([Roles.Client])
const isLoginURL = (url: string): boolean => /.*?login.*/.test(url)

const getRedirectUrl = (user: LoggedInUser, lastUrl?: UserContextProps['lastUrl']): string => {
  if (lastUrl && !isLoginURL(lastUrl.pathname)) {
    const { pathname, search, hash } = lastUrl
    return `${pathname}${search}${hash}`
  }
  if (isAdminOrEmployee(user.roles)) {
    return '/advice-requests'
  }

  if (isChannelOrTRB(user.roles)) {
    return '/advice-requests'
  }

  if (isLawyer(user.roles)) {
    return '/chancellery/advice-requests/open-requests'
  }

  return '/advice-requests'
}

export const LoginBox: FC = () => {
  const { setUser, lastUrl, user: currentUser } = useContext(UserContext)

  const history = useHistory()
  const { handleSubmit, control } = useForm<LoginFormFields>({
    defaultValues: { email: '', password: '' },
    resolver: zodResolver(loginSchema),
  })

  const [errorMessage, setErrorMessage] = useState('')

  useEffect(() => {
    if (currentUser && currentUser.id) {
      const redirectURL = getRedirectUrl(currentUser, lastUrl)
      // eslint-disable-next-line fp/no-mutating-methods
      history.push(redirectURL)
    }
  }, [currentUser, history, lastUrl])

  const resetErrorMessage = (): void => {
    setErrorMessage('')
  }

  const handleFieldError = (errors: Record<keyof LoginFormFields, FieldError>): void => {
    const messageError = errors.email?.message
    const passwordError = errors.password?.message

    setErrorMessage(messageError || passwordError || '')
  }

  const handleLogin = async (data: LoginFormFields): Promise<void> => {
    if (/.*@devk.de/.test(data.email)) {
      // eslint-disable-next-line fp/no-mutating-methods
      history.push('/login-azure')
    }

    const userResponse = await loginUser(data.email, data.password)
    if (!userResponse) {
      setErrorMessage('Ungültiger Login')
      return
    }
    const user = {
      id: userResponse.user.id,
      name: userResponse.user.name,
      email: userResponse.user.email,
      organisation: userResponse.user.organization || '',
      organisationType: userResponse.user.organizationType || '',
      channel: userResponse.user.channel || '',
      roles: userResponse.user.roles.map((r: Role) => r.name),
      accessBitSet: userResponse.user.accessBitSet,
    }

    if (user.roles.length === 1 && isCustomer(user.roles)) {
      setErrorMessage('Ihr Kundenkonto hat nicht die nötigen Rechte für diesen Service.')
      return
    }

    setUser(user)
    localStorage.setItem('token', userResponse.jwtToken)
    // eslint-disable-next-line fp/no-mutation
    document.cookie = `token=${userResponse.jwtToken};path=/`
    // eslint-disable-next-line fp/no-mutating-methods
    history.push(getRedirectUrl(user, lastUrl))
  }

  return (
    <SigninBox>
      <Stack alignItems="center">
        <SVGKlugoLogo width="124" height="40" />
        <Typography variant="loginTitle">Antragsverwaltung</Typography>
      </Stack>

      <Box sx={{ display: 'flex', gap: 3, alignItems: 'center', justifyContent: 'center', my: 2.5, mx: 1 }}>
        <Divider color="GrayText" sx={{ flexGrow: 1, borderBottomWidth: 0.5 }} />
        <Typography variant="subtitle2" color="GrayText" fontWeight={400} letterSpacing="1px">
          LOGIN
        </Typography>
        <Divider color="GrayText" sx={{ flexGrow: 1, borderBottomWidth: 0.5 }} />
      </Box>
      <Stack component="form" spacing={2} noValidate onSubmit={handleSubmit(handleLogin, handleFieldError)} textAlign="center">
        <LoginTextField
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <PersonIcon />
              </InputAdornment>
            ),
          }}
          control={control}
          onChange={resetErrorMessage}
          placeholder="User"
          type="email"
          name="email"
        />
        <LoginTextField
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <LockIcon />
              </InputAdornment>
            ),
          }}
          onChange={resetErrorMessage}
          placeholder="Passwort"
          type="password"
          name="password"
          control={control}
          error={!!errorMessage}
        />
        {errorMessage && (
          <Typography color="error" variant="subtitle2" fontWeight={400}>
            {errorMessage}
          </Typography>
        )}
        <Link to="/password-reset" sx={{ p: theme => theme.spacing(0.25, 0) }}>
          Sie haben Ihr Passwort vergessen?
        </Link>
        <Button variant="loginContained" fullWidth type="submit">
          Einloggen
        </Button>
      </Stack>
    </SigninBox>
  )
}
