import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { groupBy } from 'ramda'

import { FIELDS_OF_LAW_ENTRIES } from 'common/constants/fieldsOfLaw'
import { FieldOfLaw } from 'common/graphql/schemaDefinition'
import { addOptionalPropIf, enqueueSnackbar } from 'common/utils'

import {
  EmptyFieldOfLawPhone,
  EmptyOrderVolume,
  ChancelleryFormValues,
  ConfigurationFormValues,
  LocationFormValues,
} from '../interfaces/formSchemas'
import {
  ConfigWeight,
  HolidayInput,
  ProductType,
  ChancelleryLocation,
  GetChancelleryLocationQuery,
  GetChancelleryQuery,
  GetMatchingConfigurationQuery,
  Holiday,
  ChancelleryLocationInput,
  FieldOfLawPhone,
} from '../interfaces/schemaDefinition'
import { GetProductsLiteQuery } from '../interfaces/schemaProducts'

// Use UTC plugin
dayjs.extend(utc)

export const isValidNumber = (lower: number, upper: number) => (n: number | undefined) => {
  if (typeof n === 'undefined' || Number.isNaN(n) || n < lower || n > upper) {
    return false
  }
  return true
}

const isValidHour = isValidNumber(0, 23)
const isValidMinute = isValidNumber(0, 59)

export const toUTCTime = (timeString: string): number => {
  if (timeString === '') {
    return 0
  }

  const [hours, minutes] = timeString.split(':').map(i => parseInt(i, 10))
  if (!isValidHour(hours) || !isValidMinute(minutes)) {
    throw new Error(`Wrong time string format. Expected: HH:mm, seen ${timeString}`)
  }
  // Create dayjs object in local time, set value from form, convert to UTC.
  return dayjs(0).hour(hours).minute(minutes).utc().valueOf()
}

export const getNewHolidayEntry = (): HolidayInput => ({
  global: false,
  start: dayjs().startOf('day').toDate(),
  end: dayjs().endOf('day').toDate(),
})

export const getNewPhoneEntry = (): EmptyFieldOfLawPhone => ({
  fieldOfLaw: null,
  phone: '',
})

export const getNewOrderVolumeEntry = (): EmptyOrderVolume => ({
  fieldOfLaw: null,
  weeklyMax: '',
})

type BaselineConfig = {
  folMax: number
  partnersMax: number
  productsMax: number
  weight: ConfigWeight
}

export const calculatePriority = (baselineConfig: BaselineConfig, values: ConfigurationFormValues): number => {
  const basePrio = 0
  return (
    basePrio +
    (baselineConfig.folMax > values.fieldsOfLaw.length ? 1 : 0) +
    (baselineConfig.partnersMax > values.partners.length ? 1 : 0) +
    (baselineConfig.productsMax > values.products.length ? 1 : 0) +
    (baselineConfig.weight !== ConfigWeight.Default ? 1 : 0)
  )
}

export type ExtractRowType<T, N extends string> = T extends { [name in N]: { list: Array<infer U> } } ? U : never

export const listFormatter = <T extends { name: string }>(value: Array<T>): string =>
  value.length > 1 ? 'konfiguriert' : value[0]?.name || ''

type Extract<T> = T extends Array<infer U> ? U : never

type Product = Extract<Exclude<GetProductsLiteQuery, undefined>['products']['list']> & {
  type?: ProductType
}

type ProductList = Array<Product>

type IndexProduct = {
  [index: string]: Array<
    {
      __typename?: 'Product' | undefined
    } & Pick<Product, 'id' | 'name' | 'type'>
  >
}

export const sortProducts = (list: ProductList, consumerFirst = false): Array<{ name: string; id: string; type: string }> => {
  const grouped: IndexProduct = groupBy((product: Product) => product.type || ProductType.Consumer, list)
  // We are mutating the list of keys that is created in this line and using it only once.
  // eslint-disable-next-line fp/no-mutating-methods
  return Object.keys(grouped)
    .sort((a, b) => (consumerFirst ? -1 : 1) * a.localeCompare(b))
    .flatMap(key =>
      // The lists in grouped are also created in this function and only used in this flow.
      // eslint-disable-next-line fp/no-mutating-methods
      grouped[key]
        .sort((a: Extract<ProductList>, b: Extract<ProductList>) => a.name.localeCompare(b.name))
        .map((p: Extract<ProductList>) => ({ ...p, type: p.type === ProductType.Business ? 'B2B' : 'B2C' }))
    )
}

// eslint-disable-next-line complexity
export const mapChancelleryAPIDataToForm = (
  data: GetChancelleryQuery['chancellery']
): Omit<ChancelleryFormValues, 'throttle'> & { throttle: { max: string; apply: boolean } } & {
  matchings: GetChancelleryQuery['chancellery']['matchings']
  matchingConfigs: GetChancelleryQuery['chancellery']['matchingConfigs']
} => ({
  active: data.active,
  deleted: data.deleted,
  name: data.name,
  contactPersonFamilyName: data.contactPerson?.lastname ?? '',
  contactPersonGivenName: data.contactPerson?.firstname ?? '',
  contactPersonSalutation: data.contactPerson?.foa ?? '',
  email: data.email,
  phone: data.phone,
  chancelleryId: data.id, // On edit value from API
  taxId: data.taxId ?? '',
  taxNumber: data.taxNumber ?? '',
  iban: data.iban ?? '',
  throttle: { apply: !!data.matchingThrottleCount, max: (data.matchingThrottleCount ?? 0).toString() },
  invoiceEmail: data.invoiceEmail ?? '',
  invoiceNumberRange: data.invoiceNumberRange ?? '',
  letterheadAddress: data.letterhead?.address ?? '',
  letterheadCity: data.letterhead?.city ?? '',
  letterheadEmail: data.letterhead?.email ?? '',
  letterheadPhone: data.letterhead?.phone ?? '',
  letterheadWeb: data.letterhead?.web ?? '',
  letterheadZip: data.letterhead?.zip ?? '',
  eConsultId: data.eConsult?.active ? data.eConsult?.customerId ?? '' : '',
  secupay: data.secupay?.active ? data.secupay.contractId ?? '' : '',
  mollieId: data.mollieId || '',
  services: data.services || '',
  powerBI1: data.powerBI?.[0] ?? '',
  powerBI2: data.powerBI?.[1] ?? '',
  callbacks: Boolean(data.callbacks),
  users: data.users,
  matchingConfigs: data.matchingConfigs ?? [],
  matchings: data.matchings ?? [],
  orderVolumes:
    data.orderVolumes?.map(entry => ({
      ...entry,
      weeklyMax: entry.weeklyMax?.toString() ?? '',
    })) ?? [],
})

const ensureSevenDays = (openingHours: ChancelleryLocation['openingHours']): ChancelleryLocation['openingHours'] => {
  if (openingHours.length === 7) return openingHours
  const missingDays = 7 - openingHours.length
  const filler: typeof openingHours = Array(missingDays).fill({
    enabled: false,
    begin: '',
    end: '',
  })
  return openingHours.concat(filler)
}

export const notOverYet = (holiday: Holiday): boolean => dayjs().isBefore(dayjs(holiday.end))

export const mapChancelleryLocationAPIDataToForm = (
  apiResponse: GetChancelleryLocationQuery['chancelleryLocation']
): LocationFormValues => ({
  active: apiResponse.active,
  locationId: apiResponse.id,
  chancelleryId: apiResponse.chancelleryId,
  phone: apiResponse.phone,
  zip: apiResponse.address.zip,
  city: apiResponse.address.city,
  streetAddress: apiResponse.address.street,
  fallbackFor: apiResponse.fallbackFor.map(fol => fol.id),
  openingHours: ensureSevenDays(apiResponse.openingHours).map(day => {
    const begin = day.start ? dayjs.utc(day.start) : undefined
    const end = day.end ? dayjs.utc(day.end) : undefined
    return {
      enabled: day.enabled,
      // Take UTC times from API and convert to local, then print formatted.
      begin: begin ? `${begin.local().format('HH')}:${begin.local().format('mm')}` : '',
      end: end ? `${end.local().format('HH')}:${end.local().format('mm')}` : '',
    }
  }),
  holidays:
    apiResponse.holidays.filter(notOverYet).map(holidayEntry => ({
      ...holidayEntry,
      global: holidayEntry.global ?? false,
      fieldsOfLaw: holidayEntry.fieldsOfLaw ?? [],
    })) ?? [],
  fieldOfLawPhones: apiResponse.fieldOfLawPhones ?? [],
  deleted: apiResponse.deleted,
  users: apiResponse.users,
})

export const mapMatchingConfigAPIDataToForm = (
  apiResponse: GetMatchingConfigurationQuery['matchingConfig']
): ConfigurationFormValues & {
  chancelleryId?: string
  matchableLocations: GetMatchingConfigurationQuery['matchingConfig']['matchableLocations']
} => ({
  applyZip: !!apiResponse.zipAreas.length,
  fallback: apiResponse.fallback,
  configurationId: apiResponse.id,
  locationId: apiResponse.chancelleryLocation.id,
  active: apiResponse.active,
  deleted: apiResponse.deleted,
  zipCodes: apiResponse.zipAreas,
  fieldsOfLaw: apiResponse.fieldsOfLaw.included.map(f => f.name),
  partners: apiResponse.partners.excluded ?? [],
  products: apiResponse.products.included.map(product => ({ ...product, type: product.type ?? ProductType.Consumer })),
  priority: apiResponse.priority ?? 0,
  weight: apiResponse.weight,
  chancelleryId: apiResponse?.chancelleryLocation.chancellery?.id,
  matchableLocations: apiResponse.matchableLocations ?? [],
})

export const toLocationApiInputFormat = (values: LocationFormValues): ChancelleryLocationInput => {
  const {
    locationId,
    chancelleryId,
    fallbackFor,
    streetAddress,
    zip,
    city,
    phone,
    openingHours,
    fieldOfLawPhones,
    holidays,
    deleted,
    active,
  } = values

  const baseInput = {
    active,
    phone: phone ?? '',
    address: {
      zip,
      city,
      street: streetAddress,
    },
    openingHours: openingHours.map(day => ({
      enabled: day.enabled,
      start: toUTCTime(day.begin!),
      end: toUTCTime(day.end!),
    })),
    fallbackFor,
    fieldOfLawPhones: fieldOfLawPhones
      .filter((folPhone): folPhone is FieldOfLawPhone => Boolean(folPhone.fieldOfLaw?.id))
      .map(folPhone => ({
        fieldOfLawId: folPhone.fieldOfLaw.id,
        phone: folPhone.phone,
      })),
    // eslint-disable-next-line fp/no-rest-parameters
    holidays: holidays.map(({ fieldsOfLaw, ...holidayEntry }) => ({
      ...holidayEntry,
      fieldOfLawIds: fieldsOfLaw?.map(field => field.id),
    })),
    deleted,
    chancelleryId: chancelleryId ?? '',
  }

  const withId = addOptionalPropIf<{ id: string }, ChancelleryLocationInput>((locationId ?? '').length > 0)({
    id: locationId ?? '',
  })

  return withId(baseInput)
}

export const getRemainingFols = (
  definedOrderVolumes: Array<{ fieldOfLaw: FieldOfLaw | null }>,
  currentOrderVolume: FieldOfLaw | null
): Array<FieldOfLaw> => {
  const orderVolumeIds = definedOrderVolumes.map(({ fieldOfLaw }) => fieldOfLaw?.id).filter(Boolean)
  const filteredOptions = FIELDS_OF_LAW_ENTRIES.filter(entry => !orderVolumeIds.includes(entry.id))
  // Re-add the one that is chosen here in this instance. Otherwise MUI Autocomplete can't find the selected option in the list.
  const availableOptions = currentOrderVolume ? [currentOrderVolume].concat(filteredOptions) : filteredOptions

  return availableOptions
}

export const toProductType = (product: {
  id: string
  name: string
  type: string
}): { id: string; name: string; type: ProductType } => ({
  ...product,
  type: product.type === 'BUSINESS' ? ProductType.Business : ProductType.Consumer,
})

export const onError = (): void => {
  window.scrollTo({ top: 0, behavior: 'smooth' })
  enqueueSnackbar('Bitte überprüfen Sie Ihre Daten', { variant: 'error' })
}
