import { Button, ButtonProps, Typography } from '@mui/material'
import React, { FunctionComponent } from 'react'

import { useFormActionButtonStyles } from '../helpers'

// eslint-disable-next-line fp/no-rest-parameters
export const AbortButton: FunctionComponent<ButtonProps> = ({ children, ...rest }) => (
  <Button sx={useFormActionButtonStyles} type="button" {...rest}>
    <Typography variant="button" color="primary">
      {children || 'Abbrechen'}
    </Typography>
  </Button>
)
