import { Box } from '@mui/material'
import React, { ComponentProps, FC } from 'react'

export const SigninBox: FC<ComponentProps<typeof Box>> = props => (
  <Box
    {...props}
    sx={[
      theme => ({
        position: 'absolute',
        bgcolor: theme.palette.loginbg.main,
        maxWidth: '362px',
        width: '100%',
        minWidth: '350px',
        top: '50%',
        left: '50%',
        borderRadius: 5,
        boxShadow: '0 20px 25px rgba(0, 0, 0, 0.15)',
        transform: 'translate(-50%, -50%)',
        p: 5,
        zIndex: theme.zIndex.mobileStepper,
      }),
    ]}
  />
)
