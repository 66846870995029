import { Box, useTheme } from '@mui/material'
import React, { FunctionComponent } from 'react'

export type BadgeProps = {
  color?: 'primary' | 'secondary' | 'warning' | 'error' | 'success' | 'info' | 'default'
  title?: string
}

export const Badge: FunctionComponent<BadgeProps> = ({ title, children, color }) => {
  const theme = useTheme()
  return (
    <Box
      title={title}
      sx={[
        {
          display: 'inline-flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '24px',
          borderRadius: '12px',
          fontSize: theme.typography.pxToRem(11),
          fontWeight: 'bold',
          bgcolor: theme.palette.grey['700'],
          color: 'white',
          px: 1,
        },
        color === 'primary' && {
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.primary.contrastText,
        },
        color === 'secondary' && {
          backgroundColor: theme.palette.secondary.main,
          color: theme.palette.secondary.contrastText,
        },
        color === 'warning' && {
          backgroundColor: theme.palette.warning.main,
          color: theme.palette.warning.contrastText,
        },
        color === 'error' && {
          backgroundColor: theme.palette.error.main,
          color: theme.palette.error.contrastText,
        },
        color === 'success' && {
          backgroundColor: theme.palette.success.main,
          color: 'white',
        },
        color === 'info' && {
          backgroundColor: theme.palette.info.main,
          color: 'white',
        },
      ]}
    >
      {children}
    </Box>
  )
}
