import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { Fragment, FC } from 'react'
import { Col, Row, Table } from 'reactstrap'

import { Partner } from 'common/api/types'
import { FilterTagsComponent as FilterTags, SortFilterTh, Spinner } from 'common/components'
import { DatePickerInput, TextFilterInput } from 'common/components/FilterInput'
import { ADVICE_REQUESTS_ENDPOINT, FIELDS_OF_LAW_ENTRIES } from 'common/constants'
import { ListProps, withList } from 'common/container'
import { LoggedInUser } from 'common/user-context'
import { convertToTagValues, formatDate } from 'common/utils'

import query from '../graphql/adviceRequests.graphql'
import { LawyerRequest } from '../interfaces'
import { ChancelleryLocationsQuery, FilterOperator } from '../interfaces/schemaDefinition'
import { getChancelleryFilter } from '../utils'

import { AdviceMenuButton } from './AdviceMenu'
import { PanelTabs } from './PanelTabs/PanelTabs'
import { PartnerCell } from './PartnerCell'
import { ToolbarTabs } from './ToolbarTabs'

interface ClosedRequestsProps extends ListProps<LawyerRequest> {
  user?: LoggedInUser
  chancelleryLocations: ChancelleryLocationsQuery['chancelleryLocations']['list']
  assignableUsers: Array<{ id: string; name: string }>
  assignFilter?: string
  onRowClick: (rowId: string) => React.EventHandler<React.FormEvent<HTMLTableRowElement>>
  partners: Array<Partner>
}

const createOnSort = (func: (id: string) => void, id: string) => () => func(id)

export const ClosedRequestsView: FC<ClosedRequestsProps> = ({
  partners,
  assignableUsers,
  list,
  sort,
  page,
  pageSize,
  total,
  filters,
  onRowClick,
  onSort,
  onFilterChange,
  onFilterRemove,
  onNext,
  onPrev,
  onReload,
  loading,
}) => (
  <Fragment>
    <PanelTabs>
      <ToolbarTabs
        pagination={{ page, pageSize, total, label: 'Geschlossenen Anfragen' }}
        onNext={onNext}
        onPrev={onPrev}
        title="Geschlossene Anfragen"
      />
      <Row className="m-b-20">
        <Col sm="12">
          <Spinner condition={loading} center>
            <FilterTags filters={filters} onTagRemove={onFilterRemove} partners={partners} />
            {list.length > 0 ? (
              <Table hover responsive>
                <thead>
                  <tr>
                    <SortFilterTh
                      id="closedRequestsAdviceId"
                      name="adviceId"
                      sort={sort}
                      onSort={createOnSort(onSort, 'adviceId')}
                      onFilterSubmit={onFilterChange}
                      FilterInput={TextFilterInput}
                      placeholder="Beratungs ID..."
                    >
                      ID
                    </SortFilterTh>
                    <SortFilterTh
                      id="closedRequestsPersonName"
                      name="personName"
                      sort={sort}
                      onSort={createOnSort(onSort, 'personName')}
                      onFilterSubmit={onFilterChange}
                      FilterInput={TextFilterInput}
                      placeholder="Name..."
                    >
                      Name
                    </SortFilterTh>
                    <SortFilterTh
                      id="closedRequestsFieldOfLaw"
                      name="fieldOfLaw"
                      sort={sort}
                      onSort={createOnSort(onSort, 'fieldOfLaw')}
                      onFilterSubmit={onFilterChange}
                      suggestions={convertToTagValues(FIELDS_OF_LAW_ENTRIES)}
                      FilterInput={TextFilterInput}
                      placeholder="Rechtsgebiet..."
                    >
                      Rechtsgebiet
                    </SortFilterTh>
                    <SortFilterTh
                      id="closedRequestsPartnerId"
                      name="partnerId"
                      sort={sort}
                      onSort={createOnSort(onSort, 'partnerId')}
                      onFilterSubmit={onFilterChange}
                      suggestions={convertToTagValues(partners)}
                      FilterInput={TextFilterInput}
                      placeholder="Partner ID..."
                    >
                      Partner
                    </SortFilterTh>
                    <SortFilterTh
                      id="closedChancellery"
                      name="chancellery"
                      sort={sort}
                      onSort={createOnSort(onSort, 'chancellery')}
                      onFilterSubmit={onFilterChange}
                      FilterInput={TextFilterInput}
                      placeholder="Kanzlei..."
                    >
                      Kanzlei
                    </SortFilterTh>
                    <SortFilterTh
                      id="closedRequestsCreatedAt"
                      name="createdAt"
                      sort={sort}
                      onSort={createOnSort(onSort, 'createdAt')}
                      onFilterSubmit={onFilterChange}
                      FilterInput={DatePickerInput}
                      placeholder="Erstellt..."
                    >
                      Erstellt
                    </SortFilterTh>
                    <th>In Bearbeitung durch</th>
                    <th className="text-center action-header">Aktion</th>
                  </tr>
                </thead>
                <tbody>
                  {list.map((adviceRequest, index) => (
                    <tr key={index} className="row-hover" onClick={onRowClick(adviceRequest.adviceId)}>
                      <th scope="row" className="text-center">
                        <Fragment>{adviceRequest.adviceId}</Fragment>
                      </th>
                      <td className="text-center">
                        {adviceRequest.person.firstname} {adviceRequest.person.lastname}
                      </td>
                      <td className="text-center">{adviceRequest.fieldOfLaw ? adviceRequest.fieldOfLaw.name : '-'} </td>
                      {adviceRequest.followsUp ? (
                        <PartnerCell partner={{ id: 'klugo', name: 'klugo' }} />
                      ) : (
                        <PartnerCell partner={adviceRequest.partner} />
                      )}
                      <td className="text-center">{adviceRequest.chancellery ? adviceRequest.chancellery.name : '-'}</td>
                      <td className="text-center">{formatDate(new Date(adviceRequest.createdAt))}</td>
                      <td className="text-center">
                        {adviceRequest.assignedTo && adviceRequest.assignedTo.name ? (
                          adviceRequest.assignedTo.name
                        ) : (
                          <span>
                            <FontAwesomeIcon icon={faExclamationTriangle} size="lg" className="inline-icon" /> Nicht zugewiesen
                          </span>
                        )}
                      </td>
                      <td className="text-center action-cell">
                        <AdviceMenuButton adviceRequest={adviceRequest} onAssignTo={onReload} assignableUsers={assignableUsers} />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : (
              <h3 className="no-data">Keine neuen geschlossene Anfragen</h3>
            )}
          </Spinner>
        </Col>
      </Row>
    </PanelTabs>
  </Fragment>
)

export const ClosedRequests = withList<LawyerRequest, ClosedRequestsProps>({
  query,
  endpoint: ADVICE_REQUESTS_ENDPOINT,
  responseKey: 'adviceRequests',
  searchPrefix: 'closed',
  pageSize: 100,
  queryMapper: (listState, props) => ({
    ...listState,
    filters: [
      ...listState.filters,
      ...(props.assignFilter
        ? [
            {
              name: 'assignedTo',
              operator: FilterOperator.Equals,
              value: props.assignFilter,
            },
          ]
        : []),
      getChancelleryFilter(props.user, props.chancelleryLocations),
      {
        name: 'product.type',
        operator: FilterOperator.Equals,
        value: 'CONSUMER',
      },
      {
        name: 'status',
        operator: FilterOperator.Equals,
        value: 'closed',
      },
    ],
  }),
})(ClosedRequestsView)
