import { formatCurrencyNoUnit } from '@grapes-agency/universal'
import { zodResolver } from '@hookform/resolvers/zod'
import CheckIcon from '@mui/icons-material/Check'
import { InputAdornment, TextField as MuiTextField, Stack, Typography } from '@mui/material'
import React, { FC, useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'

import { AbortButton, DeleteWithConfirmation, SaveButton } from 'common/components-mui'
import { TextField, Autocomplete, Checkbox } from 'common/components-mui/react-hook-form'
import { PartnerDiscountDialogProps, partnerDiscountInitialValues } from 'packages-mui/products/interfaces'

import { partnerDiscountSchema } from '../constants/formSchema'
import { calculatePrice, convertToNum } from '../utils'

import { Dialog } from './Dialog'

export const PartnerDiscountDialog: FC<PartnerDiscountDialogProps> = ({
  open,
  partnerDiscounts,
  partnerDiscountId,
  partners,
  netPrice,
  onClose,
  onSubmit,
  onDelete,
}) => {
  const { control, watch, setValue, handleSubmit, reset } = useForm({
    defaultValues: partnerDiscountInitialValues,
    resolver: zodResolver(partnerDiscountSchema),
  })

  const [discount, id] = watch(['discount', 'id'])

  const hasDiscount = discount && netPrice
  const disNetPrice = hasDiscount ? convertToNum(calculatePrice(netPrice, discount)) : 0
  const disGrossPrice = hasDiscount ? convertToNum(calculatePrice(netPrice, discount, true)) : 0

  useEffect(() => {
    setValue('disNetPrice', disNetPrice.toString())
    setValue('disGrossPrice', disGrossPrice.toString())
  }, [disNetPrice, disGrossPrice, setValue])

  useEffect(() => {
    const toEdit = partnerDiscounts.find(({ id: discountId }) => discountId === partnerDiscountId)
    if (toEdit) {
      reset(toEdit)
    } else {
      reset(partnerDiscountInitialValues)
    }
  }, [partnerDiscounts, partnerDiscountId, reset])

  const partnerIdsSelectedInOtherDiscounts = useMemo(() => {
    if (!id) {
      return partnerDiscounts.map(p => p.partners.map(pp => pp.id)).flat()
    } else {
      const editIndex = partnerDiscounts.findIndex(pd => pd.id === id)
      const selectedPartners = [...partnerDiscounts.slice(0, editIndex), ...partnerDiscounts.slice(editIndex + 1)]
      return selectedPartners.map(p => p.partners.map(pp => pp.id)).flat()
    }
  }, [partnerDiscounts, id])

  return (
    <Dialog
      open={open}
      title={`Partnerrabatt ${id ? 'bearbeiten' : 'hinzufügen'}`}
      onClose={onClose}
      actions={
        <>
          {id && (
            <DeleteWithConfirmation
              actionButtonText="Partnerrabatt löschen"
              onConfirm={async () => {
                await onDelete(partnerDiscountId)
              }}
            >
              <Typography>{`Soll der Partnerrabatt von ${discount} % gelöscht werden?`}</Typography>
            </DeleteWithConfirmation>
          )}
          <AbortButton onClick={onClose} />
          <SaveButton
            onClick={handleSubmit(values =>
              onSubmit({ ...values, ...{ id: values.id ? values.id : `new-${values.discount}-${values.partners[0].id}` } })
            )}
            startIcon={<CheckIcon />}
          >
            {discount ? 'Akzeptieren' : 'Hinzufügen'}
          </SaveButton>
        </>
      }
    >
      <Stack spacing={3} pt="1.5rem">
        <Checkbox control={control} label="Aktiv" name="active" />
        <Autocomplete
          name="partners"
          label="Partner"
          control={control}
          options={partners}
          multiple
          filterSelectedOptions
          filterOptions={(options, state) =>
            options
              .filter((p: (typeof partners)[number]) => !partnerIdsSelectedInOtherDiscounts.includes(p.id))
              .filter((p: (typeof partners)[number]) => p.name.toLowerCase().includes(state.inputValue.toLowerCase()))
          }
          getOptionLabel={(option: (typeof partners)[number]) => (typeof option === 'object' ? option.name : option)}
          isOptionEqualToValue={(option: (typeof partners)[number], value: (typeof partners)[number]) => option.id === value.id}
          fullWidth
          ChipProps={{ color: 'primary' }}
        />
        <TextField
          label="Rabatt"
          name="discount"
          control={control}
          InputProps={{
            startAdornment: <InputAdornment position="start">%</InputAdornment>,
          }}
          fullWidth
        />
        <MuiTextField
          disabled
          label="Rabattierter Nettopreis"
          value={formatCurrencyNoUnit(disNetPrice)}
          InputProps={{
            startAdornment: <InputAdornment position="start">€</InputAdornment>,
          }}
          fullWidth
        />
        <MuiTextField
          disabled
          label="Rabattierter Bruttopreis"
          value={formatCurrencyNoUnit(disGrossPrice)}
          InputProps={{
            startAdornment: <InputAdornment position="start">€</InputAdornment>,
          }}
          fullWidth
        />
      </Stack>
    </Dialog>
  )
}
