// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.T7LRRSTMeJfnN6zuMD3s{fill:#0a1343;width:72.5px;height:23.5px}`, "",{"version":3,"sources":["webpack://./src/packages-mui/chancelleries/utils/PartnerLogos.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,YAAA,CACA,aAAA","sourcesContent":[".logoKlugo {\n  fill: #0a1343;\n  width: 72.5px;\n  height: 23.5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"logoKlugo": `T7LRRSTMeJfnN6zuMD3s`
};
export default ___CSS_LOADER_EXPORT___;
