import { SxProps, Theme } from '@mui/material'

export const useFormActionButtonStyles: SxProps<Theme> = {
  marginLeft: 1,
  marginRight: 1,
  marginBottom: 3,
  '&:first-child': {
    marginLeft: 0,
  },
  '&:last-child': {
    marginRight: 0,
  },
}
