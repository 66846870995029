export const CANCEL_REASONS = [
  '',
  'Beschwerde - Kunde unzufrieden',
  'Doppelte Anfrage',
  'Rechtsgebiet nicht vorhanden',
  'Keine Kanzlei verfügbar',
  'Anliegen/Problem hat sich erledigt + Geld zurück',
  'Testanfrage',
  'Sonstiges',
] as const

export type CancelReason = keyof typeof CANCEL_REASONS
