import { API_BASE } from 'app/constants'

import { ensureEndsWith } from './string'

interface FileUploadOptions {
  endpoint?: string
  fieldName?: string
}

const DEFAULT_OPTIONS = {
  endpoint: 'files',
  fieldName: 'files',
}

export const uploadFiles = async (files: Array<File>, options: FileUploadOptions = {}) => {
  const { endpoint, fieldName } = {
    ...DEFAULT_OPTIONS,
    ...options,
  }
  const url = `${ensureEndsWith('/', API_BASE)}${endpoint}`
  const data = new FormData()
  files.forEach(file => {
    data.append(fieldName, file)
  })

  const fetchOptions = {
    method: 'POST',
    body: data,
  }

  const response = await fetch(url, fetchOptions)
  return (await response.json()).files
}
