import { Alert, Typography } from '@mui/material'
import React, { FC } from 'react'

export const RolandHint: FC = () => (
  <Alert severity="warning" sx={{ marginBottom: '1rem', alignItems: 'center', justifyContent: 'center' }}>
    <Typography variant="body2">
      Sämtliche Kommunikation mit der Roland Rechtsschutz Versicherung ist an folgende E-Mail zu senden:{' '}
      <a href="mailto:klugo@roland-rechtsschutz.de">klugo@roland-rechtsschutz.de</a>.
      <br />
      <br />
      Wir bitten Sie um Einhaltung der folgenden Formalitäten: <br />
      <b>- Betreff Deckungsanfrage:</b> Kunden-Name, VSNR, Kennwort &quot;KLUGO&quot;, KLUGO ID <br />
      <b>- Betreff Folgepost:</b> Schadennummer, Kennwort &quot;KLUGO&quot;, KLUGO ID <br />
      <b>- Betreff Rechnung:</b> Schadennummer (wenn nicht vorhanden: VSNR), Kennwort &quot;KLUGO&quot;, KLUGO ID. Außerdem
      erforderlich: Angaben zum Rechnungsempfänger. Rechnung bitte auf maximal einer Seite abbilden.
      <br />
      <br />
      Wenn Sie Rückfragen haben, die telefonisch geklärt werden müssen, wenden Sie sich bitte unter folgender Nummer an die Roland
      Rechtsschutz Versicherung: <a href="tel:022182774563">0221-8277-4563</a>
    </Typography>
  </Alert>
)
