import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classnames from 'classnames'
import React, { Fragment, RefObject, FC } from 'react'
import { Table } from 'reactstrap'

import { Partner } from 'common/api/types'
import { FilterTagsComponent as FilterTags, SortFilterTh, Spinner } from 'common/components'
import { DatePickerInput, TextFilterInput } from 'common/components/FilterInput'
import { PRODUCT_NAMES, ADVICE_REQUESTS_ENDPOINT, FIELDS_OF_LAW_ENTRIES } from 'common/constants'
import { ListProps, withList } from 'common/container'
import SVGRedirected from 'common/icons/Icon_Forward.svg'
import { LoggedInUser } from 'common/user-context'
import { convertToTagValues, formatDate } from 'common/utils'
import { formatCallbackTime } from 'common/utils/formatter'

import { isSoonTime, isUrgentTime } from '../constants'
import query from '../graphql/adviceRequests.graphql'
import { LawyerRequest } from '../interfaces'
import { ChancelleryLocationsQuery, FilterOperator, SortDirection } from '../interfaces/schemaDefinition'
import { wasRedirected, getChancelleryFilter } from '../utils'

import { AdviceMenuButton } from './AdviceMenu'
import { PartnerCell } from './PartnerCell'
import { ToolbarTabs } from './ToolbarTabs'
import { ToolbarImage } from './ToolbarTabs/ToolbarTabs'
import Callback from './images/callback.svg'

interface CallbackRequestsProps extends ListProps<LawyerRequest> {
  partners: Array<Partner>
  chancelleryLocations: ChancelleryLocationsQuery['chancelleryLocations']['list']
  assignableUsers: Array<{ id: string; name: string }>
  assignFilter?: string
  user?: LoggedInUser
  onRowClick: (rowId: string) => React.EventHandler<React.FormEvent<HTMLTableRowElement>>
  node: RefObject<HTMLDivElement>
  hasPremium: boolean
}

const createOnSort = (func: (id: string) => void, id: string) => () => func(id)

export const CallbackRequestsView: FC<CallbackRequestsProps> = ({
  partners,
  assignableUsers,
  sort,
  onSort,
  onFilterChange,
  list,
  onRowClick,
  filters,
  onFilterRemove,
  page,
  pageSize,
  total,
  onNext,
  onPrev,
  onReload,
  loading,
  node,
  hasPremium,
}) => (
  <Fragment>
    <ToolbarTabs
      pagination={{ page, pageSize, total, label: 'Rückrufen' }}
      onNext={onNext}
      onPrev={onPrev}
      title="Ausstehende Rückrufe"
      subtitle="Bitte rufen Sie diese Kunden zur angegeben Uhrzeit eigenständig an"
      imgSource={ToolbarImage.imgBemerkung}
    />
    <Spinner condition={loading} center>
      <FilterTags filters={filters} onTagRemove={onFilterRemove} partners={partners} />
      {list.length > 0 ? (
        <div className="vertical-div">
          <Callback height="20" width="20" className="vertical-icon-center" />
          <Table hover responsive>
            <thead>
              <tr>
                <SortFilterTh
                  id="callbackRequestsPreferredCallbackTime"
                  name="preferredCallbackTime"
                  sort={sort}
                  onSort={createOnSort(onSort, 'preferredCallbackTime')}
                  onFilterSubmit={onFilterChange}
                  FilterInput={DatePickerInput}
                  placeholder="Rückruf datum..."
                >
                  Rückruf Datum
                </SortFilterTh>
                {hasPremium && <th>Produkt</th>}
                <SortFilterTh
                  id="callbackRequestsPersonName"
                  name="personName"
                  sort={sort}
                  onSort={createOnSort(onSort, 'personName')}
                  onFilterSubmit={onFilterChange}
                  FilterInput={TextFilterInput}
                  placeholder="Name..."
                >
                  Name
                </SortFilterTh>
                <SortFilterTh
                  id="callbackRequestsAdviceId"
                  name="adviceId"
                  sort={sort}
                  onSort={createOnSort(onSort, 'adviceId')}
                  onFilterSubmit={onFilterChange}
                  FilterInput={TextFilterInput}
                  placeholder="Beratungs ID..."
                >
                  ID
                </SortFilterTh>
                <SortFilterTh
                  id="callbackRequestsFieldOfLaw"
                  name="fieldOfLaw"
                  sort={sort}
                  onSort={createOnSort(onSort, 'fieldOfLaw')}
                  onFilterSubmit={onFilterChange}
                  suggestions={convertToTagValues(FIELDS_OF_LAW_ENTRIES)}
                  FilterInput={TextFilterInput}
                  placeholder="Rechtsgebiet..."
                >
                  Rechtsgebiet
                </SortFilterTh>
                <SortFilterTh
                  id="callbackRequestsPartnerId"
                  name="partnerId"
                  sort={sort}
                  onSort={createOnSort(onSort, 'partnerId')}
                  onFilterSubmit={onFilterChange}
                  suggestions={convertToTagValues(partners)}
                  FilterInput={TextFilterInput}
                  placeholder="Partner ID..."
                >
                  Partner
                </SortFilterTh>
                <SortFilterTh
                  id="callbackChancellery"
                  name="chancellery"
                  sort={sort}
                  onSort={createOnSort(onSort, 'chancellery')}
                  onFilterSubmit={onFilterChange}
                  FilterInput={TextFilterInput}
                  placeholder="Kanzlei..."
                >
                  Kanzlei
                </SortFilterTh>
                <SortFilterTh
                  id="callbackRequestsCreatedAt"
                  name="createdAt"
                  sort={sort}
                  onSort={createOnSort(onSort, 'createdAt')}
                  onFilterSubmit={onFilterChange}
                  FilterInput={DatePickerInput}
                  placeholder="Erstellt..."
                >
                  Erstellt
                </SortFilterTh>
                <th>In Bearbeitung durch</th>
                <th className="text-center action-header">Aktion</th>
              </tr>
            </thead>
            <tbody>
              {list.map((adviceRequest, index) => (
                <tr
                  key={index}
                  className={classnames(
                    'row-hover',
                    'row-relative',
                    { orange: isSoonTime(adviceRequest.preferredCallbackTime) },
                    { red: isUrgentTime(adviceRequest.preferredCallbackTime) },
                    { bold: !adviceRequest.read }
                  )}
                  onClick={onRowClick(adviceRequest.adviceId)}
                >
                  <td className="text-center">
                    {wasRedirected(adviceRequest) && (
                      <div className="icon-redirected" title="Dieser Antrag wurde an Ihre Kanzlei weitergeleitet">
                        <SVGRedirected width="24px" height="24px" />
                      </div>
                    )}
                    {adviceRequest.preferredCallbackTime ? formatCallbackTime(adviceRequest.preferredCallbackTime) : '-'}
                  </td>
                  {hasPremium && <td>{adviceRequest.product ? PRODUCT_NAMES[adviceRequest.product.id] : 'Basic'}</td>}
                  <td className="text-center">
                    {adviceRequest.person.firstname} {adviceRequest.person.lastname}
                  </td>
                  <td scope="row" className="text-center">
                    {adviceRequest.adviceId}
                  </td>
                  <td className="text-center">{adviceRequest.fieldOfLaw ? adviceRequest.fieldOfLaw.name : '-'}</td>
                  {adviceRequest.followsUp ? (
                    <PartnerCell partner={{ id: 'klugo', name: 'klugo' }} />
                  ) : (
                    <PartnerCell partner={adviceRequest.partner} />
                  )}
                  <td className="text-center">{adviceRequest.chancellery ? adviceRequest.chancellery.name : '-'}</td>
                  <td className="text-center">{formatDate(new Date(adviceRequest.createdAt))}</td>
                  <td className="text-center">
                    {adviceRequest.assignedTo && adviceRequest.assignedTo.name ? (
                      adviceRequest.assignedTo.name
                    ) : (
                      <span>
                        <FontAwesomeIcon icon={faExclamationTriangle} size="lg" className="inline-icon" /> Nicht zugewiesen
                      </span>
                    )}
                  </td>
                  <td className="text-center action-cell">
                    <AdviceMenuButton adviceRequest={adviceRequest} onAssignTo={onReload} assignableUsers={assignableUsers} />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      ) : (
        <h3 className="no-data">Keine neuen Rückrufe</h3>
      )}
      <div ref={node} />
    </Spinner>
  </Fragment>
)

export const CallbackRequests = withList<LawyerRequest, CallbackRequestsProps>({
  query,
  endpoint: ADVICE_REQUESTS_ENDPOINT,
  responseKey: 'adviceRequests',
  searchPrefix: 'callbacks',
  pageSize: 10,
  queryMapper: (listState, props) => ({
    ...listState,
    sort: {
      sortBy: 'preferredCallbackTime',
      sortDirection: SortDirection.Asc,
    },
    filters: [
      ...listState.filters,
      ...(props.assignFilter
        ? [
            {
              name: 'assignedTo',
              operator: FilterOperator.Equals,
              value: props.assignFilter,
            },
          ]
        : []),
      getChancelleryFilter(props.user, props.chancelleryLocations),
      {
        name: 'product.type',
        operator: FilterOperator.Equals,
        value: 'CONSUMER',
      },
      {
        name: 'contactType',
        operator: FilterOperator.Equals,
        value: 'CALLBACK',
      },
      {
        name: 'status',
        operator: FilterOperator.Equals,
        value: 'at_chancellery',
      },
    ],
  }),
})(CallbackRequestsView)
