import { Box, CircularProgress, Typography, Alert, Theme } from '@mui/material'
import React, { CSSProperties, FC } from 'react'

import { PageLayout } from 'common/components-mui'

import { ErrorListCard } from '../components'
import { useFallbackCoverage } from '../components/useFallbackCoverage'

export const IssuesDashboardPage: FC = () => {
  const fallbackIssues = useFallbackCoverage()
  const fallbacksLoading = !fallbackIssues.data && !fallbackIssues.error
  const fallBackInfoStyles = (theme: Theme): CSSProperties => ({
    width: '400px',
    marginRight: theme.spacing(10),
  })

  const fieldOfLawNamesMissing =
    fallbackIssues.data?.missingProblems
      .map(problem => problem.fieldOfLaw?.name)
      .filter((element): element is string => Boolean(element)) ?? []
  const hasMissingFieldsOfLaw = fieldOfLawNamesMissing.length > 0

  const fieldOfLawNamesTemporaryMissing =
    fallbackIssues.data?.temporaryMissingProblems
      .map(problem => problem.fieldOfLaw?.name)
      .filter((element): element is string => Boolean(element)) ?? []
  const hasTemporaryMissingFieldsOfLaw = fieldOfLawNamesTemporaryMissing.length > 0

  return (
    <PageLayout heading="Aktueller Handlungsbedarf">
      <Typography variant="h6" component="h2" mb={3}>
        Fallback-Konfigurationen
      </Typography>
      <Box mb={4}>
        {fallbacksLoading ? (
          <CircularProgress />
        ) : fallbackIssues.error || !fallbackIssues.data ? (
          <Alert severity="error">Es ist ein Fehler beim Laden der Fallback-Daten aufgetreten</Alert>
        ) : !hasMissingFieldsOfLaw && !hasTemporaryMissingFieldsOfLaw ? (
          <Alert severity="info">Es sind keine Probleme mit Fallback-Konfigurationen bekannt.</Alert>
        ) : (
          <Box display="flex">
            <ErrorListCard
              title="Fehlende Rechtsgebiete"
              list={fieldOfLawNamesMissing}
              severity={hasMissingFieldsOfLaw ? 'error' : 'success'}
              sx={fallBackInfoStyles}
            >
              {hasMissingFieldsOfLaw
                ? 'Für folgende Rechtsgebiete wurden keine Fallback-Konfigurationen gefunden:'
                : 'Keine Probleme gefunden.'}
            </ErrorListCard>
            <ErrorListCard
              title="Urlaubsbedingte Abdeckungsprobleme"
              list={fallbackIssues.data.temporaryMissingProblems
                .map(problem => problem.fieldOfLaw?.name)
                .filter((element): element is string => Boolean(element))}
              severity={hasTemporaryMissingFieldsOfLaw ? 'warning' : 'success'}
              sx={fallBackInfoStyles}
            >
              {hasTemporaryMissingFieldsOfLaw
                ? 'Für folgende Rechtsgebiete gibt es wegen Urlaubszeiten keine genügende Abdeckung:'
                : 'Keine Probleme gefunden.'}
            </ErrorListCard>
          </Box>
        )}
      </Box>
    </PageLayout>
  )
}
