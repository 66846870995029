/* eslint-disable fp/no-mutating-methods */
import { zodResolver } from '@hookform/resolvers/zod'
import { ArrowDownward, ArrowUpward, Delete } from '@mui/icons-material'
import { Box, Button, Chip, InputAdornment, TextField as MuiTextField, Typography, Stack, IconButton } from '@mui/material'
import React, { FC, useEffect } from 'react'
import { FormProvider, useFieldArray, useForm } from 'react-hook-form'
import { useHistory } from 'react-router'
import uriTemplate from 'uri-templates'

import { AbortButton, FormActionBox, PageLayout, SaveButton, DeleteWithConfirmation, Grid } from 'common/components-mui'
import { Autocomplete, Checkbox, TextField } from 'common/components-mui/react-hook-form'
import { FIELDS_OF_LAW_ENTRIES, PRODUCTS_ENDPOINT, URLs } from 'common/constants'
import { useRedirect } from 'common/hooks'
import { enqueueSnackbar, request } from 'common/utils'

import {
  PartnerDiscountConfiguration,
  FeatureConfiguration,
  PaymentConfiguration,
  MatchingConfiguration,
  MarketingConfiguration,
  Accordion,
} from '../components'
import { productSchema } from '../constants/formSchema'
import createProductMutation from '../graphql/createProduct.graphql'
import deleteProductMutation from '../graphql/deleteProduct.graphql'
import getProductsQuery from '../graphql/getProducts.graphql'
import updateProductMutation from '../graphql/updateProduct.graphql'
import { useProductPartnersData } from '../hooks'
import { useBruttoPrice } from '../hooks/useBruttoPrice'
import { mapProductAPIDataToForm } from '../hooks/useProductPartnersData'
import { Contact, PaymentMethod, ProductFormValues, productInitialValues, FeatureEnum } from '../interfaces/form'
import {
  ContactType,
  CreateProductMutation,
  ProductInput,
  UpdateProductMutation,
  FeatureType,
  DeleteProductMutation,
  PaymentMethodType,
  GetProductsQuery,
} from '../interfaces/schemaDefinition'
import { convertPercentageToDecimal, convertToNum } from '../utils'

const TO_OVERVIEW = '/products'
const TO_EDIT_VIEW = uriTemplate('/products/edit/{id}')

const convertContactType = (contact: Contact): ContactType => {
  if (contact.includes(Contact.Callback)) {
    return ContactType.Callback
  } else if (contact.includes(Contact.Email)) {
    return ContactType.Email
  } else {
    return ContactType.Redirect
  }
}

const convertFeatureEnum = (featureType: string): FeatureType => {
  if (featureType.includes(FeatureEnum.Normal)) {
    return FeatureType.Normal
  } else if (featureType.includes(FeatureEnum.Special)) {
    return FeatureType.Special
  } else if (featureType.includes(FeatureEnum.Optional)) {
    return FeatureType.Optional
  } else {
    return FeatureType.Excluded
  }
}

const convertPaymentMethodType = (method: PaymentMethod): PaymentMethodType => {
  if (method.includes(PaymentMethod.Paypal)) {
    return PaymentMethodType.Paypal
  } else if (method.includes(PaymentMethod.Creditcard)) {
    return PaymentMethodType.Creditcard
  } else {
    return PaymentMethodType.Klarna
  }
}

const toApiInputFormat = (productValues: ProductFormValues): ProductInput => {
  // eslint-disable-next-line fp/no-rest-parameters
  const { grossPrice, ...values } = productValues

  const baseInput = {
    ...values,
    netPrice: convertToNum(values.netPrice),
    rrp: values.rrp ? convertToNum(values.rrp) : null,
    contactTypes: values.contactTypes.map(contact => convertContactType(contact)),
    fieldsOfLaw: values.fieldsOfLaw.map(fieldOfLaw => fieldOfLaw.id),
    partners: values.partners.map(p => p.id),
    services: (values.services ?? []).map(({ service }) => service.trim()).filter(Boolean),
    features: values.features.map(feature => ({ ...feature, type: convertFeatureEnum(feature.type!) })),
    partnerDiscounts: values.partnerDiscounts.map(partnerDiscount => ({
      id: partnerDiscount.id,
      partners: partnerDiscount.partners.map(p => p.id),
      discount: convertPercentageToDecimal(partnerDiscount.discount),
      active: partnerDiscount.active,
    })),
    payment: {
      ...values.payment,
      methods: values.payment.methods.map(method => convertPaymentMethodType(method)),
    },
  }

  return baseInput
}

export const ProductDetailsPage: FC = () => {
  const history = useHistory()
  const productPath = history.location.pathname.replace(`${URLs.product.home}/`, '').split('/')
  const redirect = useRedirect()

  const existingProduct = !(productPath[2] === 'new')
  const { initialValues, partnersList: partners, isLoading: loading } = useProductPartnersData(productPath[3] ?? 'new')
  const methods = useForm({
    defaultValues: productInitialValues,
    resolver: zodResolver(productSchema),
  })
  const {
    control,
    reset,
    watch,
    getValues,
    setValue,
    setError,
    clearErrors,
    handleSubmit,
    formState: { errors },
  } = methods

  const { fields, append, move, remove } = useFieldArray({
    control,
    name: 'services',
  })

  const [locked, netPrice, grossPrice, selectedPartners, selectedFieldsOfLaw, features, partnerDiscounts] = watch([
    'locked',
    'netPrice',
    'grossPrice',
    'partners',
    'fieldsOfLaw',
    'features',
    'partnerDiscounts',
  ])
  const { price, touchedNetPrice } = useBruttoPrice(initialValues, { netPrice, grossPrice }, errors.netPrice)

  const allFieldsOfLawSelected = selectedFieldsOfLaw.length === FIELDS_OF_LAW_ENTRIES.length
  const allPartnersSelected = selectedPartners.length === partners.length
  useEffect(() => {
    if (initialValues) {
      reset(initialValues)
    }
  }, [initialValues, reset])

  const productIdExists = async (productId: string): Promise<boolean> => {
    try {
      const res = await request<GetProductsQuery>(PRODUCTS_ENDPOINT, getProductsQuery)
      const findProduct = res.products.list.find(prod => productId === prod.id)
      return !!(findProduct && !existingProduct)
    } catch (err) {
      enqueueSnackbar(`Ein Fehler ist aufgetreten: ${err}`, { variant: 'error' })
      return true
    }
  }

  const updateProduct = async (input: ProductInput): Promise<void> => {
    const { updateProduct: updatedProduct } = await request<UpdateProductMutation>(PRODUCTS_ENDPOINT, updateProductMutation, {
      product: input,
    })
    if (updatedProduct) {
      reset(mapProductAPIDataToForm(updatedProduct, partners))
    }
  }

  const createProduct = async (input: ProductInput, close = false): Promise<void> => {
    const productIdInUse = await productIdExists(input.id)

    if (productIdInUse) {
      setError('id', { type: 'value', message: 'Diese Produkt-ID existiert bereits' })
      return
    }

    const { createProduct: createdProduct } = await request<CreateProductMutation>(PRODUCTS_ENDPOINT, createProductMutation, {
      product: input,
    })

    if (createdProduct) {
      reset(mapProductAPIDataToForm(createdProduct, partners))
    }

    if (!close) {
      redirect(TO_EDIT_VIEW.fillFromObject({ id: getValues('id') }))
    }
  }

  const onSubmit = async (values: ProductFormValues, close = false): Promise<void> => {
    const input = toApiInputFormat(values)
    clearErrors('id')

    try {
      await (existingProduct ? updateProduct(input) : createProduct(input, close))
      enqueueSnackbar(`Product '${values.id}' wurde erfolgreich gespeichert`, { variant: 'success' })
    } catch (error) {
      enqueueSnackbar(`Ein Fehler ist aufgetreten: ${error}`, { variant: 'error' })
    }

    if (close) {
      redirect(TO_OVERVIEW)
    }
  }

  const handleDeleteProduct = async (): Promise<void> => {
    const id = getValues('id')

    try {
      const { deleteProduct } = await request<DeleteProductMutation>(PRODUCTS_ENDPOINT, deleteProductMutation, { id })

      if (deleteProduct.id) {
        enqueueSnackbar(`Produkt mit dem Namen '${deleteProduct.name}' wurde erfolgreich gelöscht.`, { variant: 'success' })
        redirect(TO_OVERVIEW)
      }
    } catch (error) {
      enqueueSnackbar(`Ein Fehler ist aufgetreten: ${error}`, { variant: 'error' })
    }
  }

  return (
    <PageLayout heading={initialValues?.id ? 'Produkt bearbeiten' : 'Produkt anlegen'}>
      <Box component="form" id="product" onSubmit={handleSubmit(values => onSubmit(values))}>
        <Grid container spacing={3} sx={theme => ({ mb: theme.spacing(2) })}>
          <Grid xs={12} md={6}>
            <Stack spacing={3}>
              <Checkbox name="active" label="Aktiv" control={control} disabled={loading} />
              <TextField
                control={control}
                name="id"
                label="ID"
                sx={{ marginTop: 0 }}
                disabled={loading || existingProduct}
                fullWidth
              />

              <TextField control={control} name="name" label="Produktname" disabled={loading} fullWidth />
              <TextField control={control} name="title" label="Titel" disabled={loading} fullWidth />
              <TextField control={control} name="subtitle" label="Untertitel" disabled={loading} fullWidth />
              <Autocomplete
                control={control}
                name="contactTypes"
                label="Kontaktarten"
                multiple
                options={Object.values(Contact)}
                disabled={loading}
                ChipProps={{ color: 'primary' }}
                fullWidth
              />
            </Stack>
          </Grid>
          {locked && (
            <Grid sm={12}>
              <Chip
                label="Systemprodukt"
                color="primary"
                sx={{ width: 'fit-content', gridRow: 1, gridColumn: 2, justifySelf: 'end', alignSelf: 'center' }}
              />
            </Grid>
          )}
          <Grid xs={12} md={6}>
            <Stack spacing={3}>
              <Typography component="legend" variant="h5" sx={{ marginBottom: '12px' }}>
                Preisangaben
              </Typography>
              <TextField
                control={control}
                name="netPrice"
                label="Nettopreis"
                InputProps={{
                  startAdornment: <InputAdornment position="start">€</InputAdornment>,
                }}
                disabled={loading}
                placeholder="z.B. 49,99 oder 50"
                fullWidth
              />
              <MuiTextField
                label="Bruttopreis"
                value={price}
                InputProps={{
                  startAdornment: <InputAdornment position="start">€</InputAdornment>,
                }}
                disabled
                fullWidth
              />
              <TextField
                control={control}
                name="rrp"
                label="UVP"
                InputProps={{
                  startAdornment: <InputAdornment position="start">€</InputAdornment>,
                }}
                disabled={loading}
                placeholder="z.B. 49,99 oder 50"
                fullWidth
              />
            </Stack>
          </Grid>
          <Grid container xs={12}>
            <Grid xs={12} sm={9} lg={6}>
              <Autocomplete
                control={control}
                name="fieldsOfLaw"
                label="Rechtsgebiete"
                multiple
                options={FIELDS_OF_LAW_ENTRIES}
                isOptionEqualToValue={(option: { id: string; name: string }, value: { id: string; name: string }) =>
                  option.id === value.id
                }
                getOptionLabel={(option: { id: string; name: string }) => option.name}
                limitTags={5}
                disabled={loading}
                ChipProps={{ color: 'primary' }}
                fullWidth
              />
            </Grid>
            <Grid display="flex" sm={3} lg={6}>
              <Button
                sx={{ margin: 'auto', marginLeft: '0px' }}
                variant="outlined"
                color="primary"
                onClick={() =>
                  allFieldsOfLawSelected ? setValue('fieldsOfLaw', []) : setValue('fieldsOfLaw', FIELDS_OF_LAW_ENTRIES)
                }
              >
                {allFieldsOfLawSelected ? 'Alle entfernen' : 'Alle auswählen'}
              </Button>
            </Grid>
          </Grid>

          <Grid container xs={12}>
            <Grid xs={12} sm={9} lg={6}>
              <Autocomplete
                control={control}
                name="partners"
                label="Partner"
                multiple
                options={partners}
                isOptionEqualToValue={(option: (typeof partners)[number], value: (typeof partners)[number]) =>
                  option.id === value.id
                }
                getOptionLabel={(option: (typeof partners)[number]) => (typeof option === 'object' ? option.name : option)}
                limitTags={5}
                disabled={loading}
                ChipProps={{ color: 'primary' }}
                fullWidth
              />
            </Grid>
            <Grid display="flex" sm={3} lg={6}>
              <Button
                variant="outlined"
                color="primary"
                sx={{ margin: 'auto', marginLeft: '0px' }}
                onClick={() => (allPartnersSelected ? setValue('partners', []) : setValue('partners', partners))}
              >
                {allPartnersSelected ? 'Alle entfernen' : 'Alle auswählen'}
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <FeatureConfiguration
          existingProduct={existingProduct}
          value={features}
          onChange={value => setValue('features', value)}
        />
        <Accordion title="Leistungen des Anwalts">
          <Box>
            {fields.map((field, index) => (
              <Grid key={field.id} container spacing={3}>
                <Grid sm={2} lg={1} alignItems="center" display="flex">
                  <IconButton color="primary" disabled={index === 0} onClick={() => move(index, index - 1)}>
                    <ArrowUpward />
                  </IconButton>
                  <IconButton color="primary" disabled={index === fields.length - 1} onClick={() => move(index, index + 1)}>
                    <ArrowDownward />
                  </IconButton>
                </Grid>
                <Grid sm={9} lg={6} alignItems="center">
                  <TextField
                    fullWidth
                    sx={{ flexGrow: 1 }}
                    control={control}
                    name={`services.${index}.service`}
                    label={`${index + 1}. Aufgabe des Anwalts`}
                  />
                </Grid>
                <Grid sm={1} alignItems="center" display="flex">
                  <IconButton color="primary" onClick={() => remove(index)}>
                    <Delete />
                  </IconButton>
                </Grid>
              </Grid>
            ))}
            <Box sx={{ marginTop: '1rem' }}>
              <Button variant="contained" onClick={() => append({ service: '' })}>
                Add
              </Button>
            </Box>
          </Box>
        </Accordion>
        <PartnerDiscountConfiguration
          partners={partners}
          netPrice={netPrice}
          value={partnerDiscounts}
          onChange={value => setValue('partnerDiscounts', value)}
          touchedNetPrice={touchedNetPrice}
          existingProduct={existingProduct}
          error={errors.netPrice}
        />
        <FormProvider {...methods}>
          <PaymentConfiguration loading={loading} existingProduct={existingProduct} />
          <MatchingConfiguration loading={loading} existingProduct={existingProduct} />
          <MarketingConfiguration loading={loading} existingProduct={existingProduct} />
        </FormProvider>
        <FormActionBox>
          {existingProduct && (
            <DeleteWithConfirmation actionButtonText="Produkt löschen" onConfirm={() => handleDeleteProduct()} disabled={locked}>
              <Typography>Diese Aktion ist nicht umkehrbar. Produkt wird permanent gelöscht.</Typography>
            </DeleteWithConfirmation>
          )}
          <AbortButton
            onClick={() => {
              redirect(TO_OVERVIEW)
            }}
          />
          <SaveButton submit form="product" />
          <SaveButton submit={false} onClick={handleSubmit(values => onSubmit(values, true))}>
            Speichern und Schließen
          </SaveButton>
        </FormActionBox>
      </Box>
    </PageLayout>
  )
}
