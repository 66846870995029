import React, { Fragment, FunctionComponent } from 'react'

import { containsSome } from 'common/utils'

import { UserContextProps } from './interfaces'
import { withUser } from './withUser'

interface IsAuthorizedProps {
  roles: Array<string>
  fallback?: JSX.Element
}

const IsAuthorized: FunctionComponent<IsAuthorizedProps & UserContextProps> = ({ user, roles, children, fallback }) => {
  if (user && containsSome(roles)(user.roles)) {
    return <Fragment>{children}</Fragment>
  }
  return fallback ?? null
}

const IsAuthorizedWrapped = withUser(IsAuthorized)

export { IsAuthorizedWrapped as IsAuthorized }
