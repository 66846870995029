import { IconButton } from '@mui/material'
import { closeSnackbar } from 'notistack'
import React from 'react'

import SVGExit from 'common/icons/exit.svg'

export const CloseSnackbarAction = (snackbarId: string): JSX.Element => (
  <IconButton
    sx={{
      color: 'white',
    }}
    onClick={() => {
      closeSnackbar(snackbarId)
    }}
  >
    <SVGExit height="14" width="14" />
  </IconButton>
)
