// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dX1ZC0_xIPpDUWGXSmvp{margin-left:34px}.isTXiW4Fu1nf9Tg7C4c2{fill:#0a1343;vertical-align:sub;margin-right:10px}.g22YyI6MPlCGi5x5OmjQ{margin-left:34px}`, "",{"version":3,"sources":["webpack://./src/common/ui/Panel/PanelSegment/PanelSegment.module.scss"],"names":[],"mappings":"AAAA,sBACE,gBAAA,CAGF,sBACE,YAAA,CACA,kBAAA,CACA,iBAAA,CAGF,sBACE,gBAAA","sourcesContent":[".wihtoutIcon {\n  margin-left: 34px;\n}\n\n.icon {\n  fill: #0a1343;\n  vertical-align: sub;\n  margin-right: 10px;\n}\n\n.children {\n  margin-left: 34px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wihtoutIcon": `dX1ZC0_xIPpDUWGXSmvp`,
	"icon": `isTXiW4Fu1nf9Tg7C4c2`,
	"children": `g22YyI6MPlCGi5x5OmjQ`
};
export default ___CSS_LOADER_EXPORT___;
