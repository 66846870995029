import { useEffect } from 'react'

export * from './router'
export * from './Redirect'

/**
 * Smoothly scrols to top of the page after Component rendered.
 * Only use on Page components!
 */
export const useScrollToTop = (): void => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [])
}
