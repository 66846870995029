import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs as DateAdapter } from '@mui/x-date-pickers/AdapterDayjs'
import localeDE from 'dayjs/locale/de'
import { SnackbarProvider } from 'notistack'
import React, { FC } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'

import { UserProvider } from 'common/user-context'
import { Backend } from 'layouts/backend'
import { Login } from 'layouts/login'
import theme from 'theme/theme'

import { Apollo } from './apollo'

export const App: FC = () => (
  <Apollo>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={DateAdapter} locale={localeDE}>
          <SnackbarProvider>
            <BrowserRouter>
              <Switch>
                <Route path="/login" component={Login} />
                <Route path="/login-azure" component={Login} />
                <Route path="/password-reset" component={Login} />
                <Route path="/password-set" component={Login} />
                <Route
                  path="/"
                  render={() => (
                    <UserProvider>
                      <Backend />
                    </UserProvider>
                  )}
                />
              </Switch>
            </BrowserRouter>
          </SnackbarProvider>
        </LocalizationProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  </Apollo>
)
