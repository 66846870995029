import { FieldValues } from 'react-hook-form'
import { z } from 'zod'

import { CoverageFieldType, InputField } from 'common/graphql/schemaDefinition'

import { Path } from './Path'

export type FormTemplate = Array<InputField>

const emptyError = {
  invalid_type_error: 'Bitte machen Sie eine Angabe.',
  required_error: 'Bitte machen Sie eine Angabe.',
}

const optionalStringSchema = z.string(emptyError).optional().or(z.literal(''))
const mailSchema = z.string(emptyError).email('Bitte geben Sie eine gültige E-Mail an.')
const numberSchema = z.coerce.number({
  required_error: emptyError.required_error,
  invalid_type_error: 'Bitte geben Sie eine Zahl ein.',
})

const getSchemaForType = (type: CoverageFieldType, optional = false): unknown => {
  switch (type) {
    case CoverageFieldType.Date: {
      return optional ? z.date(emptyError).optional() : z.date(emptyError)
    }
    /**
     * @todo What to expect for optional values?
     */
    case CoverageFieldType.InputCurrency:
      return z
        .string(emptyError)
        .min(1, 'Bitte geben Sie eine gültige Zahl ein.')
        .regex(/^(\d{1,3}(?:\.\d{3})*|\d+)(?:,\d{2})?$/, 'Bitte geben Sie eine gültige Zahl ein.')
        .transform(value => `${value}€`)
    case CoverageFieldType.Select:
      return optional ? optionalStringSchema : z.string(emptyError).min(1, 'Bitte treffen Sie eine Auswahl.')
    case CoverageFieldType.Checkbox:
      return optional ? z.boolean(emptyError).optional() : z.boolean(emptyError)
    case CoverageFieldType.Email:
      return optional ? optionalStringSchema : mailSchema
    case CoverageFieldType.Number:
      return optional ? optionalStringSchema : numberSchema
    default: {
      return optional ? optionalStringSchema : z.string(emptyError).min(1, emptyError.required_error)
    }
  }
}

export const createFormSchema = (template: FormTemplate): ReturnType<typeof z.object<{}>> => {
  const schema = template.map(({ type, label, dependsOn, optional }) => [
    label,
    getSchemaForType(type, !!dependsOn || !!optional),
  ])

  return z.object(Object.fromEntries(schema))
}

export const getDefaultValue = (type: CoverageFieldType): boolean | string | Date => {
  switch (type) {
    case CoverageFieldType.Checkbox:
      return false
    case CoverageFieldType.Date:
      return new Date()
    default:
      return ''
  }
}

export function flattenObject<T extends Record<string, any>>(ob: T): Partial<{ [key in Path<T>]: unknown }> {
  const keyFactory = (pk: string | number, ck: string | number): string => `${pk}.${ck}`
  const toReturn: Partial<{ [key in Path<T>]: unknown }> = {}

  return ob
    ? Object.keys(ob).reduce((acc, curr) => {
        if (!Object.prototype.hasOwnProperty.call(ob, curr)) return acc
        if (typeof ob[curr] === 'object') {
          const flatObject = flattenObject(ob[curr])
          const temp = Object.keys(flatObject).reduce(
            (prev, key) => ({
              ...prev,
              [keyFactory(curr, key)]: flatObject[key],
            }),
            toReturn
          )

          return { ...acc, ...temp }
        } else {
          return {
            ...acc,
            [curr]: ob[curr],
          }
        }
      }, toReturn)
    : toReturn
}

export const createDefaultFormValues = (template: FormTemplate, request: { [key: string]: unknown }): FieldValues =>
  Object.fromEntries(
    template.map(({ label, type, path }) => [label, path && path in request ? request[path] : getDefaultValue(type)])
  )

type SuperRefineCoverageSchema = (
  template: FormTemplate
) => <T extends { [key: string]: unknown }>(schema: T, context: z.RefinementCtx) => void

/**
 * Validates optional values if their conditions are met.
 * Also responsible to check more restictive types
 */
export const superRefineCoverageSchema: SuperRefineCoverageSchema = template => (schema, context) => {
  template.forEach(({ dependsOn, label, optional, type }) => {
    if (!dependsOn) return
    const { field, value } = dependsOn
    const fieldValue = schema[label]
    const dependencyActive = schema[field] === value
    const hasAndNeedsValue = optional || !!fieldValue

    if (!dependencyActive) return

    // If value is falsy and its´s dependency is met, communicate value as required
    if (!hasAndNeedsValue) {
      context.addIssue({
        message: 'Bitte geben Sie einen Wert an.',
        code: z.ZodIssueCode.custom,
        path: [label],
      })
      return
    }

    // Account for special Input types
    switch (type) {
      case CoverageFieldType.Email:
        {
          const results = mailSchema.safeParse(fieldValue)
          !results.success && results.error.issues.forEach(issue => context.addIssue({ ...issue, path: [label] }))
        }
        break
      case CoverageFieldType.Number:
        {
          const results = numberSchema.safeParse(fieldValue)
          !results.success && results.error.issues.forEach(issue => context.addIssue({ ...issue, path: [label] }))
        }
        break
      default:
        break
    }
  })
}
