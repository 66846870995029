import React from 'react'
import { Route } from 'react-router-dom'

import { LoginBox, PasswordReset, PasswordSet } from 'packages-mui/login'
import { LoginBoxAzure } from 'packages-mui/login-azure'

export const Login: React.FC = () => (
  <>
    <Route exact path="/login" component={LoginBox} />
    <Route exact path="/login-azure" component={LoginBoxAzure} />
    <Route exact path="/password-reset" component={PasswordReset} />
    <Route exact path="/password-set/:token?" component={PasswordSet} />
  </>
)
