import React, { FC } from 'react'
import { render } from 'react-dom'
import smoothscroll from 'smoothscroll-polyfill'

import 'common/styles/main.scss'
import { App } from './app.component'
import './fa.init'

smoothscroll.polyfill()

const renderApp = (Component: FC): void => {
  render(<Component />, document.getElementById('app'))
}

renderApp(App)
