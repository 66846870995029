import { Box, SxProps, Theme } from '@mui/material'
import React, { FC, PropsWithChildren } from 'react'

type OverlayPositioning = 'top' | 'bottom' | 'left' | 'right'

interface ElementWithOverlayProps {
  overlayElement: React.ReactNode
  sx?: SxProps<Theme>
  positioning?: Array<OverlayPositioning> // if ommitted the element will center
}

export const ElementWithOverlay: FC<PropsWithChildren<ElementWithOverlayProps>> = ({
  overlayElement,
  positioning,
  children,
  sx,
}) => {
  const overlayPosition = Object.fromEntries((positioning ?? []).map(pos => [pos, 0]))
  return (
    <Box sx={sx ?? {}} display="flex" alignContent="center" justifyContent="center" alignItems="center" position="relative">
      <Box
        display="flex"
        alignContent="center"
        justifyContent="center"
        alignItems="center"
        position="absolute"
        sx={overlayPosition}
      >
        {overlayElement}
      </Box>
      {children}
    </Box>
  )
}
