import { Card, CardHeader, CardContent, Typography, List, ListItem, ListItemText, Avatar } from '@mui/material'
import { lighten, darken } from '@mui/material/styles'
import React, { FC } from 'react'

import { ErrorListCardProps } from '../interfaces'

export const ErrorListCard: FC<ErrorListCardProps> = ({
  children,
  severity,
  sx,
  list,
  title,
  showNumber = true,
  maxHeight = 500,
}) => (
  <Card sx={sx}>
    <CardHeader
      title={title}
      titleTypographyProps={{
        variant: 'body2',
        fontWeight: 'bold',
      }}
      avatar={
        showNumber ? (
          <Avatar sx={{ bgcolor: theme => theme.palette[severity].main }}>{list.length < 100 ? list.length : '99+'}</Avatar>
        ) : null
      }
      sx={theme => ({
        backgroundColor: lighten(theme.palette[severity].main, 0.9),
        color: darken(theme.palette[severity].main, 0.6),
      })}
    />
    <CardContent>
      <Typography>{children}</Typography>
      <List
        sx={{
          maxHeight: () => `${maxHeight}px`,
          overflowY: 'auto',
        }}
      >
        {list.map(item => (
          <ListItem key={item}>
            <ListItemText>{item}</ListItemText>
          </ListItem>
        ))}
      </List>
    </CardContent>
  </Card>
)
