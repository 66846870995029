// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.IpOW1wFW1sOx7yVeNzWN{font-size:20px;font-weight:500;letter-spacing:.2px;align-items:center;margin-left:15px}.P1_g1ff9QRyIzs4ExURg{margin-bottom:26px;display:flex;justify-content:space-between;flex-wrap:wrap;gap:15px}.XNKWcX_e4uwSq2tu2UNU{margin-left:auto;display:flex;flex-wrap:wrap;gap:15px}@media screen and (max-width: 600px){.XNKWcX_e4uwSq2tu2UNU{margin:unset;float:unset;flex-wrap:wrap;gap:.5rem}.XNKWcX_e4uwSq2tu2UNU>*{margin:unset;flex-grow:1}}`, "",{"version":3,"sources":["webpack://./src/common/ui/Toolbar/Toolbar.module.scss"],"names":[],"mappings":"AAAA,sBACE,cAAA,CACA,eAAA,CACA,mBAAA,CACA,kBAAA,CACA,gBAAA,CAGF,sBACE,kBAAA,CACA,YAAA,CACA,6BAAA,CACA,cAAA,CACA,QAAA,CAGF,sBACE,gBAAA,CACA,YAAA,CACA,cAAA,CACA,QAAA,CAIF,qCACE,sBACE,YAAA,CACA,WAAA,CACA,cAAA,CACA,SAAA,CAEA,wBACE,YAAA,CACA,WAAA,CAAA","sourcesContent":[".title {\n  font-size: 20px;\n  font-weight: 500;\n  letter-spacing: 0.2px;\n  align-items: center;\n  margin-left: 15px;\n}\n\n.toolbar {\n  margin-bottom: 26px;\n  display: flex;\n  justify-content: space-between;\n  flex-wrap: wrap;\n  gap: 15px;\n}\n\n.buttons {\n  margin-left: auto;\n  display: flex;\n  flex-wrap: wrap;\n  gap: 15px;\n}\n\n// sm breakpoint from mui theme\n@media screen and (max-width: 600px) {\n  .buttons {\n    margin: unset;\n    float: unset;\n    flex-wrap: wrap;\n    gap: 0.5rem;\n\n    > * {\n      margin: unset;\n      flex-grow: 1;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": `IpOW1wFW1sOx7yVeNzWN`,
	"toolbar": `P1_g1ff9QRyIzs4ExURg`,
	"buttons": `XNKWcX_e4uwSq2tu2UNU`
};
export default ___CSS_LOADER_EXPORT___;
