// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.xw_qjJyC2f2QNVdW6SWr{display:flex;flex-direction:column;flex:1 1 0;gap:2rem}@media(min-width: 600px){.xw_qjJyC2f2QNVdW6SWr{flex-direction:row}}`, "",{"version":3,"sources":["webpack://./src/packages/lawyers/components/ConsultationPanel/ConsultationPanel.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,qBAAA,CACA,UAAA,CACA,QAAA,CAEA,yBANF,sBAOI,kBAAA,CAAA","sourcesContent":[".flex {\n  display: flex;\n  flex-direction: column;\n  flex: 1 1 0;\n  gap: 2rem;\n\n  @media (min-width: 600px) {\n    flex-direction: row;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"flex": `xw_qjJyC2f2QNVdW6SWr`
};
export default ___CSS_LOADER_EXPORT___;
