import { Box, Typography } from '@mui/material'
import React, { FC } from 'react'

interface StaticFieldProps {
  label: string
  value: React.ReactNode
  className?: string
}

export const StaticField: FC<StaticFieldProps> = ({ value, label }) => (
  <Box m={1.25}>
    <Typography component="div" fontWeight={500} variant="body2" sx={{ color: '#707070' }}>
      {value}
    </Typography>
    <Typography fontWeight={500} sx={theme => ({ color: theme.palette.disabled.main })} variant="caption">
      {label}
    </Typography>
  </Box>
)
