interface PageURLs {
  home: string
  [key: string]: any // should be: string | PageURLs but TypeScript is stupid
}

interface UrlList {
  [key: string]: PageURLs
}

const ADVICE_REQUEST_HOME = '/advice-request'
const ADVICE_REQUESTS_HOME = '/advice-requests'
const ADVICE_REQUESTS_CALLBACKS = '/callbacks'
const CHANCELLERY_HOME = '/chancellery'
const CHANCELLERY_ADVICE_REQUESTS_HOME = `${CHANCELLERY_HOME}/advice-requests`
const CHANCELLERY_B2B_HOME = `${CHANCELLERY_HOME}/b2b`
const CHANCELLERIES_HOME = '/chancelleries'
const CONTROLLING_HOME = '/controlling'
const FIELDS_OF_LAW_HOME = '/fields-of-law'
const HELP_HOME = '/support'
const USERS_HOME = '/users'
const PRODUCTS_HOME = '/products'
const PRODUCT_HOME = '/product'
const PRODUCTS_BUSINESS_HOME = '/products-business'
const PRODUCT_BUSINESS_HOME = '/product-business'
const PARTNERS_HOME = '/partners'

const _URLs: UrlList = {
  adviceRequest: {
    home: ADVICE_REQUEST_HOME,
    detail: `${ADVICE_REQUEST_HOME}/detail`,
  },
  adviceRequests: {
    home: ADVICE_REQUESTS_HOME,
    form: `${ADVICE_REQUESTS_HOME}/form`,
    callbacks: ADVICE_REQUESTS_CALLBACKS,
  },
  chancellery: {
    home: CHANCELLERY_HOME,
    dashboard: `${CHANCELLERY_HOME}/dashboard`,
    adviceRequests: {
      home: CHANCELLERY_ADVICE_REQUESTS_HOME,
      openRequests: `${CHANCELLERY_ADVICE_REQUESTS_HOME}/open-requests`,
      potentialMandates: `${CHANCELLERY_ADVICE_REQUESTS_HOME}/potential-mandates`,
      closedRequests: `${CHANCELLERY_ADVICE_REQUESTS_HOME}/closed-requests`,
      mandates: `${CHANCELLERY_ADVICE_REQUESTS_HOME}/mandates`,
      completeMandates: `${CHANCELLERY_ADVICE_REQUESTS_HOME}/complete-mandates`,
    },
    b2b: {
      home: CHANCELLERY_B2B_HOME,
      open: `${CHANCELLERY_B2B_HOME}/open`,
      processing: `${CHANCELLERY_B2B_HOME}/processing`,
      complete: `${CHANCELLERY_B2B_HOME}/complete`,
      closed: `${CHANCELLERY_B2B_HOME}/closed`,
    },
    sales: `${CHANCELLERY_HOME}/sales`,
  },
  chancelleries: {
    home: CHANCELLERIES_HOME,
    new: `${CHANCELLERIES_HOME}/new`,
  },
  controlling: {
    home: CONTROLLING_HOME,
    advice: `${CONTROLLING_HOME}/advice`,
    contactType: `${CONTROLLING_HOME}/contact-type`,
    fieldsOfLaw: `${CONTROLLING_HOME}/fields-of-law`,
    legalProtection: `${CONTROLLING_HOME}/legal-protection`,
    conversionMandate: `${CONTROLLING_HOME}/conversion-mandate`,
  },
  fieldsOfLaw: {
    home: FIELDS_OF_LAW_HOME,
  },
  help: {
    home: HELP_HOME,
  },
  users: {
    home: USERS_HOME,
    new: `${USERS_HOME}/new`,
  },
  lawyerControlling: {
    home: `${CHANCELLERY_HOME}/controlling`,
  },
  product: {
    home: PRODUCT_HOME,
  },
  products: {
    home: PRODUCTS_HOME,
  },
  productBusiness: {
    home: PRODUCT_BUSINESS_HOME,
  },
  productsBusiness: {
    home: PRODUCTS_BUSINESS_HOME,
  },
  partners: {
    home: PARTNERS_HOME,
  },
}

export const URLs: Readonly<UrlList> = Object.freeze(_URLs)
