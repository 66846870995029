import { zodResolver } from '@hookform/resolvers/zod'
import TestCalculationIcon from '@mui/icons-material/InsertChartOutlined'
import {
  Box,
  Dialog,
  DialogTitle,
  IconButton,
  IconButtonProps,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Alert,
} from '@mui/material'
import React, { FC, useState } from 'react'
import { useForm } from 'react-hook-form'

import { SaveButton, FormActionBox } from 'common/components-mui'
import { Autocomplete, DateTimePicker, TextField } from 'common/components-mui/react-hook-form'
import { ADVICE_REQUESTS_ENDPOINT, FIELDS_OF_LAW_ENTRIES, Roles } from 'common/constants'
import { withAuthorization } from 'common/user-context'
import { request } from 'common/utils'

import { weightMap } from '../constants'
import testCalculationQuery from '../graphql/testCalculation.graphql'
import { useProductAndPartnerData } from '../hooks'
import { ConfigTestPanelFormValues, configTestPanelInitialValues, configTestSchema } from '../interfaces/formSchemas'
import { TestCalculationQuery } from '../interfaces/schemaDefinition'
import { sortProducts } from '../utils'

const TableLink: FC<{ children: string; href: string }> = ({ href, children }) => (
  <Box component="a" sx={{ color: theme => theme.palette.primary.main }} target="_blank" rel="noreferrer" href={href}>
    {children}
  </Box>
)

const CandidatesTable: FC<{
  candidates: TestCalculationQuery['matchChancelleries']['candidates']
  style?: React.CSSProperties
}> = ({ candidates, style }) => (
  <Table style={style}>
    <TableHead>
      <TableRow>
        <TableCell>Name</TableCell>
        <TableCell>Adresse</TableCell>
        <TableCell>Rechtsgebiete</TableCell>
        <TableCell>Fallback</TableCell>
        <TableCell>Min</TableCell>
        <TableCell>Max</TableCell>
        <TableCell>Aktuell</TableCell>
        <TableCell>Gewichtung</TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {candidates &&
        candidates.map(matchedLocation => (
          <TableRow key={matchedLocation.id}>
            <TableCell>
              <TableLink href={`/chancelleries/edit/${matchedLocation.chancelleryId}`}>{matchedLocation.name}</TableLink>
            </TableCell>
            <TableCell>
              <TableLink href={`/chancelleries/locations/edit/${matchedLocation.id}`}>
                {`${matchedLocation.address.street}, ${matchedLocation.address.zip} ${matchedLocation.address.city}`}
              </TableLink>
            </TableCell>
            <TableCell>
              {matchedLocation.fallback ? (
                matchedLocation.fieldsOfLaw.map(fol => fol.name).join(', ')
              ) : (
                <TableLink href={`/chancelleries/configurations/edit/${matchedLocation.configId}`}>
                  {matchedLocation.fieldsOfLaw.map(fol => fol.name).join(', ')}
                </TableLink>
              )}
            </TableCell>
            <TableCell>{matchedLocation.fallback ? 'Ja' : 'Nein'}</TableCell>
            <TableCell>{matchedLocation.min || '-'}</TableCell>
            <TableCell>{matchedLocation.max || '-'}</TableCell>
            <TableCell>{matchedLocation.current || 0}</TableCell>
            <TableCell>{matchedLocation.weight ? weightMap[matchedLocation.weight] : '-'}</TableCell>
          </TableRow>
        ))}
    </TableBody>
  </Table>
)

export const ConfigurationTestPanel: FC = () => {
  const [isWaitingForResults, setIsWaitingForResults] = useState(false)
  const [resultList, setResultList] = useState<TestCalculationQuery['matchChancelleries']['chancelleries']>()
  const [candidates, setCandidates] = useState<TestCalculationQuery['matchChancelleries']['candidates']>()
  const [error, setError] = useState()

  const {
    products: { response: productsResponse, error: productsError },
    productsBusiness: { response: productsBusinessResponse, error: productsBusinessError },
    partners: { response: partnersResponse, error: partnersError },
    isLoading: isLoadingProductsAndPartner,
  } = useProductAndPartnerData()

  const products = [...(productsResponse?.data.products.list || []), ...(productsBusinessResponse?.data.products.list || [])]
  const productsAndPartnerError = productsError || productsBusinessError || partnersError

  const { handleSubmit, control } = useForm({
    defaultValues: configTestPanelInitialValues,
    resolver: zodResolver(configTestSchema),
  })

  const onSubmit = async (values: ConfigTestPanelFormValues): Promise<void> => {
    setError(undefined)
    setIsWaitingForResults(true)
    try {
      const result = await request<TestCalculationQuery>(ADVICE_REQUESTS_ENDPOINT, testCalculationQuery, {
        matchInput: {
          address: {
            zip: values.zipCode,
          },
          premium: true,
          language: 'deutsch',
          fieldOfLaw: values.fieldOfLaw?.id,
          productId: values.product?.id,
          partnerId: values.partner?.id,
          time: values.dateTime,
        },
      })
      setResultList(result.matchChancelleries.chancelleries)
      setCandidates(
        result.matchChancelleries.candidates.filter(
          candidate => !result.matchChancelleries.chancelleries.find(match => match.configId === candidate.configId)
        )
      )
    } catch (e) {
      setError(e)
    }
    setIsWaitingForResults(false)
  }

  return (
    <Box m={3} component="form" onSubmit={handleSubmit(onSubmit)}>
      {productsAndPartnerError ? (
        <Alert severity="error">
          {partnersError
            ? 'Partner konnten nicht geladen werden.'
            : productsError
            ? 'B2C Produkte konnten nicht geladen werden.'
            : 'B2B Produkte konnten nicht geladen werden.'}
        </Alert>
      ) : (
        <>
          <Box display="grid" gridTemplateColumns="1fr 1fr" gap={2} mb={3}>
            <TextField control={control} disabled={isLoadingProductsAndPartner} name="zipCode" label="PLZ" />
            <Autocomplete
              control={control}
              disabled={isLoadingProductsAndPartner}
              name="fieldOfLaw"
              label="Rechtsgebiet"
              options={FIELDS_OF_LAW_ENTRIES.concat({ id: '', name: '' })}
              getOptionLabel={option => (typeof option === 'object' ? option.name : option)}
              isOptionEqualToValue={(option, value) => option.id === value.id && option.name === value.name}
            />
            {partnersResponse && (
              <Autocomplete
                control={control}
                disabled={isLoadingProductsAndPartner}
                name="partner"
                label="Partner"
                options={partnersResponse.data.partners.list}
                getOptionLabel={option => (typeof option === 'object' ? option.name : option)}
                isOptionEqualToValue={(option, value) => option.id === value.id && option.name === value.name}
              />
            )}
            {productsResponse && productsBusinessResponse && (
              <Autocomplete
                control={control}
                disabled={isLoadingProductsAndPartner}
                name="product"
                label="Produkt"
                options={sortProducts(products, true)}
                getOptionLabel={(p: { id: string; name: string; type: string }) => `${p.id} (${p.name})`}
                isOptionEqualToValue={(option, value) => option.id === value.id && option.name === value.name}
                groupBy={option => option.type}
              />
            )}
            <DateTimePicker control={control} name="dateTime" label="Zeitpunkt" minutesStep={5} />
          </Box>
          <FormActionBox>
            <SaveButton submit disabled={isLoadingProductsAndPartner}>
              Testmatching starten
            </SaveButton>
          </FormActionBox>
        </>
      )}
      {isWaitingForResults && <LinearProgress />}
      {error && <Alert severity="error">Es ist ein Fehler aufgetreten</Alert>}
      {resultList && !error && (
        <>
          <Typography variant="h6">Ergebnisse</Typography>
          <CandidatesTable candidates={resultList} style={{ marginBottom: '2rem' }} />
          <Typography variant="h6">Weitere Kandidaten</Typography>
          <CandidatesTable candidates={candidates || []} />
        </>
      )}
    </Box>
  )
}

export const ConfigurationTestDialogButton: FC<IconButtonProps> = withAuthorization([Roles.Administrator, Roles.Employee])(
  (props: IconButtonProps) => {
    const [dialogOpen, setDialogOpen] = useState(false)
    return (
      <>
        <IconButton
          sx={{
            [`&:focus`]: {
              outline: 'none',
            },
            color: '#fff',
          }}
          onClick={() => setDialogOpen(true)}
          {...props}
          size="large"
        >
          <TestCalculationIcon titleAccess="Testmatching" />
        </IconButton>
        <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)} maxWidth="xl">
          <DialogTitle>Testmatching</DialogTitle>
          <ConfigurationTestPanel />
        </Dialog>
      </>
    )
  }
)
