import React from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'

import { ButtonConfirm, ButtonCancel } from 'common/ui/Button'
import { Procedure, noOp } from 'common/utils'

export interface ConfirmModalProps {
  open: boolean
  question: string
  description?: React.ReactNode
  onComplete: Procedure
  onCancel?: Procedure
  loading?: boolean
  confirmDisabled?: boolean
}

export const disabledConfirmModalProps: () => ConfirmModalProps = () => ({
  question: '',
  description: '',
  open: false,
  onComplete: noOp,
  onCancel: noOp,
  loading: false,
  confirmDisabled: true,
})

export const ConfirmModal: React.FC<ConfirmModalProps> = (props: ConfirmModalProps) => {
  const { open, question, description, onCancel = noOp, onComplete, loading, confirmDisabled } = props
  return (
    <Modal toggle={onCancel} isOpen={open}>
      <ModalHeader toggle={onCancel}>{question}</ModalHeader>
      <ModalBody>{!!description && <div>{description}</div>}</ModalBody>
      <ModalFooter>
        <ButtonCancel onClick={onCancel}>Abbrechen</ButtonCancel>
        <ButtonConfirm disabled={confirmDisabled} loading={loading} onClick={onComplete}>
          Bestätigen
        </ButtonConfirm>
      </ModalFooter>
    </Modal>
  )
}
