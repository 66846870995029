import { CHANCELLERIES_ENDPOINT } from 'common/constants'
import { request } from 'common/utils'

import saveChancelleryMutation from '../graphql/saveChancellery.graphql'
import saveChancelleryLocationMutation from '../graphql/saveChancelleryLocation.graphql'
import {
  ChancelleryInput,
  ChancelleryLocationInput,
  SaveChancelleryLocationMutation,
  SaveChancelleryMutation,
} from '../interfaces/schemaDefinition'

export const saveChancellery = (chancellery: ChancelleryInput): Promise<SaveChancelleryMutation> =>
  request<SaveChancelleryMutation>(CHANCELLERIES_ENDPOINT, saveChancelleryMutation, { chancellery })

export const saveChancelleryLocation = (
  chancelleryLocation: ChancelleryLocationInput
): Promise<SaveChancelleryLocationMutation> =>
  request<SaveChancelleryLocationMutation>(CHANCELLERIES_ENDPOINT, saveChancelleryLocationMutation, {
    chancelleryLocation,
  })

export { fetchAssignableUsers } from './assignableUsers'
export { saveAdviceRequest } from './saveAdviceRequest'
export { getChancelleryData } from './getChancelleryData'
export { getConfigData } from './getConfigData'
export { getLocationData } from './getLocationData'
export { createSalesDetailsExport } from './createSalesDetailsExport'
export { assignTo } from './assignTo'
export { getChancelleryList } from './getChancelleryList'
