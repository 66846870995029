import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandAllIcon from '@mui/icons-material/OpenWith'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material'
import Alert from '@mui/material/Alert'
import React, { FC, useState } from 'react'
import { Link } from 'react-router-dom'

import { FILE_BASE } from 'app/constants'
import { PageLayout, useGridData, FilterOperator, PageInfoline, HeaderButtonGroup, ExportButton } from 'common/components-mui'
import { CHANCELLERIES_ENDPOINT } from 'common/constants'
import { useScrollToTop } from 'common/hooks'
import { request } from 'common/utils'

import getCSVExportQuery from '../graphql/getCSVExport.graphql'
import orderVolumesQuery from '../graphql/getChancelleriesOrderVolumes.graphql'
import {
  ExportVariant,
  GetChancelleriesOrderVolumesQuery,
  GetCsvExportQuery,
  GetCsvExportQueryVariables,
} from '../interfaces/schemaDefinition'

const getExportUrl = (): Promise<string> =>
  request<GetCsvExportQuery, GetCsvExportQueryVariables>(CHANCELLERIES_ENDPOINT, getCSVExportQuery, {
    variant: ExportVariant.OrderVolume,
  }).then(result => `${FILE_BASE}/${result.createExport}`)

export const ChancelleryLocationsVolumesListPage: FC = () => {
  const { data, error, actions, tableState } = useGridData<'chancelleries', GetChancelleriesOrderVolumesQuery>(
    CHANCELLERIES_ENDPOINT,
    orderVolumesQuery,
    'chancelleries',
    {
      sort: { sortBy: 'name', sortDirection: 'asc' },
      filters: [
        {
          name: 'deleted',
          operator: FilterOperator.Equals,
          value: false,
        },
        {
          name: 'tags',
          operator: FilterOperator.Empty,
          value: '',
        },
      ],
    }
  )
  const list = (data?.list ?? []).map(d => ({ data: d, id: crypto.randomUUID() }))
  const [openPanels, setOpenPanels] = useState<Array<string>>([])
  const isEverythingExpanded = openPanels.length === (data?.list.length ?? 0)
  const totalChancelleries = data ? data.total - data.totalDeleted : 0
  const isLoading = !data && !error

  useScrollToTop()

  return (
    <PageLayout heading="Standortvolumen" error={error}>
      <HeaderButtonGroup>
        <ExportButton
          getExportUrl={getExportUrl}
          hoverText="Liste aller Kanzleien als CSV-Datei herunterladen"
          dialogTitle="CSV-Export"
        />
        <Button
          variant={isEverythingExpanded ? 'outlined' : 'contained'}
          color="primary"
          onClick={() => (isEverythingExpanded ? setOpenPanels([]) : data && setOpenPanels(data.list.map(c => c.id)))}
        >
          <ExpandAllIcon titleAccess={`Alle ${isEverythingExpanded ? 'schließen' : 'aufklappen'}`} />
        </Button>
      </HeaderButtonGroup>
      <PageInfoline>Insgesamt {totalChancelleries} Kanzleien</PageInfoline>
      {isLoading ? (
        <Box display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      ) : null}
      {data ? (
        <>
          {list.map(({ data: chancellery, id }) => (
            <Accordion
              key={id}
              expanded={openPanels.includes(chancellery.id)}
              onChange={(_, expanded) =>
                expanded
                  ? setOpenPanels(openPanels.concat(chancellery.id))
                  : setOpenPanels(openPanels.filter(panelId => panelId !== chancellery.id))
              }
            >
              <AccordionSummary sx={{ fontWeight: 500 }} expandIcon={<ExpandMoreIcon />}>
                {chancellery.name}
              </AccordionSummary>
              <AccordionDetails>
                {chancellery.orderVolumes.length === 0 ? (
                  <Box width="100%" mb={3}>
                    <Alert severity="info">Kein Auftragsvolumen eingerichtet</Alert>
                  </Box>
                ) : (
                  <Box mb={3} width="100%">
                    <Table
                      sx={{ thead: { display: 'flex' }, tr: { display: 'flex', flex: 1 }, th: { flex: 1 }, td: { flex: 1 } }}
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell>Rechtsgebiet</TableCell>
                          <TableCell>Wöchentlich max.</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {chancellery.orderVolumes.map(entry => (
                          <TableRow key={entry.fieldOfLaw.id}>
                            <TableCell>{entry.fieldOfLaw.name}</TableCell>
                            <TableCell>{entry.weeklyMax?.toString() ?? ''}</TableCell>
                          </TableRow>
                        ))}
                        <TableRow sx={{ td: { fontWeight: 500 } }}>
                          <TableCell>Summe</TableCell>
                          <TableCell>
                            {chancellery.orderVolumes.reduce((sum, entry) => sum + (entry.weeklyMax || 0), 0)}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Box>
                )}
                <Box width="100%" textAlign="right">
                  <Button component={Link} to={`/chancelleries/edit/${chancellery.id}`} variant="contained" color="primary">
                    Ändern
                  </Button>
                </Box>
              </AccordionDetails>
            </Accordion>
          ))}
          <Box display="flex" justifyContent="flex-end">
            <TablePagination
              component="div"
              page={tableState.page}
              count={data?.total ?? 0}
              rowsPerPage={tableState.pageSize}
              onPageChange={(_, page) => {
                actions.setPage(page)
                setOpenPanels([])
              }}
              onRowsPerPageChange={event => {
                actions.setPageSize(parseInt(event.target.value, 10))
                actions.setPage(0)
                setOpenPanels([])
              }}
            />
          </Box>
        </>
      ) : null}
    </PageLayout>
  )
}
