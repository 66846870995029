import { CHANCELLERIES_ENDPOINT } from 'common/constants'
import { request } from 'common/utils'

import query from '../graphql/getMatchingConfiguration.graphql'
import { GetMatchingConfigurationQuery } from '../interfaces/schemaDefinition'
import { mapMatchingConfigAPIDataToForm } from '../utils'

export const getConfigData = async (id: string): Promise<ReturnType<typeof mapMatchingConfigAPIDataToForm>> => {
  const { matchingConfig } = await request<GetMatchingConfigurationQuery>(CHANCELLERIES_ENDPOINT, query, { id })
  return mapMatchingConfigAPIDataToForm(matchingConfig)
}
