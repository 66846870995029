// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.xYO5TPUj7HBYJEkW23Bw{color:green}.jzjIM_lq5YCK3cH5IHZr{color:red}`, "",{"version":3,"sources":["webpack://./src/packages/products-business/components/Status/Status.module.scss"],"names":[],"mappings":"AAAA,sBACE,WAAA,CAGF,sBACE,SAAA","sourcesContent":[".status-activated {\n  color: green;\n}\n\n.status-deactivated {\n  color: red;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"status-activated": `xYO5TPUj7HBYJEkW23Bw`,
	"status-deactivated": `jzjIM_lq5YCK3cH5IHZr`
};
export default ___CSS_LOADER_EXPORT___;
