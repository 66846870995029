import { FilterList } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import React, { FC } from 'react'

import { AssignFilterProps } from '../interfaces'

export const AssignFilter: FC<AssignFilterProps> = ({ active, onClick }) => (
  <IconButton
    sx={{ background: 'white', color: active ? '#00c948' : 'currentcolor' }}
    onClick={onClick}
    size="large"
    title="Filter"
  >
    <FilterList style={{ fill: 'currentcolor' }} width={20} height={20} />
  </IconButton>
)
