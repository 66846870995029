import { TextSnippet, FileDownload, Close } from '@mui/icons-material'
import { Button, Link, Typography } from '@mui/material'
import { GridColumns, GridFilterInputSingleSelect, GridSelectionModel } from '@mui/x-data-grid'
import React, { FC, useCallback, useMemo, useState } from 'react'
import { useHistory } from 'react-router'

import { Grid, MuiDataGrid, createGridColumnsFrom, useGridData } from 'common/components-mui'
import { SearchField } from 'common/components-mui/SearchField'
import { ADVICE_REQUESTS_ENDPOINT } from 'common/constants'
import { DEFAULT_SORT_BY } from 'common/container'
import { Filters, SortDirection } from 'common/interfaces'

import { createSalesExport } from '../actions'
import adviceQuery from '../graphql/adviceRequests.graphql'
import searchQuery from '../graphql/searchAdviceRequest.graphql'
import { FilterOperator, SalesViewProps } from '../interfaces'
import { getChancelleryFilter } from '../utils'

import { AssignFilter } from './AssignFilter'
import { SalesStatusLabel, getLabelConfig } from './SalesStatusLabel'

const FILE_BASE = process.env.FILE_BASE || ''

const generalFilters: Filters = [
  {
    name: 'product.type',
    operator: FilterOperator.Equals,
    value: 'CONSUMER',
  },
  {
    name: '',
    operator: FilterOperator.Or,
    value: [
      {
        name: 'status',
        operator: FilterOperator.Equals,
        value: 'mandate',
      },
      {
        name: 'status',
        operator: FilterOperator.Equals,
        value: 'complete',
      },
    ],
  },
]

const formatDate = Intl.DateTimeFormat('de', { dateStyle: 'medium' }).format

const columns: GridColumns = createGridColumnsFrom([
  {
    field: 'adviceId',
    headerName: 'ID',
    flex: 0.5,
    minWidth: 60,
  },
  {
    field: 'createdAt',
    headerName: 'ID-Datum',
    flex: 1,
    minWidth: 120,

    valueFormatter: params => formatDate(new Date(params.value)),
  },
  {
    field: 'mandateName',
    headerName: 'Aktenname',
    flex: 1,
    minWidth: 120,
  },
  {
    field: 'assignedTo.name',
    headerName: 'In Bearbeitung durch',
    valueFormatter: params => params.value || 'Nicht zugewiesen',
    flex: 1,
    minWidth: 172,
  },
  {
    field: 'sort.personName',
    headerName: 'Mandantenname',
    valueGetter: ({ row }) => `${row.person.firstname} ${row.person.lastname}`,
    flex: 1,
    minWidth: 160,
  },
  {
    field: 'revenue',
    headerName: 'Gesamtumsatz (netto)',
    flex: 0.8,
    minWidth: 180,

    align: 'right',
    valueFormatter: params => (params.value ? `${params.value.toFixed(2)} €` : '-'),
  },
  {
    field: 'request_status',
    headerName: 'Status',
    sortable: false,
    flex: 0.5,
    minWidth: 100,

    renderCell: params => <SalesStatusLabel revenue={params.row.revenue} status={params.row.status} />,
    valueOptions: [
      { label: 'Offen', value: 'open' },
      { label: 'Kein Mandat', value: 'no_mandate' },
      { label: 'Akte Abgelegt', value: 'archived' },
      { label: 'Abgeschlossen', value: 'done' },
    ],

    type: 'singleSelect',
    filterOperators: [
      {
        label: 'ist',
        value: 'singleSelect',
        // Filtering is implemented via API-Calls. This function has no effect
        // but if it had, this would be a possible implementation
        getApplyFilterFn:
          filterItem =>
          ({ row }) => {
            const labelConfig = getLabelConfig(row.revenue, row.status)
            return filterItem.value === labelConfig.text
          },
        InputComponent: GridFilterInputSingleSelect,
      },
    ],
  },
])

export const SalesView: FC<SalesViewProps> = ({ user, chancelleryLocations }) => {
  const history = useHistory()
  const [selection, setSelection] = useState<GridSelectionModel>([])
  const [exportOpen, setExportOpen] = useState(false)
  const [link, setLink] = useState('')
  const [assignFilter, setAssignFilter] = useState('')
  const [searchTerm, setSearchTerm] = useState<string>('')

  const openExport = (): void => setExportOpen(true)
  const closeExport = (): void => setExportOpen(false)
  const initialFilters = useMemo(
    () => [
      ...generalFilters,
      ...(assignFilter
        ? [
            {
              name: 'assignedTo',
              operator: FilterOperator.Equals,
              value: assignFilter,
            },
          ]
        : []),
      getChancelleryFilter(user, chancelleryLocations),
      ...(searchTerm
        ? [
            {
              name: '',
              operator: FilterOperator.Or,
              value: [
                { name: 'adviceId', operator: FilterOperator.Contains, value: searchTerm },
                { name: 'person.firstname', operator: FilterOperator.Contains, value: searchTerm },
                { name: 'person.lastname', operator: FilterOperator.Contains, value: searchTerm },
              ],
            },
          ]
        : []),
    ],
    [assignFilter, chancelleryLocations, user, searchTerm]
  )

  const query = searchTerm ? searchQuery : adviceQuery
  const objectPropertyName = searchTerm ? 'searchAdviceRequest' : 'adviceRequests'

  const { data, error, actions, tableState } = useGridData(ADVICE_REQUESTS_ENDPOINT, query, objectPropertyName, {
    filters: initialFilters,
    pageSize: 20,
    sort: {
      sortBy: DEFAULT_SORT_BY,
      sortDirection: SortDirection.Desc,
    },
    ...(searchTerm ? { variables: { search: searchTerm } } : {}),
  })

  const handleClickedExport = async (filters: Filters): Promise<void> => {
    const createdLink = await createSalesExport({
      sort: {
        sortBy: tableState.sort.sortBy ?? DEFAULT_SORT_BY,
        sortDirection: tableState.sort.sortDirection === SortDirection.Asc ? SortDirection.Asc : SortDirection.Desc,
      },
      filters,
    })

    setLink(createdLink)
  }

  const exportAll = (): Promise<void> => handleClickedExport(initialFilters)
  const exportSelected = (): Promise<void> =>
    handleClickedExport([
      ...initialFilters,
      {
        name: '',
        operator: FilterOperator.Or,
        value: selection.map(item => ({ name: 'id', operator: FilterOperator.Equals, value: item })),
      },
    ])

  const onAssignFilterClick = useCallback(() => {
    if (user) {
      setAssignFilter(prevAssignFilter => (prevAssignFilter === user.id ? '' : user.id))
    }
  }, [user])

  const hasRequests = (data?.list.length ?? 0) > 0
  const noneSelected = selection.length === 0

  return (
    <>
      <Grid container sx={{ marginBottom: 2 }} alignItems="center" justifyContent="flex-end" gap={2}>
        {link && (
          <Grid display="flex" sx={{ marginRight: 'auto', paddingTop: 1.25 }}>
            <TextSnippet />
            <Link href={`${FILE_BASE}/${link}`} download onClick={() => setLink('')}>
              <Typography>CSV-Export herunterladen</Typography>
            </Link>
          </Grid>
        )}
        <Grid sx={{ svg: { fill: 'currentcolor' } }}>
          {exportOpen ? (
            <>
              <Button
                sx={{ marginRight: 1 }}
                variant="contained"
                startIcon={<FileDownload height="18" width="18" />}
                onClick={exportAll}
              >
                Alles exportieren
              </Button>
              <Button
                sx={{ marginRight: 1 }}
                variant="contained"
                startIcon={<FileDownload height="18" width="18" />}
                onClick={exportSelected}
                disabled={noneSelected}
              >
                Auswahl exportieren ({selection.length})
              </Button>
              <Button variant="contained" startIcon={<Close height="14" width="14" />} onClick={closeExport}>
                Export abbrechen
              </Button>
            </>
          ) : (
            <Button
              variant="contained"
              startIcon={<FileDownload height="18" width="18" />}
              onClick={openExport}
              disabled={!hasRequests}
            >
              Umsätze exportieren
            </Button>
          )}
        </Grid>

        <Grid display="flex" alignItems="center" gap={1}>
          <AssignFilter active={!!assignFilter} onClick={onAssignFilterClick} />
          <SearchField handleSubmit={setSearchTerm} />
        </Grid>
        {searchTerm.length > 0 && (
          <Grid xs={12}>
            <Typography sx={{ marginBottom: 2, textAlign: 'right' }}>Ihre Suchergebnisse: {data?.total ?? 0}</Typography>
          </Grid>
        )}
      </Grid>
      <MuiDataGrid
        // eslint-disable-next-line fp/no-mutating-methods
        onRowClick={params => history.push(`/chancellery/sales/${params.row.adviceId}`)}
        actions={actions}
        loading={!error && !data}
        selectionModel={selection}
        checkboxSelection
        disableSelectionOnClick
        onSelectionModelChange={setSelection}
        keepNonExistentRowsSelected
        rowsPerPageOptions={[20]}
        tableState={tableState}
        rows={data?.list ?? []}
        rowCount={data?.total ?? 0}
        columns={columns}
      />
    </>
  )
}
