import { ADVICE_REQUESTS_ENDPOINT } from 'common/constants'
import { request } from 'common/utils'
import {
  ProductType,
  SaveAdviceRequestMutation,
  SaveAdviceRequestMutationVariables,
} from 'packages/chancellery-search/interfaces/schemaDefinition'
import { mapCaseData } from 'packages-mui/advice/utils/caseData'

import query from '../graphql/saveAdviceRequest.graphql'
import { AdviceRequest } from '../interfaces'

export const saveAdviceRequest: (adviceRequest: AdviceRequest, saveBillingCompany?: boolean) => Promise<string> = async (
  adviceRequest,
  saveBillingCompany = false
) => {
  const variables: SaveAdviceRequestMutationVariables = {
    adviceRequest: {
      id: adviceRequest.id,
      fieldOfLawId: adviceRequest.fieldOfLaw ? adviceRequest.fieldOfLaw.id : '',
      language: adviceRequest.language ? adviceRequest.language.code : 'de',
      person: {
        foa: adviceRequest.foa,
        firstname: adviceRequest.firstname,
        lastname: adviceRequest.lastname,
        email: adviceRequest.email,
        phone: adviceRequest.phone,
        address: {
          street: adviceRequest.street,
          streetNr: adviceRequest.streetNr,
          city: adviceRequest.city,
          zip: adviceRequest.zip,
        },
      },
      company:
        adviceRequest.product.type === ProductType.Business
          ? {
              name: adviceRequest.companyName,
              companyType: adviceRequest.companyCompanyType,
              address: {
                street: adviceRequest.companyAddressStreet,
                streetNr: adviceRequest.companyAddressStreetNr,
                zip: adviceRequest.companyAddressZip,
                city: adviceRequest.companyAddressCity,
              },
              taxId: adviceRequest.companyTaxId,
            }
          : null,
      billingCompany:
        saveBillingCompany && adviceRequest.product.type === ProductType.Business
          ? {
              name: adviceRequest.billingCompanyName,
              companyType: adviceRequest.billingCompanyCompanyType,
              address: {
                street: adviceRequest.billingCompanyAddressStreet,
                streetNr: adviceRequest.billingCompanyAddressStreetNr,
                zip: adviceRequest.billingCompanyAddressZip,
                city: adviceRequest.billingCompanyAddressCity,
              },
              taxId: adviceRequest.billingCompanyTaxId,
            }
          : null,
      resultNotes: adviceRequest.resultNotes,
      lawyerNotes: adviceRequest.lawyerNotes,
      legalProtectionInsurer: adviceRequest.legalProtectionInsurer,
      description: adviceRequest.description,
      preferredCallbackTime: adviceRequest.preferredCallbackTime,
      internalNotes: adviceRequest.internalNotes,
      externalNotes: adviceRequest.externalNotes,
      insured: adviceRequest.insured,
      insuranceNumber: adviceRequest.insuranceNumber || undefined,
      agbsAccepted: adviceRequest.agbsAccepted,
      dataAccepted: adviceRequest.dataAccepted,
      partnerId: adviceRequest.partner ? adviceRequest.partner.id : undefined,
      caseData: mapCaseData(adviceRequest),
    },
  }

  const response = await request<SaveAdviceRequestMutation>(ADVICE_REQUESTS_ENDPOINT, query, variables)
  return response.saveAdviceRequest
}
