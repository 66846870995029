import React, { ComponentType, FunctionComponent } from 'react'

import { IsAuthorized } from './IsAuthorized'
import { Unauthorized } from './Unauthorized'

export const withAuthorization =
  <P extends {}>(roles: Array<string>) =>
  (Component: ComponentType<P>) => {
    const WithAuthorization: FunctionComponent<P> = props => (
      <IsAuthorized roles={roles}>
        <Component {...props} />
      </IsAuthorized>
    )
    return WithAuthorization
  }
export const withAuthorizationAndFeedback =
  <P extends {}>(roles: Array<string>) =>
  (Component: ComponentType<P>) => {
    const WithAuthorization: FunctionComponent<P> = props => (
      <IsAuthorized roles={roles} fallback={<Unauthorized />}>
        <Component {...props} />
      </IsAuthorized>
    )
    return WithAuthorization
  }
