import React, { FC } from 'react'
import { Col, Row } from 'reactstrap'

import { BooleanStaticField, DateStaticField, NumberStaticField, StaticField } from 'common/components/StaticField'
import { StaticRow } from 'common/components/StaticRow'

import './StaticFieldRow.scss'

interface GenericStaticFieldProps {
  label: string
  value: any
}

interface FieldConfig {
  label: string
  value: any
  data?: Array<{ name: string; value: string }>
  component?: React.ComponentType<GenericStaticFieldProps>
  icon?: React.ReactNode
}

interface StaticFieldRowProps {
  image: React.ReactNode
  title: string
  fields: Array<FieldConfig>
}

const StaticFieldTypeMap: { [key: string]: React.ComponentType<GenericStaticFieldProps> } = {
  string: StaticField,
  number: NumberStaticField,
  boolean: BooleanStaticField,
}

const getStaticFieldComponent = (
  value: any
): React.ComponentType<GenericStaticFieldProps> | ((props: unknown) => React.JSX.Element) => {
  if (value instanceof Date) {
    return DateStaticField
  }

  return StaticFieldTypeMap[typeof value] || StaticField
}

export const StaticFieldRow: FC<StaticFieldRowProps> = ({ title, image, fields }) => (
  <StaticRow>
    <Col md={12}>
      {image}
      <h3 className="h3 small">{title}</h3>
    </Col>
    <Row className="row-margin">
      {fields.map(key => {
        const GenericStaticField = key.component || getStaticFieldComponent(key.value)
        return (
          <Col md={3} key={key.label} className="static-row-col">
            {key.icon && <div className="icon-static-field">{key.icon}</div>}
            <GenericStaticField label={key.label} value={key.value || '-'} />
          </Col>
        )
      })}
    </Row>
  </StaticRow>
)
