import React, { FC, useContext, useEffect, useState } from 'react'

import { UserContext } from 'common/user-context'
import { UserProfileView } from 'packages-mui/user-profile/components/UserProfileView'

import { getChancellery } from '../actions/getChancellery'

export const UserProfile: FC = () => {
  const { user } = useContext(UserContext)
  const [chancellery, setChancellery] = useState<Awaited<ReturnType<typeof getChancellery>> | null>(null)

  useEffect(() => {
    if (!user || !user?.organisation) return
    getChancellery(user.organisation).then(response => setChancellery(response))
  })

  return user ? <UserProfileView user={user} chancellery={chancellery ?? undefined} /> : null
}
