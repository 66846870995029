import { DataGrid, GridColumns } from '@mui/x-data-grid'
import React, { FC } from 'react'

import { createGridColumnsFrom } from 'common/components-mui'
import { PRODUCT_NAMES, Roles } from 'common/constants'
import { ADVICE_REQUEST_FORM_DETAIL } from 'common/constants/routes'
import { useNavigate } from 'common/hooks'
import { Role } from 'common/interfaces'
import { formatDate } from 'common/utils'
import { personName, partnerName, address, email, phone, chancelleryName, fieldOfLawName } from 'common/utils/label'
import { AdviceListStatusLabel } from 'packages-mui/advice/components/AdviceListRoleStatusLabel'
import { AdviceRequest } from 'packages-mui/advice/interfaces'

import { SearchAdviceRequest } from '../interfaces'

interface Props {
  adviceRequests: Array<SearchAdviceRequest>
  role: Role
  fixedStatus?: boolean
}

const productName = (adviceRequest: SearchAdviceRequest, role: Role): string => {
  const name = PRODUCT_NAMES?.[adviceRequest.product?.id || ''] || adviceRequest.product?.name || 'Basic'
  return !adviceRequest.product ? 'Basic' : role.name === Roles.Administrator ? `${adviceRequest.product.id} (${name})` : name
}

const widthUnit = (count: number): number => 8 * Math.floor(count)

const createColumns = (role: Role, fixedStatus?: boolean): GridColumns => {
  const baseCols: GridColumns = [
    { field: 'adviceId', headerName: 'ID', width: widthUnit(10) },
    {
      headerName: 'Produkt',
      field: 'product',
      width: widthUnit(34),
      valueGetter: ({ row }) => productName(row, role),
    },
    { field: 'chancellery', width: widthUnit(28), headerName: 'Kanzlei', valueGetter: ({ row }) => chancelleryName(row) },
    { field: 'fieldOfLaw', width: widthUnit(22), headerName: 'Rechtsgebiet', valueGetter: ({ row }) => fieldOfLawName(row) },
    { field: 'name', width: widthUnit(26), headerName: 'Name', valueGetter: ({ row }) => personName(row) },
    { field: 'address', width: widthUnit(26), headerName: 'Adresse', valueGetter: ({ row }) => address(row) },
    { field: 'phone', width: widthUnit(26), headerName: 'Telefon', valueGetter: ({ row }) => phone(row) },
    { field: 'email', width: widthUnit(26), headerName: 'E-Mail', valueGetter: ({ row }) => email(row) },
    { field: 'partner', width: widthUnit(22), headerName: 'Partner', valueGetter: ({ row }) => partnerName(row) },
    {
      field: 'createdAt',
      width: widthUnit(20),
      headerName: 'Erstellt am',
      valueFormatter: ({ value }) => (value ? formatDate(new Date(value)) : ''),
    },
    {
      field: 'status',
      headerName: 'Status',
      width: widthUnit(18),
      renderCell: ({ row }) => (
        <AdviceListStatusLabel
          status={(fixedStatus && row.status !== 'editing' && row.status !== 'closed') || row.status}
          role={role}
        />
      ),
    },
  ]

  const channelCols: GridColumns = [
    {
      field: 'damageNumber',
      headerName: 'Schadennummer',
      width: widthUnit(20),
      valueGetter: params => {
        const caseData: Array<{ name: string; value: string | boolean }> = params.row.caseData ?? []
        return caseData.find(({ name }) => name === 'damageNumber')?.value || '-'
      },
    },
    {
      field: 'insuranceNumber',
      width: widthUnit(20),
      headerName: 'Versicherungsscheinnummer',
      valueFormatter: ({ value }) => value ?? '-',
    },
  ]

  return createGridColumnsFrom(role.id === Roles.Channel ? baseCols.concat(channelCols) : baseCols)
}
export const AllRequests: FC<Props> = ({ adviceRequests, role, fixedStatus }) => {
  const toAdviceDetail = useNavigate(ADVICE_REQUEST_FORM_DETAIL)
  const columns = createColumns(role, fixedStatus)
  if (adviceRequests.length === 0) {
    return null
  }
  return (
    <DataGrid
      columns={columns}
      rows={adviceRequests}
      rowCount={adviceRequests?.length ?? 0}
      autoHeight
      onRowClick={({ row }: { row: AdviceRequest }) => toAdviceDetail({ adviceId: row.adviceId })}
    />
  )
}
