import { USER_AUTH_ENDPOINT } from 'common/constants/endpoints'
import { request } from 'common/utils'
import { LoginUserQuery } from 'packages-mui/login/interfaces/schemaDefinition'

import query from '../graphql/loginUser.graphql'

export const loginUser = async (email: string, password: string): Promise<LoginUserQuery['login']> => {
  const variables = {
    email,
    password,
  }

  return (await request<LoginUserQuery>(USER_AUTH_ENDPOINT, query, variables)).login
}
