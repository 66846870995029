import { USER_AUTH_ENDPOINT } from 'common/constants/endpoints'
import { request } from 'common/utils/requests'

import query from '../graphql/resetPasswordRequest.graphql'

export const resetPasswordMutation = async (email: string, urlPrefix: string): Promise<boolean> => {
  const variables = {
    email,
    urlPrefix,
  }
  return request<boolean>(USER_AUTH_ENDPOINT, query, variables)
}
