import { Checkbox, FormControl, Input, InputLabel, ListItemText, MenuItem, Select, SelectChangeEvent } from '@mui/material'
import React, { useEffect } from 'react'
import { FormFeedback } from 'reactstrap'

import { SelectField, FieldSet, FieldRow, InputField } from 'common/components/Form'
import { DEDUCTIBLE_AMOUNTS, PROTECTION_AMOUNTS } from 'common/constants'
import SVGCaseData from 'common/icons/CaseData.svg'
import { currencyFormatter } from 'common/utils'

import { AdviceRequest } from '../../../interfaces'

import { DamageNumberInput } from './DamageNumberInput'

interface DEVKFormProps {
  adviceRequest: AdviceRequest
  errors: { [key: string]: string }
  onFieldChange: React.EventHandler<React.FormEvent<HTMLInputElement>>
  onCheckboxChange: React.EventHandler<React.FormEvent<HTMLInputElement>>
  onMultiSelectChange: (e: SelectChangeEvent<Array<string>>) => void
  onCaseDataChange: (
    vnr: string,
    disputeAmount: string,
    deductible: boolean,
    deductibleAmount: string,
    noProtection: boolean | null,
    protectionAmount: Array<string>
  ) => void
}

const EMPTY_OPTION = {
  label: '---Bitte wählen---',
  value: '',
}

export const DevkSteuerrungstarifForm = ({
  adviceRequest,
  onFieldChange,
  errors,
  onCheckboxChange,
  onCaseDataChange,
  onMultiSelectChange,
}: DEVKFormProps): JSX.Element => {
  const now = new Date()
  const mask = `${now.getFullYear()} . dd . dd . ddddd . d`
  const formatChars = {
    d: '[0-9]',
  }

  useEffect(() => {
    if (!adviceRequest.deductible) {
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      onFieldChange({
        currentTarget: {
          name: 'deductible',
          value: true,
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } as any)
    }

    if (adviceRequest.deductibleAmount !== '150' && adviceRequest.deductibleAmount !== '150,00') {
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      onFieldChange({
        currentTarget: {
          name: 'deductibleAmount',
          value: '150,00',
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } as any)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleCaseDataChange = (
    vnr: string,
    disputeAmount: string,
    _deductible: boolean,
    _deductibleAmount: string,
    _noProtection: boolean,
    protectionAmount: Array<string>
  ): void => {
    onCaseDataChange(vnr, disputeAmount, true, '150,00', false, protectionAmount)
  }

  return (
    <FieldSet className="devk-fieldset" label="DEVK Falldaten" icon={<SVGCaseData />}>
      <div className="devk-background">
        <FieldRow>
          <DamageNumberInput
            onCaseDataChange={handleCaseDataChange}
            onCheckboxChange={onCheckboxChange}
            onFieldChange={onFieldChange}
            damageNumber={adviceRequest.damageNumber}
            damageNumberDisabled={adviceRequest.damageNumberDisabled}
            maskOptions={{
              mask,
              formatChars,
            }}
          />
        </FieldRow>
        <FieldRow>
          <InputField
            label="Streitwert"
            onChange={onFieldChange}
            value={adviceRequest.disputeAmount}
            name="disputeAmount"
            id="disputeAmount"
            width="24rem"
          />
        </FieldRow>
        <FieldRow>
          <SelectField
            label="Höhe der Selbstbeteiligung"
            disabled
            onChange={onFieldChange}
            value={adviceRequest ? adviceRequest.deductibleAmount : ''}
            name="deductibleAmount"
            id="deductibleAmount"
            inputWidht="15rem"
            error={errors.deductibleAmount}
            options={[
              EMPTY_OPTION,
              ...DEDUCTIBLE_AMOUNTS.map(v => ({
                value: v,
                label: currencyFormatter(v),
              })),
            ]}
          />
        </FieldRow>
        <FieldRow>
          <div className={errors.protectionAmount ? 'select-field--invalid' : ''}>
            <InputLabel
              sx={{
                mx: 1.5,
                fontSize: '14px',
                fontWeight: 500,
                color: 'white',
              }}
              id="select-protectionAmount-label"
            >
              Umfang Kostenschutz
            </InputLabel>
            <FormControl disabled={!!adviceRequest.noProtection} sx={{ minWidth: '15rem', mx: 1.5, fontSize: 2 }}>
              <Select
                id="select-protectionAmount"
                name="protectionAmount"
                sx={{
                  backgroundColor: '#f7f7f9',
                  pl: 2,
                  py: 0.5,
                  borderRadius: 1,
                  color: '#495057',
                  '&.Mui-disabled': {
                    backgroundColor: '#e9ecef',
                  },
                  '&.Mui-disabled #select-protectionAmount': {
                    color: '#495057',
                    WebkitTextFillColor: '#495057',
                  },
                }}
                multiple
                displayEmpty
                value={adviceRequest.protectionAmount}
                onChange={onMultiSelectChange}
                renderValue={selected => (selected[0] === '' ? '---Bitte wählen---' : selected.join(', '))}
                input={<Input disableUnderline error={!!errors.protectionAmount} />}
              >
                <MenuItem value="" disabled>
                  ---Bitte wählen---
                </MenuItem>
                {PROTECTION_AMOUNTS.map(name => (
                  <MenuItem key={name} value={name}>
                    <Checkbox checked={adviceRequest.protectionAmount.indexOf(name) > -1} />
                    <ListItemText primary={name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {errors.protectionAmount && (
              <FormFeedback style={{ paddingLeft: '1rem' }} className="invalid-feedback">
                {errors.protectionAmount}
              </FormFeedback>
            )}
          </div>
        </FieldRow>
      </div>
    </FieldSet>
  )
}
