import React, { FC } from 'react'
import { Control, FieldValue, FieldValues } from 'react-hook-form'

import { Select, TextField, Checkbox, DatePicker } from 'common/components-mui/react-hook-form'
import { CoverageFieldType, InputField } from 'common/graphql/schemaDefinition'

type ComponentProps = {
  data: InputField
  control: Control<FieldValues, any>
  watch: (prop: string) => FieldValue<FieldValues>
}

export const CoverageFormInput: FC<ComponentProps> = ({ data, control, watch }) => {
  const { label, type, options, mustUsePath, dependsOn, optional } = data
  const base = { key: label, label: optional ? `${label} (optional)` : label, name: label, disabled: !!mustUsePath }

  const field = dependsOn ? watch(dependsOn.field) : null
  if (dependsOn && field !== dependsOn.value) {
    return null
  }

  switch (type) {
    case CoverageFieldType.Date:
      return <DatePicker fullWidth {...base} control={control} />

    case CoverageFieldType.Select:
      return (
        <Select {...base} control={control}>
          <option value="" disabled>
            Bitte wählen...
          </option>
          {options!.map(option => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </Select>
      )
    case CoverageFieldType.Checkbox: {
      return <Checkbox {...base} control={control} />
    }
    case CoverageFieldType.TextArea:
      return (
        <TextField
          {...base}
          InputLabelProps={{
            shrink: true,
          }}
          multiline
          rows={3}
          control={control}
        />
      )
    default:
      return (
        <TextField
          {...base}
          InputLabelProps={{
            shrink: true,
          }}
          control={control}
        />
      )
  }
}
