import { getOr } from 'lodash/fp'

import { HistoryEventType } from 'common/graphql/schemaDefinition'
import { LoggedInUser } from 'common/user-context'

import { LawyerRequest, AdviceRequest } from '../interfaces'
import { ChancelleryLocationsQuery, Filter, FilterOperator, SaveAdviceRequestInput } from '../interfaces/schemaDefinition'

export const hasEConsult: (
  chancelleryId: string,
  chancelleries: ChancelleryLocationsQuery['chancelleryLocations']['list']
) => boolean = (chancelleryId, chancelleries) =>
  getOr(
    false,
    'eConsult.active',
    chancelleries.find(c => c.id === chancelleryId)
  )

export const didSendToEConsult: (adviceRequest: LawyerRequest) => boolean = adviceRequest =>
  !!adviceRequest.history && !!(adviceRequest.history ?? []).find(h => h.type === HistoryEventType.SentToEConsult)

const NEVER_FILTER = {
  name: 'chancelleryId',
  operator: FilterOperator.Equals,
  value: 'never',
}

export const getChancelleryFilter: (user: LoggedInUser | undefined, chancelleries: Array<{ id: string }>) => Filter = (
  user,
  chancelleries
) => {
  if (!user) {
    return NEVER_FILTER
  }

  if (user.organisationType === 'chancelleryGroup') {
    return {
      name: '',
      operator: FilterOperator.Or,
      value: chancelleries.map(c => ({
        name: 'chancelleryId',
        operator: FilterOperator.Equals,
        value: c.id,
      })),
    }
  }

  const chancellery = chancelleries.find(c => c.id === user.organisation)
  return {
    name: 'chancelleryId',
    operator: FilterOperator.Equals,
    value: chancellery ? chancellery.id : '',
  }
}

export const wasRedirected = (adviceRequest: LawyerRequest): boolean =>
  !!(adviceRequest.history ?? []).find(history => history.type === HistoryEventType.RedirectedAdviceRequest)

export const isAssignable: (adviceRequest: AdviceRequest, user: LoggedInUser) => boolean = (adviceRequest, user) =>
  !adviceRequest.assignedTo || adviceRequest.assignedTo.id === user.id

export const isAssignableToMe: (adviceRequest: AdviceRequest) => boolean = adviceRequest => !adviceRequest.assignedTo

export const convertToSaveAdviceRequestInput = (adviceRequest: AdviceRequest): SaveAdviceRequestInput => {
  const fieldOfLawId = adviceRequest.fieldOfLaw ? adviceRequest.fieldOfLaw.id : ''
  const language = adviceRequest.language ? adviceRequest.language.code : 'de'
  const person = {
    foa: adviceRequest.foa,
    firstname: adviceRequest.firstname,
    lastname: adviceRequest.lastname,
    email: adviceRequest.email,
    phone: adviceRequest.phone,
    address: {
      street: adviceRequest.street,
      streetNr: adviceRequest.streetNr,
      city: adviceRequest.city,
      zip: adviceRequest.zip,
    },
  }

  return { ...adviceRequest, fieldOfLawId, language, person }
}
