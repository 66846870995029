import { CASE_DATA_FIELDS } from '../constants'

type CaseDateToArray = <T extends { [key: string]: any }>(
  request: T
) => (key: string) => { value: string | boolean | null; name: string }

export const mapCaseDataToArray: CaseDateToArray = request => key => ({
  name: key,
  value: !(key in request) ? null : Array.isArray(request[key]) ? request[key].join(',') : request[key],
})

type CaseDataEntry = { value: any; name: string }
export const filterEmptyCaseDataValue = (c: CaseDataEntry): c is CaseDataEntry => c.value !== null && c.value !== undefined

type MapCaseData = <T extends { [key: string]: any }>(request: T) => Array<CaseDataEntry>
export const mapCaseData: MapCaseData = request =>
  CASE_DATA_FIELDS.map(mapCaseDataToArray(request)).filter(filterEmptyCaseDataValue)

type ResolvedCaseData = Partial<Record<(typeof CASE_DATA_FIELDS)[number], string | boolean>>
export const resolveCaseData = (caseDataEntries: Array<CaseDataEntry> | null | undefined): any =>
  caseDataEntries
    ? caseDataEntries.reduce(
        (caseData: ResolvedCaseData, field) => ({
          ...caseData,
          [field.name]:
            field.name === 'protectionAmount' && typeof field.value === 'string'
              ? field.value.split(',').filter(Boolean)
              : field.value,
        }),
        {}
      )
    : {}
