import { Typography } from '@mui/material'
import classnames from 'classnames'
import React, { useState } from 'react'
import { Badge, Table } from 'reactstrap'

import { Panel, Spinner } from 'common/components'
import { LEGAL_HERO_FOL } from 'common/constants'
import { ButtonPrimary, ButtonSecondary } from 'common/ui'
import { formatDateTime } from 'common/utils'

import { MatchedChancellery, AdviceRequest } from '../../interfaces'

import './SearchResults.scss'

interface ChancellerySearchResultsProps {
  chancelleries: Array<MatchedChancellery>
  ownChancelleries: Array<MatchedChancellery>
  premiumChancelleries: Array<MatchedChancellery>
  centralChancelleries?: Array<MatchedChancellery> | null
  loading: boolean
  onRedirectClick: (chancellery: MatchedChancellery) => void
  onCallbackClick: (chancellery: MatchedChancellery) => void
  callbacksDisabled?: boolean
  isLawyer: boolean
  adviceRequest: AdviceRequest
}

const createOnRedirectClick = (func: (chancellery: MatchedChancellery) => void, chancellery: MatchedChancellery) => () =>
  func(chancellery)

// eslint-disable-next-line complexity
export const ChancellerySearchResults = ({
  ownChancelleries,
  centralChancelleries,
  chancelleries,
  isLawyer,
  loading,
  onRedirectClick,
  onCallbackClick,
  adviceRequest,
}: ChancellerySearchResultsProps): JSX.Element => {
  const [showMore, setShowMore] = useState(false)

  if (loading) {
    return (
      <Panel title="Ergebnisse">
        <Spinner center />
      </Panel>
    )
  }

  if ((!chancelleries || !chancelleries.length) && !centralChancelleries?.length) {
    return <Panel title="Keine Kanzleien gefunden">Bitte wenn möglich die Suchparameter anpassen und erneut versuchen.</Panel>
  }

  return (
    <Panel title="Ergebnisse">
      <Table striped responsive>
        <thead>
          <tr>
            <th>Kanzlei</th>
            <th>Adresse</th>
            <th>Entfernung</th>
            <th>Rechtsgebiete</th>
            {!isLawyer && <th>Kunden Benefits</th>}
            <th>Letzte Steuerung</th>
            <th style={{ width: '230px' }} />
          </tr>
        </thead>
        <tbody>
          {!!ownChancelleries.length &&
            ownChancelleries.map((chancellery, index) => {
              const isApraxa = chancellery.tags?.includes('apraxa')
              const isMineko = chancellery.tags?.includes('mineko')
              return (
                <tr key={index} className={classnames({ 'central-row': index === 0 })}>
                  <td className="central-cell">
                    {index === 0 && (
                      <Badge className="badge-status central-badge" color="primary">
                        Eigene Kanzleien
                      </Badge>
                    )}
                    <strong>{`${isApraxa ? '(Apraxa) ' : ''}${chancellery.name}`}</strong>
                  </td>
                  <td>
                    {chancellery.address.street}, {chancellery.address.zip} {chancellery.address.city}
                  </td>
                  <td>{`${Math.round(chancellery.distance)} km`}</td>
                  <td>{chancellery.fieldsOfLaw.map(s => s.name).join(', ')}</td>
                  <td>
                    <ButtonPrimary onClick={createOnRedirectClick(onRedirectClick, chancellery)}>Weiterleitung</ButtonPrimary>{' '}
                    {!isApraxa && !isMineko && (
                      <ButtonPrimary onClick={createOnRedirectClick(onCallbackClick, chancellery)}>Rückruf</ButtonPrimary>
                    )}
                  </td>
                </tr>
              )
            })}
          {!isLawyer &&
            // eslint-disable-next-line complexity
            chancelleries.slice(0, showMore ? chancelleries.length : 1).map((chancellery, index) => {
              const isApraxa = chancellery.tags?.includes('apraxa')
              const isMineko = chancellery.tags?.includes('mineko')
              const isLegalHero = adviceRequest.fieldOfLaw?.id === LEGAL_HERO_FOL
              const noCallbackConditions = isApraxa || isLegalHero

              const callbackEnabled = !noCallbackConditions && adviceRequest.fieldOfLaw?.id !== 'Dieselskandal'

              return (
                <tr key={index} className={classnames({ 'central-row': index === 0 })}>
                  <td className="central-cell">
                    <strong>{`${isApraxa ? '(Apraxa) ' : ''}${chancellery.name}`}</strong>
                  </td>
                  <td>
                    {chancellery.address.street}, {chancellery.address.zip} {chancellery.address.city}
                  </td>
                  <td>{`${Math.round(chancellery.distance)} km`}</td>
                  <td>{chancellery.fieldsOfLaw.map(s => s.name).join(', ')}</td>
                  <td>{chancellery.services}</td>
                  <td>
                    {chancellery?.lastMatched?.date ? (
                      <Typography variant="body2" color={chancellery.lastMatched.notReached ? 'red' : 'default'}>
                        {formatDateTime(new Date(chancellery.lastMatched.date))}
                      </Typography>
                    ) : (
                      '-'
                    )}
                  </td>
                  <td>
                    {adviceRequest.fieldOfLaw?.id === 'Dieselskandal' && (
                      <p className="important-note">
                        ⚠️ Kunde nicht telefonisch steuern, nur Button klicken. Kunde wird per Mail kontaktiert.
                      </p>
                    )}
                    <div className="button-cell">
                      {!chancellery.redirectDisabled && (
                        <ButtonPrimary onClick={createOnRedirectClick(onRedirectClick, chancellery)}>Weiterleitung</ButtonPrimary>
                      )}
                      {adviceRequest.fieldOfLaw?.id !== 'Dieselskandal' &&
                        adviceRequest.fieldOfLaw?.id !== 'Dieselcheck' &&
                        callbackEnabled &&
                        !isMineko && (
                          <ButtonPrimary
                            onClick={createOnRedirectClick(onCallbackClick, chancellery)}
                            style={{ marginLeft: '0.5rem' }}
                          >
                            Rückruf
                          </ButtonPrimary>
                        )}
                    </div>
                  </td>
                </tr>
              )
            })}
        </tbody>
      </Table>
      {chancelleries.length > 1 && !showMore && (
        <div className="more-button">
          <ButtonSecondary onClick={() => setShowMore(true)}>Weitere Kanzleien anzeigen</ButtonSecondary>
        </div>
      )}
    </Panel>
  )
}
