import { ADVICE_REQUESTS_ENDPOINT } from 'common/constants'
import { request } from 'common/utils'
import { CreateSalesExportQuery, QueryCreateSalesExportArgs } from 'packages/lawyers/interfaces/schemaDefinition'

import query from '../graphql/createSalesExport.graphql'

export const createSalesExport = async (
  variables: QueryCreateSalesExportArgs
): Promise<CreateSalesExportQuery['createSalesExport']> => {
  const response = await request<CreateSalesExportQuery>(ADVICE_REQUESTS_ENDPOINT, query, variables)
  return response.createSalesExport
}
