/* eslint-disable complexity */
import { gql, useMutation } from '@apollo/client'
import { Effect } from '@grapes-agency/universal'

import { InputError } from 'common/errors'
import { mapCaseData } from 'packages-mui/advice/utils/caseData'

import CONTACT_INSURER from '../../graphql/contactInsurer.graphql'
import { AdviceRequest, MessageType } from '../../interfaces'
import { ContactInsurerMutation } from '../../interfaces/schemaDefinition'

export interface ContactInsurerInput {
  adviceRequest: AdviceRequest
  iban?: string
  topic?: string
  formData?: Array<{ label: string; value: unknown }>
  coverageDecription: string
  caseId?: string
  partyName?: string
  attachments: Array<File>
  type?: MessageType
  blindCarbonCopy?: Array<string>
}

export const useSendCoverageRequest: () => Effect<ContactInsurerInput, string> = () => {
  const [contactInsurer] = useMutation<ContactInsurerMutation>(gql(CONTACT_INSURER))
  const sendCoverageRequest: Effect<ContactInsurerInput, string> = async ({
    adviceRequest,
    iban,
    topic = undefined,
    formData = [],
    coverageDecription,
    caseId = '',
    partyName = '',
    attachments,
    blindCarbonCopy = [],
    type = MessageType.CoverageRequest,
  }) => {
    if (type !== MessageType.Contact && topic === 'Sonstiges' && !attachments.length) {
      throw new InputError('Für diese Anfrage ist mindestens ein Dateianhang erforderlich')
    }

    if (!adviceRequest.chancellery) {
      throw new Error('Dem Antrag wurde keine Kanzlei zugewiesen')
    }

    const result = await contactInsurer({
      variables: {
        contactInput: {
          adviceId: adviceRequest.adviceId,
          description: adviceRequest.description,
          person: {
            firstname: adviceRequest.firstname,
            lastname: adviceRequest.lastname,
            phone: adviceRequest.phone,
            email: adviceRequest.email,
            address: {
              zip: adviceRequest.zip,
            },
          },
          caseData: mapCaseData(adviceRequest),
          fieldOfLaw: adviceRequest.fieldOfLaw ? adviceRequest.fieldOfLaw.name : '',
          message: coverageDecription,
          messageType: type,
          attachments,
          insuranceNumber: adviceRequest.insuranceNumber || '',
          chancellery: {
            name: adviceRequest.chancellery?.name,
            address: adviceRequest.chancellery?.address.street,
            zip: adviceRequest.chancellery?.address.zip,
            city: adviceRequest.chancellery?.address.city,
            phone: adviceRequest.chancellery?.phone,
          },
          blindCarbonCopy,
          caseId,
          partyName,
          iban,
          topic,
          formData,
        },
      },
    })
    return result.data?.contactInsurer || ''
  }

  return sendCoverageRequest
}
