import { ADVICE_REQUESTS_ENDPOINT } from 'common/constants'
import { request } from 'common/utils'

import query from '../graphql/searchAdviceRequest.graphql'
import { SearchAdviceRequestQuery } from '../interfaces/schemaDefinition'

export const searchAdviceRequest = async ({
  search,
}: {
  search: string
}): Promise<SearchAdviceRequestQuery['searchAdviceRequest']> => {
  const variables = {
    search,
  }
  const response = await request<SearchAdviceRequestQuery>(ADVICE_REQUESTS_ENDPOINT, query, variables)
  return response.searchAdviceRequest
}
