import MailOutlineIcon from '@mui/icons-material/MailOutline'
import { Box, Button, Card, CardActions, CardContent } from '@mui/material'
import React, { FC } from 'react'

import { PageLayout } from 'common/components-mui'
import { StaticField } from 'common/components-mui/StaticField'
import { useFormActionButtonStyles } from 'common/components-mui/helpers'

import { CONTACT_PERSONS } from '../constants'

export const SupportPage: FC = () => {
  const onMailButtonClick = (email: string): void => {
    // eslint-disable-next-line fp/no-mutation
    window.location.href = `mailto:${email}`
  }

  const centerStyles = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  }

  return (
    <PageLayout heading="Ihre persönlichen Ansprechpartner/innen">
      <Box sx={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', gap: 4 }}>
        {CONTACT_PERSONS.map((contactPerson, index) => (
          <Card sx={{ width: 300 }} key={index}>
            <CardContent sx={centerStyles}>
              <StaticField label="Ansprechpartner/in" value={contactPerson.name} sx={{ mb: 2, ...centerStyles }} />
              <StaticField label="E-Mail" value={contactPerson.email} sx={centerStyles} />
            </CardContent>
            <CardActions sx={centerStyles}>
              <Button
                variant="contained"
                type="submit"
                startIcon={<MailOutlineIcon />}
                onClick={() => onMailButtonClick(contactPerson.email)}
                sx={useFormActionButtonStyles}
              >
                E-Mail schreiben
              </Button>
            </CardActions>
          </Card>
        ))}
      </Box>
    </PageLayout>
  )
}
