import { AccountBalance, Person } from '@mui/icons-material'
import { Typography } from '@mui/material'
import React, { FC } from 'react'

import { Grid, PageLayout } from 'common/components-mui'
import { LoggedInUser } from 'common/user-context'

interface UserProfileProps {
  user: LoggedInUser
  chancellery?: {
    name: string
    phone: string
  }
}

export const UserProfileView: FC<UserProfileProps> = ({ user, chancellery }) => (
  <PageLayout heading="Ihr Account">
    <Grid container sx={{ paddingX: 2 }} spacing={1}>
      <Grid xs={12} display="flex" alignItems="center">
        <Person fontSize="medium" sx={{ marginRight: '0.5rem' }} />
        <Typography variant="h6">Hinterlegt Personendaten</Typography>
      </Grid>
      <Grid xs={12} md={6} lg={3}>
        <Typography variant="subtitle2">Name</Typography>
        <Typography variant="body1">{user.name || '-'}</Typography>
      </Grid>
      <Grid xs={12} md={6} lg={3}>
        <Typography variant="subtitle2">E-Mail</Typography>
        <Typography variant="body1">{user.email || '-'}</Typography>
      </Grid>
    </Grid>
    {chancellery && (
      <Grid container sx={{ padding: 2 }} spacing={1}>
        <Grid xs={12} display="flex" alignItems="center">
          <AccountBalance fontSize="medium" sx={{ marginRight: '0.5rem' }} />
          <Typography variant="h6">Hinterlegte Kanzleidaten</Typography>
        </Grid>
        <Grid xs={12} md={6} lg={3}>
          <Typography variant="subtitle2">Name</Typography>
          <Typography variant="body1">{chancellery.name || '-'}</Typography>
        </Grid>
        <Grid xs={12} md={6} lg={3}>
          <Typography variant="subtitle2">Telefonnummer</Typography>
          <Typography variant="body1">{chancellery.phone || '-'}</Typography>
        </Grid>
      </Grid>
    )}
  </PageLayout>
)
