import { Box, Typography } from '@mui/material'
import React, { FC } from 'react'

export const Unauthorized: FC = () => (
  // Height = Viewport - NavHeight - Parent Padding
  <Box display="flex" justifyContent="center" alignItems="center" height="calc(100vh - (80px + 2rem))">
    <Typography variant="h3" component="p">
      Zu dieser Ansicht hat Ihr aktueller Account keinen Zugriff.
    </Typography>
  </Box>
)
