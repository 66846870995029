import { zodResolver } from '@hookform/resolvers/zod'
import { AttachFile, Delete, ArrowBack } from '@mui/icons-material'
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  Select as MuiSelect,
  Typography,
} from '@mui/material'
import React, { FC, PropsWithChildren, useEffect, useMemo, useState } from 'react'
import { FieldValues, SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form'

import { InputError } from 'common/errors'
import SVGSecureQuestion from 'common/icons/secure-question.svg'
import { enqueueSnackbar } from 'common/utils'
import { getCoverageForms } from 'packages/lawyers/actions'

import { AdviceRequest } from '../../../interfaces'
import { ContactInsurerInput, useSendCoverageRequest } from '../../effects/sendCoverageRequest'

import { CoverageFormInput } from './CoverageFormInput'
import {
  FormTemplate,
  createDefaultFormValues,
  createFormSchema,
  flattenObject,
  superRefineCoverageSchema,
} from './coverageFormHandlers'
import { ACCEPTED_FILES, useFileHandlers } from './useFileHandlers'

interface CoverageModalProps {
  adviceRequest: AdviceRequest
  iban: string
  onSuccess: () => void
}

const CoverageFormLayout: FC<PropsWithChildren<{ protectionAmount: Array<string>; topic: string }>> = ({
  topic,
  protectionAmount,
  children,
}) => (
  <Box display="flex" flexDirection="column" gap={2}>
    <Box sx={{ marginX: 'auto' }}>
      <SVGSecureQuestion width="48px" height="48px" />
    </Box>
    <Typography variant="h6">
      {topic === 'Sonstiges'
        ? 'Um eine Deckungsanfrage zu versenden, füllen Sie das nachfolgende Formular bitte vollständig aus und hängen Sie ein entsprechendes Dokument an.'
        : 'Um eine Deckungsanfrage zu versenden, füllen Sie das nachfolgende Formular bitte vollständig aus.'}
    </Typography>
    <Typography sx={{ marginBottom: protectionAmount.length > 0 ? 0 : 4 }} variant="h6">
      Ihre Daten werden an die Versicherung zur weiteren Bearbeitung übermittelt
    </Typography>
    {protectionAmount.length > 0 && (
      <Alert severity="success" sx={{ textAlign: 'start' }}>
        <AlertTitle> Deckungsschutz gewährleistet für: </AlertTitle>
        <ul style={{ paddingLeft: '1rem' }}>
          {protectionAmount.map(amount => (
            <li key={amount}> {amount}</li>
          ))}
        </ul>
      </Alert>
    )}
    {children}
  </Box>
)

export const CoverageForm: FC<CoverageModalProps> = ({ adviceRequest, iban, onSuccess }) => {
  const [coverageFormConfigs, setCoverageFormConfigs] = useState<Array<{ name: string; template: FormTemplate }>>([])
  const [topic, setTopic] = useState<string>(' ')
  const [success, setSuccess] = useState<boolean>(false)

  const availableForms = coverageFormConfigs.map(({ name }) => name)
  const formConfigs = Object.fromEntries(coverageFormConfigs.map(({ name, template }) => [name, template]))
  const currentFormFields = useMemo(() => formConfigs[topic] ?? [], [topic, formConfigs])
  const schema = useMemo(
    () => createFormSchema(currentFormFields).superRefine(superRefineCoverageSchema(currentFormFields)),
    [currentFormFields]
  )
  const sendCoverageRequest = useSendCoverageRequest()
  const { files, filesError, onAddFile, onRemoveFile } = useFileHandlers()
  const { control, watch, handleSubmit, reset } = useForm({
    defaultValues: createDefaultFormValues(currentFormFields, { ...flattenObject(adviceRequest), iban }),
    resolver: zodResolver(schema),
  })

  useEffect(() => {
    getCoverageForms(adviceRequest.fieldOfLaw?.name).then(configs => {
      setCoverageFormConfigs(configs)
      reset(
        createDefaultFormValues(
          configs.flatMap(({ template }) => template),
          { ...flattenObject(adviceRequest), iban }
        )
      )
      if (configs.length <= 1) {
        setTopic('Sonstiges')
      }
    })
  }, [reset, adviceRequest, iban])

  const onError: SubmitErrorHandler<FieldValues> = () => {
    enqueueSnackbar('Bitte überprüfen Sie Ihre Eingaben.', { variant: 'error' })
  }
  const onSubmit: SubmitHandler<FieldValues> = async values => {
    const dependables = currentFormFields.filter(entry => !!entry.dependsOn)
    const filteredFormEntries = dependables.reduce(
      (acc, curr) => ({
        ...acc,
        [curr.label]: values[curr.dependsOn!.field!] === curr.dependsOn?.value ? values[curr.label] : null,
      }),
      values
    )

    const formData = Object.entries(filteredFormEntries)
      .filter(([_, answer]) => answer !== null)
      .map(([label, value]: [string, any]) => ({
        label,
        value: value !== null ? value?.toString() : null,
      }))

    const input: ContactInsurerInput = {
      adviceRequest,
      iban,
      attachments: files,
      formData,
      topic,
      coverageDecription: '',
      // already in `formData`. Change object structure?
      blindCarbonCopy: values['Kopie der Deckungsanfrage'] && typeof values['E-Mail'] === 'string' ? [values['E-Mail']] : [''],
    }

    try {
      await sendCoverageRequest(input, {})
      setSuccess(true)
    } catch (error) {
      if (error instanceof InputError) {
        enqueueSnackbar(error.message, { variant: 'error' })
      } else if (error?.message === 'Combined file size too big') {
        enqueueSnackbar('Die kombinierte Dateigröße darf 15MB nicht überschreiten', { variant: 'error' })
      } else {
        enqueueSnackbar('Ein unerwarter Fehler ist aufgetreten', { variant: 'error' })
      }
    }
  }

  if (success) {
    return (
      <Box display="flex" flexDirection="column" alignItems="center" gap={2}>
        <SVGSecureQuestion width="48px" height="48px" />
        <h3 className="h3 small" style={{ marginBottom: '2rem' }}>
          Ihre Deckungsanfrage wurde erfolgreich versand.
        </h3>
        <Button variant="contained" onClick={onSuccess} startIcon={<ArrowBack />}>
          Zurück
        </Button>
      </Box>
    )
  }

  return (
    <CoverageFormLayout topic={topic} protectionAmount={adviceRequest.protectionAmount}>
      <FormControl sx={{ marginBottom: '0.75rem' }}>
        <InputLabel shrink htmlFor="topic">
          Themenfeld:
        </InputLabel>
        <MuiSelect native label="Themenfeld:" name="topic" onChange={event => setTopic(event.target.value)} value={topic}>
          <option value=" " disabled>
            Bitte wählen...
          </option>
          {availableForms.map(value => (
            <option key={value} value={value}>
              {value}
            </option>
          ))}
        </MuiSelect>
      </FormControl>
      <Box
        component="form"
        id="coverage"
        display="flex"
        flexDirection="column"
        gap={4}
        onSubmit={handleSubmit(onSubmit, onError)}
      >
        {currentFormFields.map(data => (
          <CoverageFormInput key={data.label} watch={watch} data={data} control={control} />
        ))}
      </Box>
      <Box>
        <Button component="label" variant="contained" startIcon={<AttachFile />}>
          Upload
          <input style={{ display: 'none' }} type="file" accept={ACCEPTED_FILES} onChange={onAddFile} />
        </Button>
        <Typography variant="subtitle2" mt={2}>
          Bitte beachten Sie, dass die Anhänge in Summe maximal 15MB groß sein dürfen.
        </Typography>
        {files.length > 0 && (
          <List>
            {files.map((file, index) => (
              <ListItem
                sx={{ border: '1px solid', borderRadius: 2, marginTop: index ? 2 : 0 }}
                key={file.name}
                secondaryAction={
                  <IconButton edge="end" aria-label="delete" onClick={() => onRemoveFile(file.name)}>
                    <Delete />
                  </IconButton>
                }
              >
                <ListItemText>{file.name}</ListItemText>
              </ListItem>
            ))}
          </List>
        )}
        <Box sx={{ marginTop: 2 }}>
          <Button variant="contained" type="submit" form="coverage" disabled={!!filesError || !currentFormFields.length}>
            Anfrage Senden
          </Button>
        </Box>
      </Box>
    </CoverageFormLayout>
  )
}
