export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Date: { input: any; output: any; }
  FilterValue: { input: any; output: any; }
};

export type Address = {
  __typename?: 'Address';
  city: Scalars['String']['output'];
  street: Scalars['String']['output'];
  zip: Scalars['String']['output'];
};

export type AddressInput = {
  city: Scalars['String']['input'];
  street: Scalars['String']['input'];
  zip: Scalars['String']['input'];
};

export type Chancellery = {
  __typename?: 'Chancellery';
  active: Scalars['Boolean']['output'];
  callbacks?: Maybe<Scalars['Boolean']['output']>;
  chancelleryLocations: Array<ChancelleryLocation>;
  contactPerson: ContactPerson;
  deleted: Scalars['Boolean']['output'];
  eConsult?: Maybe<EConsultSettings>;
  email: Scalars['String']['output'];
  hasB2B?: Maybe<Scalars['Boolean']['output']>;
  iban?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  invoiceEmail?: Maybe<Scalars['String']['output']>;
  invoiceNumberRange?: Maybe<Scalars['String']['output']>;
  lastMatched?: Maybe<LastMatched>;
  letterhead?: Maybe<Letterhead>;
  matchingConfigs?: Maybe<Array<MatchingConfig>>;
  matchingThrottleCount?: Maybe<Scalars['Int']['output']>;
  matchings?: Maybe<Array<Matching>>;
  modifiedAt: Scalars['Date']['output'];
  mollieId?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  orderVolumes: Array<OrderVolume>;
  phone: Scalars['String']['output'];
  powerBI?: Maybe<Array<Scalars['String']['output']>>;
  secupay?: Maybe<SecupaySettings>;
  services?: Maybe<Scalars['String']['output']>;
  status: ChancelleryStatus;
  tags?: Maybe<Array<Scalars['String']['output']>>;
  taxId?: Maybe<Scalars['String']['output']>;
  taxNumber?: Maybe<Scalars['String']['output']>;
  users: Array<User>;
};

export type ChancelleryInput = {
  active: Scalars['Boolean']['input'];
  callbacks?: InputMaybe<Scalars['Boolean']['input']>;
  contactPerson: ContactPersonInput;
  deleted?: InputMaybe<Scalars['Boolean']['input']>;
  eConsult: EConsultSettingsInput;
  email: Scalars['String']['input'];
  iban?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  invoiceEmail?: InputMaybe<Scalars['String']['input']>;
  invoiceNumberRange?: InputMaybe<Scalars['String']['input']>;
  letterhead?: InputMaybe<LetterheadInput>;
  matchingThrottleCount?: InputMaybe<Scalars['Int']['input']>;
  mollieId?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  orderVolumes?: InputMaybe<Array<OrderVolumeInput>>;
  phone: Scalars['String']['input'];
  powerBI?: InputMaybe<Array<Scalars['String']['input']>>;
  products?: InputMaybe<Array<Scalars['String']['input']>>;
  secupay: SecupaySettingsInput;
  services?: InputMaybe<Scalars['String']['input']>;
  tags?: InputMaybe<Array<Scalars['String']['input']>>;
  taxId?: InputMaybe<Scalars['String']['input']>;
  taxNumber?: InputMaybe<Scalars['String']['input']>;
};

export type ChancelleryList = {
  __typename?: 'ChancelleryList';
  list: Array<Chancellery>;
  page: Scalars['Int']['output'];
  pageSize: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
  totalDeleted: Scalars['Int']['output'];
  totalInactive: Scalars['Int']['output'];
  totalLimited: Scalars['Int']['output'];
};

export type ChancelleryLocation = {
  __typename?: 'ChancelleryLocation';
  active: Scalars['Boolean']['output'];
  address: Address;
  chancellery: Chancellery;
  chancelleryId: Scalars['String']['output'];
  deleted: Scalars['Boolean']['output'];
  eConsult?: Maybe<EConsultSettings>;
  fallbackFor: Array<FieldOfLaw>;
  fieldOfLawPhones: Array<FieldOfLawPhone>;
  hasActiveConfigurations: Scalars['Boolean']['output'];
  holidays: Array<Holiday>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  openingHours: Array<OpeningHoursValue>;
  phone: Scalars['String']['output'];
  tags?: Maybe<Array<Scalars['String']['output']>>;
  users: Array<User>;
};

export type ChancelleryLocationInput = {
  active: Scalars['Boolean']['input'];
  address: AddressInput;
  chancelleryId: Scalars['String']['input'];
  deleted: Scalars['Boolean']['input'];
  fallbackFor: Array<Scalars['String']['input']>;
  fieldOfLawPhones: Array<FieldOfLawPhoneInput>;
  holidays: Array<HolidayInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  openingHours: Array<OpeningHoursValueInput>;
  phone: Scalars['String']['input'];
  tags?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type ChancelleryLocationList = {
  __typename?: 'ChancelleryLocationList';
  list: Array<ChancelleryLocation>;
  page: Scalars['Int']['output'];
  pageSize: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum ChancelleryStatus {
  Active = 'active',
  Archived = 'archived',
  Inactive = 'inactive',
  Limited = 'limited'
}

export enum ConfigWeight {
  Default = 'DEFAULT',
  Few = 'FEW',
  Less = 'LESS',
  More = 'MORE',
  Much = 'MUCH'
}

export type ContactPerson = {
  __typename?: 'ContactPerson';
  firstname: Scalars['String']['output'];
  foa: Scalars['String']['output'];
  lastname: Scalars['String']['output'];
};

export type ContactPersonInput = {
  firstname: Scalars['String']['input'];
  foa: Scalars['String']['input'];
  lastname: Scalars['String']['input'];
};

export enum ContactType {
  Callback = 'CALLBACK',
  Email = 'EMAIL',
  NotSet = 'NOT_SET',
  Redirect = 'REDIRECT'
}

export type Coverage = {
  __typename?: 'Coverage';
  ok: Scalars['Boolean']['output'];
  problems?: Maybe<CoverageProblemList>;
};

export type CoverageCacheConfiguration = {
  configs?: InputMaybe<Array<CoverageErrorType>>;
  fallbacks?: InputMaybe<Array<CoverageErrorType>>;
};

export enum CoverageErrorType {
  MissingField = 'MISSING_FIELD',
  MissingPartner = 'MISSING_PARTNER',
  MissingProduct = 'MISSING_PRODUCT',
  MissingZipArea = 'MISSING_ZIP_AREA',
  TemporaryMissingField = 'TEMPORARY_MISSING_FIELD'
}

export type CoverageInfo = {
  __typename?: 'CoverageInfo';
  configs: Coverage;
  fallbacks: Coverage;
};


export type CoverageInfoConfigsArgs = {
  filters?: InputMaybe<Array<Filter>>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Sort>;
};


export type CoverageInfoFallbacksArgs = {
  filters?: InputMaybe<Array<Filter>>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Sort>;
};

export type CoverageMissingProblem = {
  __typename?: 'CoverageMissingProblem';
  errorType: CoverageErrorType;
  fieldOfLawId: Scalars['String']['output'];
};

export type CoveragePartnerProblem = {
  __typename?: 'CoveragePartnerProblem';
  errorType: CoverageErrorType;
  fieldOfLawId: Scalars['String']['output'];
  info: PartnerErrorInfo;
};

export type CoverageProblem = CoverageMissingProblem | CoveragePartnerProblem | CoverageProductProblem | CoverageTemporaryMissingProblem | CoverageZipProblem;

export type CoverageProblemList = {
  __typename?: 'CoverageProblemList';
  list: Array<CoverageProblem>;
  page: Scalars['Int']['output'];
  pageSize: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type CoverageProductProblem = {
  __typename?: 'CoverageProductProblem';
  errorType: CoverageErrorType;
  fieldOfLawId: Scalars['String']['output'];
  info: ProductErrorInfo;
};

export type CoverageTemporaryMissingProblem = {
  __typename?: 'CoverageTemporaryMissingProblem';
  errorType: CoverageErrorType;
  fieldOfLawId: Scalars['String']['output'];
  info: PeriodErrorInfo;
};

export type CoverageZipProblem = {
  __typename?: 'CoverageZipProblem';
  errorType: CoverageErrorType;
  fieldOfLawId: Scalars['String']['output'];
  info: ZipErrorInfo;
};

export type DeletionSuccess = {
  __typename?: 'DeletionSuccess';
  success: Scalars['Boolean']['output'];
};

export type EConsultSettings = {
  __typename?: 'EConsultSettings';
  active: Scalars['Boolean']['output'];
  customerId: Scalars['String']['output'];
};

export type EConsultSettingsInput = {
  active: Scalars['Boolean']['input'];
  customerId: Scalars['String']['input'];
};

export enum ExportVariant {
  Default = 'DEFAULT',
  OrderVolume = 'ORDER_VOLUME'
}

export type Feature = {
  __typename?: 'Feature';
  id: Scalars['String']['output'];
  info: Scalars['String']['output'];
  name: Scalars['String']['output'];
  type: FeatureType;
};

export type FeatureInput = {
  id: Scalars['String']['input'];
  info: Scalars['String']['input'];
  name: Scalars['String']['input'];
  type: FeatureType;
};

export enum FeatureType {
  Excluded = 'EXCLUDED',
  Normal = 'NORMAL',
  Optional = 'OPTIONAL',
  Special = 'SPECIAL'
}

export type FieldOfLaw = {
  __typename?: 'FieldOfLaw';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type FieldOfLawIds = {
  excluded?: InputMaybe<Array<Scalars['String']['input']>>;
  included: Array<Scalars['String']['input']>;
};

export type FieldOfLawPhone = {
  __typename?: 'FieldOfLawPhone';
  fieldOfLaw: FieldOfLaw;
  phone: Scalars['String']['output'];
};

export type FieldOfLawPhoneInput = {
  fieldOfLawId: Scalars['String']['input'];
  phone: Scalars['String']['input'];
};

export type FieldsOfLaw = {
  __typename?: 'FieldsOfLaw';
  excluded?: Maybe<Array<FieldOfLaw>>;
  included: Array<FieldOfLaw>;
};

export type Filter = {
  name: Scalars['String']['input'];
  operator: FilterOperator;
  value: Scalars['FilterValue']['input'];
};

export enum FilterOperator {
  After = 'after',
  And = 'and',
  At = 'at',
  Before = 'before',
  /**
   * Will transform `FilterValue` to a regex. Special characters will not be escaped.
   *
   * Example:
   * ```
   * const data = [
   *   "a",
   *   "abc",
   *   "abd",
   *   "ccd"
   * ]
   *
   * const filter = {
   *   value: "a",
   *   operator: "contains"
   * }
   *
   * -> ["a", "abc", "abd"]
   *
   * const filter = {
   *   value: "a",
   *   operator: "equals"
   * }
   *
   * -> ["a"]
   * ```
   *
   * If the Document value by which you want to filter is an array, the `FilterValue` has to match at least one of the values of the beforementioned array.
   */
  Contains = 'contains',
  Empty = 'empty',
  /** Document value must match FilterValue. If Document value is an Array, the Array needs to include the FilterValue */
  Equals = 'equals',
  Gt = 'gt',
  Gte = 'gte',
  Lt = 'lt',
  Lte = 'lte',
  NotEmpty = 'notEmpty',
  Or = 'or',
  ZipAreaContains = 'zipAreaContains',
  ZipAreaEquals = 'zipAreaEquals'
}

export type Holiday = {
  __typename?: 'Holiday';
  end: Scalars['Date']['output'];
  fieldsOfLaw?: Maybe<Array<FieldOfLaw>>;
  global?: Maybe<Scalars['Boolean']['output']>;
  start: Scalars['Date']['output'];
};

export type HolidayInput = {
  end: Scalars['Date']['input'];
  fieldOfLawIds?: InputMaybe<Array<Scalars['String']['input']>>;
  global?: InputMaybe<Scalars['Boolean']['input']>;
  start: Scalars['Date']['input'];
};

export type LastMatched = {
  __typename?: 'LastMatched';
  date?: Maybe<Scalars['Date']['output']>;
  notReached?: Maybe<Scalars['Boolean']['output']>;
};

export type Letterhead = {
  __typename?: 'Letterhead';
  address?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  web?: Maybe<Scalars['String']['output']>;
  zip?: Maybe<Scalars['String']['output']>;
};

export type LetterheadInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  web?: InputMaybe<Scalars['String']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type Marketing = {
  __typename?: 'Marketing';
  awinSaleType: Scalars['String']['output'];
};

export type MarketingInput = {
  awinSaleType: Scalars['String']['input'];
};

export type MatchableLocation = {
  __typename?: 'MatchableLocation';
  active: Scalars['Boolean']['output'];
  address: Address;
  deleted: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
};

export type Matching = {
  __typename?: 'Matching';
  /** Human readable unique identifier */
  adviceId: Scalars['String']['output'];
  adviceUuid: Scalars['String']['output'];
  cancelReason?: Maybe<Scalars['String']['output']>;
  canceledAt?: Maybe<Scalars['Date']['output']>;
  chancelleryId: Scalars['String']['output'];
  chancelleryLocationId: Scalars['String']['output'];
  displayRank: Scalars['Int']['output'];
  fieldOfLaw: Scalars['String']['output'];
  id: Scalars['String']['output'];
  ignoreOrderVolume: Scalars['Boolean']['output'];
  matchedAt: Scalars['Date']['output'];
  partnerId: Scalars['String']['output'];
  productId: Scalars['String']['output'];
  zip?: Maybe<Scalars['String']['output']>;
};

export type MatchingConfig = {
  __typename?: 'MatchingConfig';
  active: Scalars['Boolean']['output'];
  chancelleryId?: Maybe<Scalars['String']['output']>;
  chancelleryLocation: ChancelleryLocation;
  chancelleryLocationId: Scalars['String']['output'];
  deleted: Scalars['Boolean']['output'];
  fallback: Scalars['Boolean']['output'];
  fieldsOfLaw: FieldsOfLaw;
  id: Scalars['String']['output'];
  matchableLocations?: Maybe<Array<MatchableLocation>>;
  partners: Partners;
  priority?: Maybe<Scalars['Int']['output']>;
  products: Products;
  tags?: Maybe<Array<Scalars['String']['output']>>;
  weight: ConfigWeight;
  zipAreas: Array<Scalars['String']['output']>;
};

export type MatchingConfigInput = {
  active: Scalars['Boolean']['input'];
  chancelleryId?: InputMaybe<Scalars['String']['input']>;
  chancelleryLocationId: Scalars['String']['input'];
  deleted: Scalars['Boolean']['input'];
  fallback: Scalars['Boolean']['input'];
  fieldOfLawIds: FieldOfLawIds;
  id?: InputMaybe<Scalars['String']['input']>;
  partnerIds: PartnerIds;
  priority?: InputMaybe<Scalars['Int']['input']>;
  productIds: ProductIds;
  tags?: InputMaybe<Array<Scalars['String']['input']>>;
  weight: ConfigWeight;
  zipAreas: Array<Scalars['String']['input']>;
};

export type MatchingConfigList = {
  __typename?: 'MatchingConfigList';
  list: Array<MatchingConfig>;
  page: Scalars['Int']['output'];
  pageSize: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type MatchingInput = {
  ignoreOrderVolume: Scalars['Boolean']['input'];
};

export type Mutation = {
  __typename?: 'Mutation';
  createPartner: Partner;
  createProduct: Product;
  deletePartner: DeletionSuccess;
  deleteProduct: Product;
  saveChancellery?: Maybe<Chancellery>;
  saveChancelleryLocation?: Maybe<ChancelleryLocation>;
  saveMatchingConfig?: Maybe<MatchingConfig>;
  updatePartner: Partner;
  updateProduct: Product;
};


export type MutationCreatePartnerArgs = {
  partner: PartnerInput;
};


export type MutationCreateProductArgs = {
  product: ProductInput;
};


export type MutationDeletePartnerArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteProductArgs = {
  id: Scalars['String']['input'];
};


export type MutationSaveChancelleryArgs = {
  chancellery: ChancelleryInput;
};


export type MutationSaveChancelleryLocationArgs = {
  chancelleryLocation: ChancelleryLocationInput;
};


export type MutationSaveMatchingConfigArgs = {
  matchingConfig: MatchingConfigInput;
};


export type MutationUpdatePartnerArgs = {
  partner: PartnerInput;
};


export type MutationUpdateProductArgs = {
  product: ProductInput;
};

export type OpeningHoursValue = {
  __typename?: 'OpeningHoursValue';
  enabled: Scalars['Boolean']['output'];
  end?: Maybe<Scalars['Int']['output']>;
  start?: Maybe<Scalars['Int']['output']>;
};

export type OpeningHoursValueInput = {
  enabled: Scalars['Boolean']['input'];
  end?: InputMaybe<Scalars['Int']['input']>;
  start?: InputMaybe<Scalars['Int']['input']>;
};

export type OrderVolume = {
  __typename?: 'OrderVolume';
  fieldOfLaw: FieldOfLaw;
  weeklyMax?: Maybe<Scalars['Int']['output']>;
};

export type OrderVolumeInput = {
  fieldOfLawId: Scalars['String']['input'];
  weeklyMax?: InputMaybe<Scalars['Int']['input']>;
};

export type Partner = {
  __typename?: 'Partner';
  /** Is Partner acitve or deactivated */
  active: Scalars['Boolean']['output'];
  /** Indicates if Partner has already accepted agb */
  agbsPreAccepted: Scalars['Boolean']['output'];
  createdAt?: Maybe<Scalars['Date']['output']>;
  /** uuid of Partner */
  id: Scalars['String']['output'];
  /** Legal protection insurer that is automatically set on each AdviceRequest coming from this partner */
  insurer?: Maybe<Scalars['String']['output']>;
  /** Important system-wide Partner that should not be deleted */
  locked: Scalars['Boolean']['output'];
  modifiedAt?: Maybe<Scalars['Date']['output']>;
  /** Visible name of Partner */
  name: Scalars['String']['output'];
  /** Validation configuration for discount i.e. input of valid customer id */
  partnerValidation?: Maybe<PartnerValidation>;
  /** Visible phone of Partner */
  phone?: Maybe<Scalars['String']['output']>;
};

export type PartnerDiscount = {
  __typename?: 'PartnerDiscount';
  active: Scalars['Boolean']['output'];
  /** difference between disNetPrice and disGrossPrice */
  diffNetGross: Scalars['Float']['output'];
  /** discounted gross price */
  disGrossPrice: Scalars['Float']['output'];
  /** discounted net price */
  disNetPrice: Scalars['Float']['output'];
  discount: Scalars['Float']['output'];
  id: Scalars['String']['output'];
  partners: Array<ProductPartner>;
  taxRate: Scalars['Float']['output'];
};

export type PartnerDiscountInput = {
  active: Scalars['Boolean']['input'];
  discount: Scalars['Float']['input'];
  id: Scalars['String']['input'];
  partners: Array<Scalars['String']['input']>;
};

export type PartnerErrorInfo = {
  __typename?: 'PartnerErrorInfo';
  partnerIds: Array<Scalars['String']['output']>;
};

export type PartnerIds = {
  excluded: Array<Scalars['String']['input']>;
  included?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type PartnerInput = {
  active: Scalars['Boolean']['input'];
  agbsPreAccepted: Scalars['Boolean']['input'];
  id: Scalars['String']['input'];
  insurer?: InputMaybe<Scalars['String']['input']>;
  locked: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  partnerValidation?: InputMaybe<PartnerValidationInput>;
  phone?: InputMaybe<Scalars['String']['input']>;
};

export type PartnerList = {
  __typename?: 'PartnerList';
  list: Array<Partner>;
  page: Scalars['Int']['output'];
  pageSize: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type PartnerValidation = {
  __typename?: 'PartnerValidation';
  enabled: Scalars['Boolean']['output'];
  intro: Scalars['String']['output'];
  regex: Scalars['String']['output'];
};

export type PartnerValidationInput = {
  enabled: Scalars['Boolean']['input'];
  intro: Scalars['String']['input'];
  regex: Scalars['String']['input'];
};

export type Partners = {
  __typename?: 'Partners';
  excluded?: Maybe<Array<Partner>>;
  included: Array<Partner>;
};

export type Payment = {
  __typename?: 'Payment';
  chancelleryId: Scalars['String']['output'];
  chancelleryName: Scalars['String']['output'];
  methods: Array<PaymentMethodType>;
  /** Klugo Mollie-ID or chancellery Mollie-ID if chancelleryId exists */
  mollieId: Scalars['String']['output'];
};

export type PaymentInput = {
  chancelleryId?: InputMaybe<Scalars['String']['input']>;
  methods: Array<PaymentMethodType>;
};

export enum PaymentMethodType {
  Creditcard = 'CREDITCARD',
  Klarna = 'KLARNA',
  Paypal = 'PAYPAL'
}

export type Period = {
  __typename?: 'Period';
  end: Scalars['Date']['output'];
  start: Scalars['Date']['output'];
};

export type PeriodErrorInfo = {
  __typename?: 'PeriodErrorInfo';
  periods: Array<Period>;
};

export type PeriodInput = {
  daily?: InputMaybe<Scalars['Int']['input']>;
  monthly?: InputMaybe<Scalars['Int']['input']>;
  weekly?: InputMaybe<Scalars['Int']['input']>;
};

export type PlatformInput = {
  business: Scalars['Boolean']['input'];
  consumer: Scalars['Boolean']['input'];
};

export type Product = {
  __typename?: 'Product';
  /** available for purchase */
  active: Scalars['Boolean']['output'];
  /** indicates wehether the prices are discounted or not */
  activeDiscount: Scalars['Boolean']['output'];
  /** supported contact types */
  contactTypes: Array<ContactType>;
  /** difference between netPrice and grossPrice */
  diffNetGross: Scalars['Float']['output'];
  /** supported services */
  features: Array<Feature>;
  /** supported fields of law */
  fieldsOfLaw: Array<ProductFieldOfLaw>;
  /** gross price */
  grossPrice: Scalars['Float']['output'];
  /** uuid */
  id: Scalars['String']['output'];
  /** indicates whether the product can be deleted or not */
  locked: Scalars['Boolean']['output'];
  /** product marketing options */
  marketing: Marketing;
  /** matching settings */
  matching: Matching;
  /** when the product was last updated */
  modifiedAt: Scalars['Date']['output'];
  /** app-internal name */
  name: Scalars['String']['output'];
  /** net price */
  netPrice: Scalars['Float']['output'];
  /** partner dicounts */
  partnerDiscounts: Array<PartnerDiscount>;
  /** supported partner */
  partners: Array<ProductPartner>;
  /** payment method and account */
  payment: Payment;
  /** recommended retail price */
  rrp?: Maybe<Scalars['Float']['output']>;
  /** supported services */
  services?: Maybe<Array<Scalars['String']['output']>>;
  /** advertised description for customer */
  subtitle: Scalars['String']['output'];
  /** tax rate */
  taxRate: Scalars['Float']['output'];
  /** advertised name for customer */
  title: Scalars['String']['output'];
  type?: Maybe<ProductType>;
};

export type ProductErrorInfo = {
  __typename?: 'ProductErrorInfo';
  productIds: Array<Scalars['String']['output']>;
};

export type ProductFieldOfLaw = {
  __typename?: 'ProductFieldOfLaw';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type ProductIds = {
  excluded?: InputMaybe<Array<Scalars['String']['input']>>;
  included: Array<Scalars['String']['input']>;
};

export type ProductInput = {
  /** available for purchase */
  active: Scalars['Boolean']['input'];
  /** supported contact types */
  contactTypes: Array<ContactType>;
  /** supported services */
  features: Array<FeatureInput>;
  /** supported fields of law */
  fieldsOfLaw: Array<Scalars['String']['input']>;
  /** uuid */
  id: Scalars['String']['input'];
  /** indicates whether the product can be deleted or not */
  locked: Scalars['Boolean']['input'];
  /** product marketing options */
  marketing: MarketingInput;
  /** matching settings */
  matching: MatchingInput;
  /** app-internal name */
  name: Scalars['String']['input'];
  /** net price */
  netPrice: Scalars['Float']['input'];
  /** partner dicounts */
  partnerDiscounts: Array<PartnerDiscountInput>;
  /** supported partner */
  partners: Array<Scalars['String']['input']>;
  /** payment method and account */
  payment: PaymentInput;
  /** recommended retail price */
  rrp?: InputMaybe<Scalars['Float']['input']>;
  /** supported services */
  services?: InputMaybe<Array<Scalars['String']['input']>>;
  /** advertised description for customer */
  subtitle: Scalars['String']['input'];
  /** advertised name for customer */
  title: Scalars['String']['input'];
};

export type ProductPartner = {
  __typename?: 'ProductPartner';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export enum ProductType {
  Business = 'BUSINESS',
  Consumer = 'CONSUMER'
}

export type Products = {
  __typename?: 'Products';
  excluded?: Maybe<Array<Product>>;
  included: Array<Product>;
};

export type ProductsList = {
  __typename?: 'ProductsList';
  list: Array<Product>;
  page: Scalars['Int']['output'];
  pageSize: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type Query = {
  __typename?: 'Query';
  chancelleries: ChancelleryList;
  chancellery: Chancellery;
  chancelleryLocation: ChancelleryLocation;
  chancelleryLocations: ChancelleryLocationList;
  createExport: Scalars['String']['output'];
  matchableLocations: Array<MatchableLocation>;
  matchingConfig: MatchingConfig;
  matchingConfigCoverage: CoverageInfo;
  matchingConfigs: MatchingConfigList;
  partner: Partner;
  partners: PartnerList;
  /** single product */
  product: Product;
  /** paginated list of products */
  products: ProductsList;
};


export type QueryChancelleriesArgs = {
  filters?: InputMaybe<Array<Filter>>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Sort>;
};


export type QueryChancelleryArgs = {
  id: Scalars['String']['input'];
};


export type QueryChancelleryLocationArgs = {
  id: Scalars['String']['input'];
};


export type QueryChancelleryLocationsArgs = {
  filters?: InputMaybe<Array<Filter>>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Sort>;
};


export type QueryCreateExportArgs = {
  variant?: InputMaybe<ExportVariant>;
};


export type QueryMatchableLocationsArgs = {
  chancelleryId: Scalars['String']['input'];
};


export type QueryMatchingConfigArgs = {
  id: Scalars['String']['input'];
};


export type QueryMatchingConfigCoverageArgs = {
  cached?: InputMaybe<CoverageCacheConfiguration>;
};


export type QueryMatchingConfigsArgs = {
  filters?: InputMaybe<Array<Filter>>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Sort>;
};


export type QueryPartnerArgs = {
  id: Scalars['String']['input'];
};


export type QueryPartnersArgs = {
  filters?: InputMaybe<Array<Filter>>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Sort>;
};


export type QueryProductArgs = {
  id: Scalars['String']['input'];
};


export type QueryProductsArgs = {
  filters?: InputMaybe<Array<Filter>>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Sort>;
  verificationNumber?: InputMaybe<Scalars['String']['input']>;
};

export type SecupaySettings = {
  __typename?: 'SecupaySettings';
  active: Scalars['Boolean']['output'];
  contractId: Scalars['String']['output'];
};

export type SecupaySettingsInput = {
  active: Scalars['Boolean']['input'];
  contractId: Scalars['String']['input'];
};

export type Sort = {
  /** name of sortable fields */
  sortBy: Scalars['String']['input'];
  /** sorting direction. see SortDirection for details */
  sortDirection: SortDirection;
};

export enum SortDirection {
  Asc = 'asc',
  Desc = 'desc'
}

export type User = {
  __typename?: 'User';
  active: Scalars['Boolean']['output'];
  email: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type ZipError = {
  __typename?: 'ZipError';
  lower: Scalars['String']['output'];
  upper: Scalars['String']['output'];
};

export type ZipErrorInfo = {
  __typename?: 'ZipErrorInfo';
  areas: Array<ZipError>;
};

export type CreateProductMutationVariables = Exact<{
  product: ProductInput;
}>;


export type CreateProductMutation = { __typename?: 'Mutation', createProduct: { __typename?: 'Product', id: string, active: boolean, locked: boolean, name: string, title: string, subtitle: string, netPrice: number, grossPrice: number, rrp?: number | null, contactTypes: Array<ContactType>, services?: Array<string> | null, fieldsOfLaw: Array<{ __typename?: 'ProductFieldOfLaw', id: string }>, partners: Array<{ __typename?: 'ProductPartner', id: string }>, features: Array<{ __typename?: 'Feature', id: string, name: string, type: FeatureType, info: string }>, partnerDiscounts: Array<{ __typename?: 'PartnerDiscount', id: string, discount: number, disNetPrice: number, disGrossPrice: number, active: boolean, partners: Array<{ __typename?: 'ProductPartner', id: string }> }>, payment: { __typename?: 'Payment', methods: Array<PaymentMethodType>, chancelleryId: string }, matching: { __typename?: 'Matching', ignoreOrderVolume: boolean }, marketing: { __typename?: 'Marketing', awinSaleType: string } } };

export type DeleteProductMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type DeleteProductMutation = { __typename?: 'Mutation', deleteProduct: { __typename?: 'Product', id: string, name: string } };

export type GetChancelleriesQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Sort>;
  filters?: InputMaybe<Array<Filter> | Filter>;
}>;


export type GetChancelleriesQuery = { __typename?: 'Query', chancelleries: { __typename?: 'ChancelleryList', total: number, list: Array<{ __typename?: 'Chancellery', id: string, name: string, mollieId?: string | null }> } };

export type GetPartnersQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Sort>;
  filters?: InputMaybe<Array<Filter> | Filter>;
}>;


export type GetPartnersQuery = { __typename?: 'Query', partners: { __typename?: 'PartnerList', list: Array<{ __typename?: 'Partner', id: string, name: string }> } };

export type GetProductQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type GetProductQuery = { __typename?: 'Query', product: { __typename?: 'Product', id: string, active: boolean, locked: boolean, name: string, title: string, subtitle: string, netPrice: number, grossPrice: number, rrp?: number | null, contactTypes: Array<ContactType>, services?: Array<string> | null, fieldsOfLaw: Array<{ __typename?: 'ProductFieldOfLaw', id: string }>, partners: Array<{ __typename?: 'ProductPartner', id: string }>, features: Array<{ __typename?: 'Feature', id: string, name: string, type: FeatureType, info: string }>, partnerDiscounts: Array<{ __typename?: 'PartnerDiscount', id: string, discount: number, disNetPrice: number, disGrossPrice: number, active: boolean, partners: Array<{ __typename?: 'ProductPartner', id: string }> }>, payment: { __typename?: 'Payment', methods: Array<PaymentMethodType>, chancelleryId: string }, matching: { __typename?: 'Matching', ignoreOrderVolume: boolean }, marketing: { __typename?: 'Marketing', awinSaleType: string } } };

export type GetProductsQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Sort>;
  filters?: InputMaybe<Array<Filter> | Filter>;
}>;


export type GetProductsQuery = { __typename?: 'Query', products: { __typename?: 'ProductsList', page: number, pageSize: number, total: number, list: Array<{ __typename?: 'Product', id: string, active: boolean, modifiedAt: any, name: string, netPrice: number }> } };

export type UpdateProductMutationVariables = Exact<{
  product: ProductInput;
}>;


export type UpdateProductMutation = { __typename?: 'Mutation', updateProduct: { __typename?: 'Product', id: string, active: boolean, locked: boolean, name: string, title: string, subtitle: string, netPrice: number, grossPrice: number, rrp?: number | null, contactTypes: Array<ContactType>, services?: Array<string> | null, fieldsOfLaw: Array<{ __typename?: 'ProductFieldOfLaw', id: string }>, partners: Array<{ __typename?: 'ProductPartner', id: string }>, features: Array<{ __typename?: 'Feature', id: string, name: string, type: FeatureType, info: string }>, partnerDiscounts: Array<{ __typename?: 'PartnerDiscount', id: string, discount: number, disNetPrice: number, disGrossPrice: number, active: boolean, partners: Array<{ __typename?: 'ProductPartner', id: string }> }>, payment: { __typename?: 'Payment', methods: Array<PaymentMethodType>, chancelleryId: string }, matching: { __typename?: 'Matching', ignoreOrderVolume: boolean }, marketing: { __typename?: 'Marketing', awinSaleType: string } } };
