import { AccessTime } from '@mui/icons-material'
import { Box, Link, Typography } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import React, { FC } from 'react'
import { NavLink } from 'react-router-dom'

import { createGridColumnsFrom } from 'common/components-mui'
import { HistoryEvent, HistoryEventType } from 'common/graphql/schemaDefinition'

import { AdviceRequest } from '../../interfaces'

const RenderPayload: FC<{ row: HistoryEvent }> = ({ row }) => {
  switch (row.type) {
    case HistoryEventType.FieldOfLawChanged:
      return row?.payload?.folId ?? '-'
    case HistoryEventType.AssignedToChancellery:
    case HistoryEventType.MatchingSuggestionOpened:
    case HistoryEventType.Rematched:
      return row?.payload?.chancelleryId ? (
        <Link
          component={NavLink}
          sx={{ textDecoration: 'underline' }}
          to={`/chancelleries/locations/edit/${row?.payload?.chancelleryId}`}
        >
          {row?.payload?.chancelleryId}
        </Link>
      ) : (
        '-'
      )
    case HistoryEventType.SetToClosed:
      return row?.payload?.closeReason ?? '-'
    case HistoryEventType.Cancelled:
      return !row?.payload?.cancelReason
        ? 'Grund nicht angegeben.'
        : row.payload.cancelReason === 'Sonstiges'
        ? row.payload.cancelReasonDescription
        : row.payload.cancelReason
    default:
      return row?.payload ? <code> {JSON.stringify(row.payload, null, 2)} </code> : '-'
  }
}

const dateFormatter = Intl.DateTimeFormat('de', { dateStyle: 'medium', timeStyle: 'short' })
const columns = createGridColumnsFrom([
  { field: 'id', headerName: '#', minWidth: 40 },
  { field: 'type', headerName: 'Typ', width: 220 },
  {
    field: 'date',
    headerName: 'Datum',
    width: 140,
    valueFormatter: ({ value }) => (value ? dateFormatter.format(value) : '-'),
  },
  {
    field: 'user',
    headerName: 'User ID',
    renderCell: ({ value }) =>
      value ? (
        <Link component={NavLink} sx={{ textDecoration: 'underline' }} to={`/users/edit/${value}`}>
          {value}
        </Link>
      ) : (
        <p> Kein Nutzer Hinterlegt </p>
      ),
    width: 100,
  },
  {
    field: 'payload',
    headerName: 'Payload',
    width: 2000,
    renderCell: ({ row }) => <RenderPayload row={row} />,
  },
])

const sortHistoryByDate = (a: HistoryEvent, b: HistoryEvent): number => new Date(b.date).getTime() - new Date(a.date).getTime()
const createHistoryTableRows = (
  event: HistoryEvent,
  index: number,
  events: Array<HistoryEvent>
): HistoryEvent & { id: number } => ({
  ...event,
  date: new Date(event.date),
  id: events.length - index,
})

const HEADER_HEIGHT = 56
const ROW_HEIGHT = 51

export const getTableHeight = (rows: number): number => HEADER_HEIGHT * 2 + rows * ROW_HEIGHT + ROW_HEIGHT * 0.6

export const AdviceRequestHistoryTable: FC<{ adviceRequest: AdviceRequest }> = ({ adviceRequest }) => {
  // eslint-disable-next-line fp/no-mutating-methods
  const rows = (adviceRequest.history ?? []).sort(sortHistoryByDate).map(createHistoryTableRows)

  const tableHeight = getTableHeight(4)
  return (
    <Box sx={{ marginTop: 4 }}>
      <Box display="flex" alignItems="center" gap={1} sx={{ marginBottom: 2 }}>
        <AccessTime color="primary" />
        <Typography component="legend" variant="h5" color="primary" sx={{ fontWeight: 500 }}>
          Fallhistorie
        </Typography>
      </Box>
      <Box component="fieldset" sx={{ marginBottom: 4, height: `${tableHeight}px` }}>
        <DataGrid
          rows={rows}
          columns={columns}
          initialState={{
            pagination: {
              pageSize: 25,
            },
          }}
        />
      </Box>
    </Box>
  )
}
