import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classnames from 'classnames'
import React, { useEffect, FC } from 'react'
import { Col, Row, Table } from 'reactstrap'

import { Partner } from 'common/api/types'
import { FilterTagsComponent as FilterTags, SortFilterTh, Spinner } from 'common/components'
import { DatePickerInput, TextFilterInput } from 'common/components/FilterInput'
import { PRODUCT_NAMES, ADVICE_REQUESTS_ENDPOINT, FIELDS_OF_LAW_ENTRIES } from 'common/constants'
import { ListProps, withList } from 'common/container'
import SVGRedirected from 'common/icons/Icon_Forward.svg'
import { LoggedInUser } from 'common/user-context'
import { convertToTagValues, formatDate } from 'common/utils'

import query from '../graphql/adviceRequests.graphql'
import { LawyerRequest } from '../interfaces'
import { ChancelleryLocationsQuery, FilterOperator } from '../interfaces/schemaDefinition'
import { wasRedirected, getChancelleryFilter } from '../utils'

import { AdviceMenuButton } from './AdviceMenu'
import SVGLiveReload from './LiveReloadButton/LiveReload.svg'
import { PanelTabs } from './PanelTabs/PanelTabs'
import { PartnerCell } from './PartnerCell'
import { ToolbarTabs } from './ToolbarTabs'
import { ToolbarImage } from './ToolbarTabs/ToolbarTabs'

type RedirectRequestsProps = ListProps<LawyerRequest> & {
  user?: LoggedInUser
  chancelleryLocations: ChancelleryLocationsQuery['chancelleryLocations']['list']
  assignableUsers: Array<{ id: string; name: string }>
  assignFilter?: string
  onRowClick: (rowId: string) => React.EventHandler<React.FormEvent<HTMLTableRowElement>>
  partners: Array<Partner>
  addedAdviceRequestIds: Array<string>
  hasPremium: boolean
}

const createOnSort = (func: (id: string) => void, id: string) => () => func(id)

export const RedirectRequestsView: FC<RedirectRequestsProps> = ({
  partners,
  assignableUsers,
  list,
  sort,
  page,
  pageSize,
  total,
  filters,
  onReload,
  onRowClick,
  onSort,
  onFilterChange,
  onFilterRemove,
  onNext,
  onPrev,
  loading,
  addedAdviceRequestIds,
  hasPremium,
}) => {
  useEffect(() => {
    if (addedAdviceRequestIds.length) {
      onReload()
    }
  }, [onReload, addedAdviceRequestIds])

  return (
    <>
      <PanelTabs>
        <ToolbarTabs
          pagination={{ page, pageSize, total, label: 'Direktanrufen' }}
          onNext={onNext}
          onPrev={onPrev}
          title="Eingehende Anrufe"
          subtitle="Kunden, die direkt an Sie weitergeleitet wurden"
          imgSource={ToolbarImage.imgCall}
        />
        <Row>
          <Col sm="12">
            <Spinner condition={loading} center>
              <FilterTags filters={filters} onTagRemove={onFilterRemove} partners={partners} />
              {list.length > 0 ? (
                <Table hover responsive>
                  <thead>
                    <tr>
                      <SortFilterTh
                        id="redirectRequestsAdviceId"
                        name="adviceId"
                        sort={sort}
                        onSort={createOnSort(onSort, 'adviceId')}
                        onFilterSubmit={onFilterChange}
                        FilterInput={TextFilterInput}
                        placeholder="Beratungs ID..."
                      >
                        ID
                      </SortFilterTh>
                      {hasPremium && <th>Produkt</th>}
                      <SortFilterTh
                        id="redirectRequestsPersonName"
                        name="personName"
                        sort={sort}
                        onSort={createOnSort(onSort, 'personName')}
                        onFilterSubmit={onFilterChange}
                        FilterInput={TextFilterInput}
                        placeholder="Name..."
                      >
                        Name
                      </SortFilterTh>
                      <SortFilterTh
                        id="redirectRequestsFieldOfLaw"
                        name="fieldOfLaw"
                        sort={sort}
                        onSort={createOnSort(onSort, 'fieldOfLaw')}
                        onFilterSubmit={onFilterChange}
                        suggestions={convertToTagValues(FIELDS_OF_LAW_ENTRIES)}
                        FilterInput={TextFilterInput}
                        placeholder="Rechtsgebiet..."
                      >
                        Rechtsgebiet
                      </SortFilterTh>
                      <SortFilterTh
                        id="redirectRequestsPartnerId"
                        name="partnerId"
                        sort={sort}
                        onSort={createOnSort(onSort, 'partnerId')}
                        onFilterSubmit={onFilterChange}
                        suggestions={convertToTagValues(partners)}
                        FilterInput={TextFilterInput}
                        placeholder="Partner ID..."
                      >
                        Partner
                      </SortFilterTh>
                      <SortFilterTh
                        id="redirectChancellery"
                        name="chancellery"
                        sort={sort}
                        onSort={createOnSort(onSort, 'chancellery')}
                        onFilterSubmit={onFilterChange}
                        FilterInput={TextFilterInput}
                        placeholder="Kanzlei..."
                      >
                        Kanzlei
                      </SortFilterTh>
                      <SortFilterTh
                        id="redirectRequestsCreatedAt"
                        name="createdAt"
                        sort={sort}
                        onSort={createOnSort(onSort, 'createdAt')}
                        onFilterSubmit={onFilterChange}
                        FilterInput={DatePickerInput}
                        placeholder="Erstellt..."
                      >
                        Erstellt
                      </SortFilterTh>
                      <th>In Bearbeitung durch</th>
                      <th className="text-center action-header">Aktion</th>
                    </tr>
                  </thead>
                  <tbody>
                    {list.map((adviceRequest, index) => (
                      <tr
                        key={index}
                        className={classnames('row-hover', 'row-relative', {
                          bold: !adviceRequest.read,
                          'row-green': addedAdviceRequestIds.includes(adviceRequest.id),
                        })}
                        onClick={onRowClick(adviceRequest.adviceId)}
                      >
                        <td scope="row" className="text-center">
                          {wasRedirected(adviceRequest) && (
                            <div className="icon-redirected" title="Dieser Antrag wurde an Ihre Kanzlei weitergeleitet">
                              <SVGRedirected width="24px" height="24px" />
                            </div>
                          )}
                          {addedAdviceRequestIds.includes(adviceRequest.id) && (
                            <SVGLiveReload width="24px" height="24px" className="live-reload" />
                          )}
                          {adviceRequest.adviceId}
                        </td>
                        {hasPremium && <td>{adviceRequest.product ? PRODUCT_NAMES[adviceRequest.product.id] : 'Basic'}</td>}
                        <td className="text-center">
                          {adviceRequest.person.firstname} {adviceRequest.person.lastname}
                        </td>
                        <td className="text-center">{adviceRequest.fieldOfLaw ? adviceRequest.fieldOfLaw.name : '-'}</td>
                        {adviceRequest.followsUp ? (
                          <PartnerCell partner={{ id: 'klugo', name: 'klugo' }} />
                        ) : (
                          <PartnerCell partner={adviceRequest.partner} />
                        )}
                        <td className="text-center">{adviceRequest.chancellery ? adviceRequest.chancellery.name : '-'}</td>
                        <td className="text-center">{formatDate(new Date(adviceRequest.createdAt))}</td>
                        <td className="text-center">
                          {adviceRequest.assignedTo && adviceRequest.assignedTo.name ? (
                            adviceRequest.assignedTo.name
                          ) : (
                            <span>
                              <FontAwesomeIcon icon={faExclamationTriangle} size="lg" className="inline-icon" /> Nicht zugewiesen
                            </span>
                          )}
                        </td>
                        <td className="text-center action-cell">
                          <AdviceMenuButton
                            adviceRequest={adviceRequest}
                            onAssignTo={onReload}
                            assignableUsers={assignableUsers}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              ) : (
                <h3 className="no-data">Keine neuen Anrufe</h3>
              )}
            </Spinner>
          </Col>
        </Row>
      </PanelTabs>
    </>
  )
}

export const RedirectRequests = withList<LawyerRequest, RedirectRequestsProps>({
  query,
  endpoint: ADVICE_REQUESTS_ENDPOINT,
  responseKey: 'adviceRequests',
  searchPrefix: 'redirects',
  pageSize: 10,
  queryMapper: (listState, props) => ({
    ...listState,
    filters: [
      ...listState.filters,
      ...(props.assignFilter
        ? [
            {
              name: 'assignedTo',
              operator: FilterOperator.Equals,
              value: props.assignFilter,
            },
          ]
        : []),
      getChancelleryFilter(props.user, props.chancelleryLocations),
      {
        name: 'product.type',
        operator: FilterOperator.Equals,
        value: 'CONSUMER',
      },
      {
        name: 'contactType',
        operator: FilterOperator.Equals,
        value: 'REDIRECT',
      },
      {
        name: 'status',
        operator: FilterOperator.Equals,
        value: 'at_chancellery',
      },
    ],
  }),
})(RedirectRequestsView)
