import React, { FunctionComponent, useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { Optional } from 'common/utils'
import { verifyUser } from 'packages-mui/login/actions/verifyUser'
import { User } from 'packages-mui/login/interfaces/schemaDefinition'

import { UserProvider as UserContextProvider } from './UserContext'
import { LoggedInUser } from './interfaces'

export const UserProvider: FunctionComponent = ({ children }) => {
  const history = useHistory()
  const [user, setUser] = useState<LoggedInUser>()
  const [confirmSignOut, setConfirmSignOut] = useState(false)

  const redirectToLogin = useCallback((): boolean => {
    // eslint-disable-next-line fp/no-mutating-methods
    history.push('/login')
    return true
  }, [history])

  const cookieTokenObject = Object.fromEntries(document.cookie.split(';').map(cookie => cookie.split('=')))
  const token = localStorage.getItem('token') ?? cookieTokenObject.token
  // Azure accounts are set via cookie but auth evolves around localStorage
  if (token && !localStorage.getItem('token')) {
    localStorage.setItem('token', cookieTokenObject.token)
  }

  const onConfirmSignOut = useCallback((): void => {
    localStorage.removeItem('token')
    // eslint-disable-next-line fp/no-mutation
    document.cookie = 'token='
    setUser(undefined)
    setConfirmSignOut(false)
    redirectToLogin()
  }, [redirectToLogin])

  useEffect(() => {
    if (!token) {
      redirectToLogin()
      return
    }
    try {
      verifyUser(token).then((storageUser: Optional<User>) => {
        if (!storageUser) {
          onConfirmSignOut()
          return false
        }
        // eslint-disable-next-line fp/no-mutation
        document.cookie = `token=${token};path=/`
        setUser({
          id: storageUser.id,
          name: storageUser.name,
          email: storageUser.email,
          organisation: storageUser.organization || '',
          organisationType: storageUser.organizationType || '',
          channel: storageUser.channel || '',
          roles: storageUser.roles.map(r => r.name),
          accessBitSet: storageUser.accessBitSet,
        })
        return true
      })
    } catch (error) {
      console.error(error)
      redirectToLogin()
    }
  }, [redirectToLogin, token, onConfirmSignOut])

  const contextValue = {
    user,
    confirmSignOut,
    setUser,
    signOut: () => setConfirmSignOut(true),
    // eslint-disable-next-line fp/no-mutating-methods
    userProfile: () => history.push('/user-profile'),
    onConfirmSignOut,
    onCancelSignOut: () => setConfirmSignOut(false),
  }

  return <UserContextProvider value={contextValue}>{children}</UserContextProvider>
}
