import { CHANCELLERIES_ENDPOINT } from 'common/constants'
import { Filter } from 'common/graphql/schemaDefinition'
import { request } from 'common/utils'

import query from '../graphql/getChancelleries.graphql'
import { GetChancelleriesQuery } from '../interfaces/schemaDefinition'

export const getChancelleryList = async (filters: Array<Filter>): Promise<GetChancelleriesQuery['chancelleries']> => {
  const { chancelleries } = await request<GetChancelleriesQuery>(CHANCELLERIES_ENDPOINT, query, {
    filters,
    pageSize: 1000,
  })
  return chancelleries
}
