import { ADVICE_REQUESTS_ENDPOINT } from 'common/constants'
import { request } from 'common/utils'

import query from '../graphql/addShowMatchingSuggestion.graphql'
import { AddShowMatchingSuggestionMutation, AddShowMatchingSuggestionMutationVariables } from '../interfaces/schemaDefinition'

export const shownMatchingSuggestion = async (
  variables: AddShowMatchingSuggestionMutationVariables
): Promise<AddShowMatchingSuggestionMutation> => {
  const response = await request<AddShowMatchingSuggestionMutation>(ADVICE_REQUESTS_ENDPOINT, query, variables)
  return response
}
