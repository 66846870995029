/* eslint-disable complexity */
import { Action, can } from '@grapes-agency/universal'
import { Alert, SelectChangeEvent, Typography } from '@mui/material'
import React, { ChangeEvent, EventHandler, MouseEvent, FormEvent, FC, Fragment } from 'react'
import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'
import { Col, Row } from 'reactstrap'

import { Partner } from 'common/api/getPartners'
import { Panel, Spinner, StaticField } from 'common/components'
import { SelectField, FieldRow, Form } from 'common/components/Form'
import { StaticRow } from 'common/components/StaticRow'
import { EMPTY_OPTION, FIELDS_OF_LAW_ENTRIES_FILTERED, ROLES, ROLE_IDS } from 'common/constants'
import { ADVICE_REQUEST_FORM_FOREIGN } from 'common/constants/routes'
import SVGDevk from 'common/icons/devk_partner.svg'
import { ButtonRounded, ButtonPrimary, ButtonSecondary } from 'common/ui/Button'
import { LoggedInUser } from 'common/user-context'
import {
  Consumer,
  Procedure,
  isAdmin as isAdminUser,
  isTRBManager as isTRBManagerUser,
  isTRB as isTRBUser,
  isLawyer as isLawyerUser,
  isChannel as isChannelUser,
  isCallcenter as isCallcenterUser,
} from 'common/utils'
import { AdviceRequestStatus } from 'packages/chancellery-search/interfaces/AdviceRequest'
import { AdviceListStatusLabel } from 'packages-mui/advice/components/AdviceListRoleStatusLabel'
import { getVisitedRecently } from 'packages-mui/advice/utils'

import { AdviceRequest } from '../../interfaces'
import { AdviceRequestHistoryTable } from '../AdviceRequestHistoryTable'
import { InternalNotes } from '../InternalNotes'
import { SearchForm } from '../SearchForm'
import { SearchView } from '../SearchView'
import { StatusRow } from '../StatusRow'

import './SearchPage.scss'

// A 'final' status means the status can no longer be modified (except administrators)
const isFinalStatus: (status: AdviceRequestStatus) => boolean = status =>
  ['at_chancellery', 'cancelled', 'closed', 'potential', 'mandate', 'complete'].includes(status)

interface ChancellerySearchPageProps {
  adviceRequest: AdviceRequest
  loading?: boolean
  partners: Array<Partner>
  hidePartnerSelection: boolean
  lockFieldOfLaw: boolean
  lockInsured?: boolean
  useBillingCompany?: boolean
  errors: { [key: string]: string }
  onFieldChange: EventHandler<ChangeEvent<HTMLInputElement>>
  onCaseDataChange: (
    vnr: string,
    disputeAmount: string,
    deductible: boolean,
    deductibleAmount: string,
    noProtection: boolean | null,
    protectionAmount: Array<string>
  ) => void
  onPersonChange: (firstname: string, lastname: string, street: string, streetNr: string, city: string, zip: string) => void
  onFindChancelleries: EventHandler<MouseEvent<HTMLButtonElement>>
  onRematch: EventHandler<MouseEvent<HTMLButtonElement>>
  onCheckboxChange: EventHandler<ChangeEvent<HTMLInputElement>>
  onMultiSelectChange: (e: SelectChangeEvent<Array<string>>) => void
  onCancelRequestClick: Procedure
  onResubmissionClick: Procedure
  onSendAGBs: Consumer<AdviceRequest>
  onAcceptAGBsClick: Procedure
  onAcceptDataClick: Procedure
  onSaveInternalNote: Procedure
  savingInternalNote: boolean
  onSaveAndClose: Procedure
  onSaveAdviceRequest: Procedure
  user?: LoggedInUser
  serviceType: string
  onServiceTypeChange: Consumer<FormEvent<HTMLInputElement>>
  onCustomerNotReachedClicked: () => Promise<void>
}

// tslint:disable-next-line
export const ChancellerySearchPage: FC<ChancellerySearchPageProps> = (props: ChancellerySearchPageProps) => {
  const {
    adviceRequest,
    loading,
    partners,
    hidePartnerSelection,
    lockFieldOfLaw,
    lockInsured,
    useBillingCompany,
    errors,
    onFieldChange,
    onPersonChange,
    onCaseDataChange,
    onCheckboxChange,
    onMultiSelectChange,
    onSendAGBs,
    onSaveInternalNote,
    savingInternalNote,
    onAcceptAGBsClick,
    onAcceptDataClick,
    onSaveAndClose,
    onResubmissionClick,
    onCancelRequestClick,
    onFindChancelleries,
    onRematch,
    onSaveAdviceRequest,
    onCustomerNotReachedClicked,
    user,
    serviceType,
    onServiceTypeChange,
  } = props
  const { status } = adviceRequest
  const isTRB = isTRBUser(user)
  const isTRBManager = isTRBManagerUser(user)
  const isAdmin = isAdminUser(user)
  const isCallcenter = isCallcenterUser(user)
  const isLawyer = isLawyerUser(user) && !isTRB
  const isChannel = isChannelUser(user)

  // eslint-disable-next-line no-nested-ternary
  const panelHeader = isTRB ? (
    <>
      <SVGDevk style={{ marginRight: '0.5rem' }} /> Telefonische Rechtsberatung
    </>
  ) : status === 'new' ? (
    'Antrag erfassen'
  ) : (
    `Antrag ${adviceRequest.adviceId}`
  )

  const showStatusRow = status !== 'new' && status !== 'editing'

  const isPaid = !!adviceRequest.invoice
  const { date: visitedAt, user: visitedBy } = adviceRequest?.lastVisited ?? {}
  // see: https://confluence.grapes.de/display/KS/Advice+States
  const canModify = !isFinalStatus(status)

  const canResubmission = canModify && isLawyer && status === 'awaiting_call'

  const canAdviceCancel = adviceRequest.status !== 'cancelled' && (isAdmin || (!isPaid && canModify)) && !isLawyer

  const history = useHistory()

  const showRequestHistory = status !== 'new' && status !== 'editing' && isAdmin
  const canSaveInternalComment = status !== 'new'
  const canFindChancelleries =
    (canModify && !((isTRB || isChannel) && status === 'awaiting_callback')) || ((isAdmin || isCallcenter) && status === 'closed')
  const canClose = canModify && ((isTRB && (status === 'editing' || status === 'resubmission')) || status === 'awaiting_callback')
  const isSomeTRB =
    isTRB || isTRBManager || ['devk-trb', 'dahag-trb'].includes(adviceRequest.partner ? adviceRequest.partner.id ?? '' : '')

  const canForeign = (isChannel || isAdmin) && status === 'new'
  const canSave = canModify && !isLawyer

  const visitedByColleague = visitedAt && visitedBy && getVisitedRecently(visitedAt) && user?.id !== visitedBy
  const canOverrideMatching = user
    ? isFinalStatus(status) && status !== 'cancelled' && can('matching-override')(Action.WRITE)([user.accessBitSet])
    : false
  const role = isTRB ? ROLES[ROLE_IDS.TRB] : isAdmin ? ROLES[ROLE_IDS.Administrator] : ROLES[ROLE_IDS.Callcenter]

  return (
    <Spinner condition={!!loading} center>
      <Panel
        title={panelHeader}
        statusLabel={status === 'editing' || status === 'new' ? <AdviceListStatusLabel status={status} role={role} /> : null}
        className="search-page-panel"
      >
        {visitedByColleague && isCallcenterUser(user) && (
          <Alert severity="warning" sx={{ marginBottom: '1rem', alignItems: 'center', justifyContent: 'center' }}>
            <Typography component="p" variant="h6">
              ACHTUNG! Dieser Antrag wurde vor kurzem geöffnet und ist u.U. gerade in Bearbeitung!{' '}
            </Typography>
          </Alert>
        )}
        <Form>
          <div className="top-right-button-bar">
            {canForeign && (
              <ButtonPrimary onClick={() => history.push(ADVICE_REQUEST_FORM_FOREIGN)} type="button">
                Auslandsformular
              </ButtonPrimary>
            )}
            {canSave && <ButtonRounded onClick={onSaveAdviceRequest}>Entwurf speichern</ButtonRounded>}
            {canAdviceCancel && adviceRequest.adviceId && (
              <ButtonRounded onClick={onCancelRequestClick}>Antrag stornieren</ButtonRounded>
            )}
          </div>
          {isAdmin && adviceRequest.followsUp && (
            <Fragment>
              <StaticRow>
                <Col md={12} style={{ backgroundColor: '#f6f7fe' }}>
                  <StaticField
                    label="Stammantrag"
                    value={
                      <Link to={`/advice-requests/form?adviceId=${adviceRequest.followsUp}`}>{adviceRequest.followsUp}</Link>
                    }
                  />
                </Col>
              </StaticRow>
            </Fragment>
          )}
          {showStatusRow && <StatusRow adviceRequest={adviceRequest} isTRB={isTRB} isAdmin={isAdmin} isChannel={isChannel} />}
          {canFindChancelleries ? (
            <SearchForm
              user={user}
              onCustomerNotReachedClicked={onCustomerNotReachedClicked}
              adviceRequest={adviceRequest}
              partners={partners}
              hidePartnerSelection={hidePartnerSelection || status === 'awaiting_callback'}
              lockFieldOfLaw={lockFieldOfLaw}
              lockInsured={lockInsured}
              useBillingCompany={useBillingCompany}
              errors={errors}
              onFieldChange={onFieldChange}
              onCaseDataChange={onCaseDataChange}
              onPersonChange={onPersonChange}
              onCheckboxChange={onCheckboxChange}
              onMultiSelectChange={onMultiSelectChange}
              onSendAGBs={onSendAGBs}
              onAcceptAGBsClick={onAcceptAGBsClick}
              onAcceptDataClick={onAcceptDataClick}
              onSaveInternalNote={onSaveInternalNote}
              serviceType={serviceType}
              onServiceTypeChange={onServiceTypeChange}
            />
          ) : (
            <>
              <SearchView
                isAdmin={isAdmin}
                isTRB={isSomeTRB}
                isChannel={isChannel}
                adviceRequest={adviceRequest}
                showInsuranceNumber={isSomeTRB || isChannel}
              />
              {isAdmin && (
                <FieldRow>
                  <SelectField
                    id="select-fieldOfLaw"
                    name="fieldOfLaw"
                    value={adviceRequest.fieldOfLaw ? adviceRequest.fieldOfLaw.id : ''}
                    label="Rechtsgebiet"
                    onChange={onFieldChange}
                    options={[
                      EMPTY_OPTION,
                      ...FIELDS_OF_LAW_ENTRIES_FILTERED(user).map(f => ({
                        value: f.id,
                        label: f.name,
                      })),
                    ]}
                    maxWidth="22.5rem"
                    required={true}
                    highlight={true}
                    error={errors.fieldOfLaw}
                  />
                </FieldRow>
              )}
            </>
          )}
          {showRequestHistory && <AdviceRequestHistoryTable adviceRequest={adviceRequest} />}

          {!isChannel && (
            <InternalNotes
              value={adviceRequest.internalNotes}
              onChange={onFieldChange}
              savable={canSaveInternalComment}
              loading={savingInternalNote}
              onSubmit={onSaveInternalNote}
            />
          )}
          <div style={{ paddingLeft: '36px' }}>
            {canFindChancelleries && <p className="required-legend">* Pflichtfelder</p>}

            {(canClose || canFindChancelleries || canOverrideMatching) && (
              <Row>
                {canClose && (
                  <Col md={6}>
                    <ButtonSecondary onClick={onSaveAndClose}>
                      {status === 'awaiting_callback' ? 'Fall schließen' : 'Beratung beenden'}
                    </ButtonSecondary>
                  </Col>
                )}
                {canFindChancelleries && (
                  <Col md={canClose ? 6 : 12} className={canClose ? 'text-right' : 'text-center'}>
                    {canResubmission && (
                      <ButtonSecondary onClick={onResubmissionClick} disabled={serviceType === 'Service Call'}>
                        Wiedervorlage einstellen
                      </ButtonSecondary>
                    )}{' '}
                    <ButtonPrimary type="button" onClick={onFindChancelleries} disabled={serviceType === 'Service Call'}>
                      Weiterleiten an Kanzlei
                    </ButtonPrimary>
                  </Col>
                )}
                {!canFindChancelleries && canOverrideMatching && (
                  <Col md={12} className="text-center">
                    <ButtonPrimary type="button" onClick={onRematch}>
                      Erneut weiterleiten
                    </ButtonPrimary>
                  </Col>
                )}
              </Row>
            )}
          </div>
        </Form>

        {visitedByColleague && isCallcenterUser(user) && (
          <Alert severity="warning" sx={{ marginBottom: '1rem', alignItems: 'center', justifyContent: 'center' }}>
            <Typography component="p" variant="h6">
              ACHTUNG! Dieser Antrag wurde vor kurzem geöffnet und ist u.U. gerade in Bearbeitung!{' '}
            </Typography>
          </Alert>
        )}
      </Panel>
    </Spinner>
  )
}
