/**
 * This component makes sure that a user sees the correct text representation for a given advice status
 * depending on the user's intended role.
 *
 * Note that the role is not necessary the actual user's role, but what role the visible page is made for.
 * I.E. a user that is both TRB and lawyer would see TRB label text on the TRB page, but laywer label text
 * on the lawyer page.
 *
 * @see https://klugo-gmbh.atlassian.net/wiki/spaces/KLUG/pages/618791061/Status+Konzept
 */

import React from 'react'

import { isProduction } from 'app/constants'
import { Role } from 'common/api/types'
import { Badge } from 'common/components-mui'
import { ROLES } from 'common/constants'

import { STATUS_COLORS, STATUS_TEXT } from '../constants'
import { AdviceListStatusProps } from '../constants/AdviceStatus'
import { AdviceStatus, AdviceStatusLabel } from '../interfaces'

const toStatusLabel: (role: Role, status: AdviceStatus) => AdviceStatusLabel | undefined = (role, status) => {
  const label = STATUS_TEXT[role.id][status]

  /* color exceptions for devk user - do not use these exact strings somewhere else */
  const devkColorOverride = label === 'Zugeordnet' || label === 'Rückruf'

  const color = devkColorOverride ? 'success' : STATUS_COLORS[status]
  return color && label ? { color, label } : undefined
}

export const AdviceListStatusLabel: React.FunctionComponent<AdviceListStatusProps> = ({ role, status }) => {
  const isValidRole = role && ROLES.some(r => r.id === role.id)
  if (!role || !isValidRole) {
    if (isProduction) {
      console.warn('Supplied role for AdviceListStatusLabel is not a valid role:', role)
    }
    return null
  }
  const statusLabel = toStatusLabel(role, status)

  if (!statusLabel) {
    if (isProduction) {
      console.warn(`Illegal combination of role (${role.id}) and status (${status})`)
    }
    return null
  }

  return <Badge color={statusLabel.color}>{statusLabel.label}</Badge>
}
