import { ADVICE_REQUESTS_ENDPOINT } from 'common/constants'
import { AddCustomerNotReachedInput } from 'common/graphql/schemaDefinition'
import { request } from 'common/utils'

import query from '../graphql/addCustomerNotReached.graphql'
import { AddCustomerNotReachedMutation } from '../interfaces/schemaDefinition'

export const addCustomerNotReached = async (variables: AddCustomerNotReachedInput): Promise<AddCustomerNotReachedMutation> =>
  request<AddCustomerNotReachedMutation, { customerNotReached: AddCustomerNotReachedInput }>(ADVICE_REQUESTS_ENDPOINT, query, {
    customerNotReached: variables,
  })
