import SearchIcon from '@mui/icons-material/Search'
import { Box, IconButton, TextFieldProps } from '@mui/material'
import React, { FC, useState } from 'react'

import { StyledTextField } from './react-hook-form/TextField'

export const SearchField: FC<TextFieldProps & { handleSubmit: (search: string) => void }> = props => {
  // eslint-disable-next-line fp/no-rest-parameters
  const { handleSubmit, ...rest } = props
  const [searchValue, setSearchValue] = useState('')

  return (
    <Box
      component="form"
      onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        handleSubmit(searchValue)
      }}
    >
      <StyledTextField
        {...rest}
        value={searchValue}
        onChange={event => setSearchValue(event.target.value)}
        label="Suchbegriff"
        size="small"
        name="search"
        InputProps={{
          endAdornment: (
            <IconButton type="submit">
              <SearchIcon />
            </IconButton>
          ),
        }}
      />
    </Box>
  )
}
