import React, { FC, MouseEvent, useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router'

import { MONTHS, URLs } from 'common/constants'
import { UserContext } from 'common/user-context'

import { getCallbacksCount, getChancelleryLocations, getOpenRequestsCount } from '../actions'
import { Dashboard } from '../components/DashboardView'

const getDayAndMonth = (): { day: string; month: string } => {
  const day = new Date().getDate()
  const month = MONTHS[new Date().getMonth()].substring(0, 3)

  return {
    day: `${day}`,
    month: `${month}`,
  }
}
export const DashboardPage: FC = () => {
  const { user } = useContext(UserContext)
  const history = useHistory()
  const { day, month } = getDayAndMonth()
  const userName = user?.name ?? ''

  const [openCount, setOpenCount] = useState('')
  const [callbackCount, setCallbackCount] = useState('')

  useEffect(() => {
    if (!user) return
    getChancelleryLocations()
      .then(async chancelleryLocations => {
        const [fetchedOpenCount, fetchedCallbackCount] = await Promise.all([
          getOpenRequestsCount(user, chancelleryLocations),
          getCallbacksCount(user, chancelleryLocations),
        ])
        setOpenCount(fetchedOpenCount.toString())
        setCallbackCount(fetchedCallbackCount.toString())
      })
      .catch(err => {
        console.error(err)
      })
  })

  const redirectClick = (event: MouseEvent<HTMLButtonElement>): void => {
    switch (event.currentTarget.name) {
      case 'callback':
        history.push({
          pathname: URLs.chancellery.adviceRequests.openRequests,
          state: { callback: true },
        })
        break
      case 'call':
        history.push(URLs.chancellery.adviceRequests.openRequests)
        break
      case 'support':
        history.push(URLs.help.home)
        break
      default:
    }
  }

  return (
    <Dashboard
      userName={userName}
      redirectClick={redirectClick}
      actualDay={day}
      actualMonth={month}
      openCount={openCount}
      callbackCount={callbackCount}
    />
  )
}
