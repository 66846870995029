import { Box, Typography, Button, SxProps, Theme } from '@mui/material'
import React, { EventHandler, MouseEvent } from 'react'

import { SearchContainer } from 'common/components/SearchField'

import SVGCalender from '../images/dashboard-calender.svg'
import SVGOpenRequests from '../images/dashboard-offene-anfragen.svg'
import SVGCallbacks from '../images/dashboard-rueckmeldung.svg'
import SVGSupport from '../images/dashboard-support.svg'

import { DashboardCard } from './DashboardCard'
import { ElementWithOverlay } from './ElementWithOverlay'

type DashboardProps = {
  userName: string
  redirectClick: EventHandler<MouseEvent<HTMLButtonElement>>
  actualDay: string
  actualMonth: string
  openCount: string | number
  callbackCount: string | number
}

type OverlayTextProps = {
  count: string | number
  sx?: SxProps<Theme>
}

const OverlayText: React.FC<OverlayTextProps & React.HTMLAttributes<HTMLDivElement>> = ({ count: inputCount, sx }) => {
  const count = typeof inputCount === 'number' ? inputCount : parseInt(inputCount, 10)
  const size = count < 100 ? '250%' : count < 1000 ? '200%' : '150%'
  return (
    <Typography sx={sx} fontWeight={500} fontSize={size} color="white" variant="h4" component="div">
      {count}
    </Typography>
  )
}

interface OverlayDateProps {
  day: string
  month: string
}

const OverlayDate: React.FC<OverlayDateProps> = ({ day, month }) => (
  <Box
    display="flex"
    justifyContent="center"
    alignItems="center"
    textAlign="center"
    sx={{ color: '#0a1343', fontWeight: '900', fontSize: '18px', marginTop: '60%', lineHeight: '16px' }}
  >
    {day}
    <br />
    {month}
  </Box>
)

export const Dashboard: React.FC<DashboardProps> = ({
  userName,
  redirectClick,
  actualDay,
  actualMonth,
  openCount,
  callbackCount,
}) => (
  <Box display="flex" alignItems="center" flexDirection="column" mt="4rem">
    <Typography variant="h5" component="h2" fontWeight={600}>
      Willkommen, {userName}!
    </Typography>
    <Typography variant="h6" fontWeight={400}>
      Ihre Zusammenfassung für heute:
    </Typography>
    <ElementWithOverlay sx={{ marginBottom: '-40px' }} overlayElement={<OverlayDate day={actualDay} month={actualMonth} />}>
      <SVGCalender width={62} height={62} />
    </ElementWithOverlay>
    <Box display="grid" gap={2} gridTemplateColumns="1fr 1fr" mb={2}>
      <DashboardCard>
        <ElementWithOverlay overlayElement={<OverlayText count={callbackCount} sx={{ marginTop: '1.25rem' }} />}>
          <SVGCallbacks width={90} height={90} />
        </ElementWithOverlay>
        <Typography variant="h4" component="h1" fontWeight={600}>
          Anstehende Rückmeldungen
        </Typography>
        <Box>
          <Button variant="contained" onClick={redirectClick} name="callback">
            Rückmeldungen anzeigen
          </Button>
        </Box>
      </DashboardCard>
      <DashboardCard>
        <ElementWithOverlay overlayElement={<OverlayText count={openCount} />}>
          <SVGOpenRequests width={90} height={90} />
        </ElementWithOverlay>
        <Typography variant="h4" component="h2" fontWeight={600}>
          Offene Anfragen
        </Typography>
        <Box>
          <Button variant="contained" onClick={redirectClick} name="call">
            Anfragen anzeigen
          </Button>
        </Box>
      </DashboardCard>
      <Box mx="auto" sx={{ gridColumn: '1 / 3' }}>
        <DashboardCard maxHeight="auto">
          <Box mb={2}>
            <SVGSupport width={80} height={80} />
          </Box>
          <Box>
            <Button variant="contained" onClick={redirectClick} name="support">
              Support kontaktieren
            </Button>
          </Box>
        </DashboardCard>
      </Box>
    </Box>

    <Typography variant="h6" fontWeight={600} mb={1}>
      Direkte Antragssuche:
    </Typography>
    <SearchContainer />
  </Box>
)
