import { ADVICE_REQUESTS_ENDPOINT } from 'common/constants'
import { request } from 'common/utils'
import {
  AddCallbackAdviceOpenedMutation,
  AddCallbackAdviceOpenedMutationVariables,
} from 'packages/chancellery-search/interfaces/schemaDefinition'

import query from '../graphql/addCallbackAdviceOpened.graphql'

export const callbackAdviceOpened = async (
  variables: AddCallbackAdviceOpenedMutationVariables
): Promise<AddCallbackAdviceOpenedMutation> => {
  const response = await request<AddCallbackAdviceOpenedMutation>(ADVICE_REQUESTS_ENDPOINT, query, variables)
  return response
}
