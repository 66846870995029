/* eslint-disable @typescript-eslint/consistent-type-assertions */
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import GetAppIcon from '@mui/icons-material/GetApp'
import { Button } from '@mui/material'
import {
  GridColDef,
  GridColumns,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarContainer,
} from '@mui/x-data-grid'
import dayjs from 'dayjs'
import { pathOr } from 'ramda'
import React, { useCallback, useState, FunctionComponent } from 'react'

import { FILE_BASE } from 'app/constants'
import {
  CenterBox,
  ConfirmDialog,
  createGridColumnsFrom,
  ExportButton,
  HeaderButtonGroup,
  HeaderSearchField,
  MuiDataGrid,
  PageInfoline,
  PageLayout,
  useGridData,
} from 'common/components-mui'
import { ADVICE_REQUESTS_ENDPOINT, CONTACT_TYPES, PRODUCT_NAMES } from 'common/constants'
import { useRedirect, useRouter } from 'common/hooks'
import { LoggedInUser, useUser } from 'common/user-context'
import { isTRB, isTRBManager, isAdmin, isChannel } from 'common/utils/roles'
import { startTRB } from 'packages/chancellery-search'
import { AdviceRequestStatus } from 'packages/chancellery-search/interfaces/AdviceRequest'

import { createExport } from '../actions'
import { AdviceListStatusLabel } from '../components/AdviceListRoleStatusLabel'
import { ExportModal } from '../components/ExportModal'
import { NEW_URL } from '../constants'
import adviceRequestsQuery from '../graphql/adviceRequests.graphql'
import { AdditionalRowData, AdviceRequestsQuery, ContactType, ExportType, Sort } from '../interfaces'
import '../styles/DataGridRow.scss'
import { getMostImportantRole } from '../utils'
import { computeChannelStatus } from '../utils/computeChannelStatus'
import { createGetRowClassName } from '../utils/createGetRowClassName'

const dataObjectName = 'adviceRequests'

type FromArray<T> = T extends Array<infer U> ? U : never
export type ApiAdviceRequest = FromArray<AdviceRequestsQuery[typeof dataObjectName]['list']>

const pathOrEmptyString = pathOr('')

const widthUnit = (count: number): number => 8 * Math.floor(count)

export const StatusCell = ({ status, user }: { status: AdviceRequestStatus; user: LoggedInUser }): React.ReactElement => (
  <CenterBox>
    <AdviceListStatusLabel status={status} role={getMostImportantRole(user)} />
  </CenterBox>
)
const getColumns = (user: LoggedInUser): GridColumns =>
  createGridColumnsFrom(
    (
      [
        { field: 'adviceId', headerName: 'ID', width: widthUnit(10) },
        !isTRB(user) && {
          hide: isChannel(user),
          width: widthUnit(34),
          field: 'product',
          headerName: 'Produkt',
          valueFormatter: params => {
            const product = params.value as ApiAdviceRequest['product']
            const name = PRODUCT_NAMES?.[product?.id || ''] || product?.name || 'Basic'
            return !product ? 'Basic' : isAdmin(user) ? `${product.id} (${name})` : name
          },
        },
        {
          width: widthUnit(48),
          field: 'chancellery',
          headerName: 'Kanzlei',
          valueFormatter: params => {
            const chancellery = params.value as ApiAdviceRequest['chancellery']
            return pathOrEmptyString(['name'], chancellery)
          },
        },
        {
          width: widthUnit(22),
          hide: isChannel(user),
          field: 'fieldOfLaw',
          headerName: 'Rechtsgebiet',
          valueFormatter: params => {
            const fieldOfLaw = params.value as ApiAdviceRequest['fieldOfLaw']
            return pathOrEmptyString(['name'], fieldOfLaw)
          },
        },
        {
          width: widthUnit(26),
          field: 'name',
          headerName: 'Name',
        },
        {
          hide: true,
          width: widthUnit(26),
          field: 'address',
          headerName: 'Adresse',
        },
        {
          hide: isChannel(user),
          width: widthUnit(26),
          field: 'email',
          headerName: 'E-Mail',
        },
        {
          hide: true,
          width: widthUnit(26),
          field: 'phone',
          headerName: 'Telefon',
        },
        {
          hide: isChannel(user),
          width: widthUnit(12),
          field: 'contactType',
          headerName: 'Typ',
          valueFormatter: params => {
            const contactType = params.value as ApiAdviceRequest['contactType']
            return contactType ? CONTACT_TYPES[contactType] : ContactType.NotSet
          },
        },
        !isTRB(user) && {
          hide: isChannel(user),
          width: widthUnit(42),
          field: 'partner',
          headerName: 'Partner',
          renderCell: params => {
            const partner = params.value as ApiAdviceRequest['partner']
            const id = pathOrEmptyString(['id'], partner)
            return id === 'devk-trb' && params.row.fromTrb
              ? `${pathOrEmptyString(['name'], partner)} (${params.row.fromTrb})`
              : pathOrEmptyString(['name'], partner)
          },
        },
        isTRBManager(user) && {
          width: widthUnit(20),
          field: 'createdBy',
          headerName: 'Erstellt von',
          valueFormatter: params => {
            const createdBy = params.value as ApiAdviceRequest['createdBy']
            return pathOrEmptyString(['name'], createdBy)
          },
        },
        {
          width: widthUnit(20),
          field: 'createdAt',
          headerName: 'Erstellt am',
          valueFormatter: params => {
            const createdAt = params.value as ApiAdviceRequest['createdAt']
            return dayjs(createdAt).format('DD.MM.YYYY HH:mm')
          },
        },
        {
          width: widthUnit(18),
          field: 'status',
          headerName: 'Status',
          renderCell: params => (
            <StatusCell status={isChannel(user) ? computeChannelStatus(params.row) : params.value} user={user} />
          ),
        },
        isChannel(user) && {
          // backend takes care of searching in the correct field
          field: 'damageNumber',
          width: widthUnit(20),
          headerName: 'Schadennummer',
          valueGetter: params => {
            const caseData: Array<{ name: string; value: string | boolean }> = params.row.caseData ?? []
            return caseData.find(({ name }) => name === 'damageNumber')?.value || '-'
          },
        },
        isChannel(user) && {
          field: 'insuranceNumber',
          width: widthUnit(28),
          headerName: 'Versicherungsscheinnummer',
          valueFormatter: ({ value }) => value ?? '-',
        },
        !isChannel(user) && {
          field: 'lastVisited',
          width: widthUnit(24),
          headerName: 'zuletzt geöffnet',
          valueFormatter: params => (params.value?.date ? dayjs(params.value?.date).format('DD.MM.YYYY HH:mm') : ''),
        },
        /* eslint-enable @typescript-eslint/consistent-type-assertions */
      ] as Array<GridColDef | boolean>
    ).filter((entry): entry is GridColDef => Boolean(entry))
  )

const dataToRows = (list?: Array<ApiAdviceRequest>): Array<ApiAdviceRequest & AdditionalRowData> =>
  list?.map(row => ({
    ...row,
    name: [pathOrEmptyString(['person', 'lastname'], row), pathOrEmptyString(['person', 'firstname'], row)]
      .filter(Boolean)
      .join(', '),
    address: `${pathOrEmptyString(['person', 'address', 'street'], row)} ${pathOrEmptyString(
      ['person', 'address', 'streetNr'],
      row
    )} ${pathOrEmptyString(['person', 'address', 'zip'], row)} ${pathOrEmptyString(['person', 'address', 'city'], row)}`,
    email: pathOrEmptyString(['person', 'email'], row),
    phone: pathOrEmptyString(['person', 'phone'], row),
  })) ?? []

const Toolbar: FunctionComponent = () => (
  <GridToolbarContainer>
    <GridToolbarColumnsButton />
    <GridToolbarFilterButton />
  </GridToolbarContainer>
)

// ButtonGroup needs Buttons as direct children. We need to wrap them with fragments though and therefore
// we use a wrapper component that only renders a fragment with its children but propagates its props.
// eslint-disable-next-line fp/no-rest-parameters
const TransparentFragment: FunctionComponent = ({ children, ...props }) => (
  <>{React.Children.map(children, child => React.isValidElement(child) && React.cloneElement(child, props))}</>
)

const FilterMap: { [id: string]: string } = {
  partner: 'partner.name',
  chancellery: 'chancellery.name',
  address: 'sort.addressLine',
}

export const AdviceListPage: FunctionComponent = () => {
  const [exportOpen, setExportOpen] = useState(false)
  const [isTrbModalOpen, setTrbModalOpen] = useState(false)
  const { user } = useUser()
  const { history } = useRouter()
  const redirect = useRedirect()
  const { data, error, actions, tableState } = useGridData<typeof dataObjectName, AdviceRequestsQuery>(
    ADVICE_REQUESTS_ENDPOINT,
    adviceRequestsQuery,
    dataObjectName,
    {
      sort: { sortBy: 'createdAt', sortDirection: 'desc' },
      view: isTRB(user) ? 'trb' : undefined,
      filters: [],
    }
  )

  const hasSufficientRole = typeof getMostImportantRole(user) !== 'undefined'
  const trbOrManager = isTRB(user)

  const toggleExportOpen = useCallback(() => {
    setExportOpen(!exportOpen)
  }, [exportOpen, setExportOpen])

  if (!hasSufficientRole) {
    return null
  }

  const getRowClassName = createGetRowClassName(user)
  const getTrbExportUrl = (): Promise<string> =>
    createExport({
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      sort: tableState.sort as Sort,
      filters: tableState.filters,
      type: ExportType.Controlling,
      view: 'trb',
    }).then(relativeUrl => `${FILE_BASE}/${relativeUrl}`)

  const totalCount = data?.total ?? 0

  return (
    <PageLayout
      heading={trbOrManager ? 'Antragsverwaltung der Telefonischen Rechtsberatung' : 'Antragsverwaltung'}
      spacing="table"
    >
      <HeaderButtonGroup>
        {!trbOrManager && <HeaderSearchField />}
        {trbOrManager ? (
          <TransparentFragment>
            <Button onClick={() => setTrbModalOpen(true)}>
              <AddCircleOutlineIcon titleAccess="Telefonische Rechtsberatung starten" />
            </Button>
            <ExportButton getExportUrl={getTrbExportUrl} />
          </TransparentFragment>
        ) : (
          <TransparentFragment>
            {!isChannel(user) && (
              <Button onClick={toggleExportOpen}>
                <GetAppIcon titleAccess="Herunterladen" />
              </Button>
            )}
            <Button onClick={() => redirect(NEW_URL)}>
              <AddCircleOutlineIcon titleAccess="Hinzufügen" />
            </Button>
          </TransparentFragment>
        )}
      </HeaderButtonGroup>
      <PageInfoline>Insgesamt {totalCount} Einträge</PageInfoline>
      {user && !error && (
        <MuiDataGrid
          noBorder
          getRowClassName={getRowClassName}
          actions={actions}
          tableState={tableState}
          columns={getColumns(user)}
          rows={dataToRows(data?.list)}
          rowCount={totalCount}
          loading={!data}
          hideFooter={!data?.list.length}
          mapFilterNames={field => FilterMap[field]}
          components={{ Toolbar }}
          // eslint-disable-next-line fp/no-mutating-methods
          onRowClick={params => {
            if (isTRBManager(user)) return
            redirect(
              `/advice-requests/form${params.row.partner.id === 'devk-ausland' ? '-foreign' : ''}?adviceId=${params.row.adviceId}`
            )
          }}
        />
      )}
      <ExportModal
        open={exportOpen}
        filters={tableState.filters ?? []}
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        sort={tableState.sort as Sort}
        onCancelClick={toggleExportOpen}
      />
      <ConfirmDialog
        title="Telefonische Rechtsberatung starten?"
        isOpen={isTrbModalOpen}
        onClose={() => setTrbModalOpen(false)}
        content="Bei Bestätigung erzeugen Sie eine Beratungs-ID und können die Beratung starten."
        confirmAction={startTRB(history)}
      />
    </PageLayout>
  )
}
