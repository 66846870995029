import { Breadcrumbs, Link } from '@mui/material'
import React, { FC } from 'react'
import { NavLink } from 'react-router-dom'

type Props = {
  chancelleryId?: string
}

export const ChancelleryBreadCrumbs: FC<Props> = ({ chancelleryId }) =>
  chancelleryId ? (
    <Breadcrumbs>
      <Link component={NavLink} underline="always" to={`/chancelleries/edit/${chancelleryId}`}>
        Zur Kanzlei
      </Link>
    </Breadcrumbs>
  ) : null
