import MenuIcon from '@mui/icons-material/Menu'
import { AppBar, Box, IconButton, Toolbar, useMediaQuery, useTheme } from '@mui/material'
import React, { FunctionComponent } from 'react'

import SVGKlugoLogo from 'common/icons/klugo-logo-white.svg'
import { ConfigurationTestDialogButton } from 'packages-mui/chancelleries'

import { Profile } from './profile/Profile'

interface HeaderPanelProps {
  onMenuToggle: () => void
}

export const HeaderPanel: FunctionComponent<HeaderPanelProps> = ({ onMenuToggle }) => {
  const theme = useTheme()
  const isXL = useMediaQuery(theme.breakpoints.up('maxContentWidth'))
  const stageName = window.location.hostname.includes('preview')
    ? window.location.hostname.replace(/.*?([a-z]+)\.spa.*?$/, '$1')
    : window.location.hostname === 'qa.spa.klugo.de'
    ? 'QA'
    : window.location.hostname === 'localhost'
    ? 'DEV'
    : undefined

  return (
    <AppBar
      sx={[
        { position: 'fixed', zIndex: theme.zIndex.drawer + 1, bgcolor: theme.palette.primary.main },
        stageName !== undefined && { bgcolor: 'black' },
      ]}
    >
      <Toolbar
        sx={{
          '&.MuiToolbar-gutters': {
            pl: 2,
          },
        }}
      >
        <IconButton
          edge="start"
          color="inherit"
          onClick={e => {
            e.stopPropagation()
            onMenuToggle()
          }}
          size="large"
          style={{ display: isXL ? 'none' : 'inline-flex', outline: 'none' }}
        >
          <MenuIcon titleAccess="Seitenmenü aufklappen" />
        </IconButton>
        <ConfigurationTestDialogButton color="inherit" edge={isXL ? 'start' : false} />
        <SVGKlugoLogo
          width={93}
          height={30}
          style={{ fill: '#fff', position: 'absolute', left: '50%', transform: 'translateX(-50%)' }}
        />
        {stageName && (
          <Box sx={{ fontWeight: 'bold', color: 'lime', position: 'absolute', left: '50%', transform: 'translateX(3.5rem)' }}>
            {stageName}
          </Box>
        )}
        <Profile />
      </Toolbar>
    </AppBar>
  )
}
