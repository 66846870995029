import React, { FC } from 'react'
import { Label, Modal, ModalBody, ModalFooter } from 'reactstrap'

import { Spinner } from 'common/components'
import Transfer from 'common/icons/Transfer.svg'
import { ButtonCancel, ButtonConfirm, ButtonPrimary } from 'common/ui/Button'
import { Procedure } from 'common/utils'

import Folder from '../images/icon_akten.svg'
import Sending from '../images/sending.svg'

import './EConsultModal.scss'

interface EConsultModalProps {
  open: boolean
  success: boolean
  error?: string | null
  transferloading: boolean
  onCancelClick: Procedure
  onCompleteClick: Procedure
  onTransferClick: Procedure
}

export const EConsultModal: FC<EConsultModalProps> = ({
  success,
  error,
  open,
  transferloading,
  onCancelClick,
  onCompleteClick,
  onTransferClick,
}) => {
  if (success) {
    return (
      <Modal toggle={onCompleteClick} isOpen={open} className="success-modal">
        <ModalBody>
          <Folder height="160" width="200" />
          <Label> Diese Akte wurde erfolgreich übertragen.</Label>
        </ModalBody>
        <ModalFooter>
          <ButtonPrimary onClick={onCompleteClick} className="m-b-10">
            Zurück zu Ihren Akten
          </ButtonPrimary>
        </ModalFooter>
      </Modal>
    )
  }

  if (transferloading) {
    return (
      <Modal isOpen={open} className="success-modal">
        <ModalBody>
          <Spinner center />
          <Sending className="m-b-20 m-t-20" />
          <Label className="m-b-20 m-t-20">Wir bitten um einen Moment Geduld.</Label>
          <Label className="m-b-20"> Ihre Akte wird an die hinterlegte Kanzleisoftware übermittelt.</Label>
        </ModalBody>
      </Modal>
    )
  }

  if (error) {
    return (
      <Modal toggle={onCancelClick} isOpen={open} id="EConsultModal">
        <ModalBody>
          <Label className="label-error">{error}</Label>
        </ModalBody>
        <ModalFooter>
          <ButtonCancel onClick={onCancelClick}>Zurück</ButtonCancel>
        </ModalFooter>
      </Modal>
    )
  }

  return (
    <Modal toggle={onCancelClick} isOpen={open} id="EConsultModal">
      <ModalBody>
        <Label className="label-medium">Möchten Sie die Akte an ihre hinterlegte Kanzlei-Software übertragen?</Label>
      </ModalBody>
      <ModalFooter>
        <ButtonCancel onClick={onCancelClick}>Zurück</ButtonCancel>
        <ButtonConfirm onClick={onTransferClick} iconLeft={<Transfer width="20" height="20" />}>
          Übertragen
        </ButtonConfirm>
      </ModalFooter>
    </Modal>
  )
}
