import { zodResolver } from '@hookform/resolvers/zod'
import { Box, Typography } from '@mui/material'
import { enqueueSnackbar } from 'notistack'
import { pipe } from 'ramda'
import React, { FunctionComponent, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router'

import { PageLayout, FormActionBox, AbortButton, SaveButton, DeleteWithConfirmation, Grid } from 'common/components-mui'
import { Checkbox, TextField, Select } from 'common/components-mui/react-hook-form'
import { legalProtectionInsurer, VALIDATION_PATTERN } from 'common/constants'
import { useRedirect } from 'common/hooks'
import { useSequence } from 'common/hooks/Sequence'
import { addOptionalPropIf } from 'common/utils'

import { createPartner, deletePartner, getPartner, updatePartner } from '../actions'
import { partnerInitialValues, partnerSchema } from '../constants'
import { PartnerInput, PartnerFormValues, GetPartnerQuery, PartnerFormPageParams } from '../interfaces'

const fromApiInputFormat = (partner: GetPartnerQuery['partner']): PartnerFormValues => ({
  ...partner,
  phone: partner.phone ?? '',
  insurer: partner.insurer ?? '',

  customerIdValidation: {
    pattern: partner.partnerValidation?.regex ?? '',
    description: partner.partnerValidation?.intro ?? '',
  },

  // missing in QuerInput!
  discount: false,
  platform: {
    business: false,
    consumer: false,
  },
  hasCustomerValidation: !!(partner.partnerValidation?.regex ?? ''),
})

const toApiInputFormat = (values: PartnerFormValues): PartnerInput => {
  const { id, active, name, locked, phone, insurer, agbsPreAccepted, customerIdValidation } = values
  const baseInput = {
    id,
    active,
    name,
    locked,
    phone,
    agbsPreAccepted,
    partnerValidation: {
      enabled: Boolean(customerIdValidation.pattern),
      regex: customerIdValidation.pattern,
      intro: customerIdValidation.description,
    },
  }

  const withInsurer = addOptionalPropIf<{ insurer: string }, PartnerInput>(insurer.length > 0)({ insurer })
  const withId = addOptionalPropIf<{ id: string }, PartnerInput>(id.length > 0)({ id })

  const withOptionalValues = pipe(withInsurer, withId)
  return withOptionalValues(baseInput)
}

export const PartnerFormPage: FunctionComponent = () => {
  const { id } = useParams<PartnerFormPageParams>()
  const redirect = useRedirect()
  const handleRedirect = (): void => redirect('/partners')

  const { control, reset, watch, handleSubmit, getValues } = useForm<PartnerFormValues>({
    defaultValues: partnerInitialValues,
    resolver: zodResolver(partnerSchema),
  })
  const [isLoading, setIsLoading] = useState(true)
  const weHaveACheater = useSequence(VALIDATION_PATTERN)

  const isNewProduct = window.location.pathname === '/partners/new'
  const locked = getValues('locked')

  const [watchedCustomerIdValidation] = watch(['customerIdValidation'])
  const showValidationSchema = (watchedCustomerIdValidation.description && watchedCustomerIdValidation.pattern) || weHaveACheater

  const stopLoading = (): void => setIsLoading(false)

  const handleDeletePartner = (): Promise<void> => deletePartner(getValues('id')).then(handleRedirect).catch()

  const setCreatePartnerError = (): void => {
    enqueueSnackbar(`Partner-ID "${id}" existiert bereits`, { variant: 'error' })
  }
  const setCreatePartnerSuccess = (): void => {
    const partnerId = getValues('id')
    enqueueSnackbar(`Partner-ID "${partnerId}" wurde angelegt`, { variant: 'success' })
  }
  const setUpdatePartnerError = (): void => {
    enqueueSnackbar('Partner konnte nicht aktualisiert werden', { variant: 'error' })
  }
  const setUpdatePartnerSuccess = (): void => {
    enqueueSnackbar(`Partner-ID "${id}" wurde aktualisiert`, { variant: 'success' })
  }

  useEffect(() => {
    if (!id) {
      setIsLoading(false)
      return
    }
    const setGetPartnerError = (): void => {
      enqueueSnackbar(`Partner mit der ID "${id}" existiert nicht`, { variant: 'error' })
    }

    setIsLoading(true)

    getPartner(id)
      .then(({ partner }) => {
        reset(fromApiInputFormat(partner))
      })
      .catch(setGetPartnerError)
      .then(() => setIsLoading(false))
  }, [id, reset])

  useEffect(() => {
    if (!weHaveACheater) return
    enqueueSnackbar('Du kannst jetzt das Validierungsmuster bearbeiten', { variant: 'success' })
  }, [weHaveACheater])

  const onError = (): void => {
    enqueueSnackbar('Bitte prüfen Sie die Eingaben des Formulars.', { variant: 'error' })
  }

  const onSubmit = (data: PartnerFormValues): Promise<void> => {
    setIsLoading(true)
    const partner = toApiInputFormat(data)

    if (isNewProduct) {
      return createPartner(partner).then(setCreatePartnerSuccess).catch(setCreatePartnerError).finally(stopLoading)
    } else {
      return updatePartner(partner).then(setUpdatePartnerSuccess).catch(setUpdatePartnerError).finally(stopLoading)
    }
  }

  const onSubmitAndRedirect = (data: PartnerFormValues): Promise<void> => onSubmit(data).then(handleRedirect)

  return (
    <PageLayout heading={id ? 'Partner bearbeiten' : 'Partner anlegen'}>
      <Box
        component="form"
        onSubmit={handleSubmit(
          values => onSubmit(values).then(() => (isNewProduct ? redirect(`/partners/edit/${getValues('id')}`) : null)),
          onError
        )}
      >
        <Grid container columns={{ xs: 1, md: 2, lg: 5 }} spacing={{ xs: 8, lg: 0 }} justifyContent="space-between">
          {/* Should use <Stack useFlexGap /> but cant as prop is not accepted */}
          <Grid xs={1} lg={2} display="flex" flexDirection="column" gap={2}>
            <Checkbox control={control} name="active" label="Aktiv" disabled={isLoading} />
            <TextField control={control} name="name" label="Name Partner" disabled={isLoading} />
            <TextField control={control} name="id" label="Partner-ID" disabled={isLoading || !!id} />
            {weHaveACheater ? (
              <TextField
                control={control}
                name="customerIdValidation.pattern"
                label="Validierungsmuster Kundennummer"
                disabled={isLoading || !weHaveACheater}
              />
            ) : (
              <Checkbox
                control={control}
                name="hasCustomerValidation"
                label="Kundennummervalidierung eingerichtet"
                disabled={true}
              />
            )}
            {showValidationSchema && (
              <TextField
                multiline
                control={control}
                name="customerIdValidation.description"
                label="Beschreibung Validierungsmuster"
                disabled={isLoading || !weHaveACheater}
              />
            )}
          </Grid>
          {/* Should use <Stack useFlexGap /> but cant as prop is not accepted */}
          <Grid xs={1} lg={2} display="flex" flexDirection="column" gap={2}>
            <Checkbox control={control} name="locked" label="System-Partner" disabled={isLoading || !weHaveACheater} />
            <Select control={control} name="insurer" label="Versicherer" disabled={isLoading}>
              {legalProtectionInsurer.map(insurer => (
                <option key={insurer}>{insurer}</option>
              ))}
            </Select>
            <TextField control={control} name="phone" label="Telefonnummer Beratung" disabled={isLoading} />
            <Checkbox control={control} name="agbsPreAccepted" label="AGBs akzeptiert voreingestellt" disabled={isLoading} />
          </Grid>
        </Grid>
        <FormActionBox>
          {!isNewProduct && (
            <DeleteWithConfirmation
              actionButtonText="Partner löschen"
              disabled={locked && !weHaveACheater}
              onConfirm={handleDeletePartner}
            >
              <Typography>Diese Aktion ist nicht umkehrbar. Partner wird permanent gelöscht.</Typography>
            </DeleteWithConfirmation>
          )}
          <AbortButton onClick={handleRedirect} disabled={isLoading} />
          <SaveButton submit disabled={isLoading} />
          <SaveButton submit={false} onClick={handleSubmit(onSubmitAndRedirect, onError)} disabled={isLoading}>
            Speichern und Schließen
          </SaveButton>
        </FormActionBox>
      </Box>
    </PageLayout>
  )
}
