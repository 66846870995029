import { useEffect, useState } from 'react'

import { FIELDS_OF_LAW_ENTRIES, PARTNERS_ENDPOINT, PRODUCTS_ENDPOINT } from 'common/constants'
import { request } from 'common/utils'
import { ContactType } from 'packages/lawyers/interfaces'
import { GetPartnersQuery } from 'packages-mui/partners/interfaces'

import getPartnersQuery from '../graphql/getPartners.graphql'
import getProductQuery from '../graphql/getProduct.graphql'
import { Contact, PaymentMethod, ProductFormValues, FeatureEnum } from '../interfaces'
import { GetProductQuery, PaymentMethodType, FeatureType } from '../interfaces/schemaDefinition'
import { roundDecimal } from '../utils'

const convertContactType = (contactType: ContactType): Contact => {
  if (contactType.includes(ContactType.Callback)) {
    return Contact.Callback
  } else if (contactType.includes(ContactType.Email)) {
    return Contact.Email
  } else {
    return Contact.Redirect
  }
}

const convertFeatureType = (featureType: FeatureType): FeatureEnum => {
  if (featureType.includes(FeatureType.Normal)) {
    return FeatureEnum.Normal
  } else if (featureType.includes(FeatureType.Special)) {
    return FeatureEnum.Special
  } else if (featureType.includes(FeatureType.Optional)) {
    return FeatureEnum.Optional
  } else {
    return FeatureEnum.Excluded
  }
}

const convertPaymentMethodType = (method: PaymentMethodType): PaymentMethod => {
  if (method.includes(PaymentMethodType.Paypal)) {
    return PaymentMethod.Paypal
  } else if (method.includes(PaymentMethodType.Creditcard)) {
    return PaymentMethod.Creditcard
  } else {
    return PaymentMethod.Klarna
  }
}

export const mapProductAPIDataToForm = (
  data: GetProductQuery['product'],
  partners: GetPartnersQuery['partners']['list']
): ProductFormValues => ({
  id: data.id,
  active: data.active,
  locked: data.locked,
  name: data.name,
  title: data.title,
  subtitle: data.subtitle,
  netPrice: data.netPrice.toString().replace('.', ','),
  grossPrice: data.grossPrice.toString().replace('.', ','),
  rrp: data.rrp ? data.rrp.toString().replace('.', ',') : '',
  contactTypes: data.contactTypes.map(contactType => convertContactType(contactType)),
  fieldsOfLaw: FIELDS_OF_LAW_ENTRIES.filter(fieldOfLaw => data.fieldsOfLaw.find(item => item.id === fieldOfLaw.id)),
  partners: partners.filter(partner => data.partners.find(item => item.id === partner.id)),
  features: data.features.map(feature => ({ ...feature, type: convertFeatureType(feature.type) })),
  services: (data.services ?? ['']).map(service => ({ service })),
  partnerDiscounts: data.partnerDiscounts.map(partnerDiscount => ({
    ...partnerDiscount,
    partners: partners.filter(partner => partnerDiscount.partners.find(item => item.id === partner.id)),
    discount: roundDecimal(partnerDiscount.discount * 100, 2)
      .toString()
      .replace('.', ','),
    disNetPrice: partnerDiscount.disNetPrice.toString().replace('.', ','),
    disGrossPrice: partnerDiscount.disGrossPrice.toString().replace('.', ','),
  })),
  payment: {
    ...data.payment,
    methods: data.payment.methods.map(method => convertPaymentMethodType(method)),
  },
  matching: data.matching,
  marketing: data.marketing,
})

export const useProductPartnersData = (
  id: string
): {
  partnersList: GetPartnersQuery['partners']['list']
  initialValues: ProductFormValues | null
  isLoading: boolean
} => {
  const [initialValues, setInitialValues] = useState<ProductFormValues | null>(null)
  const [partnersList, setPartnersList] = useState<GetPartnersQuery['partners']['list']>([])
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const partnersRequest = request<GetPartnersQuery>(PARTNERS_ENDPOINT, getPartnersQuery, {
      sort: { sortBy: 'name', sortDirection: 'asc' },
      page: 1,
      pageSize: 200,
    })
    const productRequest = request<GetProductQuery>(PRODUCTS_ENDPOINT, getProductQuery, { id })

    Promise.allSettled([partnersRequest, productRequest]).then(([partnersResult, productResult]) => {
      const fetchedPartnersList = partnersResult.status === 'fulfilled' ? partnersResult.value.partners.list : []
      const fetchedProduct = productResult.status === 'fulfilled' ? productResult.value.product : undefined

      setPartnersList(fetchedPartnersList)

      if (fetchedProduct) {
        const values = mapProductAPIDataToForm(fetchedProduct, fetchedPartnersList)
        setInitialValues(values)
      }
      setIsLoading(false)
    })
  }, [id])

  return {
    partnersList,
    initialValues,
    isLoading,
  }
}
