import { Theme } from '@mui/material'
import { TypographyOptions } from '@mui/material/styles/createTypography'

export const createTypography: (theme: Theme) => TypographyOptions = theme => ({
  fontFamily: 'Poppins, Arial',
  loginTitle: {
    ...theme.typography.h6,
    fontFamily: 'Poppins, Arial',
    letterSpacing: 0.7,
    fontWeight: 400,
    color: theme.palette.grey[600],
    marginTop: theme.spacing(2),
  },
  staticLabel: {
    ...theme.typography.body2,
    display: 'block',
    lineHeight: 1.43,
    fontFamily: 'Poppins, Arial',
    color: '#9c9c9c',
    fontWeight: 500,
    fontSize: 12,
  },
  staticValue: {
    ...theme.typography.body2,
    paddingBlockEnd: 4,
    display: 'block',
    lineHeight: 1.43,
    fontFamily: 'Poppins, Arial',
    fontWeight: 500,
  },
})
