import { Box, Alert } from '@mui/material'
import { GridCellParams, GridColumns } from '@mui/x-data-grid'
import dayjs from 'dayjs'
import React, { FC, memo } from 'react'
import { useHistory } from 'react-router-dom'

import {
  AddButton,
  useGridData,
  MuiDataGrid,
  FilterOperator,
  createGridColumnsFrom,
  CenterBox,
  Badge,
} from 'common/components-mui'
import { CHANCELLERIES_ENDPOINT } from 'common/constants/endpoints'

import getChancelleryLocationsQuery from '../graphql/getChancelleryLocations.graphql'
import { ChancelleryLocationsTableProps } from '../interfaces'
import { Address, GetChancelleryLocationsQuery, FieldOfLaw, Holiday } from '../interfaces/schemaDefinition'

import { InputLegend } from './InputLegend'

const entryHasFol = (entry: Holiday): boolean => Array.isArray(entry.fieldsOfLaw) && entry.fieldsOfLaw.length > 0

const getCurrentLocationHolidayEntry = (holidays: Array<Holiday>): Holiday | undefined => {
  const now = dayjs()
  return holidays.find(
    entry => dayjs(entry.start).isBefore(now) && dayjs(entry.end).isAfter(now) && (entry.global || !entryHasFol(entry))
  )
}

const getCurrentFieldOfLawHolidayEntry = (holidays: Array<Holiday>): Holiday | undefined => {
  const now = dayjs()
  const currentHolidays = holidays.filter(entry => dayjs(entry.start).isBefore(now) && dayjs(entry.end).isAfter(now))
  return currentHolidays.find(holiday => entryHasFol(holiday) && !holiday.global)
}

export const isOnHoliday = (holidays: Array<Holiday>): boolean => Boolean(getCurrentLocationHolidayEntry(holidays))

const getStatus: FC<GridCellParams> = (params: GridCellParams) => {
  /* eslint-disable @typescript-eslint/consistent-type-assertions */
  const active = (params.row.active as boolean) && (params.row.hasActiveConfigurations as boolean)
  const deleted = params.row.deleted as boolean
  const holidays = params.row.holidays as Array<Holiday>

  const fieldsOfLawHoliday = getCurrentFieldOfLawHolidayEntry(holidays)
  return !active || deleted ? (
    <CenterBox>
      <Badge color="default">Inaktiv</Badge>
    </CenterBox>
  ) : isOnHoliday(holidays) || isOnHoliday(params.row.globalHolidays) ? (
    <CenterBox>
      <Badge color="warning" title={`Urlaub bis ${dayjs(getCurrentLocationHolidayEntry(holidays)?.end).format('DD.MM.YY')}`}>
        Urlaub
      </Badge>
    </CenterBox>
  ) : fieldsOfLawHoliday ? (
    <CenterBox>
      <Badge
        color="warning"
        title={`Urlaub für Rechtsgebiet(e) ${
          fieldsOfLawHoliday?.fieldsOfLaw?.map(field => field.name).join(', ') ?? ''
        } bis ${dayjs(fieldsOfLawHoliday?.end).format('DD.MM.YY')}`}
      >
        Urlaub
      </Badge>
    </CenterBox>
  ) : (
    <CenterBox>
      <Badge color="success">Aktiv</Badge>
    </CenterBox>
  )
  /* eslint-enable @typescript-eslint/consistent-type-assertions */
}

const dataObjectName = 'chancelleryLocations'

const columns: GridColumns = createGridColumnsFrom([
  /* eslint-disable @typescript-eslint/consistent-type-assertions */
  { field: 'address', headerName: 'Stadt', valueFormatter: params => (params.value as Address).city, flex: 0.33 },
  {
    field: 'fallbackFor',
    headerName: 'Fallback(s)',
    valueFormatter: params => (params.value as Array<FieldOfLaw>).map(f => f.name).join(', '),
    flex: 0.33,
  },
  { field: 'status', headerName: 'Status', renderCell: getStatus, flex: 0.33 },
  { field: 'hasActiveConfigurations', headerName: 'Status' },
  /* eslint-enable @typescript-eslint/consistent-type-assertions */
])

const ChancelleryLocationsTable: FC<ChancelleryLocationsTableProps> = ({ chancelleryId, disabled }) => {
  const history = useHistory()
  const { data, error, actions, tableState } = useGridData<typeof dataObjectName, GetChancelleryLocationsQuery>(
    CHANCELLERIES_ENDPOINT,
    getChancelleryLocationsQuery,
    dataObjectName,
    {
      sort: { sortBy: 'address.city', sortDirection: 'asc' },
      filters: [
        {
          name: 'chancelleryId',
          value: chancelleryId,
          operator: FilterOperator.Equals,
        },
        {
          name: 'deleted',
          value: false,
          operator: FilterOperator.Equals,
        },
      ],
    }
  )

  const listHasElements = (data?.list?.length ?? 0) > 0
  const globalHolidays = (data?.list ?? []).flatMap(item => item.holidays).filter(({ global }) => global)
  const dataWithGlobalHolidays = (data?.list ?? []).map(item => ({ ...item, globalHolidays }))

  return (
    <Box mb={3} component="fieldset">
      <InputLegend>Standorte</InputLegend>
      <AddButton
        disabled={disabled}
        aria-label="Standort hinzufügen"
        onClick={
          () => history.push(`/chancelleries/locations/${chancelleryId}/new`) /* eslint-disable-line fp/no-mutating-methods */
        }
      />

      {error ? (
        <Alert severity="error">Ein Fehler ist beim Laden der Standorte aufgetreten.</Alert>
      ) : (
        <MuiDataGrid
          actions={actions}
          columnVisibilityModel={{
            hasActiveConfigurations: false,
          }}
          tableState={tableState}
          columns={listHasElements ? columns : []}
          rows={dataWithGlobalHolidays}
          rowCount={data?.total ?? 0}
          loading={!data}
          components={{}}
          hideFooter={!listHasElements}
          // eslint-disable-next-line fp/no-mutating-methods
          onRowClick={r => (r.row.id ? history.push(`/chancelleries/locations/edit/${r.row.id}`) : undefined)}
        />
      )}
    </Box>
  )
}

const MemoedChancelleryLocationsTable = memo(ChancelleryLocationsTable)

export { MemoedChancelleryLocationsTable as ChancelleryLocationsTable }
