import { FormControl, FormHelperText, InputLabel, InputLabelProps, Select as MuiSelect, SelectProps } from '@mui/material'
import React from 'react'
import { Controller, ControllerFieldState } from 'react-hook-form'

import { ControlledFormField } from 'common/interfaces'

const hasError = (fieldState: ControllerFieldState): boolean => fieldState.invalid && !!fieldState.error

export const Select: ControlledFormField<SelectProps & InputLabelProps> = props => {
  const { control, name, children, label, shrink = true, fullWidth = true, variant = 'outlined', ...rest } = props

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => (
        <FormControl fullWidth={fullWidth} variant={variant}>
          <InputLabel htmlFor={name} shrink={shrink || (field.value && Boolean(field.value.length))}>
            {label}
          </InputLabel>
          <MuiSelect native {...rest} {...field} id={name} name={name} label={label} notched error={hasError(fieldState)}>
            {children}
          </MuiSelect>
          {hasError(fieldState) && <FormHelperText error>{fieldState.error?.message}</FormHelperText>}
        </FormControl>
      )}
    />
  )
}
