import { ADVICE_REQUESTS_ENDPOINT } from 'common/constants'
import { request } from 'common/utils'
import {
  SaveAdviceRequestInput,
  SaveAdviceRequestMutation,
  SaveAdviceRequestMutationVariables,
} from 'packages/lawyers/interfaces/schemaDefinition'
import { mapCaseData } from 'packages-mui/advice/utils/caseData'

import query from '../graphql/saveAdviceRequest.graphql'

export const saveAdviceRequest = async (adviceRequest: SaveAdviceRequestInput): Promise<SaveAdviceRequestMutation> => {
  const variables: SaveAdviceRequestMutationVariables = {
    adviceRequest: {
      id: adviceRequest.id || '',
      fieldOfLawId: adviceRequest.fieldOfLawId,
      language: adviceRequest.language,
      person: adviceRequest.person,
      company: adviceRequest.company
        ? { ...adviceRequest.company, name: adviceRequest.company?.name ? adviceRequest.company?.name : '' }
        : null,
      billingCompany: adviceRequest.billingCompany
        ? {
            ...adviceRequest.billingCompany,
            name: adviceRequest.billingCompany?.name ? adviceRequest.billingCompany?.name : '',
          }
        : null,
      description: adviceRequest.description,
      internalNotes: adviceRequest.internalNotes,
      externalNotes: adviceRequest.externalNotes,
      lawyerNotes: adviceRequest.lawyerNotes,
      resultNotes: adviceRequest.resultNotes,
      insuranceNumber: adviceRequest.insuranceNumber,
      legalProtectionInsurer: adviceRequest.legalProtectionInsurer,
      caseData: mapCaseData(adviceRequest),
      mandateName: adviceRequest.mandateName,
    },
  }

  const response = await request<SaveAdviceRequestMutation, SaveAdviceRequestMutationVariables>(
    ADVICE_REQUESTS_ENDPOINT,
    query,
    variables
  )
  return response
}
