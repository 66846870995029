import { resolveCaseData } from 'packages-mui/advice/utils/caseData'

import { AdviceRequest } from '../interfaces'
import { AdviceRequestStatus } from '../interfaces/AdviceRequest'
import { AdviceRequestQuery, ProductType } from '../interfaces/schemaDefinition'

const orString = (a: string | null | undefined): string => a ?? ''
// eslint-disable-next-line complexity
export const mapResponseToAdviceRequest: (
  response: AdviceRequestQuery['adviceRequest']
) => Omit<AdviceRequest, 'instance' | 'resultNotes' | 'lawyerNotes'> = response => ({
  id: response.id,
  followsUp: orString(response.followsUp),
  createdAt: new Date(response.createdAt),
  fromTrb: orString(response.fromTrb),
  foa: orString(response.person.foa),
  firstname: orString(response.person.firstname),
  lastname: orString(response.person.lastname),
  phone: response.person.phone,
  email: orString(response.person.email),
  zip: orString(response.person.address.zip),
  street: orString(response.person.address.street),
  streetNr: orString(response.person.address.streetNr),
  city: orString(response.person.address.city),
  description: orString(response.description),
  externalNotes: orString(response.externalNotes),
  internalNotes: orString(response.internalNotes),
  adviceId: response.adviceId,
  fieldOfLaw: response.fieldOfLaw ?? undefined,
  contactType: response.contactType ?? undefined,
  chancellery: response.chancellery,
  history: response.history ?? [],
  hasUploads: response.hasUploads ?? false,
  revenue: response.revenue,
  status: response.status as AdviceRequestStatus,
  paymentMethod: response.paymentMethod,
  agbsAccepted: !!response.agbsAccepted,
  dataAccepted: !!response.dataAccepted,
  insured: !!response.insured,
  newsletter: !!response.newsletter,
  partner: response.partner ?? undefined,
  product: response.product || {
    id: '',
    price: 0,
    name: '',
    type: ProductType.Consumer,
  },

  chancelleryNotReached: response.chancelleryNotReached ?? [],

  companyName: orString(response.company?.name),
  companyCompanyType: orString(response.company?.companyType),
  companyAddressStreet: orString(response.company?.address?.street),
  companyAddressStreetNr: orString(response.company?.address?.streetNr),
  companyAddressZip: orString(response.company?.address?.zip),
  companyAddressCity: orString(response.company?.address?.city),
  companyTaxId: response.company?.taxId ?? '',

  billingCompanyName: orString(response.billingCompany?.name),
  billingCompanyCompanyType: orString(response.billingCompany?.companyType),
  billingCompanyAddressStreet: orString(response.billingCompany?.address?.street),
  billingCompanyAddressStreetNr: orString(response.billingCompany?.address?.streetNr),
  billingCompanyAddressZip: orString(response.billingCompany?.address?.zip),
  billingCompanyAddressCity: orString(response.billingCompany?.address?.city),
  billingCompanyTaxId: response.billingCompany?.taxId,

  customerNotReached: response.customerNotReached ?? [],
  lastVisited: response.lastVisited ?? undefined,

  preferredCallbackTime: response.preferredCallbackTime,
  insuranceNumber: orString(response.insuranceNumber),
  invoice: response.invoice ?? undefined,
  ...resolveCaseData(response.caseData),
})
