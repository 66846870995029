import classnames from 'classnames'
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Spinner, Form } from 'reactstrap'

import { SearchField } from 'common/components/SearchField'
import SVGExit from 'common/icons/exit.svg'
import { ButtonPrimary } from 'common/ui'
import { UserContextProps, withUser } from 'common/user-context'
import { fetchDataWith } from 'common/utils/fetchDataWith'
import { searchAdviceRequest } from 'packages/search/actions/searchAdviceRequest'
import { groupAdviceRequests } from 'packages-mui/lawyers/actions/groupAdviceRequests'

import { getChancelleryLocations } from '../actions'
import { AssignFilter } from '../components/AssignFilter'
import { Sales } from '../components/SalesView'
import SVGExport from '../components/images/icon_export.svg'
import { ChancelleryLocationsQuery } from '../interfaces/schemaDefinition'

import './SalesContainer.scss'

type LawyerProps = UserContextProps

const useSearch = fetchDataWith(searchAdviceRequest)

const SalesContainer: FC<LawyerProps> = ({ user }) => {
  const history = useHistory()
  const [chancelleryLocations, setChancelleryLocations] = useState<ChancelleryLocationsQuery['chancelleryLocations']['list']>()
  const [premium] = useState<boolean>(false)
  const [assignFilter, setAssignFilter] = useState('')
  const [searchValue, setSearchValue] = useState<string>('')
  const [searchTerm, setSearchTerm] = useState<string>('')
  const { data, isLoading } = useSearch({ search: searchTerm })
  const { list = [] } = data || { list: [] }
  const groups = groupAdviceRequests(list)
  const [openExport, setOpenExport] = useState<boolean>(false)
  const [exportSales, setExportSales] = useState<boolean>(false)
  const [selectAll, setSelectAll] = useState<boolean>(false)
  const [selectionLength, setSelectionLength] = useState<number>(0)

  const noSearchResults = useMemo(
    () => !!searchTerm && [...groups.complete, ...groups.mandate].length === 0,
    [groups.complete, groups.mandate, searchTerm]
  )

  const getChancelleries = useCallback(async () => {
    const res = await getChancelleryLocations()
    setChancelleryLocations(res)
  }, [])

  useEffect(() => {
    getChancelleries()
  }, [getChancelleries])

  const handleRowClick = (rowId: string) => () => {
    // eslint-disable-next-line fp/no-mutating-methods
    !openExport && history.push(`/chancellery/sales/${rowId}`)
  }

  const onAssignFilterClick = useCallback(() => {
    if (user) {
      setAssignFilter(prevAssignFilter => (prevAssignFilter === user.id ? '' : user.id))
    }
  }, [user])

  const submitSearch = (event: React.FormEvent): void => {
    event.preventDefault()
    setSearchTerm(searchValue)
  }

  if (!chancelleryLocations) {
    return <Spinner />
  }

  return (
    <>
      <h2 className="text-center h2 small">Umsatzverwaltung</h2>
      <div className="sales-container__header-action-container">
        {openExport ? (
          <>
            <ButtonPrimary
              iconLeft={<SVGExport height="18" width="18" />}
              onClick={() => {
                setSelectAll(true)
                setExportSales(true)
              }}
              className={classnames('sales-container__export-btn', 'sales-container__export-btn__margin')}
            >
              Alles exportieren
            </ButtonPrimary>
            <ButtonPrimary
              iconLeft={<SVGExport height="18" width="18" />}
              onClick={() => {
                setSelectAll(false)
                setExportSales(true)
              }}
              className={classnames(
                'sales-container__export-btn',
                'sales-container__export-btn__margin',
                selectionLength === 0 && 'sales-container__export-btn__disabled'
              )}
              disabled={selectionLength === 0}
            >
              {`Auswahl exportieren (${selectionLength})`}
            </ButtonPrimary>
            <ButtonPrimary
              iconLeft={<SVGExit height="14" width="14" />}
              onClick={() => {
                setOpenExport(false)
                setExportSales(false)
              }}
              className="sales-container__export-btn"
            >
              Export abbrechen
            </ButtonPrimary>
          </>
        ) : (
          <ButtonPrimary
            iconLeft={<SVGExport height="18" width="18" />}
            onClick={() => setOpenExport(true)}
            className={classnames('sales-container__export-btn', noSearchResults && 'sales-container__export-btn__disabled')}
            disabled={noSearchResults}
          >
            Umsätze exportieren
          </ButtonPrimary>
        )}
        <div className="sales-container__search-filter">
          <AssignFilter active={!!assignFilter} onClick={onAssignFilterClick} />
          <Form onSubmit={submitSearch}>
            <SearchField onChange={setSearchValue} value={searchValue} onSearchClick={submitSearch} />
          </Form>
        </div>
      </div>
      <Sales
        user={user}
        hasPremium={premium}
        chancelleryLocations={chancelleryLocations}
        onRowClick={handleRowClick}
        assignFilter={assignFilter}
        searchTerm={searchTerm}
        searchList={[...groups.complete, ...groups.mandate]}
        searchLoading={isLoading}
        openExport={openExport}
        exportSales={exportSales}
        selectAll={selectAll}
        onSelectionChanged={setSelectionLength}
        onGeneratedLink={() => {
          setOpenExport(false)
          setExportSales(false)
          setSelectAll(false)
        }}
      />
    </>
  )
}

const SalesContainerWithUser = withUser(SalesContainer)

export { SalesContainerWithUser as SalesContainer }
