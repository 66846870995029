import { zodResolver } from '@hookform/resolvers/zod'
import LockIcon from '@mui/icons-material/Lock'
import { Box, InputAdornment, Stack, Typography, Button } from '@mui/material'
import { Base64 } from 'js-base64'
import React, { FC, useState } from 'react'
import { SubmitErrorHandler, useForm } from 'react-hook-form'
import { useHistory, useParams } from 'react-router-dom'

import SVGKlugoLogo from 'common/icons/klugo-logo-original.svg'
import { setPasswordMutation } from 'packages-mui/login/actions/setPassword'

import SVGConfirmation from '../assets/images/icon_bestätigung.svg'
import { LoginTextField, SigninBox } from '../components'
import { passwordSetSchema } from '../constants'
import { PasswordSetParams, PasswordSetFormFields } from '../interfaces'

export const PasswordSet: FC = () => {
  const { control, handleSubmit } = useForm<PasswordSetFormFields>({
    defaultValues: { password: '', confirmPassword: '' },
    resolver: zodResolver(passwordSetSchema),
  })
  const params = useParams<PasswordSetParams>()
  const history = useHistory()

  const [isSuccess, setIsSuccess] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [token] = useState(params?.token ?? '')

  const onRedirectSuccess = (): void => {
    // eslint-disable-next-line fp/no-mutating-methods
    history.push('/login')
  }

  const resetErrorMessage = (): void => {
    setErrorMessage('')
  }

  const handleFormFieldError: SubmitErrorHandler<PasswordSetFormFields> = errors => {
    setErrorMessage(errors.password?.message || errors.confirmPassword?.message || '')
  }

  const onSetPassword = (data: PasswordSetFormFields): void => {
    const { password, confirmPassword } = data

    if (confirmPassword !== password) {
      setErrorMessage('Die Passwörter stimmen nicht überein.')
      return
    }

    if (!token) {
      setErrorMessage('Sie haben kein Token.')
      return
    }

    const decodedToken = Base64.decode(token)

    setPasswordMutation(decodedToken, password)
      .then(() => {
        setIsSuccess(true)
      })
      .catch(error => {
        setErrorMessage(error.message)
        throw error
      })
  }

  return (
    <SigninBox>
      <Stack alignItems="center">
        <SVGKlugoLogo width="124" height="40" />
        <Typography variant="loginTitle">Passwortwiederherstellung</Typography>
      </Stack>
      {isSuccess ? (
        <Stack textAlign="center">
          <SVGConfirmation height="80" width="80" style={{ margin: '1rem auto 0.25rem' }} />
          <Typography variant="body2" color="green">
            Ihr Passwort wurde erfolgreich abgeändert.
          </Typography>
          <Button variant="loginContained" fullWidth sx={{ m: theme => theme.spacing(5, 0, 0.5) }} onClick={onRedirectSuccess}>
            Zum Login
          </Button>
        </Stack>
      ) : (
        <Stack spacing={2} component="form" textAlign="center" onSubmit={handleSubmit(onSetPassword, handleFormFieldError)}>
          <Typography sx={{ mt: 2 }} variant="body2" color="GrayText" textAlign="center">
            Bitte geben Sie nachfolgend Ihr neues Passwort ein.
          </Typography>
          <LoginTextField
            control={control}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LockIcon />
                </InputAdornment>
              ),
            }}
            onChange={resetErrorMessage}
            placeholder="Neues Passwort"
            type="password"
            name="password"
          />
          <LoginTextField
            control={control}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LockIcon />
                </InputAdornment>
              ),
            }}
            onChange={resetErrorMessage}
            placeholder="Passwort bestätigen"
            type="password"
            name="confirmPassword"
          />
          {errorMessage && (
            <Typography color="error" sx={{ mt: 1 }} variant="subtitle2" fontWeight={400}>
              {errorMessage}
            </Typography>
          )}
          <Box>
            <Button variant="loginContained" fullWidth sx={{ mt: 3.5 }} type="submit">
              Bestätigen
            </Button>
          </Box>
        </Stack>
      )}
    </SigninBox>
  )
}
