import { AdviceRequestStatus, AdviceRequest } from 'packages/chancellery-search/interfaces/AdviceRequest'

export const computeChannelStatus = (request: Pick<AdviceRequest, 'status' | 'revenue'>): AdviceRequestStatus => {
  const revenue = typeof request.revenue === 'string' ? parseInt(request.revenue, 10) : request.revenue ?? null
  if (request.status === 'complete' && revenue === 0) {
    return 'closed'
  } else if (request.status === 'closed' && revenue && revenue > 0) {
    return 'complete'
  } else {
    return request.status
  }
}
