import { Checkbox, FormControl, Input, InputLabel, ListItemText, MenuItem, Select, SelectChangeEvent } from '@mui/material'
import React, { FC } from 'react'
import { FormFeedback } from 'reactstrap'

import { SelectField, CheckboxField, FieldSet, FieldRow, InputField } from 'common/components/Form'
import { DEDUCTIBLE_AMOUNTS, PROTECTION_AMOUNTS } from 'common/constants'
import SVGCaseData from 'common/icons/CaseData.svg'
import { currencyFormatter } from 'common/utils'

import { AdviceRequest } from '../../../interfaces'

import { DamageNumberInput } from './DamageNumberInput'

interface DEVKFormProps {
  adviceRequest: AdviceRequest
  errors: { [key: string]: string }
  onFieldChange: React.EventHandler<React.FormEvent<HTMLInputElement>>
  onCheckboxChange: React.EventHandler<React.FormEvent<HTMLInputElement>>
  onMultiSelectChange: (e: SelectChangeEvent<Array<string>>) => void
  onCaseDataChange: (
    vnr: string,
    disputeAmount: string,
    deductible: boolean,
    deductibleAmount: string,
    noProtection: boolean | null,
    protectionAmount: Array<string>
  ) => void
}

const EMPTY_OPTION = {
  label: '---Bitte wählen---',
  value: '',
}

export const DevkForm: FC<DEVKFormProps> = ({
  adviceRequest,
  onFieldChange,
  onCheckboxChange,
  onCaseDataChange,
  onMultiSelectChange,
  errors,
}) => {
  const hasProtectionError = adviceRequest.noProtection === null || !!errors.protectionAmount
  return (
    <FieldSet className="devk-fieldset" label="DEVK Falldaten" icon={<SVGCaseData />}>
      <div className="devk-background">
        <FieldRow>
          <DamageNumberInput
            damageNumber={adviceRequest.damageNumber}
            damageNumberDisabled={adviceRequest.damageNumberDisabled}
            onFieldChange={onFieldChange}
            onCaseDataChange={onCaseDataChange}
            onCheckboxChange={onCheckboxChange}
          />
        </FieldRow>
        <FieldRow>
          <InputField
            label="Streitwert"
            onChange={onFieldChange}
            value={adviceRequest.disputeAmount}
            name="disputeAmount"
            id="disputeAmount"
            width="24rem"
          />
        </FieldRow>
        <FieldRow>
          <SelectField
            label="Höhe der Selbstbeteiligung"
            disabled={!!adviceRequest.noProtection}
            onChange={onFieldChange}
            value={adviceRequest ? adviceRequest.deductibleAmount : ''}
            name="deductibleAmount"
            id="deductibleAmount"
            inputWidht="15rem"
            error={errors.deductibleAmount}
            options={[
              EMPTY_OPTION,
              ...DEDUCTIBLE_AMOUNTS.map(v => ({
                value: v,
                label: currencyFormatter(v),
              })),
            ]}
          />
        </FieldRow>
        <FieldRow>
          <div className={hasProtectionError ? 'select-field--invalid' : ''}>
            <InputLabel
              sx={{
                mx: 1.5,
                fontSize: '14px',
                fontWeight: 500,
                color: 'white',
              }}
              id="select-protectionAmount-label"
            >
              Umfang Kostenschutz
            </InputLabel>
            <FormControl disabled={!!adviceRequest.noProtection} sx={{ minWidth: '15rem', mx: 1.5, fontSize: 2 }}>
              <Select
                id="select-protectionAmount"
                name="protectionAmount"
                sx={{
                  backgroundColor: '#f7f7f9',
                  pl: 2,
                  py: 0.5,
                  borderRadius: 1,
                  color: '#495057',
                  '&.Mui-disabled': {
                    backgroundColor: '#e9ecef',
                  },
                  '&.Mui-disabled #select-protectionAmount': {
                    color: '#495057',
                    WebkitTextFillColor: '#495057',
                  },
                }}
                multiple
                displayEmpty
                value={adviceRequest.protectionAmount}
                onChange={onMultiSelectChange}
                renderValue={selected => (!selected[0] ? '---Bitte wählen---' : selected.join(', '))}
                input={<Input disableUnderline error={hasProtectionError} />}
              >
                <MenuItem value="" disabled>
                  ---Bitte wählen---
                </MenuItem>
                {PROTECTION_AMOUNTS.map(name => (
                  <MenuItem key={name} value={name}>
                    <Checkbox checked={adviceRequest.protectionAmount.indexOf(name) > -1} />
                    <ListItemText primary={name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {adviceRequest.noProtection === null && (
              <FormFeedback style={{ paddingLeft: '1rem' }} className="invalid-feedback">
                Bitte Kostenschutz manuell ausfüllen
              </FormFeedback>
            )}
            {adviceRequest.noProtection !== null && errors.protectionAmount && (
              <FormFeedback style={{ paddingLeft: '1rem' }} className="invalid-feedback">
                {errors.protectionAmount}
              </FormFeedback>
            )}
          </div>
        </FieldRow>
        <FieldRow>
          <CheckboxField
            className="padding-top-1-5rem"
            onChange={onCheckboxChange}
            checked={!!adviceRequest.noProtection}
            name="noProtection"
            id="checkbox-no-protection"
            label="Kein Kostenschutz"
            width="12.5rem"
          />
        </FieldRow>
      </div>
    </FieldSet>
  )
}
