export const palette = {
  primary: {
    main: '#0a1343',
  },
  loginbg: {
    main: '#f7f7f9',
  },
  warningdark: {
    main: '#663c00',
  },
  disabled: {
    main: '#9c9c9c',
  },
}
