import { CircularProgress } from '@mui/material'
import React, { ChangeEventHandler, FC, useContext, useEffect, useState } from 'react'
import { Button } from 'reactstrap'

import { DamageNumberField } from 'common/components/Devk'
import { CheckboxField, InputFieldProps } from 'common/components/Form'
import { DEDUCTIBLE_AMOUNTS, protectionAmounts } from 'common/constants'
import { UserContext } from 'common/user-context'
import * as roles from 'common/utils/roles'
import { checkDamageNumber } from 'packages/chancellery-search/actions'
import { CheckDamageNumberQuery } from 'packages/chancellery-search/interfaces/schemaDefinition'

type DamageNumberinputProps = {
  damageNumberDisabled?: boolean
  damageNumber: string
  error?: string
  maskOptions?: InputFieldProps['maskOptions']
  onFieldChange: React.EventHandler<React.FormEvent<HTMLInputElement>>
  onCheckboxChange: React.EventHandler<React.FormEvent<HTMLInputElement>>
  onCaseDataChange: (
    vnr: string,
    disputeAmount: string,
    deductible: boolean,
    deductibleAmount: string,
    noProtection: boolean | null,
    protectionAmount: Array<string>
  ) => void
}

type DamageNumberResponse = CheckDamageNumberQuery['checkDamageNumber']
type ProtectionAmountProperties = keyof Omit<typeof protectionAmounts, 'unknown'>
type ProtectionAmountOptions = Pick<DamageNumberResponse, ProtectionAmountProperties>

const getProtectionAmount = (amount: ProtectionAmountOptions): Array<string> =>
  [
    amount.consultation ? protectionAmounts.consultation : '',
    amount.extrajudicial ? protectionAmounts.extrajudicial : '',
    amount.firstInstance ? protectionAmounts.firstInstance : '',
  ].filter(Boolean)

export const DamageNumberInput: FC<DamageNumberinputProps> = ({
  onFieldChange,
  onCheckboxChange,
  onCaseDataChange,
  error,
  damageNumber,
  damageNumberDisabled,
  maskOptions,
}) => {
  const [loading, setLoading] = useState(false)
  const [badFetch, setBadFetch] = useState('')
  const [caseData, setCaseData] = useState<null | DamageNumberResponse>(null)

  const { user } = useContext(UserContext)
  const hasWebserviceAccess = roles.isChannel(user)
  const showTakeDataButton = !damageNumberDisabled && hasWebserviceAccess && caseData && !error

  useEffect(() => {
    if (damageNumberDisabled) {
      setCaseData(null)
    }
  }, [damageNumberDisabled])

  const onTakeData = (): void => {
    if (!caseData) return
    const deductibleAmount = caseData.deductibleAmount?.replace('.', ',')
    const protectionAmount = getProtectionAmount(caseData)

    /**
     * every protection === false => noProtection = true
     * some protection === true => noProtection = false
     * no explicit protection === true => noProtection = null
     */
    const protections = Object.keys(protectionAmounts).filter(key => key !== 'unknown')
    const hasNoProtection = protections.every((a: ProtectionAmountProperties) => caseData[a] === false)
    const hasProtection = protections.some((a: ProtectionAmountProperties) => caseData[a] === true)
    const noProtection = hasNoProtection || (hasProtection ? false : null)

    const deductible = !!parseInt(caseData.deductibleAmount ?? '0', 10)
    onCaseDataChange(
      caseData.vnr ?? '',
      caseData.disputeAmount?.replace('.', ',') ?? '',
      /**
       * Having a deductible without protection
       * is nonsensical.
       */
      !noProtection && deductible,
      !noProtection && deductibleAmount && DEDUCTIBLE_AMOUNTS.includes(deductibleAmount) ? deductibleAmount : '',
      noProtection,
      protectionAmount
    )
  }

  const onDamageNumberChange: ChangeEventHandler<HTMLInputElement> = async event => {
    onFieldChange(event)
    const cleanedDamageNumber = event.target.value.replace(/\D/g, '')
    const getDamageData = hasWebserviceAccess && cleanedDamageNumber.length === 14

    if (!getDamageData) {
      setCaseData(null)
      return
    }

    setBadFetch('')
    setLoading(true)

    try {
      const response = await checkDamageNumber(cleanedDamageNumber)

      if (!response.exists) {
        setBadFetch('Es existieren keine Daten zu dieser Schadennummer')
      } else {
        setCaseData(response)
      }
    } catch (_e) {
      setBadFetch('Es konnten keine Daten abgerufen werden')
    }

    setLoading(false)
  }

  return (
    <>
      <DamageNumberField
        label="Schadensnummer"
        disabled={damageNumberDisabled}
        value={damageNumber}
        maskOptions={maskOptions}
        onChange={onDamageNumberChange}
        name="damageNumber"
        id="input-damageNumber"
        error={badFetch || error}
        width="24rem"
      />
      {loading && <CircularProgress style={{ marginTop: '2rem', marginLeft: '-3rem', marginRight: '1.5rem' }} size={24} />}
      {showTakeDataButton && (
        <Button onClick={onTakeData} style={{ marginTop: 'auto' }}>
          Daten Übernehmen
        </Button>
      )}
      <CheckboxField
        className="padding-top-1-5rem"
        onChange={onCheckboxChange}
        checked={!!damageNumberDisabled}
        name="damageNumberDisabled"
        id="checkbox-damageNumberDisabled"
        label="Keine Schadensnummer vorhanden"
      />
    </>
  )
}
