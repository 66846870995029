import { zodResolver } from '@hookform/resolvers/zod'
import { Box, Alert, Typography } from '@mui/material'
import React, { FC, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

import {
  PageLayout,
  LoadingMessage,
  AbortButton,
  SaveButton,
  DeleteWithConfirmation,
  FormActionBox,
  Grid,
} from 'common/components-mui'
import { Autocomplete, Checkbox, Select, GroupInput } from 'common/components-mui/react-hook-form'
import { CHANCELLERIES_ENDPOINT, FIELDS_OF_LAW_ENTRIES } from 'common/constants'
import { MatchingConfig } from 'common/graphql/schemaDefinition'
import { useParams, useRedirect, useScrollToTop } from 'common/hooks'
import { request, addOptionalPropIf, enqueueSnackbar } from 'common/utils'

import { getConfigData } from '../actions'
import { getMatchableLocations } from '../actions/getMatchableLocations'
import { ChancelleryBreadCrumbs, FallbackIssueWarnings } from '../components'
import saveMatchingConfigMutation from '../graphql/saveMatchingConfig.graphql'
import { useProductAndPartnerData } from '../hooks'
import { ConfigurationFormPageParams } from '../interfaces'
import { ConfigurationFormValues, configurationInitialValues, configurationSchema } from '../interfaces/formSchemas'
import { MatchableLocation, MatchingConfigInput, ProductType, SaveMatchingConfigMutation } from '../interfaces/schemaDefinition'
import { sortProducts, mapMatchingConfigAPIDataToForm, onError } from '../utils'

type isOptionEqual = <T extends string | { id: string; name: string }>(option: T, value: T) => boolean

const toApiInputFormat = (values: ConfigurationFormValues): MatchingConfigInput => {
  const {
    locationId,
    fallback,
    zipCodes,
    applyZip,
    fieldsOfLaw,
    partners,
    products,
    weight,
    priority,
    configurationId,
    active,
    deleted,
  } = values

  const baseInput = {
    zipAreas: applyZip ? zipCodes : [],
    active,
    deleted,
    fieldOfLawIds: {
      included: fieldsOfLaw,
    },
    productIds: {
      included: products.map(p => p.id),
    },
    partnerIds: {
      excluded: partners.map(p => p.id),
    },
    weight,
    priority,
    chancelleryLocationId: locationId ?? '',
    fallback,
  }

  const withId = addOptionalPropIf<{ id: string }, MatchingConfigInput>(configurationId.length > 0)({ id: configurationId })

  return withId(baseInput)
}

const LocationOption: FC<{ location: MatchableLocation }> = ({ location }) => (
  <option key={location.id} value={location.id}>{`${location.address.city}, ${location.address.street}${
    location.active ? '' : ' (inaktiv)'
  }`}</option>
)

export const ConfigurationFormPage: FC = () => {
  const { id, cid } = useParams<ConfigurationFormPageParams>()
  const [chancelleryId, setChancelleryId] = useState(cid)
  const [configurationDataLoading, setConfigurationDataLoading] = useState(false)
  const [matchableLocations, setMatchableLocations] = useState<MatchingConfig['matchableLocations']>([])

  const redirect = useRedirect()

  useScrollToTop()

  const {
    products: { response: productsResponse, error: productsError },
    productsBusiness: { response: productsBusinessResponse, error: productsBusinessError },
    partners: { response: partnersResponse, error: partnersError },
    isLoading: isLoadingProductsAndPartner,
  } = useProductAndPartnerData()

  const hasError = productsError || productsBusinessError || partnersError
  const isLoading = isLoadingProductsAndPartner && configurationDataLoading
  const products = [...(productsResponse?.data.products.list || []), ...(productsBusinessResponse?.data.products.list || [])]

  const { reset, control, handleSubmit, getValues, setValue, watch } = useForm({
    defaultValues: configurationInitialValues,
    resolver: zodResolver(configurationSchema),
  })
  const [configurationId, zipCodes, applyZip] = watch(['configurationId', 'zipCodes', 'applyZip'])

  const toOverview = (): void => redirect('/chancelleries')

  useEffect(() => {
    if (id) {
      setConfigurationDataLoading(true)
      getConfigData(id).then(values => {
        reset({
          ...values,
          products: values.products.map(product => ({ ...product, type: product.type ?? ProductType.Consumer })),
        })
        setChancelleryId(values.chancelleryId)
        setMatchableLocations(values.matchableLocations)
        setConfigurationDataLoading(false)
      })
    }

    if (!id && cid) {
      getMatchableLocations(cid).then(locations => setMatchableLocations(locations))
    }
  }, [id, cid, reset])

  const isOptionEqual: isOptionEqual = (option, value) =>
    typeof option === 'object' && typeof value === 'object'
      ? option.id === value.id && option.name === value.name
      : option === value

  const sortFol = (a: string, b: string): number => a.localeCompare(b)
  const sortPartner = (a: { id: string; name: string }, b: { id: string; name: string }): number => a.name.localeCompare(b.name)
  // eslint-disable-next-line fp/no-mutating-methods
  partnersResponse?.data.partners.list.sort(sortPartner)

  const productOptions = sortProducts(
    products.filter(({ id: pid }) => !pid.includes('-system')),
    true
  )

  const onSubmit = (values: ConfigurationFormValues): Promise<void> => {
    const input: MatchingConfigInput = toApiInputFormat(values)
    return request<SaveMatchingConfigMutation>(CHANCELLERIES_ENDPOINT, saveMatchingConfigMutation, {
      matchingConfig: input,
    })
      .then(({ saveMatchingConfig }) => {
        if (saveMatchingConfig) {
          reset(mapMatchingConfigAPIDataToForm(saveMatchingConfig))
          enqueueSnackbar('Konfiguration wurde gespeichert.', { variant: 'success' })

          // No config id in url means user saved new config and should be redirected to edit screen
          if (!id && !saveMatchingConfig.deleted) {
            redirect(`/chancelleries/configurations/edit/${saveMatchingConfig.id}`)
          }

          if (saveMatchingConfig.deleted) {
            toOverview()
          }
        }
      })
      .catch(() => {
        enqueueSnackbar('Konfiguration konnte nicht gespeichert werden.', { variant: 'error' })
      })
  }

  return (
    <PageLayout
      breadcrumbs={<ChancelleryBreadCrumbs chancelleryId={chancelleryId} />}
      heading={id ? 'Konfiguration bearbeiten' : 'Konfiguration anlegen'}
    >
      <FallbackIssueWarnings />
      <LoadingMessage isLoading={isLoading} />

      {hasError ? (
        <Alert severity="error">
          {partnersError
            ? 'Partner konnten nicht geladen werden.'
            : productsError
            ? 'B2C Produkte konnten nicht geladen werden.'
            : 'B2B Produkte konnten nicht geladen werden.'}
        </Alert>
      ) : (
        <Box component="form" id="configuration" onSubmit={handleSubmit(onSubmit, onError)}>
          <Grid container spacing={3} alignItems="center">
            <Grid sm={12} display="flex" gap={3}>
              <Checkbox control={control} name="active" label="Aktiv" disabled={isLoading} />
              <Checkbox control={control} name="fallback" label="ebenfalls Fallback" disabled={isLoading} />
            </Grid>

            <Grid sm={12} lg={6}>
              <Select native name="locationId" label="Standort" control={control}>
                <option value="" disabled>
                  -- Bitte wählen --
                </option>
                {matchableLocations?.map(location => (
                  <LocationOption key={location.id} location={location} />
                ))}
              </Select>
            </Grid>
            <Grid sm={12} sx={{ mb: 3 }}>
              <Checkbox control={control} name="applyZip" label="Auf PLZ beschränken" disabled={isLoading} />

              {applyZip && (
                <GroupInput
                  onChange={v => setValue('zipCodes', v)}
                  value={zipCodes}
                  name="zipCodes"
                  label="PLZ"
                  disabled={isLoading}
                />
              )}
            </Grid>
            <Grid sm={12} container>
              <Grid sm={12} lg={6}>
                <Autocomplete
                  multiple
                  sortSelected={sortFol}
                  label="Rechtsgebiete"
                  name="fieldsOfLaw"
                  control={control}
                  limitTags={5}
                  ChipProps={{ color: 'primary' }}
                  disabled={isLoading}
                  options={FIELDS_OF_LAW_ENTRIES.map(f => f.name)}
                  sx={{ sm: { marginTop: '1.5rem' }, md: { marginTop: 0 } }}
                />
              </Grid>
              <Grid sm={12} lg={6}>
                {products.length > 0 && (
                  <Autocomplete
                    control={control}
                    multiple
                    name="products"
                    label="Produkte"
                    fullWidth
                    limitTags={5}
                    ChipProps={{ color: 'primary' }}
                    disabled={isLoading}
                    options={productOptions}
                    groupBy={({ type }) => type}
                    isOptionEqualToValue={isOptionEqual}
                    getOptionLabel={(p: { id: string; name: string; type: string }) => `${p.id} (${p.name})`}
                  />
                )}
              </Grid>
              <Grid sm={12} lg={6}>
                {partnersResponse && (
                  <Autocomplete
                    multiple
                    control={control}
                    sx={{ sm: { marginTop: '1.5rem' }, md: { marginTop: 0 } }}
                    name="partners"
                    label="Partnerausnahmen"
                    sortSelected={sortPartner}
                    options={partnersResponse.data.partners.list}
                    getOptionLabel={p => (typeof p === 'object' ? p.name : p)}
                    isOptionEqualToValue={isOptionEqual}
                    fullWidth
                    limitTags={5}
                    ChipProps={{ color: 'primary' }}
                    disabled={isLoading}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>

          <FormActionBox>
            {configurationId && (
              <DeleteWithConfirmation
                actionButtonText="Konfiguration löschen"
                onConfirm={async () => {
                  setValue('deleted', true)
                  onSubmit(getValues()).then(toOverview)
                }}
              >
                <Typography>Diese Aktion ist nicht umkehrbar. Konfiguration wird permanent gelöscht.</Typography>
              </DeleteWithConfirmation>
            )}
            <AbortButton onClick={toOverview} />
            <SaveButton form="configuration" />
            <SaveButton
              submit={false}
              onClick={() => {
                onSubmit(getValues()).then(toOverview)
              }}
            >
              Speichern und Schließen
            </SaveButton>
          </FormActionBox>
        </Box>
      )}
    </PageLayout>
  )
}
