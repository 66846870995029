export const CASE_DATA_FIELDS = [
  'damageNumber',
  'deductible',
  'deductibleAmount',
  'noProtection',
  'protectionAmount',
  'disputeAmount',
  'internalPartnerNotes',
  'subject',
] as const
