import InfoIcon from '@mui/icons-material/Info'
import { TextField as MuiTextField, TextFieldProps, Tooltip } from '@mui/material'
import React, { ForwardRefRenderFunction, forwardRef } from 'react'
import { Controller } from 'react-hook-form'

import { ControlledFormField } from 'common/interfaces'

const StyledTextField: ForwardRefRenderFunction<HTMLDivElement, TextFieldProps> = (props, ref) => (
  <MuiTextField variant="outlined" {...props} ref={ref} />
)
const ForwardedStyledTextField = forwardRef(StyledTextField)

const infoTextIcon = (title: string): JSX.Element => (
  <Tooltip title={title} placement="top">
    <InfoIcon />
  </Tooltip>
)

const TextField: ControlledFormField<TextFieldProps, { hideErrorMessage?: boolean; infoText?: string }> = props => {
  // eslint-disable-next-line fp/no-rest-parameters
  const { control, name, onChange, hideErrorMessage = false, infoText, InputProps = {}, ...rest } = props

  const mergedInputProps = infoText ? { ...InputProps, ...{ endAdornment: infoTextIcon(infoText) } } : InputProps

  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState }) => (
        <ForwardedStyledTextField
          {...rest}
          {...field}
          error={fieldState.invalid}
          helperText={!hideErrorMessage && fieldState.error?.message}
          name={name}
          InputProps={mergedInputProps}
        />
      )}
    />
  )
}

export { ForwardedStyledTextField as StyledTextField, TextField }
