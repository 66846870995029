import AddIcon from '@mui/icons-material/AddCircleOutline'
import { IconButton, IconButtonProps } from '@mui/material'
import React, { FunctionComponent } from 'react'

export const AddButton: FunctionComponent<IconButtonProps> = props => (
  <IconButton
    aria-label="Standort hinzufügen"
    color="primary"
    {...props}
    sx={theme => ({
      marginBottom: 2,
      bgcolor: 'white',
      boxShadow: theme.shadows[1],
      '&:hover, &:focus': {
        bgcolor: 'white',
        boxShadow: theme.shadows[3],
        outline: 'none',
      },
    })}
    size="large"
  >
    <AddIcon />
  </IconButton>
)
