import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import { Button } from '@mui/material'
import { GridColDef } from '@mui/x-data-grid'
import React, { FunctionComponent } from 'react'
import { useHistory } from 'react-router'

import {
  createGridColumnsFrom,
  HeaderButtonGroup,
  MuiDataGrid,
  PageInfoline,
  PageLayout,
  useGridData,
} from 'common/components-mui'
import { FIELDS_OF_LAW_ENDPOINT, FIELDS_OF_LAW_ROLE_PERMISSIONS_STRINGIFIED } from 'common/constants'

import getFieldsOfLawQuery from '../graphql/getFieldsOfLaw.graphql'
import { GetFieldsOfLawQuery } from '../interfaces/schemaDefinition'

export const FieldsOfLawListPage: FunctionComponent = () => {
  const history = useHistory()

  const { data, error, actions, tableState } = useGridData<'fieldsOfLaw', GetFieldsOfLawQuery>(
    FIELDS_OF_LAW_ENDPOINT,
    getFieldsOfLawQuery,
    'fieldsOfLaw',
    {
      sort: { sortBy: 'name', sortDirection: 'asc' },
      pageSize: 100,
    }
  )

  const rows = (data?.list ?? []).map(entry => ({
    ...entry,
    roles: FIELDS_OF_LAW_ROLE_PERMISSIONS_STRINGIFIED[entry.name] ?? '',
  }))

  const columns: Array<GridColDef> = createGridColumnsFrom([
    { field: 'name', headerName: 'Rechtsgebiet', flex: 0.33 },
    { field: 'weeklyMax', headerName: 'Wöchentlich max.', sortable: false, filterable: false, flex: 0.33 },
    { field: 'roles', headerName: 'Zuweisbar von:', sortable: false, filterable: false, flex: 0.33 },
  ])

  return (
    <PageLayout error={error} heading="Rechtsgebieteverwaltung" spacing="table">
      <HeaderButtonGroup>
        {/* eslint-disable-next-line fp/no-mutating-methods */}
        <Button onClick={() => history.push('/fields-of-law/new')}>
          <AddCircleOutlineIcon titleAccess="Eintrag hinzufügen" />
        </Button>
      </HeaderButtonGroup>
      <PageInfoline>Insgesamt {data?.total ?? '0'} Einträge</PageInfoline>
      <MuiDataGrid
        noBorder
        actions={actions}
        tableState={tableState}
        columns={columns}
        rows={rows}
        loading={!data}
        rowCount={data?.total}
        onRowClick={params => {
          // eslint-disable-next-line fp/no-mutating-methods
          history.push(`/fields-of-law/${params.row.id}`)
        }}
      />
    </PageLayout>
  )
}
