import React, { FC, useCallback, useContext, useEffect, useState } from 'react'
import { Spinner } from 'reactstrap'

import { PageLayout } from 'common/components-mui'
import { UserContext } from 'common/user-context'

import { getChancelleryLocations } from '../actions'
import { SalesView } from '../components/SalesView'
import { ChancelleryLocationsQuery } from '../interfaces/schemaDefinition'

export const SalesList: FC = () => {
  const { user } = useContext(UserContext)

  const [chancelleryLocations, setChancelleryLocations] = useState<ChancelleryLocationsQuery['chancelleryLocations']['list']>()

  const getChancelleries = useCallback(async () => {
    const res = await getChancelleryLocations()
    setChancelleryLocations(res)
  }, [])

  useEffect(() => {
    getChancelleries()
  }, [getChancelleries])

  if (!chancelleryLocations) {
    return <Spinner />
  }

  return (
    <PageLayout heading="Umsatzverwaltung">
      <SalesView user={user} chancelleryLocations={chancelleryLocations} />
    </PageLayout>
  )
}
