import { useCallback, useState } from 'react'

const ALLOWED_FILES = ['image/jpeg', 'image/png', 'image/gif', 'application/pdf']
export const ACCEPTED_FILES = ALLOWED_FILES.join(',')
const MAX_FILES_SIZE = 26214400

const validateFiles = (files: Array<File>): string => {
  if (files.length === 0) return ''

  const fileSize = files.reduce((sum, file) => sum + file.size, 0)
  if (fileSize >= MAX_FILES_SIZE) {
    return 'Die Größe der angehängten Dateien darf 25 MB nicht überschreiten'
  }

  if (!!files.find(file => !ALLOWED_FILES.includes(file.type))) {
    return 'Es dürfen nur Dateien im Format pdf, jpeg, jpg oder png angehangen werden'
  }

  if (!!files.find(file => /\d{14}\.\w+/.test(file.name))) {
    return 'Dateien mit einer Schadensnummer als Namen dürfen nicht angehangen werden'
  }

  return ''
}

type FileHandlers = {
  files: Array<File>
  filesError: string
  onAddFile: React.ChangeEventHandler<HTMLInputElement>
  onRemoveFile: (name: string) => void
}

export const useFileHandlers = (): FileHandlers => {
  const [files, setFiles] = useState<Array<File>>([])
  const [filesError, setFilesError] = useState<string>('')

  const onAddFile: React.ChangeEventHandler<HTMLInputElement> = useCallback(
    event => {
      if (!event.currentTarget.files) return

      const newFiles = files.concat([...event.currentTarget.files])
      setFiles(newFiles)
      setFilesError(validateFiles(newFiles))
    },
    [setFiles, files]
  )

  const onRemoveFile = useCallback(
    (name: string): void => {
      const index = files.findIndex(file => file.name === name)
      const newFiles = [...files.slice(0, index), ...files.slice(index + 1)]
      setFiles(newFiles)
      setFilesError(validateFiles(newFiles))
    },
    [files, setFiles]
  )

  return {
    onRemoveFile,
    onAddFile,
    files,
    filesError,
  }
}
