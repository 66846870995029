import { ADVICE_REQUESTS_ENDPOINT } from 'common/constants'
import { request } from 'common/utils'

import query from '../graphql/addChancelleryNotReached.graphql'
import { AddChancelleryNotReachedMutation, AddChancelleryNotReachedMutationVariables } from '../interfaces/schemaDefinition'

export const addChancelleryNotReached = async ({
  chancelleryNotReached,
}: AddChancelleryNotReachedMutationVariables): Promise<AddChancelleryNotReachedMutation> => {
  const variables = {
    chancelleryNotReached,
  }

  return request<AddChancelleryNotReachedMutation, AddChancelleryNotReachedMutationVariables>(
    ADVICE_REQUESTS_ENDPOINT,
    query,
    variables
  )
}
