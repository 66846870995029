import { EnqueueSnackbar, enqueueSnackbar as notistackEnqueueSnackbar, SnackbarMessage, SnackbarProviderProps } from 'notistack'

import { CloseSnackbarAction } from './DismissSnackbarAction'

export const enqueueSnackbar: EnqueueSnackbar = (message: SnackbarMessage, options?: SnackbarProviderProps) =>
  notistackEnqueueSnackbar(message, {
    anchorOrigin: { horizontal: 'left', vertical: 'bottom' },
    ...(options?.variant === 'error' ? { persist: true } : { autoHideDuration: 5000 }),
    disableWindowBlurListener: false,
    hideIconVariant: false,
    preventDuplicate: false,
    transitionDuration: { enter: 225, exit: 195 },
    variant: 'default',
    ...(options?.variant === 'error' ? { action: CloseSnackbarAction } : {}),
    ...options,
  })
