export const protectionAmounts = Object.freeze({
  firstInstance: '1. Instanz',
  extrajudicial: 'Außergerichtlich',
  consultation: 'Beratung',
  unknown: 'Kostenschutz ist anzufragen',
})

export const PROTECTION_AMOUNTS = [
  'Rechtsmittelinstanz',
  'Beratung ohne Anerkennung einer Rechtspflicht (aus Kulanz)',
  ...Object.values(protectionAmounts),
]
