import React from 'react'

import { TextField } from 'common/components-mui/react-hook-form'

export const LoginTextField: typeof TextField = props => (
  <TextField
    fullWidth
    hideErrorMessage
    {...props}
    variant="standard"
    sx={theme => ({
      backgroundColor: theme.palette.common.white,
      'MuiTextField-root': {
        p: 2,
      },
      '& .MuiInput-input': {
        fontSize: theme.typography.subtitle2.fontSize,
        pl: 0.5,
        '&::placeholder': {
          opacity: 0.75,
          fontSize: theme.typography.subtitle2.fontSize,
        },
      },
      '& .MuiInput-root:before': {
        display: 'none',
      },
      '& .MuiInput-root:after': {
        display: 'none',
      },
      borderRadius: theme.spacing(1.25),
      p: theme.spacing(0.75, 1.5),
      boxShadow: '0 3px 10px #b3b3b3',
      borderColor: 'transparent',
      'Mui-error': {
        background: theme.palette.error.main,
      },
    })}
  />
)
