import React, { FC, useState } from 'react'

import Transfer from 'common/icons/Transfer.svg'
import TransferCheck from 'common/icons/TransferCheck.svg'
import { ButtonClear } from 'common/ui/Button'

import { sendToEConsult } from '../../actions'
import { LawyerRequest } from '../../interfaces'
import { didSendToEConsult } from '../../utils'
import { EConsultModal } from '../EConsultModal'

import './TransferButton.scss'

interface TransferButtonProps {
  adviceRequest: LawyerRequest
}

export const TransferButton: FC<TransferButtonProps> = ({ adviceRequest }) => {
  const [modalOpen, setModalOpen] = useState(false)
  const [modalSuccess, setModalSuccess] = useState(false)
  const [modalLoading, setModalLoading] = useState(false)
  const [hasSentNow, setHasSentNow] = useState(false)
  const didSend = didSendToEConsult(adviceRequest) || hasSentNow

  const error = adviceRequest.mandateName ? null : 'Ein Antrag ohne Mandatsnummer kann nicht weitergeleitet werden.'

  const onCloseModal = (): void => {
    setModalOpen(false)
  }

  const openModal = (event: React.MouseEvent<HTMLButtonElement>): false => {
    event.stopPropagation()
    if (!didSend) {
      setModalOpen(true)
    }
    return false
  }

  const onTransferClick = async (): Promise<void> => {
    setModalLoading(true)
    try {
      await sendToEConsult(adviceRequest.id)
      setHasSentNow(true)
    } catch (e: unknown) {
      setModalLoading(false)
      console.error(e)
    }
    setModalSuccess(true)
  }

  return (
    <>
      <ButtonClear
        title={didSend ? 'Akte wurde bereits an Ihre Kanzlei-Software übertragen' : 'Akte an Kanzlei-Software übertragen'}
        disabled={didSend}
        iconLeft={didSend ? TransferCheck : Transfer}
        onClick={openModal}
      />
      <EConsultModal
        open={modalOpen}
        error={error}
        success={modalSuccess}
        transferloading={modalLoading}
        onCancelClick={onCloseModal}
        onCompleteClick={onCloseModal}
        onTransferClick={onTransferClick}
      />
    </>
  )
}
