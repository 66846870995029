import { CreateMuiComponentOverrides } from 'theme/types/MuiComponentOverrides'

declare module '@mui/material/Paper' {
  interface PaperPropsVariantOverrides {
    card: true
    rounded: true
  }
}

export const createMuiPaperTheme: CreateMuiComponentOverrides<'MuiPaper'> = () => ({
  variants: [
    {
      props: { variant: 'rounded' },
      style: {
        borderRadius: 16,
      },
    },
    {
      props: { variant: 'card' },
      style: {
        borderRadius: 16,
        boxShadow: '0 2px 8px #d7d0f1',
        padding: '1.5rem 1.25rem',
        textAlign: 'center',
      },
    },
  ],
})
