import { LoggedInUser } from 'common/user-context'
import { isCallcenter } from 'common/utils'

import { getVisitedRecently } from './getVisitedRecently'
import '../styles/DataGridRow.scss'

export const createGetRowClassName =
  (user: LoggedInUser | undefined) =>
  (params: { row: { lastVisited: { date?: string; user?: string | null } } }): string => {
    const { lastVisited } = params.row

    const applyBackground = user && isCallcenter(user) && lastVisited?.date && lastVisited.user
    if (!applyBackground || !lastVisited.date) return ''

    const visitedRecently = getVisitedRecently(lastVisited?.date)
    return visitedRecently ? `visited-${lastVisited?.user === user.id ? 'self' : 'other'}` : ''
  }
