import { Box, Typography } from '@mui/material'
import { get } from 'lodash/fp'
import { pathOr } from 'ramda'
import React, { FC } from 'react'
import { Col, FormGroup } from 'reactstrap'

import { BooleanStaticField, StaticField, TextAreaStaticField } from 'common/components/StaticField'
import { StaticRow } from 'common/components/StaticRow'
import { HistoryEventType } from 'common/graphql/schemaDefinition'
import { ProductType } from 'packages/search/interfaces/schemaDefinition'

import { customPartners } from '../../constants'
import { AdviceRequest } from '../../interfaces'
import { getAddress as getChancelleryAddress } from '../../utils/chancellery'
import { formatAddress, getAddress, getFullName } from '../../utils/person'

import { PartnerView } from './PartnerView'

interface PersonalDataFormProps {
  showInsuranceNumber: boolean
  adviceRequest: AdviceRequest
  isChannelOrTRB?: boolean
}

const PersonalDataForm: FC<PersonalDataFormProps> = ({ showInsuranceNumber, adviceRequest, isChannelOrTRB }) => (
  <FormGroup tag="fieldset">
    <legend>Personendaten</legend>
    <StaticRow>
      <Col md={3}>
        <StaticField label="Name" value={getFullName(adviceRequest) || '-'} />
      </Col>
      <Col md={3}>
        <StaticField label="Adresse" value={getAddress(adviceRequest) || '-'} />
      </Col>
      <Col md={3}>
        <StaticField label="E-Mail" value={adviceRequest.email || '-'} />
      </Col>
      <Col md={3}>
        <StaticField label="Telefon" value={adviceRequest.phone || '-'} />
      </Col>
    </StaticRow>
    <StaticRow>
      {showInsuranceNumber && (
        <Col md={3}>
          <StaticField label="Versicherungsscheinnummer" value={adviceRequest.insuranceNumber || '-'} />
        </Col>
      )}
      {!isChannelOrTRB && (
        <>
          <Col md={3}>
            <BooleanStaticField label="AGBs aktzeptiert" value={adviceRequest.agbsAccepted} />
          </Col>
          <Col md={3}>
            <BooleanStaticField label="Einwilligungserklärung akzeptiert" value={adviceRequest.dataAccepted} />
          </Col>
        </>
      )}
    </StaticRow>
  </FormGroup>
)

const CompanyDataForm: FC<ChancelleryFormProps> = ({ adviceRequest }) => (
  <FormGroup tag="fieldset">
    <legend>Unternehmensinformationen</legend>
    <StaticRow>
      <Col md={3}>
        <StaticField label="Unternehmensname / Name" value={adviceRequest.companyName || '-'} />
      </Col>
      <Col md={3}>
        <StaticField label="Unternehmensform" value={adviceRequest.companyCompanyType || '-'} />
      </Col>
      <Col md={3}>
        <StaticField
          label="Adresse"
          value={
            formatAddress({
              street: adviceRequest.companyAddressStreet,
              streetNr: adviceRequest.companyAddressStreetNr,
              zip: adviceRequest.companyAddressZip,
              city: adviceRequest.companyAddressCity,
            }) || '-'
          }
        />
      </Col>
      <Col md={3}>
        <StaticField label="USt-IdNr. / Steuernummer" value={adviceRequest.companyTaxId || '-'} />
      </Col>
    </StaticRow>
  </FormGroup>
)

const BillingCompanyDataForm: FC<ChancelleryFormProps> = ({ adviceRequest }) => (
  <FormGroup tag="fieldset">
    <legend>Abweichende Rechnungsadresse</legend>
    <StaticRow>
      <Col md={3}>
        <StaticField label="Unternehmensname / Name" value={adviceRequest.billingCompanyName || '-'} />
      </Col>
      <Col md={3}>
        <StaticField label="Unternehmensform" value={adviceRequest.billingCompanyCompanyType || '-'} />
      </Col>
      <Col md={3}>
        <StaticField
          label="Adresse"
          value={
            formatAddress({
              street: adviceRequest.billingCompanyAddressStreet,
              streetNr: adviceRequest.billingCompanyAddressStreetNr,
              zip: adviceRequest.billingCompanyAddressZip,
              city: adviceRequest.billingCompanyAddressCity,
            }) || '-'
          }
        />
      </Col>
      <Col md={3}>
        <StaticField label="USt-IdNr. / Steuernummer" value={adviceRequest.billingCompanyTaxId || '-'} />
      </Col>
    </StaticRow>
  </FormGroup>
)

interface ChancelleryFormProps {
  adviceRequest: AdviceRequest
}

const ChancelleryForm: FC<ChancelleryFormProps> = ({ adviceRequest }) => {
  const { chancellery } = adviceRequest
  return (
    <>
      {chancellery && !chancellery?.callbacksDisabled && (
        <FormGroup tag="fieldset">
          <legend>Zugewiesene Kanzlei</legend>
          <StaticRow>
            <Col md={4}>
              <StaticField label="Name" value={chancellery.name} />
            </Col>
            <Col md={4}>
              <StaticField label="Adresse" value={getChancelleryAddress(chancellery)} />
            </Col>
            <Col md={4}>
              <StaticField label="Telefon" value={chancellery.phone} />
            </Col>
          </StaticRow>
        </FormGroup>
      )}
    </>
  )
}

const AdminRequestInfo: FC<{ adviceRequest: AdviceRequest }> = ({ adviceRequest }) => (
  <Box display="grid" gap={3} gridTemplateColumns={{ lg: 'repeat(4, minmax(0, 1fr))' }}>
    <Box m="10px">
      <Typography variant="staticValue">{`${pathOr('-', ['revenue'], adviceRequest)} €`}</Typography>
      <Typography variant="staticLabel">Umsatz</Typography>
    </Box>
    <Box m="10px">
      <Typography variant="staticValue">{adviceRequest.paymentMethod ?? '-'}</Typography>
      <Typography variant="staticLabel">Bezahlmethode</Typography>
    </Box>
    <Box m="10px">
      <Typography variant="staticValue">
        {adviceRequest.history?.find(({ type }) => type === HistoryEventType.CoverageRequest) ? 'Ja' : 'Nein'}
      </Typography>

      <Typography variant="staticLabel">Deckungsanfrage gestellt</Typography>
    </Box>
    <Box m="10px">
      <Typography variant="staticValue">
        {adviceRequest.history?.find(({ type }) => type === HistoryEventType.InsurerInvoice) ? 'Ja' : 'Nein'}
      </Typography>
      <Typography variant="staticLabel">Rechnung an Versicherer verschickt</Typography>
    </Box>
    <Box m="10px">
      <Typography variant="staticValue">
        {adviceRequest.history?.find(({ type }) => type === HistoryEventType.InsurerMessage) ? 'Ja' : 'Nein'}
      </Typography>

      <Typography variant="staticLabel">Kontakt zum Versicherer aufgenommen</Typography>
    </Box>
    <Box m="10px">
      <Typography variant="staticValue">{adviceRequest.hasUploads ? 'Ja' : 'Nein'}</Typography>
      <Typography variant="staticLabel">Useruploads vorhanden</Typography>
    </Box>
  </Box>
)

interface StatusRowProps {
  adviceRequest: AdviceRequest
  showInsuranceNumber?: boolean
  isTRB?: boolean
  isAdmin?: boolean
  isChannel?: boolean
}

export const SearchView: FC<StatusRowProps> = ({ adviceRequest, showInsuranceNumber, isTRB, isAdmin, isChannel }) => (
  <>
    {!!get(get('partner.id', adviceRequest), customPartners) && <PartnerView adviceRequest={adviceRequest} />}
    <PersonalDataForm
      showInsuranceNumber={!!showInsuranceNumber}
      adviceRequest={adviceRequest}
      isChannelOrTRB={isChannel || isTRB}
    />
    {adviceRequest.product.type === ProductType.Business && (
      <>
        <CompanyDataForm adviceRequest={adviceRequest} />
        {!!adviceRequest.billingCompanyName && <BillingCompanyDataForm adviceRequest={adviceRequest} />}
      </>
    )}
    <FormGroup tag="fieldset">
      <legend>Fallbeschreibung</legend>
      <StaticRow>
        <Col md={12}>
          <TextAreaStaticField label="" value={adviceRequest.description || '-'} />
        </Col>
      </StaticRow>
    </FormGroup>
    {isAdmin && (
      <Box sx={{ marginBlock: '1rem 2rem' }}>
        <legend>Zusatzinformationen KLUGO</legend>
        <AdminRequestInfo adviceRequest={adviceRequest} />
      </Box>
    )}
    <FormGroup>
      <legend>Bemerkung für den Anwalt</legend>
      <StaticRow>
        <Col md={12}>
          <TextAreaStaticField label="" value={adviceRequest.externalNotes || '-'} />
        </Col>
      </StaticRow>
    </FormGroup>
    <ChancelleryForm adviceRequest={adviceRequest} />
  </>
)
