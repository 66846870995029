import { Box } from '@mui/material'
import { GridCellParams, GridColumns, DataGrid, GridValueFormatterParams } from '@mui/x-data-grid'
import React, { FC } from 'react'
import { useHistory } from 'react-router'

import { addZipAreaOperators, createGridColumnsFrom, CenterBox, Badge, AddButton } from 'common/components-mui'
import { MatchingConfig } from 'common/graphql/schemaDefinition'
import { getTableHeight } from 'packages/chancellery-search/components/AdviceRequestHistoryTable/AdviceRequestHistoryTable'

import { GetChancelleryQuery, Holiday, Product } from '../interfaces/schemaDefinition'

import { isOnHoliday } from './ChancelleryLocationsTable'
import { InputLegend } from './InputLegend'

const getStatus: FC<GridCellParams> = (params: GridCellParams) => {
  /* eslint-disable @typescript-eslint/consistent-type-assertions */
  const active = params.row.active as Boolean
  const onHoliday = isOnHoliday(params.row.chancelleryLocation.holidays as Array<Holiday>)
  return active ? (
    onHoliday ? (
      <CenterBox>
        <Badge color="warning">Eingeschränkt</Badge>
      </CenterBox>
    ) : (
      <CenterBox>
        <Badge color="success">Aktiv</Badge>
      </CenterBox>
    )
  ) : (
    <CenterBox>
      <Badge color="default">Inaktiv</Badge>
    </CenterBox>
  )
  /* eslint-enable @typescript-eslint/consistent-type-assertions */
}

const columns: GridColumns = createGridColumnsFrom([
  /* eslint-disable @typescript-eslint/consistent-type-assertions */
  {
    field: 'chancelleryLocation',
    filterable: false,
    sortable: false,
    headerName: 'Standort',
    valueGetter: params => {
      const location = params.row as MatchingConfig
      return `${location.chancelleryLocation.address.city}, ${location.chancelleryLocation.address.street}`
    },
    flex: 0.167,
  },
  {
    field: 'fieldsOfLaw',
    sortable: false,
    headerName: 'Rechtsgebiete',
    valueFormatter: (params: GridValueFormatterParams): string => {
      const { included = [] } = params.value ?? {}
      return (included as Array<{ name: string; id: string }>).map(({ name }) => name).join(', ')
    },
    flex: 0.25,
  },
  {
    field: 'zipAreas',
    sortable: false,
    filterOperators: addZipAreaOperators(),
    headerName: 'PLZ',
    valueFormatter: params => (params.value as Array<string>)?.join(', ') || 'Keine Einschränkung',
    flex: 0.167,
  },
  {
    field: 'products',
    sortable: false,
    headerName: 'Produkte',
    valueFormatter: params => (params.value.included as Array<Product>)?.map(e => e.name).join(', ') || '-',
    flex: 0.167,
  },
  {
    field: 'fallback',
    sortable: false,
    headerName: 'Ist Fallback',
    valueFormatter: params => (params.value ? 'Ja' : 'Nein'),
    width: 150,
  },
  {
    field: 'active',
    headerName: 'Status',
    filterable: false,
    renderCell: getStatus,
    width: 150,
  },
  /* eslint-enable @typescript-eslint/consistent-type-assertions */
])

export const MatchingConfigsTable: FC<{
  matchingConfigs: GetChancelleryQuery['chancellery']['matchingConfigs']
  chancelleryId: string
}> = ({ matchingConfigs, chancelleryId }) => {
  const history = useHistory()
  const tableHeight = getTableHeight(4)
  return (
    <Box>
      <InputLegend>Matching Konfigurationen</InputLegend>
      <AddButton
        aria-label="Konfiguration hinzufügen"
        // eslint-disable-next-line fp/no-mutating-methods
        onClick={() => history.push(`/chancelleries/configurations/${chancelleryId}/new`)}
      />
      <Box component="fieldset" sx={{ height: `${tableHeight}px` }}>
        <DataGrid
          sx={{
            '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': { py: '8px', alignItems: 'flex-start' },
            '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': { py: '15px', alignItems: 'flex-start' },
            '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': { py: '22px', alignItems: 'flex-start' },
          }}
          getRowHeight={() => 'auto'}
          columns={columns}
          rows={matchingConfigs ?? []}
          rowCount={matchingConfigs?.length ?? 0}
          // eslint-disable-next-line fp/no-mutating-methods
          onRowClick={r => (r.row.id ? history.push(`/chancelleries/configurations/edit/${r.row.id}`) : undefined)}
        />
      </Box>
    </Box>
  )
}
