/* eslint-disable react/destructuring-assignment */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
/* eslint-disable fp/no-mutation, fp/no-this */
import { SelectChangeEvent } from '@mui/material'
import { isHoliday } from 'feiertagejs'
import * as H from 'history'
import { get, set } from 'lodash/fp'
import React, { ChangeEvent, Component, Fragment } from 'react'
import { RouteComponentProps } from 'react-router-dom'

import { fetchPartners, Partner } from 'common/api/getPartners'
import { ConfirmModal } from 'common/components/ConfirmModal'
import { SelectField, TextAreaField } from 'common/components/Form'
import { B2B_INDIVIDUAL_PRODUCT, FIELDS_OF_LAW_ENTRIES, URLs } from 'common/constants'
import { CANCEL_REASONS } from 'common/constants/adviceRequests'
import { UserContextProps, withUser } from 'common/user-context'
import {
  Consumer,
  Procedure,
  getQueryParam,
  isAdmin,
  isChannel,
  isEmpty,
  isLawyer,
  isTRB,
  isValidAmount,
  isDevk,
  enqueueSnackbar,
  isCallcenter,
} from 'common/utils'
import {
  MutationCancelRequestArgs,
  ContactType,
  ProductType,
  AdviceRequestQuery,
} from 'packages/chancellery-search/interfaces/schemaDefinition'
import { setLastVisited } from 'packages-mui/advice/actions'
import { canCancelWithReason, getVisitedRecently } from 'packages-mui/advice/utils'

import {
  getAdviceRequest,
  cancelRequest,
  createResubmission,
  findChancelleries,
  finishAdviceRequest,
  mapResponseToAdviceRequest,
  saveAdviceRequest,
  saveInternalNote,
  sendAGBs,
  startTRB as startTRBAction,
  setToClosed,
  rematch,
  addCustomerNotReached,
  addChancelleryNotReached,
} from '../actions'
import { shownMatchingSuggestion } from '../actions/shownMatchingSuggestion'
import { CancelModal } from '../components/CancelModal'
import { FinishModal } from '../components/FinishModal'
import { ChancellerySearchPage } from '../components/SearchPage'
import { ChancellerySearchResults } from '../components/SearchResults'
import { closingReasons, internalClosingReasons } from '../constants'
import { AdviceRequest, MatchedChancellery } from '../interfaces'
import { defaultCallbackTime } from '../utils/getCallbackTimes'
import { getPartnerById, hasPartnerCustomForm } from '../utils/partner'

type ChancellerySearchProps = RouteComponentProps<{}> & UserContextProps

interface ErrorMap {
  [key: string]: string
}

interface ChancellerySearchState {
  adviceRequest: AdviceRequest
  tmpAdviceRequest: Partial<AdviceRequest> | null
  partners: Array<Partner>
  hidePartnerSelection: boolean
  lockFieldOfLaw: boolean
  matchedChancelleries: {
    chancelleries: Array<MatchedChancellery>
    ownChancelleries: Array<MatchedChancellery>
    centralChancelleries?: Array<MatchedChancellery> | null
    premiumChancelleries: Array<MatchedChancellery>
  }
  initialLoading: boolean
  chancelleriesNotReached: Array<{ calledAt: string; chancellery: string }>
  loading: boolean
  matching: boolean
  completing: boolean
  searched: boolean
  rematched: boolean
  phone: string
  confirmCancel: boolean
  matchResetConfirmOpen: boolean
  cancelOpen: boolean
  finishOpen: null | ContactType
  errors: ErrorMap
  isMounted: boolean
  isCopied: boolean
  isTRB: boolean
  isLawyer: boolean
  useBillingCompany: boolean
  dateError?: string
  closeErrors: ErrorMap
  savingInternalNote: boolean
  serviceType: string
  sendVisited: boolean
  cancelReason?: string
  cancelReasonDescription?: string
  fromTrb?: string
}

const criticalValues = ['zip', 'fieldOfLaw']
const isCriticalValue = (name: string): name is keyof AdviceRequest => criticalValues.includes(name)
const isWeekend = (date: Date): boolean => date.getDay() === 0 || date.getDay() === 6
const isClosedHour = (date: Date): boolean => date.getHours() < 8 || date.getHours() >= 19
const isOpen = (date?: string | Date | null): string => {
  if (!date) return ''
  const dateFormat = new Date(date)
  if (
    isHoliday(dateFormat, 'BUND') ||
    isHoliday(dateFormat, 'NW') ||
    isWeekend(dateFormat) ||
    isClosedHour(dateFormat) ||
    (dateFormat.getDate() === 24 && dateFormat.getMonth() === 11)
  ) {
    return 'Bitte einen Zeitpunkt Werktags zwischen 8 und 19 Uhr wählen.'
  }
  return ''
}

const emptyAdviceRequest: () => AdviceRequest = () => ({
  foa: '',
  createdAt: new Date(),
  firstname: '',
  lastname: '',
  street: '',
  streetNr: '',
  city: '',
  zip: '',
  email: '',
  phone: '',
  companyName: '',
  companyCompanyType: '',
  companyAddressStreet: '',
  companyAddressStreetNr: '',
  companyAddressZip: '',
  companyAddressCity: '',
  companyTaxId: '',
  billingCompanyName: '',
  billingCompanyCompanyType: '',
  billingCompanyAddressStreet: '',
  billingCompanyAddressStreetNr: '',
  billingCompanyAddressZip: '',
  billingCompanyAddressCity: '',
  billingCompanyTaxId: '',
  agbsAccepted: false,
  dataAccepted: false,
  insuranceNumber: '',
  damageNumber: '',
  noProtection: null,
  damageNumberDisabled: false,
  deductible: false,
  deductibleAmount: '',
  protectionAmount: [],
  disputeAmount: '',
  internalPartnerNotes: '',
  instance: '',
  description: '',
  internalNotes: '',
  externalNotes: '',
  closeReason: '',
  status: 'new',
  adviceId: '',
  insured: false,
  newsletter: false,
  resultNotes: '',
  lawyerNotes: '',
  customerNotReached: [],
  chancelleryNotReached: [],
  paymentMethod: '-',
  product: {
    id: '',
    name: '',
    price: 0,
    type: ProductType.Consumer,
  },
  language: {
    code: 'de',
    language: 'Deutsch',
  },
  fromTrb: '',
})

const redirectToAdvice: (history: H.History) => (adviceId?: string) => void = history => adviceId => {
  if (adviceId) {
    if (typeof adviceId !== 'string') {
      throw new Error(`Expected adviceId of type string but got ${typeof adviceId}: ${JSON.stringify(adviceId)}`)
    }
    // eslint-disable-next-line fp/no-mutating-methods
    history.push(`${URLs.adviceRequests.home}/form?adviceId=${adviceId}`)
  } else {
    // eslint-disable-next-line fp/no-mutating-methods
    history.push(URLs.adviceRequests.home)
  }
}

/* Callback for ConfirmNavLink to prepare new TRB and link to this page */
export const startTRB: (history: H.History) => Procedure = history => async () => {
  const adviceRequest = await startTRBAction()
  redirectToAdvice(history)(adviceRequest.adviceId)
}

const ChancellerySearchContainer = withUser(
  class ChancellerySearchContainer extends Component<ChancellerySearchProps, ChancellerySearchState> {
    public state: ChancellerySearchState = {
      adviceRequest: emptyAdviceRequest(),
      tmpAdviceRequest: null,
      partners: [],
      sendVisited: false,
      hidePartnerSelection: false,
      lockFieldOfLaw: false,
      matchedChancelleries: {
        chancelleries: [],
        ownChancelleries: [],
        centralChancelleries: undefined,
        premiumChancelleries: [],
      },
      /**
       * local copy to avoid refetching DB-Data
       * and Data mismatches
       */
      chancelleriesNotReached: [],
      initialLoading: false,
      loading: false,
      matching: false,
      completing: false,
      searched: false,
      rematched: false,
      phone: '',
      errors: {},
      closeErrors: {},
      confirmCancel: false,
      cancelOpen: false,
      /**
       * either type of finish modal to open
       * or null if closed
       */
      matchResetConfirmOpen: false,
      finishOpen: null,
      isMounted: true,
      isCopied: false,
      isTRB: false,
      isLawyer: false,
      useBillingCompany: false,
      savingInternalNote: false,
      serviceType: 'Telefonische Rechtsberatung',
      fromTrb: '',
    }

    private redirectToAdvice: (adviceId?: string) => void = redirectToAdvice(this.props.history)

    public async componentDidMount(): Promise<void> {
      const { user, location } = this.props
      const partners = await this.fetchAndStorePartners()
      this.setDefaultPartner()
      const { adviceRequest } = this.state
      const partnerId = getQueryParam(location, 'partnerId')
      const adviceId = getQueryParam(location, 'adviceId')

      if (user) {
        if (isChannel(user)) {
          const channel = partners.find(partner => partner.id === user.channel)
          const setPartner = set('partner', channel)
          this.setState({
            adviceRequest: setPartner(adviceRequest),
            hidePartnerSelection: true,
            lockFieldOfLaw: true,
          })
          this.handlePartnerId(user.channel!)
        }

        if (isTRB(user)) {
          const channel = partners.find(partner => partner.id === 'devk-trb')
          const setPartner = set('partner', channel)

          this.setState({
            adviceRequest: setPartner(adviceRequest),
            hidePartnerSelection: true,
            isTRB: true,
          })
          this.handlePartnerId('devk-trb')
        }
        if (isLawyer(user)) {
          this.setState({ isLawyer: true })

          if (!isTRB(user)) {
            this.handlePartnerId('devk')
            this.setState({ hidePartnerSelection: true })
          }
        }
      }

      if (partnerId) {
        this.handlePartnerId(partnerId)
      }
      if (adviceId) {
        this.handleAdviceId(adviceId)
      }
    }

    public componentDidUpdate(prevProps: ChancellerySearchProps): void {
      const { location, user } = this.props
      const adviceId = getQueryParam(location, 'adviceId')
      if (location !== prevProps.location && adviceId) {
        this.handleAdviceId(adviceId)
      }

      /**
       * We only access state so that we can be sure, that all
       * information is available at the same time.
       */
      if (
        this.state.adviceRequest.adviceId &&
        isCallcenter(user) &&
        !getVisitedRecently(this.state.adviceRequest.lastVisited?.date) &&
        !this.state.sendVisited
      ) {
        setLastVisited({ adviceRequestId: this.state.adviceRequest.adviceId })
        this.setState({ sendVisited: true })
      }
    }

    public componentWillUnmount(): void {
      this.setState({ isMounted: false })
    }

    private onServiceTypeChange: Consumer<React.FormEvent<HTMLInputElement>> = event => {
      const { name, value } = event.currentTarget
      if (!name) {
        throw new Error('missing name on event target')
      } else {
        this.setState({ serviceType: value })
      }
    }

    private setDevkData = (
      vnr: string,
      disputeAmount: string,
      deductible: boolean,
      deductibleAmount: string,
      noProtection: boolean | null,
      protectionAmount: Array<string>
    ): void => {
      this.setState(prevState => {
        const adviceRequest: AdviceRequest = {
          ...prevState.adviceRequest,
          insuranceNumber: vnr,
          disputeAmount,
          deductible,
          deductibleAmount,
          noProtection,
          protectionAmount,
        }

        return { adviceRequest }
      })
    }

    private onPersonChange = (
      firstname: string,
      lastname: string,
      street: string,
      streetNr: string,
      city: string,
      zip: string
    ): void => {
      this.setState(prevState => {
        const adviceRequest = {
          ...prevState.adviceRequest,
          firstname,
          lastname,
          street,
          streetNr,
          city,
          zip,
        }
        return { adviceRequest }
      })
    }

    public showsMatchedChancelleries = (): boolean => {
      const { chancelleries, ownChancelleries, centralChancelleries, premiumChancelleries } = this.state.matchedChancelleries
      return [chancelleries, ownChancelleries, centralChancelleries, premiumChancelleries].some(c => !!c?.length)
    }

    public commitCriticalPropertyChange = (): void => {
      this.setState(state => ({
        matchedChancelleries: {
          chancelleries: [],
          ownChancelleries: [],
          centralChancelleries: undefined,
          premiumChancelleries: [],
        },
        adviceRequest: {
          ...state.adviceRequest,
          ...(state.tmpAdviceRequest ? state.tmpAdviceRequest : {}),
        },
        matchResetConfirmOpen: false,
        tmpAdviceRequest: null,
        matching: false,
        completing: false,
        searched: false,
        rematched: false,
      }))
    }

    public cancelCriticalPropertyChange = (): void => {
      this.setState({
        tmpAdviceRequest: null,
        matchResetConfirmOpen: false,
      })
    }

    /**
     *
     * If a value is changed which determines matching,
     * only save change in `tmpAdviceRequest` and wait for
     * change to be confirmed via modal
     */
    public handleCriticalValueChange = (name: string, value: string): void => {
      if (name === 'fieldOfLaw') {
        const fieldOfLaw = FIELDS_OF_LAW_ENTRIES.find(fol => fol.id === value) || { id: '', name: '' }
        this.setState({
          tmpAdviceRequest: { fieldOfLaw },
          matchResetConfirmOpen: true,
        })
      } else {
        this.setState({
          tmpAdviceRequest: { [name]: value },
          matchResetConfirmOpen: true,
        })
      }
    }

    // eslint-disable-next-line complexity
    private onFieldChange: Consumer<React.FormEvent<HTMLInputElement | HTMLSelectElement>> = event => {
      const { name, value } = event.currentTarget
      let { adviceRequest } = this.state

      if (isCriticalValue(name) && this.showsMatchedChancelleries()) {
        this.handleCriticalValueChange(name, value)
      } else if (name === 'fieldOfLaw') {
        const fieldOfLaw = FIELDS_OF_LAW_ENTRIES.find(fol => fol.id === value) || { id: '', name: '' }
        adviceRequest = {
          ...this.state.adviceRequest,
          fieldOfLaw,
        }
      } else if (name === 'language') {
        adviceRequest = {
          ...this.state.adviceRequest,
          language: {
            code: value,
            language: value,
          },
        }
      } else if (name === 'disputeAmount') {
        adviceRequest = {
          ...this.state.adviceRequest,
          disputeAmount: isValidAmount(value) ? value : this.state.adviceRequest.disputeAmount,
        }
      } else if (name === 'deductibleAmount') {
        adviceRequest = {
          ...this.state.adviceRequest,
          [name]: value,
          deductible: !!parseInt(value, 10),
        }
      } else if (name === 'damageNumber') {
        const damageNumber = value.replace(/\s\.\s/g, '')
        adviceRequest = {
          ...this.state.adviceRequest,
          damageNumber,
        }
      } else if (name === 'partner') {
        const partner = getPartnerById(value, this.state.partners)
        adviceRequest = {
          ...this.getPartnerDefaults(value),
          agbsAccepted: partner ? partner.agbsPreAccepted : this.state.adviceRequest.agbsAccepted,
          dataAccepted: partner ? partner.agbsPreAccepted : this.state.adviceRequest.dataAccepted,
          partner: partner!,
        }
      } else {
        adviceRequest = {
          ...this.state.adviceRequest,
          [name]: value,
        }
      }
      this.setState({ adviceRequest })
    }

    private onCheckboxChange = (event: React.FormEvent<HTMLInputElement>): void => {
      const { useBillingCompany, adviceRequest } = this.state
      if (!event.currentTarget.name) {
        throw new Error('missing name on event target')
      } else if (event.currentTarget.name === 'billingCompany') {
        this.setState({ useBillingCompany: !useBillingCompany })
      } else {
        const renamedAdviceRequest = set(event.currentTarget.name, !get(event.currentTarget.name, adviceRequest), adviceRequest)

        if (event.currentTarget.name === 'damageNumberDisabled') {
          renamedAdviceRequest.damageNumber = ''
        }

        if (event.currentTarget.name === 'insuranceNumberNotAvailabe') {
          renamedAdviceRequest.insuranceNumber = ''
        }

        if (event.currentTarget.name === 'noProtection') {
          renamedAdviceRequest.protectionAmount = []
          renamedAdviceRequest.deductibleAmount = ''
        }

        this.setState({ adviceRequest: renamedAdviceRequest })
      }
    }

    private onMultiSelectChange = (event: SelectChangeEvent<Array<string>>): void => {
      const newValue = typeof event.target.value === 'string' ? [event.target.value] : event.target.value

      this.setState(prevState => ({
        adviceRequest: {
          ...prevState.adviceRequest,
          [event.target.name]: newValue,
          noProtection: !!newValue.length ? false : prevState.adviceRequest.noProtection,
        },
      }))
    }

    private getAdviceRequest = async (adviceId: string, tries = 0): Promise<void> => {
      try {
        const response = await getAdviceRequest(adviceId)
        this.handleResponse(response)
        this.setState({ initialLoading: false })
      } catch (error) {
        if (tries < 5) {
          setTimeout(() => {
            this.getAdviceRequest(adviceId, tries + 1)
          }, 100)
        } else {
          throw error
        }
      }
    }

    private handleAdviceId = (adviceId: string): void => {
      if (adviceId) {
        this.setState({ initialLoading: true })
        this.getAdviceRequest(adviceId)
      }
    }

    private handlePartnerId = (id: string): void => {
      const { partners, adviceRequest, isMounted } = this.state
      const partner = getPartnerById(id, partners)
      if (!partner) {
        this.redirectToError()
      } else {
        const changedAdviceRequest = {
          ...this.getPartnerDefaults(id),
          partner,
          insured: adviceRequest.insured,
          agbsAccepted: partner.agbsPreAccepted,
          dataAccepted: partner.agbsPreAccepted,
        }
        if (isMounted) {
          this.setState({
            adviceRequest: changedAdviceRequest,
          })
        }
      }
    }

    private getPartnerDefaults = (id: string): AdviceRequest => {
      const { adviceRequest } = this.state
      if ((id === 'devk' || id === 'devk-steuerungstarif' || id === 'devk-diesel') && adviceRequest.status === 'new') {
        return {
          ...adviceRequest,
          noProtection: false,
          protectionAmount: [],
        }
      }
      return {
        ...adviceRequest,
      }
    }

    public setDefaultPartner = (): void => {
      const partner = getPartnerById('klugo', this.state.partners)
      if (partner) {
        this.setState(state => ({
          adviceRequest: {
            ...state.adviceRequest,
            partner,
          },
        }))
      }
    }

    public setPartnerById = async (partnerId: string): Promise<void> => {
      const partner = getPartnerById(partnerId, this.state.partners)
      if (partner) {
        this.setState(state => ({
          adviceRequest: {
            ...state.adviceRequest,
            partner,
          },
        }))
      }
    }

    public fetchAndStorePartners = async (): Promise<Array<Partner>> => {
      const partners = await fetchPartners()
      this.setState({ partners })
      return partners
    }

    public redirectToError: () => void = () => this.props.history.push('/404')

    public onRematch = async (): Promise<void> => {
      this.setState({ rematched: true, matching: true })

      const matchedChancelleries = await findChancelleries(this.state.adviceRequest)

      this.setState({ matchedChancelleries, matching: false })
      setTimeout(() => {
        window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' })
      })
    }

    public onFindChancelleries = async (): Promise<void> => {
      const { isMounted } = this.state
      const errors = this.validateInput(this.state.adviceRequest)
      if (Object.keys(errors).length) {
        if (isMounted) {
          this.setState({ errors })
        }
        window.scrollTo({ top: 0, behavior: 'smooth' })
        return
      } else {
        this.setState({ errors: {} })
      }

      if (isMounted) {
        this.setState({ searched: true, matching: true })
      }
      setTimeout(() => {
        window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' })
      })

      try {
        const { user } = this.props

        const updatePartner = (request?: AdviceRequest): void => {
          this.setState(state => ({
            adviceRequest: {
              ...state.adviceRequest,
              ...(request ?? {}),
              insured: true,
              legalProtectionInsurer: 'DEVK',
            },
          }))
        }

        if (this.state.adviceRequest.partner?.id === 'devk-direktsteuerung' && this.state.adviceRequest.noProtection) {
          await this.setPartnerById('devk-direktsteuerung-2')
          updatePartner()
        }

        if (
          this.state.adviceRequest.partner?.id === 'devk-direktsteuerung-2' &&
          this.state.adviceRequest.noProtection === false
        ) {
          await this.setPartnerById('devk-direktsteuerung')
          updatePartner()
        }

        const matchedChancelleries = await findChancelleries(
          this.state.adviceRequest,
          isTRB(user) ? ContactType.Callback : undefined
        )
        if (isMounted) {
          this.setState({ matchedChancelleries, matching: false })
        }

        if (!this.state.adviceRequest.adviceId || !this.state.adviceRequest.id) {
          await this.saveAdviceRequestDraft()
        }

        setTimeout(() => {
          window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' })
        })
      } catch (error) {
        if (error && error.message === 'address_not_found') {
          if (isMounted) {
            this.setState({
              searched: false,
              matching: false,
              errors: { address: 'Die angegebene Adresse konnte nicht gefunden werden.' },
            })
          }
        } else {
          throw error
        }
      }
    }

    public onRedirectClick: Consumer<MatchedChancellery> = chancellery => {
      this.setState({ loading: true })
      shownMatchingSuggestion({
        showMatchingSuggestion: {
          id: this.state.adviceRequest.id,
          adviceId: this.state.adviceRequest.adviceId,
          chancelleryId: chancellery.id,
          via: 'redirect',
        },
      })
        .then(request =>
          this.setState(state => ({
            adviceRequest: {
              ...state.adviceRequest,
              ...request,
              chancellery,
              chancelleryId: chancellery.id,
            },
            phone: chancellery.phone,
            finishOpen: ContactType.Redirect,
            isCopied: false,
          }))
        )
        .catch(() => {
          enqueueSnackbar('Ein unerwarter Fehler ist aufgetreten. Bitte versuchen Sie es erneut.', { variant: 'error' })
        })
    }

    public onCallbackClick = (chancellery: MatchedChancellery): void => {
      this.setState({ loading: true })
      shownMatchingSuggestion({
        showMatchingSuggestion: {
          id: this.state.adviceRequest.id,
          adviceId: this.state.adviceRequest.adviceId,
          chancelleryId: chancellery.id,
          via: 'callback',
        },
      })
        .then(request =>
          this.setState(state => ({
            adviceRequest: {
              ...state.adviceRequest,
              ...request,
              chancellery,
              loading: false,
              chancelleryId: chancellery.id,
              preferredCallbackTime: state.adviceRequest.preferredCallbackTime ?? defaultCallbackTime(new Date()).value,
            },
            finishOpen: ContactType.Callback,
          }))
        )
        .catch(() => {
          enqueueSnackbar('Ein unerwarter Fehler ist aufgetreten. Bitte versuchen Sie es erneut.', { variant: 'error' })
        })
    }

    public onCancelClick = (): void => {
      this.setState(state => ({
        adviceRequest: {
          ...state.adviceRequest,
          chancelleryId: undefined,
        },
        phone: '',
        finishOpen: null,
      }))
    }

    public onCompleteClick = async (): Promise<void> => {
      const dateError =
        this.validateCallbackTime(this.state.adviceRequest.preferredCallbackTime) ||
        isOpen(this.state.adviceRequest.preferredCallbackTime)

      if (this.state.finishOpen === 'CALLBACK' && dateError) {
        this.setState({ dateError })
        return
      }
      this.setState({ completing: true })
      const adviceRequest = {
        ...this.state.adviceRequest,
        contactType: this.state.finishOpen!,
      }

      if (!this.state.rematched) {
        finishAdviceRequest(adviceRequest)
          .then(result => {
            if (this.state.isMounted) {
              this.setState(state => ({
                adviceRequest: {
                  ...state.adviceRequest,
                  adviceId: result.finishMatching,
                },
                completing: false,
                finishOpen: null,
                searched: false,
              }))

              this.redirectToAdvice(result.finishMatching)
            }
          })
          .catch(error => {
            if (this.state.isMounted) {
              this.setState({
                completing: false,
                finishOpen: null,
              })
              throw error
            }
          })
      } else if (adviceRequest.id && adviceRequest.chancelleryId) {
        await rematch({
          rematchInput: {
            adviceRequestId: adviceRequest.id,
            chancelleryId: adviceRequest.chancelleryId,
            fieldOfLawId: adviceRequest.fieldOfLaw?.id || '',
          },
        })

        this.setState({
          adviceRequest,
          completing: false,
          finishOpen: null,
          rematched: false,
        })

        this.redirectToAdvice()
      }
    }

    public pushError = (error: ErrorMap): void => {
      this.setState(prevState => ({
        errors: { ...prevState.errors, ...error },
      }))
    }

    public onSendAGBs: Consumer<AdviceRequest> = adviceRequest => {
      const { foa, firstname, lastname, email, adviceId } = adviceRequest
      if (isEmpty(email)) {
        this.pushError({ email: 'Bitte eine gültige Emailadresse angeben.' })
      } else {
        this.setState({ loading: true })
        sendAGBs({ foa, firstname, lastname, email, adviceId })
          .then(() =>
            this.setState(prevState => ({
              adviceRequest: { ...prevState.adviceRequest, agbsSent: new Date() },
              loading: false,
            }))
          )
          .catch(error => {
            // eslint-disable-next-line
            console.error(`Failed to send AGBs email: ${error}`)
          })
      }
    }

    public validateCallbackTime = (date?: string | Date | null): string => {
      if (this.state.isTRB) {
        if (!date) {
          return 'Bitte einen Rückrufzeitpunkt wählen'
        }
      }
      return ''
    }

    public onChangeCallbackTime = (event: any): void => {
      const getCallbackTime = (event: any) => {
        if (event instanceof Date) {
          return event
        }
        if (event) {
          return event.currentTarget.value
        }
        return undefined
      }

      const adviceRequest: AdviceRequest = {
        ...this.state.adviceRequest,
        preferredCallbackTime: getCallbackTime(event),
      }
      this.setState({ adviceRequest, dateError: this.validateCallbackTime(event) })
    }

    public onSaveInternalNote = async (): Promise<void> => {
      this.setState({
        savingInternalNote: true,
      })
      const variables = {
        internalNote: {
          adviceId: this.state.adviceRequest.adviceId,
          internalNotes: this.state.adviceRequest.internalNotes,
        },
      }
      await saveInternalNote(variables).catch(error => {
        throw error
      })

      // this is for UX to get some feedback on the button (it is too fast otherwise)
      setTimeout(() => {
        this.setState({
          savingInternalNote: false,
        })
      }, 400)
    }

    // eslint-disable-next-line complexity
    public validateInput = (adviceRequest: AdviceRequest): { [key: string]: string } => {
      let errors: { [key: string]: string } = {}

      if (adviceRequest.product.type === ProductType.Business) {
        if (!adviceRequest.companyName) {
          errors = {
            ...errors,
            companyName: 'Bitte einen Unternehmensnamen angeben.',
          }
        }

        if (adviceRequest.product.id !== B2B_INDIVIDUAL_PRODUCT) {
          if (!adviceRequest.companyAddressStreet) {
            errors = {
              ...errors,
              companyAddressStreet: 'Bitte eine Straße angeben.',
            }
          }
          if (!adviceRequest.companyAddressStreetNr) {
            errors = {
              ...errors,
              companyAddressStreetNr: 'Bitte eine Hausnummer angeben.',
            }
          }
        }

        if (!adviceRequest.companyAddressZip || !/\d{5}/.test(adviceRequest.companyAddressZip)) {
          errors = {
            ...errors,
            companyAddressZip: 'Bitte eine gültige deutsche Postleitzahl angeben.',
          }
        }
        if (!adviceRequest.companyAddressCity) {
          errors = {
            ...errors,
            companyAddressCity: 'Bitte eine Stadt angeben.',
          }
        }

        if (this.state.useBillingCompany) {
          if (!adviceRequest.billingCompanyName) {
            errors = {
              ...errors,
              billingCompanyName: 'Bitte einen Unternehmensnamen angeben.',
            }
          }
          if (!adviceRequest.billingCompanyAddressStreet) {
            errors = {
              ...errors,
              billingCompanyAddressStreet: 'Bitte eine Straße angeben.',
            }
          }
          if (!adviceRequest.billingCompanyAddressStreetNr) {
            errors = {
              ...errors,
              billingCompanyAddressStreetNr: 'Bitte eine Hausnummer angeben.',
            }
          }
          if (!adviceRequest.billingCompanyAddressZip) {
            errors = {
              ...errors,
              billingCompanyAddressZip: 'Bitte eine Postleitzahl angeben.',
            }
          }
          if (!adviceRequest.billingCompanyAddressCity) {
            errors = {
              ...errors,
              billingCompanyAddressCity: 'Bitte eine Stadt angeben.',
            }
          }
        }
      }

      if (!adviceRequest.firstname) {
        errors = {
          ...errors,
          firstname: 'Bitte einen Vornamen angeben.',
        }
      }

      if (!adviceRequest.lastname) {
        errors = {
          ...errors,
          lastname: 'Bitte einen Nachnamen angeben.',
        }
      }

      const insuranceNumber = (adviceRequest.insuranceNumber || '').replace(/\s|_/g, '')
      if (
        (this.state.isTRB || ['devk-trb', 'dahag-trb'].includes(adviceRequest.partner ? adviceRequest.partner.id ?? '' : '')) &&
        !adviceRequest.insuranceNumberNotAvailabe &&
        (!insuranceNumber || insuranceNumber.length !== 9)
      ) {
        errors = {
          ...errors,
          insuranceNumber: 'Bitte 9-stellige Versicherungsscheinnummer angeben.',
        }
      }

      if (adviceRequest.product.type !== ProductType.Business && (!adviceRequest.zip || !/\d{5}/.test(adviceRequest.zip))) {
        errors = {
          ...errors,
          zip: 'Bitte eine gültige deutsche Postleitzahl angeben.',
        }
      }
      if (!adviceRequest.phone) {
        errors = {
          ...errors,
          phone: 'Bitte eine Telefonnummer angeben.',
        }
      }
      if (!adviceRequest.fieldOfLaw || !adviceRequest.fieldOfLaw.id) {
        errors = {
          ...errors,
          fieldOfLaw: 'Bitte ein Rechtsgebiet auswählen.',
        }
      }

      if (!adviceRequest.email) {
        errors = {
          ...errors,
          email: 'Bitte eine E-Mail Adresse angeben.',
        }
      }
      if (!adviceRequest.agbsAccepted) {
        errors = {
          ...errors,
          agbsAccepted: 'Bitte die AGBs akzeptieren.',
        }
      }

      const { partner } = adviceRequest

      if (partner && isDevk(partner.id)) {
        if (!adviceRequest.damageNumberDisabled && (!adviceRequest.damageNumber || !/\d{14}/.test(adviceRequest.damageNumber))) {
          errors = {
            ...errors,
            damageNumber: 'Bitte 14-stellige Schadensnummer angeben oder bestätigen, dass keine Schadensnummer vorhanden ist',
          }
        }
      }

      if (hasPartnerCustomForm(get('partner.id', adviceRequest))) {
        if (
          adviceRequest.noProtection === false &&
          (!adviceRequest.deductibleAmount || adviceRequest.protectionAmount.length === 0)
        ) {
          if (!adviceRequest.deductibleAmount) {
            errors = {
              ...errors,
              deductibleAmount: 'Bitte die Höhe der Selbstbeteiligung wählen',
            }
          }
          if (adviceRequest.protectionAmount.length === 0) {
            errors = {
              ...errors,
              protectionAmount: 'Bitte den Umfang des Kostenschutzes wählen',
            }
          }
        }

        if (adviceRequest.noProtection === null) {
          errors = {
            ...errors,
            protectionAmount: 'Bitte Kostenschutz manuell ausfüllen',
          }
        }
      }
      if (this.state.isTRB && !adviceRequest.dataAccepted) {
        errors = {
          ...errors,
          dataAccepted: 'Bitte die Einwilligungserklärung akzeptieren.',
        }
      }

      return errors
    }

    onCancelRequest = (): void => {
      this.setState({ confirmCancel: true })
    }

    completeCancel = (): void => {
      this.setState({ loading: true })

      if (!this.state.adviceRequest.id) {
        enqueueSnackbar('Ein Antrag muss angelegt werden bevor er storniert werden kann.', { variant: 'error' })
        this.setState({ loading: false, confirmCancel: false })
        throw new Error('Missing adviceRequest.id')
      }

      const variables: MutationCancelRequestArgs = {
        cancelRequestInput: {
          id: this.state.adviceRequest.id,
          cancelReason: this.state.cancelReason,
          cancelReasonDescription: this.state.cancelReason === 'Sonstiges' ? this.state.cancelReasonDescription : undefined,
        },
      }
      cancelRequest(variables)
        .then(result => {
          if (result.cancelRequest === this.state.adviceRequest.id) {
            const adviceRequest: AdviceRequest = {
              ...this.state.adviceRequest,
              status: 'cancelled',
            }
            this.setState({ adviceRequest, loading: false, confirmCancel: false })
          } else {
            throw new Error('unexpected result')
          }
        })
        .catch(_error => {
          enqueueSnackbar('Der Antrag konnte leider nicht storniert werden. Bitte versuchen Sie es später noch einmal.', {
            variant: 'error',
          })
          this.setState({ loading: false, confirmCancel: false })
        })
    }

    cancelCancel = (): void => {
      this.setState({ confirmCancel: false })
    }

    onResubmissionClick = async (): Promise<void> => {
      const response = await createResubmission(this.state.adviceRequest.adviceId)

      if (response.createResubmission === this.state.adviceRequest.id) {
        await saveAdviceRequest({
          ...this.state.adviceRequest,
          status: 'resubmission',
        })
        this.setState({
          adviceRequest: {
            ...this.state.adviceRequest,
            status: 'resubmission',
          },
        })

        this.redirectToAdvice()
      }
    }

    onAcceptAGBsClick: Procedure = () => {
      this.setState(prevState => ({
        adviceRequest: {
          ...prevState.adviceRequest,
          agbsAccepted: !prevState.adviceRequest.agbsAccepted,
        },
      }))
    }

    onAcceptDataClick: Procedure = () => {
      this.setState(prevState => ({
        adviceRequest: {
          ...prevState.adviceRequest,
          dataAccepted: !prevState.adviceRequest.dataAccepted,
        },
      }))
    }

    onCopyClick = () => {
      this.setState({ isCopied: true })
    }

    onSaveAndClose = async (): Promise<void> => {
      let errors: { [key: string]: string } = {}
      const { serviceType, adviceRequest } = this.state

      if (!adviceRequest.id) {
        throw new Error('cannot close adviceRequest without id')
      }

      if (serviceType !== 'Service Call') {
        if (!adviceRequest.fieldOfLaw || !adviceRequest.fieldOfLaw.id) {
          errors = {
            ...errors,
            fieldOfLaw: 'Bitte ein Rechtsgebiet auswählen.',
          }
        }

        if (
          adviceRequest.status !== 'awaiting_callback' &&
          !adviceRequest.insuranceNumberNotAvailabe &&
          !adviceRequest.insuranceNumber
        ) {
          errors = {
            ...errors,
            insuranceNumber: 'Bitte Versicherungsscheinnummer angeben.',
          }
        }

        if (Object.keys(errors).length) {
          this.setState({ errors })
          return
        }
        this.setState({ cancelOpen: true })
      } else {
        await saveAdviceRequest(adviceRequest)
        await setToClosed({
          id: adviceRequest.id,
          lawyerNotes: adviceRequest.lawyerNotes,
          resultNotes: adviceRequest.resultNotes,
          closeReason: 'Service Call',
        })

        this.setState({
          cancelOpen: false,
          loading: false,
        })

        this.redirectToAdvice()
      }
    }

    onSaveAdviceRequest = async (): Promise<void> => {
      try {
        await saveAdviceRequest({
          ...this.state.adviceRequest,
          status: 'editing',
        })
        enqueueSnackbar('Antrag als Entwurf gespeichert', { variant: 'success' })
        this.redirectToAdvice()
      } catch (error) {
        console.error(error)
        enqueueSnackbar('Entwurf konnte nicht gespeichert werden', { variant: 'error' })
      }
    }

    completeSaveAndClose = async (): Promise<void> => {
      if (!this.state.loading) {
        const { adviceRequest } = this.state

        if (!adviceRequest.id) {
          throw new Error('cannot close adviceRequest without id')
        }

        if (!adviceRequest.closeReason) {
          this.setState({
            closeErrors: {
              closeReason: 'Bitte wählen Sie einen Grund aus.',
            },
          })
          return
        }
        this.setState({ loading: true })
        try {
          await saveAdviceRequest(adviceRequest)
          await setToClosed({
            id: adviceRequest.id,
            lawyerNotes: adviceRequest.lawyerNotes,
            resultNotes: adviceRequest.resultNotes,
            closeReason: adviceRequest.closeReason,
          })

          this.setState({
            cancelOpen: false,
            loading: false,
          })

          this.redirectToAdvice()
        } catch (e) {
          this.setState({
            loading: false,
          })
          throw e
        }
      }
    }

    onChancelleryNotReachedClick = async (): Promise<void> => {
      const ids = this.getAdviceId()

      if (!this.state.adviceRequest.chancelleryId) {
        throw new Error('Missing information to reference adviceRequest')
      }

      // This is a failsave. It will only show if the userflow is buggy
      if (!ids.adviceId && !ids.id) {
        enqueueSnackbar('Bitte legen sie diesen Antrag vorher als Entwurf an.')
        return
      }

      const data = {
        ...ids,
        chancelleryId: this.state.adviceRequest.chancelleryId,
      }

      const notReached = await addChancelleryNotReached({ chancelleryNotReached: data })
      this.setState(state => ({
        adviceRequest: {
          ...state.adviceRequest,
          ...(!data.adviceId ? data : {}),
        },
        chancelleriesNotReached: notReached.addChancelleryNotReached ?? [],
      }))
    }

    getConfirmButtonDisabled = (): boolean => {
      if (!canCancelWithReason(this.props.user)) return false
      const needsDescription = this.state.cancelReason === 'Sonstiges'
      return needsDescription ? !this.state.cancelReasonDescription?.trim() : !this.state.cancelReason
    }

    cancelSaveAndClose = (): void => {
      this.setState({ cancelOpen: false })
    }

    saveAdviceRequestDraft = async (): Promise<void> => {
      const id = await saveAdviceRequest({
        ...this.state.adviceRequest,
        status: 'editing',
      })

      this.setState(state => ({
        adviceRequest: {
          ...state.adviceRequest,
          id,
        },
      }))
    }

    getAdviceId = (): { adviceId?: string; id?: string } => {
      const queryId = getQueryParam(this.props.location, 'adviceId')
      const { adviceId: idFromRequest } = this.state.adviceRequest

      const adviceId = queryId || idFromRequest
      const id = adviceId ? undefined : this.state.adviceRequest.id

      /**
       * @todo Refactor saveAdvicerequest to return adviceId so
       * that we do not have to do this abomination
       */
      return {
        adviceId,
        id,
      }
    }

    onCustomerNotReachedClicked = async (): Promise<void> => {
      if (!this.state.adviceRequest.preferredCallbackTime) {
        throw new Error('"preferredCallbackTime" does not exist')
      }

      const data = this.getAdviceId()

      // This is a failsave. It will only show if the userflow is buggy
      if (!data.adviceId && !data.id) {
        enqueueSnackbar('Bitte legen sie diesen Antrag vorher als Entwurf an.')
        return
      }

      const notReached = await addCustomerNotReached(data)
      this.setState(state => ({
        adviceRequest: {
          ...state.adviceRequest,
          customerNotReached: notReached.addCustomerNotReached ?? [],
        },
      }))
    }

    setCancelReasonDescription = (e: ChangeEvent<HTMLInputElement>): void => {
      const { value } = e.currentTarget
      this.setState({ cancelReasonDescription: value })
    }

    onCancelReasonChange = (e: ChangeEvent<HTMLInputElement>): void => {
      const { value } = e.currentTarget
      this.setState({ cancelReason: value })
    }

    handleResponse(response: AdviceRequestQuery): void {
      if (response) {
        const { adviceRequest: advicerequestResponse } = response
        if (advicerequestResponse) {
          if (this.state.isMounted) {
            this.setState({
              adviceRequest: {
                ...emptyAdviceRequest(),
                ...mapResponseToAdviceRequest(advicerequestResponse),
              },
              chancelleriesNotReached: advicerequestResponse.chancelleryNotReached ?? [],
              useBillingCompany:
                !!advicerequestResponse.billingCompany && advicerequestResponse.product?.type === ProductType.Business,
            })
            if (this.state.adviceRequest.insuranceNumber === '') {
              const adviceRequest = {
                ...this.state.adviceRequest,
                chancelleriesNotReached: advicerequestResponse.chancelleryNotReached ?? [],

                insuranceNumberNotAvailabe: false,
              }
              this.setState({ adviceRequest })
            }
          }
        }
      }
    }

    render(): JSX.Element {
      const isPaid = !!this.state.adviceRequest.invoice
      const closeReasons = isAdmin(this.props.user) ? internalClosingReasons : closingReasons

      return (
        <Fragment>
          <ChancellerySearchPage
            adviceRequest={this.state.adviceRequest}
            loading={this.state.initialLoading}
            partners={this.state.partners}
            hidePartnerSelection={this.state.hidePartnerSelection}
            lockFieldOfLaw={this.state.lockFieldOfLaw}
            useBillingCompany={this.state.useBillingCompany}
            lockInsured={this.state.isTRB}
            errors={this.state.errors}
            onFieldChange={this.onFieldChange}
            onCaseDataChange={this.setDevkData}
            onPersonChange={this.onPersonChange}
            onFindChancelleries={this.onFindChancelleries}
            onRematch={this.onRematch}
            onCheckboxChange={this.onCheckboxChange}
            onMultiSelectChange={this.onMultiSelectChange}
            onCancelRequestClick={this.onCancelRequest}
            onResubmissionClick={this.onResubmissionClick}
            onSendAGBs={this.onSendAGBs}
            onAcceptAGBsClick={this.onAcceptAGBsClick}
            onAcceptDataClick={this.onAcceptDataClick}
            onSaveInternalNote={this.onSaveInternalNote}
            savingInternalNote={this.state.savingInternalNote}
            user={this.props.user}
            onSaveAndClose={this.onSaveAndClose}
            onSaveAdviceRequest={this.onSaveAdviceRequest}
            serviceType={this.state.serviceType}
            onServiceTypeChange={this.onServiceTypeChange}
            onCustomerNotReachedClicked={this.onCustomerNotReachedClicked}
          />
          {(this.state.searched || this.state.rematched) && (
            <ChancellerySearchResults
              loading={this.state.matching}
              premiumChancelleries={this.state.matchedChancelleries.premiumChancelleries}
              chancelleries={this.state.matchedChancelleries.chancelleries}
              ownChancelleries={this.state.matchedChancelleries.ownChancelleries}
              centralChancelleries={this.state.matchedChancelleries.centralChancelleries}
              onRedirectClick={this.onRedirectClick}
              onCallbackClick={this.onCallbackClick}
              isLawyer={isLawyer(this.props.user) && !isTRB(this.props.user)}
              adviceRequest={this.state.adviceRequest}
            />
          )}
          <FinishModal
            loading={this.state.completing}
            contactType={this.state.finishOpen}
            adviceRequest={this.state.adviceRequest}
            phone={this.state.phone}
            onChangeCallbackTime={this.onChangeCallbackTime}
            onCancelClick={this.onCancelClick}
            onCompleteClick={this.onCompleteClick}
            onCopyClick={this.onCopyClick}
            onNotReachedClick={this.onChancelleryNotReachedClick}
            chancelleriesNotReached={this.state.chancelleriesNotReached}
            isCopied={this.state.isCopied}
            dateError={this.state.dateError}
            isTRB={isChannel(this.props.user) || isTRB(this.props.user)}
            isAdmin={isAdmin(this.props.user)}
            isLawyer={isLawyer(this.props.user)}
          />
          <ConfirmModal
            open={this.state.matchResetConfirmOpen}
            question="Kanzlei neu zuweisen?"
            onComplete={this.commitCriticalPropertyChange}
            onCancel={this.cancelCriticalPropertyChange}
            description={
              <p>Wenn Sie dieses Feld editieren, wird die bereits zugewiesene Kanzlei entfernt. Wollen Sie fortfahren?</p>
            }
          />
          <ConfirmModal
            open={this.state.confirmCancel}
            confirmDisabled={this.getConfirmButtonDisabled()}
            question={`Antrag ${this.state.adviceRequest.adviceId} wirklich stornieren?`}
            description={
              <>
                {isPaid ? (
                  <span>
                    Sie sind dabei, den Antrag zu stornieren und eine <strong>Gutschrift der Zahlung zu veranlassen.</strong>
                    <br />
                    <br /> Es wird eine Stornorechnung erstellt und der Kunde erhält diese per E-Mail. <br />
                    <br />
                    <strong>Dies kann nicht rückgängig gemacht werden.</strong>
                  </span>
                ) : (
                  'Sie sind dabei, den Antrag zu stornieren. Dies kann nicht rückgängig gemacht werden.'
                )}
                {canCancelWithReason(this.props.user) && (
                  <div style={{ margin: '0.5rem -0.75rem', display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                    <SelectField
                      id="select-cancel-reason"
                      name="cancel-reason"
                      required
                      value={this.state.cancelReason ?? ''}
                      label="Stornierungsgrund"
                      onChange={this.onCancelReasonChange}
                      options={[
                        ...CANCEL_REASONS.map(r => ({
                          value: r,
                          label: r || '-- Bitte wählen --',
                        })),
                      ]}
                    />
                    {this.state.cancelReason === 'Sonstiges' && (
                      <TextAreaField
                        label="Details zur Stornierung:"
                        onChange={this.setCancelReasonDescription}
                        value={this.state.cancelReasonDescription ?? ''}
                        id="text-cancel-description"
                        name="cancel-description"
                      />
                    )}
                  </div>
                )}
                <span>Soll der Antrag wirklich storniert werden?</span>
              </>
            }
            onComplete={this.completeCancel}
            onCancel={this.cancelCancel}
            loading={this.state.loading}
          />
          <CancelModal
            open={this.state.cancelOpen}
            reason={this.state.adviceRequest.closeReason || ''}
            onCompleteClick={this.completeSaveAndClose}
            onCancelClick={this.cancelSaveAndClose}
            onReasonChange={this.onFieldChange}
            errors={this.state.closeErrors}
            title={
              this.state.adviceRequest.status === 'awaiting_callback'
                ? 'Bitte geben Sie an warum der Fall geschlossen wird.'
                : undefined
            }
            closeReasons={this.state.adviceRequest.status === 'awaiting_callback' ? closeReasons : undefined}
          />
        </Fragment>
      )
    }
  }
)

export { ChancellerySearchContainer }
