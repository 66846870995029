import { Box, Paper } from '@mui/material'
import React, { FC, PropsWithChildren } from 'react'

export const DashboardCard: FC<PropsWithChildren<{ maxHeight?: string }>> = ({ children, maxHeight }) => (
  <Paper variant="card">
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      sx={{ width: '18rem', height: maxHeight ?? '15.5rem' }}
    >
      {children}
    </Box>
  </Paper>
)
