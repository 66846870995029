import React, { FC } from 'react'

import { Badge } from 'common/components-mui'

import { LabelProps } from '../interfaces'

enum BadgeColor {
  Warning = 'warning',
  Success = 'success',
  Error = 'error',
}

export const getLabelConfig = (status: string, revenue?: number | null): { text: string; color?: BadgeColor } => {
  switch (status) {
    case 'mandate':
      return { text: 'Offen', color: BadgeColor.Warning }
    case 'complete':
      if (revenue === 0) {
        return { text: 'Kein Mandat', color: BadgeColor.Error }
      } else if (!revenue) {
        return { text: 'Akte abgelegt', color: BadgeColor.Warning }
      } else {
        return { text: 'Abgeschlossen', color: BadgeColor.Success }
      }
    default:
      return { text: status }
  }
}

export const SalesStatusLabel: FC<LabelProps> = ({ status, revenue }) => {
  const config = getLabelConfig(status, revenue)

  return <Badge color={config.color}>{config.text}</Badge>
}
