import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classnames from 'classnames'
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Col, Row, Table } from 'reactstrap'

import { FILE_BASE } from 'app/constants'
import {
  SortFilterTh,
  Spinner,
  FilterTagsComponent as FilterTags,
  DatePickerInput,
  TextFilterInput,
  NumberFilterInput,
} from 'common/components'
import { CheckboxField } from 'common/components/Form'
import { ADVICE_REQUESTS_ENDPOINT, SALES_STATUS_ENTRIES } from 'common/constants'
import { ListProps, withList } from 'common/container'
import { Column, Filters, Filter } from 'common/interfaces'
import { ButtonClear } from 'common/ui'
import { LoggedInUser } from 'common/user-context'
import { currencyFormatter, formatDate, convertToTagValues, Procedure } from 'common/utils'
import { SearchAdviceRequest } from 'packages/search/interfaces'

import { createSalesExport } from '../actions'
import query from '../graphql/adviceRequests.graphql'
import { LawyerRequest, FilterOperator, ChancelleryLocationsQuery } from '../interfaces'
import { getChancelleryFilter } from '../utils'

import { PanelTabs } from './PanelTabs'
import { SalesStatusLabel } from './SalesStatusLabel'
import { ToolbarTabs } from './ToolbarTabs'

import './SalesView.scss'

const createOnSort = (func: (id: string) => void, id: string) => () => func(id)

const generalFilters: Filters = [
  {
    name: 'product.type',
    operator: FilterOperator.Equals,
    value: 'CONSUMER',
  },
  {
    name: '',
    operator: FilterOperator.Or,
    value: [
      {
        name: 'status',
        operator: FilterOperator.Equals,
        value: 'mandate',
      },
      {
        name: 'status',
        operator: FilterOperator.Equals,
        value: 'complete',
      },
    ],
  },
]

type SalesProps = ListProps<LawyerRequest> & {
  user?: LoggedInUser
  chancelleryLocations: ChancelleryLocationsQuery['chancelleryLocations']['list']
  hasPremium: boolean
  onRowClick: (rowId: string) => React.EventHandler<React.FormEvent<HTMLTableRowElement>>
  assignFilter?: string
  searchTerm: string
  searchList: Array<SearchAdviceRequest>
  searchLoading: boolean
  openExport: boolean
  exportSales: boolean
  selectAll: boolean
  onSelectionChanged: (itemLength: number) => void
  onGeneratedLink: Procedure
}

export const SalesView: FC<SalesProps> = ({
  list: originalList,
  sort,
  onSort,
  onFilterChange,
  onFilterRemove,
  onNext,
  onPrev,
  loading,
  searchLoading,
  page,
  pageSize,
  total,
  filters: originalFilters,
  onRowClick,
  searchTerm,
  searchList,
  openExport,
  exportSales,
  selectAll,
  assignFilter,
  onSelectionChanged,
  onGeneratedLink,
}) => {
  const history = useHistory()
  const [list, setList] = useState<Array<LawyerRequest | SearchAdviceRequest>>(originalList)
  const [selection, setSelection] = useState<Array<LawyerRequest | SearchAdviceRequest>>([])
  const [link, setLink] = useState<string>('')

  const filters: Filters = useMemo(
    () => [
      ...generalFilters,
      ...originalFilters,
      ...(assignFilter
        ? [
            {
              name: 'assignedTo',
              operator: FilterOperator.Equals,
              value: assignFilter,
            },
          ]
        : []),
      ...(searchTerm
        ? [
            {
              name: '',
              operator: FilterOperator.Or,
              value: [
                { name: 'adviceId', operator: FilterOperator.Contains, value: searchTerm },
                { name: 'person.firstname', operator: FilterOperator.Contains, value: searchTerm },
                { name: 'person.lastname', operator: FilterOperator.Contains, value: searchTerm },
              ],
            },
          ]
        : []),
    ],
    [originalFilters, assignFilter, searchTerm]
  )

  useEffect(() => {
    if (searchTerm && assignFilter) {
      setList(originalList.filter(advice => searchList.find(searchItem => searchItem.id === advice.id)))
    } else if (searchTerm) {
      setList(searchList)
    } else {
      setList(originalList)
    }
  }, [searchTerm, searchList, originalList, assignFilter])

  const handleStatusOnFilterSubmit = (filter: Filter<Column>) => {
    switch (filter.value) {
      case 'noMandate':
        onFilterChange({ name: 'revenue', operator: FilterOperator.Equals, value: 0 })
        onFilterChange({ name: 'status', operator: FilterOperator.Contains, value: 'complete' })
        break
      case 'filed':
        onFilterChange({ name: 'revenue', operator: FilterOperator.Empty, value: '' })
        onFilterChange({ name: 'status', operator: FilterOperator.Contains, value: 'complete' })
        break
      case 'complete':
        onFilterChange({ name: 'revenue', operator: FilterOperator.Gt, value: 0 })
        onFilterChange({ name: 'status', operator: FilterOperator.Contains, value: 'complete' })
        break
      default:
        onFilterChange(filter as Filter<Column>)
    }
  }

  const handleChangeSelection = (adviceRequest: LawyerRequest | SearchAdviceRequest) => {
    const index = selection.findIndex(item => item.id === adviceRequest.id)
    if (index !== -1) {
      const newSelection = [...selection.slice(0, index), ...selection.slice(index + 1)]
      setSelection(newSelection)
      onSelectionChanged(newSelection.length)
    } else {
      setSelection([...selection, adviceRequest])
      onSelectionChanged([...selection, adviceRequest].length)
    }
  }

  const handleSelectAllOfPage = () => {
    const allSelected = list.every(adviceRequest => selection.find(item => item.id === adviceRequest.id))
    if (allSelected) {
      const remainingSelection = selection.filter(adviceRequest => !list.find(item => item.id === adviceRequest.id))
      setSelection(remainingSelection)
      onSelectionChanged(remainingSelection.length)
    } else {
      const addMissing = list.filter(adviceRequest => !selection.find(item => item.id === adviceRequest.id))
      setSelection([...selection, ...addMissing])
      onSelectionChanged([...selection, ...addMissing].length)
    }
  }

  const checkIfAllSelected = () => !!list.every(adviceRequest => selection.find(item => item.id === adviceRequest.id))

  const handleClickedExport = useCallback(async () => {
    let link: string
    if (selectAll) {
      link = await createSalesExport({ sort, filters })
    } else {
      const selectionFilter = selection.map(item => ({ name: 'id', operator: FilterOperator.Equals, value: item.id }))
      link = await createSalesExport({
        sort,
        filters: [
          ...filters,
          {
            name: '',
            operator: FilterOperator.Or,
            value: selectionFilter,
          },
        ],
      })
    }
    setLink(link)
    onGeneratedLink()
  }, [sort, filters, selection, selectAll, onGeneratedLink])

  useEffect(() => {
    if (openExport && exportSales) {
      handleClickedExport()
    } else if (!openExport && selection.length > 0) {
      setSelection([])
      onSelectionChanged(0)
    } else if (openExport && link) {
      setLink('')
    }
  }, [openExport, exportSales, handleClickedExport, selection.length, onSelectionChanged, link])

  return (
    <>
      <div
        className={classnames(
          (link && !openExport) || searchTerm
            ? 'sales-view__link-search-results-container'
            : 'sales-view__link-search-results-container__hidden'
        )}
      >
        {link && !openExport && (
          <a
            href={`${FILE_BASE}/${link}`}
            download
            onClick={() => setLink('')}
            className="sales-view__link-search-results-container__export-link"
          >
            <FontAwesomeIcon icon="file-excel" size="3x" />
            <p className="sales-view__link-search-results-container__export-link__info">CSV-Export herunterladen</p>
          </a>
        )}
        {searchTerm && !searchLoading && (
          <p className={classnames('search-results', 'sales-view__link-search-results-container__search')}>
            Ihre Suchergebnisse: {list.length}
          </p>
        )}
      </div>
      <PanelTabs>
        <ToolbarTabs
          title={openExport ? 'Selektieren Sie die für Ihren Export gewünschten Umsätze:' : 'Umsatzübersicht'}
          pagination={{ page, pageSize, total, label: 'Fallumsätze' }}
          onNext={onNext}
          onPrev={onPrev}
        />
        <Row>
          <Col sm="12" className={classnames((loading || searchLoading) && 'sales-view__col')}>
            <Spinner condition={loading || searchLoading}>
              <FilterTags filters={originalFilters} onTagRemove={onFilterRemove} />
              {list.length > 0 ? (
                <Table hover responsive>
                  <thead>
                    <tr>
                      {openExport && (
                        <th>
                          <CheckboxField
                            name="sales-selection"
                            id="checkbox-salesSelection"
                            label=""
                            onChange={handleSelectAllOfPage}
                            checked={checkIfAllSelected()}
                            className="sales-view__checkbox"
                          />
                        </th>
                      )}
                      <SortFilterTh
                        id="salesAdviceId"
                        name="adviceId"
                        sort={sort}
                        onSort={createOnSort(onSort, 'adviceId')}
                        onFilterSubmit={onFilterChange}
                        FilterInput={TextFilterInput}
                        placeholder="Beratungs ID..."
                      >
                        ID
                      </SortFilterTh>
                      <SortFilterTh
                        id="salesCreatedAt"
                        name="createdAt"
                        sort={sort}
                        onSort={createOnSort(onSort, 'createdAt')}
                        onFilterSubmit={onFilterChange}
                        FilterInput={DatePickerInput}
                        placeholder="ID-Datum..."
                      >
                        ID-Datum
                      </SortFilterTh>
                      <SortFilterTh
                        id="salesMandateName"
                        name="mandateName"
                        sort={sort}
                        onSort={createOnSort(onSort, 'mandateName')}
                        onFilterSubmit={onFilterChange}
                        FilterInput={TextFilterInput}
                        placeholder="Aktenname..."
                      >
                        Aktenname
                      </SortFilterTh>
                      <th>In Bearbeitung durch</th>
                      <SortFilterTh
                        id="salesPersonName"
                        name="personName"
                        sort={sort}
                        onSort={createOnSort(onSort, 'personName')}
                        onFilterSubmit={onFilterChange}
                        FilterInput={TextFilterInput}
                        placeholder="Mandantenname..."
                      >
                        Mandantenname
                      </SortFilterTh>
                      <SortFilterTh
                        id="revenue"
                        name="revenue"
                        sort={sort}
                        onSort={createOnSort(onSort, 'revenue')}
                        operator={FilterOperator.Equals}
                        onFilterSubmit={onFilterChange}
                        FilterInput={NumberFilterInput}
                        placeholder="Gesamtumsatz..."
                      >
                        Gesamtumsatz (netto)
                      </SortFilterTh>
                      <SortFilterTh
                        id="salesStatus"
                        name="status"
                        sort={sort}
                        onSort={createOnSort(onSort, 'status')}
                        suggestions={convertToTagValues(SALES_STATUS_ENTRIES)}
                        onFilterSubmit={handleStatusOnFilterSubmit}
                        FilterInput={TextFilterInput}
                        placeholder="Status..."
                      >
                        Status
                      </SortFilterTh>
                      <th className="text-center action-header">Bearbeiten</th>
                    </tr>
                  </thead>
                  <tbody>
                    {(list as Array<LawyerRequest | SearchAdviceRequest>).map(adviceRequest => (
                      <tr
                        key={adviceRequest.id}
                        className={classnames(!openExport && 'row-hover', 'row-relative')}
                        onClick={onRowClick(adviceRequest.adviceId)}
                      >
                        {openExport && (
                          <td>
                            <CheckboxField
                              name={`salesSelection-${adviceRequest.id}`}
                              id={`checkbox-salesSelection-${adviceRequest.id}`}
                              label=""
                              onChange={() => handleChangeSelection(adviceRequest)}
                              checked={!!selection.find(item => item.id === adviceRequest.id)}
                              className="sales-view__checkbox"
                            />
                          </td>
                        )}
                        <td scope="row" className="text-center">
                          {adviceRequest.adviceId}
                        </td>
                        <td className="text-center">{formatDate(new Date(adviceRequest.createdAt))}</td>
                        <td className="text-center">{adviceRequest.mandateName}</td>
                        <td className="text-center">
                          {adviceRequest.assignedTo!.name || (
                            <span>
                              <FontAwesomeIcon icon={faExclamationTriangle} size="lg" className="inline-icon" /> Nicht zugewiesen
                            </span>
                          )}
                        </td>
                        <td className="text-center">{`${adviceRequest.person.firstname} ${adviceRequest.person.lastname}`}</td>
                        <td className="text-center">{currencyFormatter(adviceRequest.revenue)}</td>
                        <td className="text-center">
                          <SalesStatusLabel status={adviceRequest.status} revenue={adviceRequest.revenue} />
                        </td>
                        <td className="text-center action-cell">
                          <ButtonClear
                            iconLeft={<FontAwesomeIcon icon="pencil-alt" />}
                            onClick={() => history.push(`/chancellery/sales/${adviceRequest.adviceId}`)}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              ) : (
                <h3 className="no-data">Keine Fallumsätze vorhanden</h3>
              )}
            </Spinner>
          </Col>
        </Row>
      </PanelTabs>
    </>
  )
}

export const Sales = withList<LawyerRequest, SalesProps>({
  query,
  endpoint: ADVICE_REQUESTS_ENDPOINT,
  responseKey: 'adviceRequests',
  pageSize: 20,
  queryMapper: (listState, props) => ({
    ...listState,
    filters: [
      ...listState.filters,
      ...(props.assignFilter
        ? [
            {
              name: 'assignedTo',
              operator: FilterOperator.Equals,
              value: props.assignFilter,
            },
          ]
        : []),
      getChancelleryFilter(props.user, props.chancelleryLocations),
      ...generalFilters,
    ],
  }),
})(SalesView)
