import { Check } from '@mui/icons-material'
import { Box } from '@mui/material'
import React, { FC } from 'react'

type StatusLabelProps = { status: string; revenue?: number | null }

const contentMap = {
  open: {
    label: 'Offen',
    color: '#ff900f',
  },
  noMandate: {
    label: 'Kein Mandat',
    color: '#ff900f',
  },
  done: {
    label: (
      <>
        <Check /> Abgeschlossen
      </>
    ),
    color: '#3fab64',
  },
  closed: {
    label: 'Akte abgelegt',
    color: '#f93232',
  },
}

type ContentMapKey = keyof typeof contentMap

const salesStatusLabels = (
  status: string,
  revenue: number | undefined | null
): (typeof contentMap)[ContentMapKey] | undefined => {
  if (status === 'mandate') {
    return contentMap.open
  } else if (status === 'complete' && revenue === 0) {
    return contentMap.noMandate
  } else if (status === 'complete' && !revenue) {
    return contentMap.closed
  } else if (status === 'complete') {
    return contentMap.done
  }

  return undefined
}

export const SalesStatusLabel: FC<StatusLabelProps> = ({ status, revenue }) => {
  const content = salesStatusLabels(status, revenue)
  return content ? (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'end',
        color: content.color,

        svg: {
          marginLeft: '0.5rem',
          height: '1rem',
        },
      }}
    >
      {content.label}
    </Box>
  ) : (
    <span>{status}</span>
  )
}
