// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NbfsCHgab3DYsGwqXuTo label{color:#0a1343;font-weight:500;font-size:14px;margin:0 .75rem .25rem .75rem}.NbfsCHgab3DYsGwqXuTo .mecBTVXnlHl9bSlLzG3X{display:flex;flex-direction:column}.NbfsCHgab3DYsGwqXuTo .d3f6hkb3wZxTzPTZf2Ev{margin-bottom:1rem;display:flex;align-items:center}.NbfsCHgab3DYsGwqXuTo .fmT01ZrTjfAdU1nL6cA8{margin:.5rem .75rem}.NbfsCHgab3DYsGwqXuTo .LLPxKlxRF1tnVyHMoPAv{margin:-0.25rem .75rem .5rem .75rem}.NbfsCHgab3DYsGwqXuTo .AcGOK2srUI5dFdNkTXMi{margin:0 .75rem 0 -0.5rem;height:38px}.NbfsCHgab3DYsGwqXuTo .AcGOK2srUI5dFdNkTXMi>div>span{display:flex;align-items:center;justify-content:center}.NbfsCHgab3DYsGwqXuTo .Db40zr3UiB0oPjkS_YV4{fill:#0a1343}`, "",{"version":3,"sources":["webpack://./src/packages/products-business/components/CustomFieldArray/CustomFieldArray.module.scss"],"names":[],"mappings":"AACE,4BACE,aAAA,CACA,eAAA,CACA,cAAA,CACA,6BAAA,CAGF,4CACE,YAAA,CACA,qBAAA,CAGF,4CACE,kBAAA,CACA,YAAA,CACA,kBAAA,CAGF,4CACE,mBAAA,CAGF,4CACE,mCAAA,CAGF,4CACE,yBAAA,CACA,WAAA,CAEA,qDACE,YAAA,CACA,kBAAA,CACA,sBAAA,CAIJ,4CACE,YAAA","sourcesContent":[".fieldArray {\n  label {\n    color: #0a1343;\n    font-weight: 500;\n    font-size: 14px;\n    margin: 0 0.75rem 0.25rem 0.75rem;\n  }\n\n  .list {\n    display: flex;\n    flex-direction: column;\n  }\n\n  .entry {\n    margin-bottom: 1rem;\n    display: flex;\n    align-items: center;\n  }\n\n  .buttonAddText {\n    margin: 0.5rem 0.75rem;\n  }\n\n  .buttonAdd {\n    margin: -0.25rem 0.75rem 0.5rem 0.75rem;\n  }\n\n  .buttonRemove {\n    margin: 0 0.75rem 0 -0.5rem;\n    height: 38px;\n\n    > div > span {\n      display: flex;\n      align-items: center;\n      justify-content: center;\n    }\n  }\n\n  .icon {\n    fill: #0a1343;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fieldArray": `NbfsCHgab3DYsGwqXuTo`,
	"list": `mecBTVXnlHl9bSlLzG3X`,
	"entry": `d3f6hkb3wZxTzPTZf2Ev`,
	"buttonAddText": `fmT01ZrTjfAdU1nL6cA8`,
	"buttonAdd": `LLPxKlxRF1tnVyHMoPAv`,
	"buttonRemove": `AcGOK2srUI5dFdNkTXMi`,
	"icon": `Db40zr3UiB0oPjkS_YV4`
};
export default ___CSS_LOADER_EXPORT___;
