import { CHANCELLERIES_ENDPOINT } from 'common/constants'
import { request } from 'common/utils'

import query from '../graphql/getChancelleryLocation.graphql'
import { GetChancelleryLocationQuery } from '../interfaces/schemaDefinition'
import { mapChancelleryLocationAPIDataToForm } from '../utils'

export const getLocationData = async (id: string): Promise<ReturnType<typeof mapChancelleryLocationAPIDataToForm>> => {
  const { chancelleryLocation } = await request<GetChancelleryLocationQuery>(CHANCELLERIES_ENDPOINT, query, { id })
  return mapChancelleryLocationAPIDataToForm(chancelleryLocation)
}
